<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M27 15.9C27 17.6 26.9 19.3 26.6 21C26.5 21.4 26.5 21.7 26.4 22.1L26.3 22.5C25.9 24.2 25 25.8 23.8 27C23.2 27.6 22.6 28.1 21.8 28.6C19.5 30 16.9 30.4 14.3 29.7H14.2C11.7 28.9 9.2 27.8 7 26.4C4.8 25 2.8 23.4 1 21.5V3C1 1.9 1.9 1 3 1H23.2C24.6 3.6 25.6 6.3 26.2 9C26.6 11 26.9 13 27 15C27 15.3 27 15.6 27 15.9Z" fill="#00FF86"/>
    <path d="M37 40H3C1.3 40 0 38.7 0 37V3C0 1.3 1.3 0 3 0H37C38.7 0 40 1.3 40 3V37C40 38.7 38.7 40 37 40ZM2 13V37C2 37.6 2.4 38 3 38H37C37.6 38 38 37.6 38 37V11C38 10.4 37.6 10 37 10H3C2.4 10 2 10.4 2 11V13ZM2 7C2 7.6 2.4 8 3 8H37C37.6 8 38 7.6 38 7V3C38 2.4 37.6 2 37 2H3C2.4 2 2 2.4 2 3V7ZM33 34H13C12.4 34 12 33.6 12 33C12 32.4 12.4 32 13 32H33C33.6 32 34 32.4 34 33C34 33.6 33.6 34 33 34ZM7 34C6.4 34 6 33.6 6 33V15C6 14.4 6.4 14 7 14C7.6 14 8 14.4 8 15V33C8 33.6 7.6 34 7 34ZM33 28H13C12.4 28 12 27.6 12 27C12 26.4 12.4 26 13 26H33C33.6 26 34 26.4 34 27C34 27.6 33.6 28 33 28ZM33 22H13C12.4 22 12 21.6 12 21C12 20.4 12.4 20 13 20H33C33.6 20 34 20.4 34 21C34 21.6 33.6 22 33 22ZM33 16H13C12.4 16 12 15.6 12 15C12 14.4 12.4 14 13 14H33C33.6 14 34 14.4 34 15C34 15.6 33.6 16 33 16Z" fill="#0F0017"/>
  </svg>
</template>
