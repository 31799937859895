<template>
  <svg
    width="30"
    height="40"
    viewBox="0 0 30 40"
  >
    <path d="M18.4 1.4C17.3 1.2 16.2 1 15 1C7.3 1 1 7.3 1 15C1 18.6 4.1 24.2 7.4 29C8 29.2 8.6 29.5 9.2 29.6H9.3C11.9 30.2 14.5 29.8 16.8 28.5C19.1 27.1 20.7 25 21.3 22.4L21.4 22C21.8 20 22 17.9 22 15.8C22 11 20.8 6 18.4 1.4ZM15 20C12.2 20 10 17.8 10 15C10 12.2 12.2 10 15 10C17.8 10 20 12.2 20 15C20 17.8 17.8 20 15 20Z" fill="#00FF86"/>
    <path d="M15 40C14.7 40 14.4 39.9 14.2 39.7C14.2 39.7 10.2 35.1 6.5 29.6C2.2 23.2 0 18.3 0 15C0 6.7 6.7 0 15 0C16.2 0 17.5 0.2 18.7 0.4C25.3 2.1 30 8.1 30 15C30 23 16.3 39 15.8 39.7C15.6 39.9 15.3 40 15 40ZM15 2C7.8 2 2 7.8 2 15C2 17.8 4.2 22.5 8.2 28.5C10.9 32.4 13.7 35.9 15 37.5C17.9 34 28 21.3 28 15C28 9 24 3.8 18.2 2.4C17.1 2.1 16.1 2 15 2ZM15 21C11.7 21 9 18.3 9 15C9 11.7 11.7 9 15 9C18.3 9 21 11.7 21 15C21 18.3 18.3 21 15 21ZM15 11C12.8 11 11 12.8 11 15C11 17.2 12.8 19 15 19C17.2 19 19 17.2 19 15C19 12.8 17.2 11 15 11Z" fill="#0F0017"/>
  </svg>
</template>
