<template>
  <svg
    width="32"
    height="36"
    viewBox="0 0 32 36"
  >
    <path d="M23 13.9C23 16 22.8 18.1 22.4 20.1L22.3 20.5C21.7 23.1 20.1 25.2 17.8 26.6C15.5 28 12.9 28.4 10.3 27.7H10.2C7.1 26.7 4.1 25.2 1.3 23.3C1.1 22.2 1 21.1 1 20C1 11.7 7.7 5 16 5C18.1 5 20.2 5.5 22 6.3C22.7 8.8 23 11.3 23 13.9Z" fill="#00FF86"/>
    <path d="M16 36C7.2 36 0 28.8 0 20C0 14.6 2.7 9.9 6.7 7L6 5.9C5.6 6.3 5 6.1 4.6 5.7C4.3 5.2 4.4 4.6 4.9 4.3L5.7 3.7L6.5 3.1C7 2.8 7.6 2.9 7.9 3.4C8.2 3.9 8.1 4.5 7.7 4.8L8.5 5.9C9.9 5.1 11.4 4.6 13.1 4.3V2H12.1C11.5 2 11.1 1.6 11.1 1C11.1 0.4 11.5 0 12.1 0H20.1C20.7 0 21.1 0.4 21.1 1C21.1 1.6 20.7 2 20.1 2H19.1V4.3C20.8 4.6 22.3 5.2 23.7 6L24.4 5C23.9 4.7 23.8 4.1 24.2 3.6C24.4 3.1 25 3 25.5 3.3L27.1 4.4C27.6 4.7 27.7 5.3 27.3 5.8C27 6.3 26.4 6.4 26 6.1L25.3 7C29.3 9.9 32 14.6 32 20C32 28.8 24.8 36 16 36ZM14.8 6.1C7.6 6.7 2 12.7 2 20C2 27.7 8.3 34 16 34C23.7 34 30 27.7 30 20C30 12.7 24.4 6.7 17.3 6.1C16.8 6 16.4 6 16 6C15.6 6 15.2 6 14.8 6.1ZM16 4C16.3 4 16.7 4 17 4V2H15V4C15.3 4 15.7 4 16 4ZM15 23H10C9.4 23 9 22.6 9 22C9 21.4 9.4 21 10 21H15C15.4 21 16 20.8 16 20V11C16 10.4 16.4 10 17 10C17.6 10 18 10.4 18 11V20C18 22.2 16.2 23 15 23Z" fill="#0F0017"/>
  </svg>
</template>
