<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M27 17C26.9 18.6 26.7 20.1 26.4 21.6L26.3 22C26.2 22.2 26.2 22.5 26.1 22.8C25.4 25 23.9 26.9 21.8 28.1C21.2 28.4 20.6 28.7 20 28.9C18.2 29.6 16.2 29.7 14.3 29.2H14.2C13.5 29 12.7 28.7 12 28.4C8.7 27.1 5.7 25.2 3 22.9C2.3 22.3 1.7 21.7 1 21V19C1 17.9 1.9 17 3 17H27Z" fill="#00FF86"/>
    <path d="M37 40H3C1.3 40 0 38.7 0 37V19C0 17.3 1.3 16 3 16H37C38.7 16 40 17.3 40 19V37C40 38.7 38.7 40 37 40ZM34 38H37C37.6 38 38 37.6 38 37V34C35.8 34 34 35.8 34 38ZM8 38H32C32 34.7 34.7 32 38 32V24C34.7 24 32 21.3 32 18H8C8 21.3 5.3 24 2 24V32C5.3 32 8 34.7 8 38ZM2 34V37C2 37.6 2.4 38 3 38H6C6 35.8 4.2 34 2 34ZM34 18C34 20.2 35.8 22 38 22V19C38 18.4 37.6 18 37 18H34ZM3 18C2.4 18 2 18.4 2 19V22C4.2 22 6 20.2 6 18H3ZM20 37C15 37 11 33 11 28C11 23 15 19 20 19C25 19 29 23 29 28C29 33 25 37 20 37ZM20 21C16.1 21 13 24.1 13 28C13 31.9 16.1 35 20 35C23.9 35 27 31.9 27 28C27 24.1 23.9 21 20 21ZM22 33H18C17.4 33 17 32.6 17 32C17 31.4 17.4 31 18 31H19V25.6L18.4 25.9C17.9 26.1 17.3 25.9 17.1 25.5C16.9 25 17.1 24.4 17.5 24.2L19.5 23.2C19.8 23 20.2 23.1 20.5 23.2C20.8 23.4 21 23.7 21 24.1V31.1H22C22.6 31.1 23 31.5 23 32.1C23 32.7 22.6 33 22 33ZM20 14C19.4 14 19 13.6 19 13V3.4L16.7 5.7C16.3 6.1 15.7 6.1 15.3 5.7C14.9 5.3 14.9 4.7 15.3 4.3L19.3 0.3C19.4 0.2 19.5 0.1 19.6 0.1C19.7 0 19.9 0 20 0C20.1 0 20.3 0 20.4 0.1C20.5 0.1 20.6 0.2 20.7 0.3L24.7 4.3C25.1 4.7 25.1 5.3 24.7 5.7C24.3 6.1 23.7 6.1 23.3 5.7L21 3.4V13C21 13.6 20.6 14 20 14Z" fill="#0F0017"/>
  </svg>
</template>
