<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      d="M7 7H2.75C2.33579 7 2 7.33579 2 7.75C2 8.16421 2.33579 8.5 2.75 8.5H7H8.5H12.75C13.1642 8.5 13.5 8.16421 13.5 7.75C13.5 7.33579 13.1642 7 12.75 7H8.5H7Z"
      fill="#0F0017"
    />
  </svg>
</template>
