<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path d="M2.5 14C1.11929 14 9.78513e-08 12.8807 2.18557e-07 11.5L1.00536e-06 2.5C1.12607e-06 1.11929 1.11929 -1.12607e-06 2.5 -1.00536e-06L11.5 -2.18557e-07C12.8807 -9.78513e-08 14 1.11929 14 2.5L14 11.5C14 12.8807 12.8807 14 11.5 14L2.5 14ZM2.5 13L11.5 13C12.3284 13 13 12.3284 13 11.5L13 2.5C13 1.67157 12.3284 1 11.5 1L2.5 0.999999C1.67157 0.999999 1 1.67157 1 2.5L1 11.5C1 12.3284 1.67157 13 2.5 13ZM3.5 10C3.22386 10 3 9.77614 3 9.5C3 9.25454 3.17688 9.05039 3.41013 9.00805L3.5 9L10.5 9C10.7761 9 11 9.22386 11 9.5C11 9.74546 10.8231 9.94961 10.5899 9.99194L10.5 10L3.5 10Z" />
    <path d="M5.68248e-07 7.5C5.44107e-07 7.77614 0.223858 8 0.500001 8L13.5 8L13.5899 7.99194C13.8231 7.94961 14 7.74546 14 7.5C14 7.22386 13.7761 7 13.5 7L0.500001 7L0.410124 7.00805C0.176875 7.05039 5.89707e-07 7.25454 5.68248e-07 7.5Z" />
    <path d="M3 11.5C3 11.7761 3.22386 12 3.5 12L10.5 12L10.5899 11.9919C10.8231 11.9496 11 11.7455 11 11.5C11 11.2239 10.7761 11 10.5 11L3.5 11L3.41013 11.0081C3.17688 11.0504 3 11.2545 3 11.5Z" />
  </svg>
</template>
