<template>
  <svg
    width="40"
    height="34"
    viewBox="0 0 40 34"
  >
    <path d="M39 21H1C0.45 21 0 21.45 0 22C0 22.55 0.45 23 1 23C1.03 26.18 1.32 30.74 4.29 33.71L4.58 34H4.99C6.15 34 7.03 33.71 7.81 33.45C8.53 33.21 9.16 33 9.99 33C10.75 33 12.08 33.17 13.61 33.36C15.83 33.64 18.58 34 20.99 34C24.74 34 35.65 26.58 37.71 23H38.99C39.54 23 39.99 22.55 39.99 22C39.99 21.45 39.54 21 38.99 21H39ZM26.7 23.53C26.67 23.35 26.62 23.17 26.56 23H27.76L26.7 23.53ZM7.18 31.55C6.58 31.75 6.05 31.93 5.41 31.98C3.27 29.54 3.03 25.74 3 23H5.03C5.16 24.99 5.76 28.28 8.05 31.28C7.74 31.37 7.45 31.46 7.18 31.55ZM21 32C18.72 32 16.03 31.66 13.87 31.38C12.51 31.21 11.33 31.06 10.45 31.02C7.85 28.25 7.19 24.85 7.04 23H23.75C24.3 23 24.75 23.45 24.75 24C24.75 24.55 24.3 25 23.75 25H17C16.45 25 16 25.45 16 26C16 26.55 16.45 27 17 27H24C24.83 27 25.51 26.66 26 26.12L32.24 23H35.3C32.64 25.87 23.94 32 21 32Z" fill="#0F0017"/>
    <path d="M3 19H37C37.55 19 38 18.55 38 18C38 17.45 37.55 17 37 17H35.97C35.73 9.64 30.17 3.56 22.9 2.27C22.57 0.97 21.4 0 20 0C18.6 0 17.43 0.97 17.1 2.27C9.82 3.57 4.27 9.64 4.03 17H3C2.45 17 2 17.45 2 18C2 18.55 2.45 19 3 19ZM20 4C27.54 4 33.7 9.79 33.97 17H6.03C6.31 9.79 12.46 4 20 4Z" fill="#0F0017"/>
    <path d="M12.29 23H20.63C18.59 23.96 16.31 24.18 14.09 23.64L14 23.61C13.42 23.42 12.85 23.22 12.29 23Z" fill="#00FF86"/>
    <path d="M26.5 5.54C26.69 6.96 26.79 8.38 26.79 9.8C26.79 11.89 26.58 13.96 26.17 16L26.08 16.39V16.42C26.03 16.61 25.98 16.81 25.92 17H6.03003C6.30003 9.79 12.46 4 20 4C22.34 4 24.56 4.56 26.5 5.54Z" fill="#00FF86"/>
  </svg>
</template>
