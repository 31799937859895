<template>
  <svg
    width="24"
    height="40"
    viewBox="0 0 24 40"
  >
    <path d="M19 15.9C19 18 18.8 20.1 18.4 22.1L18.3 22.5C17.7 25.1 16.1 27.2 13.8 28.6C11.5 30 8.9 30.4 6.3 29.7H6.2C4.4 29.1 2.7 28.4 1 27.5V3C1 1.9 1.9 1 3 1H15.2C17.8 5.7 19 10.8 19 15.9Z" fill="#00FF86"/>
    <path d="M21 40H3C1.3 40 0 38.7 0 37V3C0 1.3 1.3 0 3 0H21C22.7 0 24 1.3 24 3V37C24 38.7 22.7 40 21 40ZM3 2C2.4 2 2 2.4 2 3V37C2 37.6 2.4 38 3 38H21C21.6 38 22 37.6 22 37V3C22 2.4 21.6 2 21 2H3ZM15 36H9C8.4 36 8 35.6 8 35C8 34.4 8.4 34 9 34H15C15.6 34 16 34.4 16 35C16 35.6 15.6 36 15 36Z" fill="#0F0017"/>
  </svg>
</template>
