<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path d="M9.9 0H2.1C0.940202 0 0 0.940202 0 2.1V9.9C0 11.0598 0.940202 12 2.1 12H9.9C11.0598 12 12 11.0598 12 9.9V2.1C12 0.940202 11.0598 0 9.9 0Z" fill="#0072B1"/>
    <path d="M3.37879 4.41382H1.68567V10.5488H3.37879V4.41382Z" fill="white"/>
    <path d="M9.03753 4.41368C7.78878 3.93743 6.88503 4.74368 6.49878 5.19368V4.41368H4.81128V10.5487H6.49878V7.31243C6.48917 6.90225 6.63906 6.50434 6.9169 6.20243C6.99621 6.10908 7.09393 6.03308 7.20394 5.97921C7.31396 5.92533 7.4339 5.89473 7.55628 5.88931C7.69315 5.88668 7.82914 5.91174 7.95608 5.96297C8.08303 6.0142 8.19832 6.09055 8.29503 6.18743C8.50474 6.4066 8.61932 6.69977 8.61378 7.00306V10.5487H10.5188V6.74993C10.5188 6.74993 10.7307 5.04743 9.03753 4.41368Z" fill="white"/>
    <path d="M2.53125 3.56617C3.1153 3.56617 3.58875 3.09271 3.58875 2.50867C3.58875 1.92463 3.1153 1.45117 2.53125 1.45117C1.94721 1.45117 1.47375 1.92463 1.47375 2.50867C1.47375 3.09271 1.94721 3.56617 2.53125 3.56617Z" fill="white"/>
  </svg>
</template>
