import { default as _91_46_46_46slug_93asSdIWWerQMeta } from "/opt/build/repo/apps/spa/pages/[...slug].vue?macro=true";
import { default as index82lZJgnB1AMeta } from "/opt/build/repo/apps/spa/pages/404/index.vue?macro=true";
import { default as aanleveren_45belastingdienstKifZhBHV3zMeta } from "/opt/build/repo/apps/spa/pages/aanleveren-belastingdienst.vue?macro=true";
import { default as indexgWu3oR8uVyMeta } from "/opt/build/repo/apps/spa/pages/admin/client/invoices/index.vue?macro=true";
import { default as indexlekkSp2i1WMeta } from "/opt/build/repo/apps/spa/pages/admin/contractor/invoices/index.vue?macro=true";
import { default as callbackwe3WggBFIdMeta } from "/opt/build/repo/apps/spa/pages/auth/callback.vue?macro=true";
import { default as complete_45your_45accountzCfN3PYnCAMeta } from "/opt/build/repo/apps/spa/pages/complete-your-account.vue?macro=true";
import { default as indexD1u7p859cYMeta } from "/opt/build/repo/apps/spa/pages/dashboard/client/invoices/index.vue?macro=true";
import { default as indexCK9LFF530yMeta } from "/opt/build/repo/apps/spa/pages/dashboard/client/jobs/[...slug]/index.vue?macro=true";
import { default as createkvB6nbw4f6Meta } from "/opt/build/repo/apps/spa/pages/dashboard/client/jobs/[...slug]/shifts/create.vue?macro=true";
import { default as new_45job_45template0BHMyaPCEWMeta } from "/opt/build/repo/apps/spa/pages/dashboard/client/jobs/new-job-template.vue?macro=true";
import { default as notification_45settingsaMCng9V71XMeta } from "/opt/build/repo/apps/spa/pages/dashboard/client/notification-settings.vue?macro=true";
import { default as statisticsUWUg2XH4bzMeta } from "/opt/build/repo/apps/spa/pages/dashboard/client/statistics.vue?macro=true";
import { default as indexddd96WxDHzMeta } from "/opt/build/repo/apps/spa/pages/dashboard/contractor/invoices/index.vue?macro=true";
import { default as indexsnYoUl7GCbMeta } from "/opt/build/repo/apps/spa/pages/dashboard/index.vue?macro=true";
import { default as indexfS1JU2zqyeMeta } from "/opt/build/repo/apps/spa/pages/dashboard/planning/index.vue?macro=true";
import { default as _91_46_46_46slug_93U9fWSYuuCfMeta } from "/opt/build/repo/apps/spa/pages/freeflexer/complete-profile/[...slug].vue?macro=true";
import { default as insurancesYtO9sgse3gMeta } from "/opt/build/repo/apps/spa/pages/freeflexer/insurances.vue?macro=true";
import { default as company_45identification_45numberkDfNd7okq3Meta } from "/opt/build/repo/apps/spa/pages/freeflexer/onboarding/company-identification-number.vue?macro=true";
import { default as profileuw0YfPk0w6Meta } from "/opt/build/repo/apps/spa/pages/freeflexer/profile.vue?macro=true";
import { default as indexEcK1QlptJPMeta } from "/opt/build/repo/apps/spa/pages/get-started/index.vue?macro=true";
import { default as indexigIsVyE8j8Meta } from "/opt/build/repo/apps/spa/pages/index.vue?macro=true";
import { default as indexQcmSlNg2CoMeta } from "/opt/build/repo/apps/spa/pages/login/index.vue?macro=true";
import { default as logoutQjushKSqWcMeta } from "/opt/build/repo/apps/spa/pages/logout.vue?macro=true";
import { default as platform_45guideKCFrKkPKufMeta } from "/opt/build/repo/apps/spa/pages/platform-guide.vue?macro=true";
import { default as account_45activated42FlxQ5HWGMeta } from "/opt/build/repo/apps/spa/pages/signup/account-activated.vue?macro=true";
import { default as activate_45accountdWVZ02OVISMeta } from "/opt/build/repo/apps/spa/pages/signup/activate-account.vue?macro=true";
import { default as already_45activatediNvt9EpHgyMeta } from "/opt/build/repo/apps/spa/pages/signup/already-activated.vue?macro=true";
import { default as indexbbgz6IbXwJMeta } from "/opt/build/repo/apps/spa/pages/signup/index.vue?macro=true";
import { default as terms_45and_45conditionsLWFMTxoY0GMeta } from "/opt/build/repo/apps/spa/pages/terms-and-conditions.vue?macro=true";
import { default as nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4614OtwfUB8zamMeta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.9_@unocss+reset@_z23zqksrrrk4jctemxs42yksfq/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4614OtwfUB8zam } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.14.9_@unocss+reset@_z23zqksrrrk4jctemxs42yksfq/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91_46_46_46slug_93asSdIWWerQMeta?.name ?? "slug",
    path: _91_46_46_46slug_93asSdIWWerQMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93asSdIWWerQMeta || {},
    alias: _91_46_46_46slug_93asSdIWWerQMeta?.alias || [],
    redirect: _91_46_46_46slug_93asSdIWWerQMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index82lZJgnB1AMeta?.name ?? "404",
    path: index82lZJgnB1AMeta?.path ?? "/404",
    meta: index82lZJgnB1AMeta || {},
    alias: index82lZJgnB1AMeta?.alias || [],
    redirect: index82lZJgnB1AMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/404/index.vue").then(m => m.default || m)
  },
  {
    name: aanleveren_45belastingdienstKifZhBHV3zMeta?.name ?? "aanleveren-belastingdienst",
    path: aanleveren_45belastingdienstKifZhBHV3zMeta?.path ?? "/aanleveren-belastingdienst",
    meta: aanleveren_45belastingdienstKifZhBHV3zMeta || {},
    alias: aanleveren_45belastingdienstKifZhBHV3zMeta?.alias || [],
    redirect: aanleveren_45belastingdienstKifZhBHV3zMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/aanleveren-belastingdienst.vue").then(m => m.default || m)
  },
  {
    name: indexgWu3oR8uVyMeta?.name ?? "admin-client-invoices",
    path: indexgWu3oR8uVyMeta?.path ?? "/admin/client/invoices",
    meta: indexgWu3oR8uVyMeta || {},
    alias: indexgWu3oR8uVyMeta?.alias || [],
    redirect: indexgWu3oR8uVyMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/admin/client/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: indexlekkSp2i1WMeta?.name ?? "admin-contractor-invoices",
    path: indexlekkSp2i1WMeta?.path ?? "/admin/contractor/invoices",
    meta: indexlekkSp2i1WMeta || {},
    alias: indexlekkSp2i1WMeta?.alias || [],
    redirect: indexlekkSp2i1WMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/admin/contractor/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: callbackwe3WggBFIdMeta?.name ?? "auth-callback",
    path: callbackwe3WggBFIdMeta?.path ?? "/auth/callback",
    meta: callbackwe3WggBFIdMeta || {},
    alias: callbackwe3WggBFIdMeta?.alias || [],
    redirect: callbackwe3WggBFIdMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/auth/callback.vue").then(m => m.default || m)
  },
  {
    name: complete_45your_45accountzCfN3PYnCAMeta?.name ?? "complete-your-account",
    path: complete_45your_45accountzCfN3PYnCAMeta?.path ?? "/complete-your-account",
    meta: complete_45your_45accountzCfN3PYnCAMeta || {},
    alias: complete_45your_45accountzCfN3PYnCAMeta?.alias || [],
    redirect: complete_45your_45accountzCfN3PYnCAMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/complete-your-account.vue").then(m => m.default || m)
  },
  {
    name: indexD1u7p859cYMeta?.name ?? "dashboard-client-invoices",
    path: indexD1u7p859cYMeta?.path ?? "/dashboard/client/invoices",
    meta: indexD1u7p859cYMeta || {},
    alias: indexD1u7p859cYMeta?.alias || [],
    redirect: indexD1u7p859cYMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/client/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: indexCK9LFF530yMeta?.name ?? "dashboard-client-jobs-slug",
    path: indexCK9LFF530yMeta?.path ?? "/dashboard/client/jobs/:slug(.*)*",
    meta: indexCK9LFF530yMeta || {},
    alias: indexCK9LFF530yMeta?.alias || [],
    redirect: indexCK9LFF530yMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/client/jobs/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: createkvB6nbw4f6Meta?.name ?? "dashboard-client-jobs-slug-shifts-create",
    path: createkvB6nbw4f6Meta?.path ?? "/dashboard/client/jobs/:slug(.*)*/shifts/create",
    meta: createkvB6nbw4f6Meta || {},
    alias: createkvB6nbw4f6Meta?.alias || [],
    redirect: createkvB6nbw4f6Meta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/client/jobs/[...slug]/shifts/create.vue").then(m => m.default || m)
  },
  {
    name: new_45job_45template0BHMyaPCEWMeta?.name ?? "dashboard-client-jobs-new-job-template",
    path: new_45job_45template0BHMyaPCEWMeta?.path ?? "/dashboard/client/jobs/new-job-template",
    meta: new_45job_45template0BHMyaPCEWMeta || {},
    alias: new_45job_45template0BHMyaPCEWMeta?.alias || [],
    redirect: new_45job_45template0BHMyaPCEWMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/client/jobs/new-job-template.vue").then(m => m.default || m)
  },
  {
    name: notification_45settingsaMCng9V71XMeta?.name ?? "dashboard-client-notification-settings",
    path: notification_45settingsaMCng9V71XMeta?.path ?? "/dashboard/client/notification-settings",
    meta: notification_45settingsaMCng9V71XMeta || {},
    alias: notification_45settingsaMCng9V71XMeta?.alias || [],
    redirect: notification_45settingsaMCng9V71XMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/client/notification-settings.vue").then(m => m.default || m)
  },
  {
    name: statisticsUWUg2XH4bzMeta?.name ?? "dashboard-client-statistics",
    path: statisticsUWUg2XH4bzMeta?.path ?? "/dashboard/client/statistics",
    meta: statisticsUWUg2XH4bzMeta || {},
    alias: statisticsUWUg2XH4bzMeta?.alias || [],
    redirect: statisticsUWUg2XH4bzMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/client/statistics.vue").then(m => m.default || m)
  },
  {
    name: indexddd96WxDHzMeta?.name ?? "dashboard-contractor-invoices",
    path: indexddd96WxDHzMeta?.path ?? "/dashboard/contractor/invoices",
    meta: indexddd96WxDHzMeta || {},
    alias: indexddd96WxDHzMeta?.alias || [],
    redirect: indexddd96WxDHzMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/contractor/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: indexsnYoUl7GCbMeta?.name ?? "dashboard",
    path: indexsnYoUl7GCbMeta?.path ?? "/dashboard",
    meta: indexsnYoUl7GCbMeta || {},
    alias: indexsnYoUl7GCbMeta?.alias || [],
    redirect: indexsnYoUl7GCbMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexfS1JU2zqyeMeta?.name ?? "dashboard-planning",
    path: indexfS1JU2zqyeMeta?.path ?? "/dashboard/planning",
    meta: indexfS1JU2zqyeMeta || {},
    alias: indexfS1JU2zqyeMeta?.alias || [],
    redirect: indexfS1JU2zqyeMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/dashboard/planning/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93U9fWSYuuCfMeta?.name ?? "freeflexer-complete-profile-slug",
    path: _91_46_46_46slug_93U9fWSYuuCfMeta?.path ?? "/freeflexer/complete-profile/:slug(.*)*",
    meta: _91_46_46_46slug_93U9fWSYuuCfMeta || {},
    alias: _91_46_46_46slug_93U9fWSYuuCfMeta?.alias || [],
    redirect: _91_46_46_46slug_93U9fWSYuuCfMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/freeflexer/complete-profile/[...slug].vue").then(m => m.default || m)
  },
  {
    name: insurancesYtO9sgse3gMeta?.name ?? "freeflexer-insurances",
    path: insurancesYtO9sgse3gMeta?.path ?? "/freeflexer/insurances",
    meta: insurancesYtO9sgse3gMeta || {},
    alias: insurancesYtO9sgse3gMeta?.alias || [],
    redirect: insurancesYtO9sgse3gMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/freeflexer/insurances.vue").then(m => m.default || m)
  },
  {
    name: company_45identification_45numberkDfNd7okq3Meta?.name ?? "freeflexer-onboarding-company-identification-number",
    path: company_45identification_45numberkDfNd7okq3Meta?.path ?? "/freeflexer/onboarding/company-identification-number",
    meta: company_45identification_45numberkDfNd7okq3Meta || {},
    alias: company_45identification_45numberkDfNd7okq3Meta?.alias || [],
    redirect: company_45identification_45numberkDfNd7okq3Meta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/freeflexer/onboarding/company-identification-number.vue").then(m => m.default || m)
  },
  {
    name: profileuw0YfPk0w6Meta?.name ?? "freeflexer-profile",
    path: profileuw0YfPk0w6Meta?.path ?? "/freeflexer/profile",
    meta: profileuw0YfPk0w6Meta || {},
    alias: profileuw0YfPk0w6Meta?.alias || [],
    redirect: profileuw0YfPk0w6Meta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/freeflexer/profile.vue").then(m => m.default || m)
  },
  {
    name: indexEcK1QlptJPMeta?.name ?? "get-started",
    path: indexEcK1QlptJPMeta?.path ?? "/get-started",
    meta: indexEcK1QlptJPMeta || {},
    alias: indexEcK1QlptJPMeta?.alias || [],
    redirect: indexEcK1QlptJPMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/get-started/index.vue").then(m => m.default || m)
  },
  {
    name: indexigIsVyE8j8Meta?.name ?? "index",
    path: indexigIsVyE8j8Meta?.path ?? "/",
    meta: indexigIsVyE8j8Meta || {},
    alias: indexigIsVyE8j8Meta?.alias || [],
    redirect: indexigIsVyE8j8Meta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexQcmSlNg2CoMeta?.name ?? "login",
    path: indexQcmSlNg2CoMeta?.path ?? "/login",
    meta: indexQcmSlNg2CoMeta || {},
    alias: indexQcmSlNg2CoMeta?.alias || [],
    redirect: indexQcmSlNg2CoMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: logoutQjushKSqWcMeta?.name ?? "logout",
    path: logoutQjushKSqWcMeta?.path ?? "/logout",
    meta: logoutQjushKSqWcMeta || {},
    alias: logoutQjushKSqWcMeta?.alias || [],
    redirect: logoutQjushKSqWcMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: platform_45guideKCFrKkPKufMeta?.name ?? "platform-guide",
    path: platform_45guideKCFrKkPKufMeta?.path ?? "/platform-guide",
    meta: platform_45guideKCFrKkPKufMeta || {},
    alias: platform_45guideKCFrKkPKufMeta?.alias || [],
    redirect: platform_45guideKCFrKkPKufMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/platform-guide.vue").then(m => m.default || m)
  },
  {
    name: account_45activated42FlxQ5HWGMeta?.name ?? "signup-account-activated",
    path: account_45activated42FlxQ5HWGMeta?.path ?? "/signup/account-activated",
    meta: account_45activated42FlxQ5HWGMeta || {},
    alias: account_45activated42FlxQ5HWGMeta?.alias || [],
    redirect: account_45activated42FlxQ5HWGMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/signup/account-activated.vue").then(m => m.default || m)
  },
  {
    name: activate_45accountdWVZ02OVISMeta?.name ?? "signup-activate-account",
    path: activate_45accountdWVZ02OVISMeta?.path ?? "/signup/activate-account",
    meta: activate_45accountdWVZ02OVISMeta || {},
    alias: activate_45accountdWVZ02OVISMeta?.alias || [],
    redirect: activate_45accountdWVZ02OVISMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/signup/activate-account.vue").then(m => m.default || m)
  },
  {
    name: already_45activatediNvt9EpHgyMeta?.name ?? "signup-already-activated",
    path: already_45activatediNvt9EpHgyMeta?.path ?? "/signup/already-activated",
    meta: already_45activatediNvt9EpHgyMeta || {},
    alias: already_45activatediNvt9EpHgyMeta?.alias || [],
    redirect: already_45activatediNvt9EpHgyMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/signup/already-activated.vue").then(m => m.default || m)
  },
  {
    name: indexbbgz6IbXwJMeta?.name ?? "signup",
    path: indexbbgz6IbXwJMeta?.path ?? "/signup",
    meta: indexbbgz6IbXwJMeta || {},
    alias: indexbbgz6IbXwJMeta?.alias || [],
    redirect: indexbbgz6IbXwJMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditionsLWFMTxoY0GMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditionsLWFMTxoY0GMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditionsLWFMTxoY0GMeta || {},
    alias: terms_45and_45conditionsLWFMTxoY0GMeta?.alias || [],
    redirect: terms_45and_45conditionsLWFMTxoY0GMeta?.redirect,
    component: () => import("/opt/build/repo/apps/spa/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4614OtwfUB8zamMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4614OtwfUB8zamMeta?.path ?? "/fr-fr/inscription",
    meta: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4614OtwfUB8zamMeta || {},
    alias: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4614OtwfUB8zamMeta?.alias || [],
    redirect: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4614OtwfUB8zamMeta?.redirect,
    component: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4614OtwfUB8zam
  }
]