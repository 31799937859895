<template>
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
  >
    <path d="M4.22176 12.4567C1.55468 12.153 0 10.3026 0 8.5V8C0 7.17157 0.671573 6.5 1.5 6.5H3.59971C3.43777 6.81679 3.30564 7.15136 3.20703 7.5H1.5C1.22386 7.5 1 7.72386 1 8V8.5C1 9.62813 1.88187 10.833 3.50235 11.2996C3.69426 11.716 3.93668 12.1043 4.22176 12.4567Z" fill="#0F0017"/>
    <path d="M7.62596 3.56907C7.70657 3.31036 7.75 3.03525 7.75 2.75C7.75 1.23122 6.51878 0 5 0C3.48122 0 2.25 1.23122 2.25 2.75C2.25 4.03662 3.13357 5.11687 4.32704 5.41706C4.64202 5.05055 5.00446 4.726 5.40482 4.45294C5.27488 4.48371 5.13934 4.5 5 4.5C4.0335 4.5 3.25 3.7165 3.25 2.75C3.25 1.7835 4.0335 1 5 1C5.9665 1 6.75 1.7835 6.75 2.75C6.75 3.23141 6.55561 3.66743 6.24104 3.98382C6.67558 3.78783 7.14016 3.64664 7.62596 3.56907Z" fill="#0F0017"/>
    <path d="M13 9C13 11.4853 10.9853 13.5 8.5 13.5C6.01472 13.5 4 11.4853 4 9C4 6.51472 6.01472 4.5 8.5 4.5C10.9853 4.5 13 6.51472 13 9ZM10.3536 7.85355C10.5488 7.65829 10.5488 7.34171 10.3536 7.14645C10.1583 6.95118 9.84171 6.95118 9.64645 7.14645L8.5 8.29289L7.35355 7.14645C7.15829 6.95118 6.84171 6.95118 6.64645 7.14645C6.45118 7.34171 6.45118 7.65829 6.64645 7.85355L7.79289 9L6.64645 10.1464C6.45118 10.3417 6.45118 10.6583 6.64645 10.8536C6.84171 11.0488 7.15829 11.0488 7.35355 10.8536L8.5 9.70711L9.64645 10.8536C9.84171 11.0488 10.1583 11.0488 10.3536 10.8536C10.5488 10.6583 10.5488 10.3417 10.3536 10.1464L9.20711 9L10.3536 7.85355Z" fill="#0F0017"/>
  </svg>
</template>
