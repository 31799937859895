<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M23.5 1.7C22.4 1.2 21.2 1 20 1C16.7 1 13.8 2.6 12 5C11.7 5 11.4 5 11 5C5.5 5 1 9.5 1 15C1 17.8 2.5 21.6 4.5 24.6C7.1 26.6 9.9 28.1 12.8 29.2C14.1 28.3 15.6 26.7 16.9 24.7L17 24.6C18 25.4 19 25.9 19.9 25.9C21 25.9 22.6 24.7 24.2 22.8C25.1 21.8 25.9 20.7 26.7 19.5C26.9 18.3 26.9 17 26.9 15.8C27 11 25.9 6.2 23.5 1.7Z" fill="#00FF86"/>
    <path d="M30 40C29.4 40 29 39.6 29 39C29 37.3 29.7 36.5 30.2 35.8C30.7 35.3 31 35 31 34C31 32.9 30.6 32.3 30.1 31.5C29.6 30.7 29 29.8 29 28.3C27.1 27.8 25.3 25.8 24.2 24.4C23.1 25.5 22 26.3 21 26.7C21.1 27.4 21.4 27.8 21.8 28.4C22.3 29.2 23 30.2 23 32C23 33.7 22.4 34.9 21.9 35.9C21.4 36.9 21 37.7 21 39C21 39.6 20.6 40 20 40C19.4 40 19 39.6 19 39C19 37.3 19.6 36.1 20.1 35.1C20.6 34.1 21 33.3 21 32C21 30.8 20.6 30.3 20.2 29.6C19.8 29 19.3 28.2 19.1 26.9C18.5 26.8 17.9 26.5 17.3 26.1C16 27.8 13.3 31 11.1 31C11.2 31.5 11.3 31.8 11.5 32.1C11.8 32.7 12.1 33.4 12.1 35C12.1 36.6 11.8 37.1 11.4 37.6C11.1 37.8 11 38 11 39C11 39.6 10.6 40 10 40C9.4 40 9 39.6 9 39C9 37.4 9.3 36.9 9.7 36.4C9.9 36.2 10 36 10 35C10 33.9 9.8 33.4 9.6 32.9C9.4 32.3 9.1 31.7 9 30.5C4.7 28.4 0 20.1 0 15C0 8.9 4.9 4 11 4C11.2 4 11.3 4 11.5 4C13.5 1.6 16.6 0 20 0C23.5 0 26.5 1.6 28.6 4.1C29 4 29.5 4 30 4C35.5 4 40 8.5 40 14C40 18.7 34.6 26.9 31 28.3C31.1 29.2 31.4 29.8 31.8 30.5C32.3 31.4 32.9 32.4 32.9 34C32.9 35.7 32.2 36.5 31.7 37.2C31.3 37.7 31 38 31 39C31 39.6 30.6 40 30 40ZM10.2 6C5.6 6.5 2 10.3 2 15C2 20.1 7.7 29 11 29C11.6 29 13.4 27.9 15.7 24.8C12.2 21.5 9 15.2 9 11C9 9.2 9.4 7.5 10.2 6ZM25.6 23C27.2 25.2 28.9 26.5 30 26.5C31.8 26.5 38 18.7 38 14C38 9.6 34.4 6 30 6C29.9 6 29.9 6 29.8 6C30.6 7.5 31 9.2 31 11C31 14.4 28.5 19.4 25.6 23ZM12.7 5.8C11.6 7.3 11 9.1 11 11C11 16.1 16.7 25 20 25C22 25 29 16.3 29 11C29 6 25 2 20 2C17.1 2 14.6 3.4 12.9 5.5C12.9 5.6 12.8 5.7 12.7 5.8Z" fill="#0F0017"/>
  </svg>
</template>
