<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M14.9239 10.6173C14.7691 10.2436 14.4045 10 14 10L10 10C9.59557 10 9.23093 10.2436 9.07615 10.6173C8.92137 10.991 9.00692 11.4211 9.29292 11.7071L11.2929 13.7071C11.6834 14.0976 12.3166 14.0976 12.7071 13.7071L14.7071 11.7071C14.9931 11.4211 15.0787 10.991 14.9239 10.6173Z"
      fill="#0F0017"
    />
  </svg>
</template>
