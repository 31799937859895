<template>
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
  >
    <path d="M20 10.5C20 4.978 15.523 0.5 10 0.5C4.477 0.5 0 4.978 0 10.5C0 16.022 4.477 20.5 10 20.5C10.2628 20.5 10.5232 20.4899 10.7809 20.47C10.3226 19.9863 9.93785 19.4321 9.64459 18.8255C5.2138 18.6387 1.667 14.9759 1.667 10.5C1.667 5.905 5.405 2.167 10 2.167C14.4759 2.167 18.1387 5.7138 18.3255 10.1446C18.9321 10.4379 19.4862 10.8226 19.9699 11.2809C19.9899 11.0232 20 10.7628 20 10.5Z" fill="#0F0017"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6109 19.8892C13.7588 22.0371 17.2412 22.0371 19.3891 19.8892C21.537 17.7413 21.537 14.2589 19.3891 12.111C17.2412 9.96313 13.7588 9.96313 11.6109 12.111C9.46303 14.2589 9.46303 17.7413 11.6109 19.8892ZM13.0251 17.7679L16.4142 14.3788H14.0858C13.8096 14.3788 13.5858 14.1549 13.5858 13.8788C13.5858 13.6026 13.8096 13.3788 14.0858 13.3788L17.6213 13.3788C17.8975 13.3788 18.1213 13.6026 18.1213 13.8788V17.4143C18.1213 17.6905 17.8975 17.9143 17.6213 17.9143C17.3452 17.9143 17.1213 17.6905 17.1213 17.4143V15.0859L13.7322 18.475C13.537 18.6702 13.2204 18.6702 13.0251 18.475C12.8299 18.2797 12.8299 17.9631 13.0251 17.7679Z" fill="#0F0017"/>
    <path d="M9.15556 14.58H7.39447C7.04058 14.58 6.8017 14.35 6.8017 13.9961C6.8017 13.6688 6.96095 13.483 7.29715 13.3414C7.88993 13.1468 8.41192 12.5628 8.41192 11.6869C8.41192 11.4569 8.35884 11.2003 8.2969 10.9438H7.31485C7.01403 10.9438 6.8017 10.7491 6.8017 10.4572C6.8017 10.1563 7.01403 9.9617 7.31485 9.9617H8.07572C7.98725 9.63435 7.92531 9.2893 7.92531 8.94425C7.92531 7.33403 9.04009 6.29004 10.898 6.29004C11.5174 6.29004 12.0217 6.36082 12.4198 6.53777C12.7471 6.67048 12.871 6.87397 12.871 7.13054C12.871 7.44905 12.6764 7.64369 12.3313 7.64369C12.2269 7.64369 12.116 7.61675 11.9788 7.5834C11.7555 7.52914 11.4623 7.45789 11.0131 7.45789C9.91598 7.45789 9.27896 8.05952 9.27896 8.9531C9.27896 9.32469 9.3409 9.65204 9.42052 9.9617H11.7385C12.0179 9.9617 12.2108 10.1201 12.2459 10.3719C11.9538 10.5412 11.676 10.7326 11.415 10.9438H9.64171C9.69479 11.1738 9.73018 11.4127 9.73018 11.6869C9.73018 12.377 9.44707 13.0141 9.01354 13.3945H9.54327C9.37779 13.7723 9.2472 14.1688 9.15556 14.58Z" fill="#0F0017"/>
  </svg>
</template>
