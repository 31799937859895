<template>
  <svg
    width="40"
    height="37"
    viewBox="0 0 40 37"
  >
    <path d="M31.23 4.77C30.2 4.77 29.2 4.94 28.25 5.28C26.84 2.17 23.72 0 20.09 0C16.46 0 13.35 2.09 11.92 5.3C10.98 4.97 9.99 4.78 8.95 4.78C4.02 4.77 0 8.79 0 13.73C0 17.71 2.68 21.32 6.36 22.46V37H33.81V22.46C37.55 21.32 40.17 17.78 40.17 13.73C40.17 8.79 36.15 4.77 31.22 4.77H31.23ZM8.36 35V30.53H31.82V35H8.36ZM32.63 20.7L31.83 20.86V28.53H8.36V20.86L7.56 20.7C4.39 20.05 2 17.05 2 13.73C2 9.9 5.12 6.77 8.95 6.77C11.75 6.77 14.27 8.44 15.36 11.02L17.2 10.24C16.47 8.52 15.24 7.12 13.73 6.17C14.83 3.65 17.28 2 20.08 2C23.91 2 27.03 5.12 27.03 8.95C27.03 10.34 26.62 11.69 25.84 12.84L27.5 13.96C28.5 12.48 29.03 10.75 29.03 8.95C29.03 8.34 28.97 7.75 28.85 7.18C29.6 6.91 30.39 6.77 31.21 6.77C35.04 6.77 38.16 9.89 38.16 13.73C38.16 17.06 35.77 20.06 32.61 20.7H32.63Z" fill="#0F0017"/>
    <path d="M25.86 12.84C26.64 11.69 27.05 10.34 27.05 8.95C27.05 5.12 23.93 2 20.1 2C17.3 2 14.84 3.65 13.75 6.17C15.26 7.13 16.49 8.52 17.22 10.24L15.38 11.02C14.29 8.44 11.77 6.77 8.97002 6.77C5.14002 6.77 2.02002 9.89 2.02002 13.73C2.02002 17.06 4.41002 20.06 7.58002 20.7L8.38002 20.86V23.56C10.5 24.84 12.76 25.85 15.11 26.59L15.2 26.63C17.77 27.26 20.43 26.86 22.7 25.49C24.96 24.13 26.55 21.97 27.19 19.41C27.19 19.41 27.19 19.4 27.19 19.39L27.28 19C27.66 17.14 27.86 15.24 27.89 13.34C27.77 13.55 27.67 13.76 27.53 13.96L25.87 12.84H25.86Z" fill="#00FF86"/>
  </svg>
</template>
