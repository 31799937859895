<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M24.58 26.15C23.84 27.1 22.93 27.92 21.86 28.57C19.59 29.94 14.27 29.68 14.27 29.68L19.92 18.42L24.58 26.16V26.15Z" fill="#00FF86"/>
    <path d="M27.0501 16.46C27.1501 11.22 25.9301 5.89 23.2601 1H15.0001L12.0001 5H1.08008V9H5.00008V24.94C6.51008 26.07 8.12008 27.05 9.79008 27.87C9.79008 27.87 8.71008 15.12 18.9101 14.12L27.0501 16.45V16.46Z" fill="#00FF86"/>
    <path d="M19.9999 35.08C16.6499 35.08 13.9199 32.35 13.9199 29C13.9199 26.02 18.5499 18.31 19.0799 17.44C19.4699 16.8 20.5299 16.8 20.9199 17.44C21.4499 18.31 26.0799 26.02 26.0799 29C26.0799 32.35 23.3499 35.08 19.9999 35.08ZM19.9999 20.13C18.2099 23.26 16.0799 27.49 16.0799 29.01C16.0799 31.17 17.8399 32.93 19.9999 32.93C22.1599 32.93 23.9199 31.17 23.9199 29.01C23.9199 27.49 21.7899 23.27 19.9999 20.13Z" fill="#0F0017"/>
    <path d="M39 10C39.55 10 40 9.55 40 9V6C40 4.9 39.1 4 38 4H28.5L25.8 0.4C25.61 0.15 25.31 0 25 0H15C14.69 0 14.39 0.15 14.2 0.4L11.5 4H2C0.9 4 0 4.9 0 6V9C0 9.55 0.45 10 1 10H4.03V34H2C0.9 34 0 34.9 0 36V39C0 39.55 0.45 40 1 40H39C39.55 40 40 39.55 40 39V36C40 34.9 39.1 34 38 34H35.97V10H39ZM15.5 2H24.5L26 4H14L15.5 2ZM38 36V38H2V36H38ZM28.95 34H11.05V23.54C11.05 18.86 15.06 15.05 20 15.05C24.94 15.05 28.95 18.86 28.95 23.54V34ZM20 12.95C13.91 12.95 8.95 17.7 8.95 23.54V34H5.97V10H34.03V34H31.05V23.54C31.05 17.7 26.09 12.95 20 12.95ZM2 8V6H38V8H2Z" fill="#0F0017"/>
    <path d="M19.9999 33.81C17.8999 33.81 16.1899 32.1 16.1899 30C16.1899 28.13 18.8599 25.06 19.3999 24.46C19.7099 24.12 20.2899 24.12 20.5999 24.46C21.1399 25.06 23.8099 28.13 23.8099 30C23.8099 32.1 22.0999 33.81 19.9999 33.81ZM19.9999 26.25C18.9499 27.54 17.8099 29.23 17.8099 30.01C17.8099 31.22 18.7899 32.2 19.9999 32.2C21.2099 32.2 22.1899 31.22 22.1899 30.01C22.1899 29.23 21.0499 27.54 19.9999 26.25Z" fill="#0F0017"/>
  </svg>
</template>
