<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
  >
    <path d="M6 0.5C9.31371 0.5 12 3.18629 12 6.5C12 9.81371 9.31371 12.5 6 12.5C2.68629 12.5 0 9.81371 0 6.5C0 3.18629 2.68629 0.5 6 0.5ZM6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.90125 2.12389L10.4012 10.1239L9.59875 10.8762L2.09875 2.87622L2.90125 2.12389Z" />
    <path d="M4.13934 9.46H7.84803C8.10707 9.46 8.28397 9.28941 8.28397 9.03669C8.28397 8.77765 8.10707 8.61338 7.84803 8.61338H5.29554C5.60512 8.34171 5.8073 7.88681 5.8073 7.394C5.8073 7.19814 5.78203 7.02756 5.74412 6.86329H7.24149C7.46263 6.86329 7.60794 6.72429 7.60794 6.5158C7.60794 6.30098 7.46263 6.16199 7.24149 6.16199H5.58617C5.52931 5.94085 5.48508 5.70709 5.48508 5.44173C5.48508 4.80361 5.93998 4.37398 6.72342 4.37398C7.24149 4.37398 7.46894 4.50666 7.6648 4.50666C7.91121 4.50666 8.0502 4.36766 8.0502 4.14021C8.0502 3.95699 7.96175 3.81168 7.72798 3.7169C7.44367 3.59054 7.08354 3.54 6.64128 3.54C5.31449 3.54 4.51842 4.28553 4.51842 5.43541C4.51842 5.68181 4.56265 5.92822 4.62583 6.16199H4.08248C3.86766 6.16199 3.71603 6.30098 3.71603 6.5158C3.71603 6.72429 3.86766 6.86329 4.08248 6.86329H4.78378C4.828 7.04651 4.86591 7.22973 4.86591 7.394C4.86591 8.01949 4.49315 8.43648 4.06984 8.57548C3.82975 8.67656 3.71603 8.80924 3.71603 9.04301C3.71603 9.29573 3.88662 9.46 4.13934 9.46Z" />
  </svg>
</template>
