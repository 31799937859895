<template>
  <svg
    width="29"
    height="40"
    viewBox="0 0 29 40"
  >
    <path d="M21 15.9C21 18 20.8 20.1 20.4 22.1L20.3 22.5C19.7 25.1 18.1 27.2 15.8 28.6C13.5 30 10.9 30.4 8.3 29.7H8.2C5.7 28.9 3.3 27.8 1 26.4V3C1 1.9 1.9 1 3 1H16.2C16.7 1 17.1 1.2 17.5 1.5C19.1 4.6 20.2 7.8 20.7 11C20.9 12.6 21 14.3 21 15.9Z" fill="#00FF86"/>
    <path d="M25 40H3C1.3 40 0 38.7 0 37V3C0 1.3 1.3 0 3 0H16.2C16.9 0 17.5 0.2 18.1 0.7C18.2 0.8 18.3 0.8 18.4 0.9L27.2 9.7C27.8 10.3 28.1 11 28.1 11.8V37C28 38.7 26.7 40 25 40ZM3 2C2.4 2 2 2.4 2 3V37C2 37.6 2.4 38 3 38H25C25.6 38 26 37.6 26 37V12H19C17.3 12 16 10.7 16 9V2H3ZM18 3.4V9C18 9.6 18.4 10 19 10H24.6L18 3.4Z" fill="#0F0017"/>
  </svg>
</template>
