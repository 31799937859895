<template>
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.88052 7.41237C9.65192 6.876 9.11987 6.5 8.5 6.5H4.75777C4.55127 6.80851 4.3819 7.14397 4.25606 7.5H8.5C8.77614 7.5 9 7.72386 9 8V8.29289L9.88052 7.41237ZM9.99271 8.7144L7.85355 10.8536C7.71051 10.9962 7.50214 11.0344 7.32421 10.9679C6.70698 11.2961 5.92759 11.5 5 11.5C4.91699 11.5 4.83517 11.4984 4.75454 11.4952C4.99951 11.8621 5.29698 12.1911 5.63622 12.4713C8.28282 12.2281 9.87289 10.4786 9.99271 8.7144ZM7.62596 3.56907C7.70657 3.31036 7.75 3.03525 7.75 2.75C7.75 1.23122 6.51878 0 5 0C3.48122 0 2.25 1.23122 2.25 2.75C2.25 4.03662 3.13357 5.11687 4.32704 5.41706C4.64202 5.05055 5.00446 4.726 5.40482 4.45294C5.27488 4.48371 5.13934 4.5 5 4.5C4.0335 4.5 3.25 3.7165 3.25 2.75C3.25 1.7835 4.0335 1 5 1C5.9665 1 6.75 1.7835 6.75 2.75C6.75 3.23141 6.55561 3.66743 6.24104 3.98382C6.67558 3.78783 7.14016 3.64664 7.62596 3.56907ZM6.79325 4.83495C6.51804 5.07187 6.1954 5.25524 5.84151 5.36886C6.1339 5.15443 6.45314 4.97446 6.79325 4.83495ZM3.59971 6.5H1.5C0.671573 6.5 0 7.17157 0 8V8.5C0 10.3026 1.55468 12.153 4.22176 12.4567C3.93668 12.1043 3.69426 11.716 3.50235 11.2996C1.88187 10.833 1 9.62813 1 8.5V8C1 7.72386 1.22386 7.5 1.5 7.5H3.20703C3.30564 7.15136 3.43777 6.81679 3.59971 6.5Z" />
    <path d="M8.5 13.5C6.01472 13.5 4 11.4853 4 9C4 6.51472 6.01472 4.5 8.5 4.5C10.9853 4.5 13 6.51472 13 9C13 11.4853 10.9853 13.5 8.5 13.5ZM10.8536 7.85355C11.0488 7.65829 11.0488 7.34171 10.8536 7.14645C10.6583 6.95118 10.3417 6.95118 10.1464 7.14645L7.5 9.79289L6.85355 9.14645C6.65829 8.95118 6.34171 8.95118 6.14645 9.14645C5.95118 9.34171 5.95118 9.65829 6.14645 9.85355L7.14645 10.8536C7.34171 11.0488 7.65829 11.0488 7.85355 10.8536L10.8536 7.85355Z" />
  </svg>
</template>
