<template>
  <svg
    width="30"
    height="40"
    viewBox="0 0 30 40"
  >
    <path d="M22.0001 15.9C22.0001 18 21.8001 20.1 21.4001 22.1L21.3001 22.5C20.7001 24.7 19.4001 26.7 17.6001 28C17.4001 28.2 17.1001 28.3 16.9001 28.5C15.7001 29.2 14.4001 29.7 13.1001 29.8C11.9001 30 10.6001 29.9 9.4001 29.6H9.3001C6.4001 28.7 3.6001 27.4 1.1001 25.6V15C1.1001 13.9 2.0001 13 3.1001 13H22.0001C22.0001 14 22.0001 14.9 22.0001 15.9Z" fill="#00FF86"/>
    <path d="M27 40H3C1.3 40 0 38.7 0 37V15C0 13.3 1.3 12 3 12H5V10C5 4.5 9.5 0 15 0C20.5 0 25 4.5 25 10V12H27C28.7 12 30 13.3 30 15V37C30 38.7 28.7 40 27 40ZM3 14C2.4 14 2 14.4 2 15V37C2 37.6 2.4 38 3 38H27C27.6 38 28 37.6 28 37V15C28 14.4 27.6 14 27 14H3ZM7 12H23V10C23 5.6 19.4 2 15 2C10.6 2 7 5.6 7 10V12ZM15 34C13.3 34 12 32.7 12 31V29C10.8 28.1 10 26.6 10 25C10 22.2 12.2 20 15 20C17.8 20 20 22.2 20 25C20 26.6 19.2 28.1 18 29V31C18 32.7 16.7 34 15 34ZM14 29.9V31C14 31.6 14.4 32 15 32C15.6 32 16 31.6 16 31V29.9C15.7 30 15.3 30 15 30C14.7 30 14.3 30 14 29.9ZM15 28C15.1 28 15.2 28 15.2 28C16.7 27.9 18 26.6 18 25C18 23.3 16.7 22 15 22C13.3 22 12 23.3 12 25C12 26.6 13.2 27.9 14.8 28C14.8 28 14.9 28 15 28Z" fill="#0F0017"/>
  </svg>
</template>
