<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M20.0001 1C21.7001 1 23.0001 2.3 23.0001 4C23.0001 5.7 21.7001 7 20.0001 7C18.3001 7 17.0001 5.7 17.0001 4C17.0001 2.3 18.3001 1 20.0001 1ZM11.0001 23C11.0001 24.8 10.0001 26.4 8.6001 27.3C6.5001 26.1 4.5001 24.7 2.6001 23H11.0001ZM20.0001 7L20.6001 12L22.4001 28.2C22.2001 28.3 22.0001 28.4 21.8001 28.6C20.5001 29.4 19.0001 29.9 17.5001 30L19.5001 12L20.0001 7Z" fill="#00FF86"/>
    <path d="M28 40H12C10.3 40 9 38.7 9 37V36C9 34.3 10.3 33 12 33H16.1L18.3 13H7.6L11.9 22.6C12 22.7 12 22.8 12 23C12 26.3 9.3 29 6 29C2.7 29 0 26.3 0 23C0 22.9 0 22.7 0.1 22.6L5.1 11.6C5.1 11.5 5.2 11.4 5.2 11.4C5.2 11.3 5.3 11.3 5.3 11.3C5.5 11.1 5.7 11 6 11H18.5L18.9 7.8C17.2 7.4 16 5.8 16 4C16 1.8 17.8 0 20 0C22.2 0 24 1.8 24 4C24 5.8 22.8 7.4 21.1 7.8L21.5 11H34C34.2 11 34.5 11.1 34.6 11.2C34.7 11.2 34.7 11.3 34.7 11.3C34.8 11.4 34.8 11.4 34.8 11.5L39.8 22.5C40 22.7 40 22.8 40 23C40 26.3 37.3 29 34 29C30.7 29 28 26.3 28 23C28 22.9 28 22.7 28.1 22.6L32.4 13H21.7L23.9 33H28C29.7 33 31 34.3 31 36V37C31 38.7 29.7 40 28 40ZM12 35C11.4 35 11 35.4 11 36V37C11 37.6 11.4 38 12 38H28C28.6 38 29 37.6 29 37V36C29 35.4 28.6 35 28 35H12ZM18.1 33H21.9L20 16.1L18.1 33ZM30.1 24C30.5 25.7 32.1 27 34 27C35.9 27 37.4 25.7 37.9 24H30.1ZM2.1 24C2.5 25.7 4.1 27 6 27C7.9 27 9.4 25.7 9.9 24H2.1ZM30.6 22H37.5L34 14.4L30.6 22ZM2.6 22H9.5L6 14.4L2.6 22ZM20 2C18.9 2 18 2.9 18 4C18 5.1 18.9 6 20 6C21.1 6 22 5.1 22 4C22 2.9 21.1 2 20 2Z" fill="#0F0017"/>
  </svg>
</template>
