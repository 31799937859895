<template>
  <svg
    width="28"
    height="40"
    viewBox="0 0 28 40"
  >
    <path d="M7 9H20.2C20.7 11.3 21 13.6 21 15.9C21 18 20.8 20.1 20.4 22.1L20.3 22.5C19.7 25.1 18.1 27.2 15.8 28.6C13.5 30 10.9 30.4 8.3 29.7H8.2C5.7 28.9 3.3 27.8 1 26.4V10C1 9.5 1.4 9 2 9H7Z" fill="#00FF86"/>
    <path d="M25 40H3C1.3 40 0 38.7 0 37V11C0 9.3 1.3 8 3 8H6C6 3.6 9.6 0 14 0C18.4 0 22 3.6 22 8H25C26.7 8 28 9.3 28 11V37C28 38.7 26.7 40 25 40ZM3 10C2.4 10 2 10.4 2 11V37C2 37.6 2.4 38 3 38H25C25.6 38 26 37.6 26 37V11C26 10.4 25.6 10 25 10H22V14C22 14.6 21.6 15 21 15C20.4 15 20 14.6 20 14V10H8V14C8 14.6 7.6 15 7 15C6.4 15 6 14.6 6 14V10H3ZM8 8H20C20 4.7 17.3 2 14 2C10.7 2 8 4.7 8 8Z" fill="#0F0017"/>
  </svg>
</template>
