<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
  >
    <path d="M10 0.5C15.523 0.5 20 4.978 20 10.5C20 16.022 15.523 20.5 10 20.5C4.477 20.5 0 16.022 0 10.5C0 4.978 4.477 0.5 10 0.5ZM10 2.167C5.405 2.167 1.667 5.905 1.667 10.5C1.667 15.095 5.405 18.833 10 18.833C14.595 18.833 18.333 15.095 18.333 10.5C18.333 5.905 14.595 2.167 10 2.167Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.60107 3.39899L17.6011 16.399L16.399 17.6011L3.39899 4.60107L4.60107 3.39899Z" />
    <path d="M7.39447 14.58H12.5879C12.9506 14.58 13.1984 14.3411 13.1984 13.9872C13.1984 13.6245 12.9506 13.3945 12.5879 13.3945H9.01354C9.44707 13.014 9.73018 12.377 9.73018 11.6869C9.73018 11.4126 9.69479 11.1738 9.64171 10.9437H11.7385C12.0482 10.9437 12.2517 10.7491 12.2517 10.4571C12.2517 10.1563 12.0482 9.96166 11.7385 9.96166H9.42052C9.3409 9.65201 9.27896 9.32465 9.27896 8.95306C9.27896 8.05948 9.91598 7.45785 11.0131 7.45785C11.7385 7.45785 12.057 7.64365 12.3313 7.64365C12.6764 7.64365 12.871 7.44901 12.871 7.1305C12.871 6.87393 12.7471 6.67044 12.4198 6.53773C12.0217 6.36078 11.5174 6.29 10.898 6.29C9.04008 6.29 7.92531 7.33399 7.92531 8.94422C7.92531 9.28926 7.98725 9.63431 8.07572 9.96166H7.31485C7.01403 9.96166 6.8017 10.1563 6.8017 10.4571C6.8017 10.7491 7.01403 10.9437 7.31485 10.9437H8.2969C8.35884 11.2003 8.41192 11.4569 8.41192 11.6869C8.41192 12.5628 7.88993 13.1467 7.29715 13.3414C6.96095 13.4829 6.8017 13.6687 6.8017 13.9961C6.8017 14.35 7.04058 14.58 7.39447 14.58Z" />
  </svg>
</template>
