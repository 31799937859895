<template>
  <svg
    width="40"
    height="39"
    viewBox="0 0 40 39"
  >
    <path d="M25.3499 27.2399C18.9299 32.7399 13.4799 29.1199 10.5599 27.1699C10.0999 26.8599 9.47994 26.9899 9.16994 27.4499C8.85994 27.9099 8.98994 28.5299 9.44994 28.8399C11.3499 30.0999 14.4899 32.1999 18.3599 32.1999C20.8699 32.1999 23.6799 31.3199 26.6599 28.7699C27.0799 28.4099 27.1299 27.7799 26.7699 27.3599C26.4099 26.9399 25.7799 26.8899 25.3599 27.2499L25.3499 27.2399Z" fill="#0F0017"/>
    <path d="M38.83 20.45C38.69 20.25 25.13 0 19 0H13C10.41 0 9.16 2.45 9.11 2.55C9.09 2.59 9.07 2.64 9.06 2.68C9.02 2.81 8.01 5.87 8.01 9C8.01 10.18 8.81 10.67 9.39 11.03C9.69 11.21 10 11.41 10.3 11.71C11.76 13.17 12.31 12.97 14.45 11.9C14.92 11.67 15.57 11.48 16.25 11.29C17.37 10.97 18.72 10.59 19.7 9.8C21.26 11.83 21.69 15.34 21.03 17.74C17.13 16.65 13.33 17.87 11.29 18.77C8.78 16.01 3.96 16.01 1 16.01C0.45 16.01 0 16.46 0 17.01V37.01C0 37.56 0.45 38.01 1 38.01H6C7.08 38.01 8.41 38.01 9.67 36.74C9.85 36.69 10.53 36.86 11.13 37.01C12.6 37.38 15.07 38.01 19 38.01C25.78 38.01 37.32 35.1 38.71 33.72C40.1 32.34 41.05 23.78 38.83 20.46V20.45ZM37.31 32.28C36.28 33.09 25.83 36.01 18.99 36.01C15.31 36.01 13.08 35.44 11.61 35.07C10.23 34.72 9.14 34.44 8.28 35.3C7.57 36.01 6.91 36.01 5.99 36.01H2V18C4.8 18.03 8.66 18.28 10.17 20.55C10.2 20.59 10.24 20.61 10.27 20.65C10.32 20.7 10.36 20.75 10.41 20.79C10.46 20.83 10.52 20.85 10.57 20.88C10.63 20.91 10.69 20.94 10.75 20.95C10.81 20.97 10.87 20.97 10.93 20.97C11 20.97 11.06 20.97 11.13 20.97C11.19 20.97 11.25 20.94 11.31 20.92C11.36 20.9 11.4 20.9 11.45 20.88C13.45 19.88 17.38 18.43 21.23 19.9C23.33 20.7 25.41 22.38 27.13 25.48C27.31 25.81 27.65 25.99 28.01 25.99C28.17 25.99 28.34 25.95 28.49 25.86C28.97 25.59 29.15 24.98 28.88 24.5C27.13 21.35 25.04 19.48 22.91 18.43C23.85 15.26 23.24 10.61 20.87 8.04C20.96 7.72 21.01 7.37 21.01 6.98C21.01 6.43 20.56 5.98 20.01 5.98C19.46 5.98 19.01 6.43 19.01 6.98C19.01 8.3 17.76 8.75 15.72 9.34C14.94 9.56 14.2 9.77 13.57 10.09C12.98 10.38 12.42 10.66 12.19 10.72C12.08 10.64 11.89 10.45 11.72 10.27C11.26 9.81 10.79 9.52 10.45 9.31C10.28 9.21 10.05 9.06 10.01 8.98C10.01 6.45 10.78 3.86 10.93 3.38C11.09 3.11 11.82 1.98 13.01 1.98H19.01C22.64 1.98 32.63 14.72 37.18 21.53C38.92 24.14 37.95 31.41 37.33 32.25L37.31 32.28Z" fill="#0F0017"/>
    <path d="M19 2H13C11.81 2 11.08 3.13 10.92 3.4C10.77 3.89 10 6.47 10 9C10.04 9.08 10.27 9.23 10.44 9.33C10.78 9.54 11.25 9.83 11.71 10.29C11.88 10.46 12.07 10.65 12.18 10.74C12.41 10.68 12.97 10.4 13.56 10.11C14.19 9.79 14.93 9.58 15.71 9.36C17.76 8.78 19 8.33 19 7C19 6.45 19.45 6 20 6C20.55 6 21 6.45 21 7C21 7.39 20.95 7.74 20.86 8.06C23.23 10.63 23.84 15.27 22.9 18.45C24.23 19.1 25.53 20.07 26.75 21.47C26.88 21.13 26.99 20.78 27.08 20.43C27.08 20.43 27.08 20.42 27.08 20.41L27.17 20.02C27.91 16.37 28 12.58 27.39 8.84C24 5 20.75 2.02 19 2.02V2Z" fill="#00FF86"/>
    <path d="M15 27.61L15.09 27.64C17.66 28.27 20.32 27.87 22.59 26.5C23.89 25.71 24.97 24.66 25.77 23.41C24.36 21.6 22.8 20.51 21.23 19.91C17.38 18.44 13.45 19.89 11.45 20.89C11.41 20.91 11.36 20.91 11.31 20.93C11.25 20.95 11.19 20.97 11.13 20.98C11.06 20.98 11 20.98 10.93 20.98C10.87 20.98 10.81 20.98 10.75 20.96C10.69 20.94 10.63 20.92 10.57 20.89C10.51 20.86 10.46 20.84 10.41 20.8C10.36 20.76 10.31 20.71 10.27 20.66C10.24 20.63 10.2 20.6 10.17 20.56C8.66 18.29 4.8 18.04 2 18.01V19.61C5.66 23.38 10.15 26.09 15 27.61Z" fill="#00FF86"/>
  </svg>
</template>
