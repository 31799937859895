<template>
  <svg
    width="30"
    height="40"
    viewBox="0 0 30 40"
  >
    <path d="M22 15.9C22 18 21.8 20.1 21.4 22.1L21.3 22.5C21.2 22.8 21.1 23.1 21 23.5C20.2 25.6 18.8 27.4 16.8 28.6C16.2 29 15.6 29.2 15 29.5C13.2 30.2 11.2 30.3 9.3 29.8H9.2C9.1 29.8 9.1 29.8 9 29.7C6.9 29 4.9 28.1 3 27V6H10C10 3.2 12.2 1 15 1C17.8 1 20 3.2 20 6H20.4C21.5 9.2 22 12.6 22 15.9Z" fill="#00FF86"/>
    <path d="M25 40H5C3.3 40 2 38.7 2 37V7H1C0.4 7 0 6.6 0 6C0 5.4 0.4 5 1 5H9.1C9.6 2.2 12 0 15 0C18 0 20.4 2.2 20.9 5H29C29.6 5 30 5.4 30 6C30 6.6 29.6 7 29 7H28V37C28 38.7 26.7 40 25 40ZM4 7V37C4 37.6 4.4 38 5 38H25C25.6 38 26 37.6 26 37V7H4ZM11.1 5H18.8C18.4 3.3 16.8 2 14.9 2C13 2 11.6 3.3 11.1 5ZM21 34C20.4 34 20 33.6 20 33V12C20 11.4 20.4 11 21 11C21.6 11 22 11.4 22 12V33C22 33.6 21.6 34 21 34ZM15 34C14.4 34 14 33.6 14 33V12C14 11.4 14.4 11 15 11C15.6 11 16 11.4 16 12V33C16 33.6 15.6 34 15 34ZM9 34C8.4 34 8 33.6 8 33V12C8 11.4 8.4 11 9 11C9.6 11 10 11.4 10 12V33C10 33.6 9.6 34 9 34Z" fill="#0F0017"/>
  </svg>
</template>
