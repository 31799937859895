<template>
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 6.5C5.5 4.84315 6.84315 3.5 8.5 3.5C10.1569 3.5 11.5 4.84315 11.5 6.5C11.5 6.85828 11.4372 7.20189 11.322 7.52042C10.7777 7.9776 10.3094 8.52239 9.93887 9.13307C9.5117 9.367 9.02138 9.5 8.5 9.5C6.84315 9.5 5.5 8.15685 5.5 6.5ZM8.5 5C7.67157 5 7 5.67157 7 6.5C7 7.32843 7.67157 8 8.5 8C9.32843 8 10 7.32843 10 6.5C10 5.67157 9.32843 5 8.5 5Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.17393 11H4.5V10.25C4.5 9.00736 3.49264 8 2.25 8H1.5V5H2.25C3.49264 5 4.5 3.99264 4.5 2.75V2H12.5V2.75C12.5 3.99264 13.5074 5 14.75 5H15.5V6C16.0163 6 16.5185 6.06019 17 6.17393V2.75C17 1.50736 15.9926 0.5 14.75 0.5H2.25C1.00736 0.5 0 1.50736 0 2.75V10.25C0 11.4926 1.00736 12.5 2.25 12.5H9C9 11.9837 9.06019 11.4815 9.17393 11ZM1.5 2.75C1.5 2.33579 1.83579 2 2.25 2H3V2.75C3 3.16421 2.66421 3.5 2.25 3.5H1.5V2.75ZM15.5 2.75V3.5H14.75C14.3358 3.5 14 3.16421 14 2.75V2H14.75C15.1642 2 15.5 2.33579 15.5 2.75ZM2.25 11C1.83579 11 1.5 10.6642 1.5 10.25V9.5H2.25C2.66421 9.5 3 9.83579 3 10.25V11H2.25Z" />
    <path d="M9.17394 14H2.40137C2.92008 14.8967 3.8896 15.5 5.00002 15.5H9.73222C9.48761 15.0307 9.29861 14.5278 9.17394 14Z" />
    <path d="M20 7.80959V5.50002C20 4.3896 19.3967 3.42008 18.5 2.90137V6.73222C19.051 7.01941 19.5558 7.38325 20 7.80959Z" />
    <path d="M15.5 7C18.5376 7 21 9.46243 21 12.5C21 15.5376 18.5376 18 15.5 18C12.4624 18 10 15.5376 10 12.5C10 9.46243 12.4624 7 15.5 7ZM13.0238 10.0241C12.8285 10.2194 12.8285 10.536 13.0238 10.7312L14.793 12.501L13.0264 14.2675C12.8311 14.4628 12.8311 14.7793 13.0264 14.9746C13.2217 15.1699 13.5382 15.1699 13.7335 14.9746L15.5 13.208L17.2694 14.9768C17.4647 15.1721 17.7812 15.1721 17.9765 14.9768C18.1718 14.7816 18.1718 14.465 17.9765 14.2697L16.208 12.501L17.9793 10.7313C18.1745 10.536 18.1745 10.2194 17.9793 10.0242C17.784 9.82892 17.4674 9.82892 17.2722 10.0242L15.501 11.794L13.7309 10.0241C13.5356 9.82888 13.2191 9.82888 13.0238 10.0241Z" />
  </svg>
</template>
