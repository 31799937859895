<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
  >
    <path d="M8 0.5C12.4183 0.5 16 4.08172 16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5ZM8 1.5C4.13401 1.5 1 4.63401 1 8.5C1 12.366 4.13401 15.5 8 15.5C11.866 15.5 15 12.366 15 8.5C15 4.63401 11.866 1.5 8 1.5Z" />
    <path d="M5.75897 12.0629H10.2257C10.5377 12.0629 10.7507 11.8574 10.7507 11.553C10.7507 11.241 10.5377 11.0432 10.2257 11.0432H7.15149C7.52435 10.716 7.76785 10.1681 7.76785 9.57459C7.76785 9.3387 7.73742 9.13325 7.69176 8.9354H9.49519C9.76151 8.9354 9.93653 8.76799 9.93653 8.51688C9.93653 8.25817 9.76151 8.09076 9.49519 8.09076H7.50153C7.43304 7.82443 7.37978 7.54288 7.37978 7.22329C7.37978 6.45474 7.92765 5.9373 8.87122 5.9373C9.49519 5.9373 9.76912 6.0971 10.005 6.0971C10.3018 6.0971 10.4692 5.92969 10.4692 5.65575C10.4692 5.43508 10.3627 5.26007 10.0811 5.14592C9.73869 4.99374 9.30495 4.93286 8.77229 4.93286C7.17432 4.93286 6.21554 5.83077 6.21554 7.21568C6.21554 7.51245 6.2688 7.80921 6.3449 8.09076H5.69049C5.43177 8.09076 5.24915 8.25817 5.24915 8.51688C5.24915 8.76799 5.43177 8.9354 5.69049 8.9354H6.53513C6.5884 9.15607 6.63405 9.37675 6.63405 9.57459C6.63405 10.3279 6.1851 10.8301 5.67527 10.9975C5.38611 11.1193 5.24915 11.2791 5.24915 11.5606C5.24915 11.865 5.4546 12.0629 5.75897 12.0629Z" />
  </svg>
</template>
