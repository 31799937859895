<template>
  <svg
    width="35"
    height="36"
    viewBox="0 0 35 36"
  >
    <path d="M25 11.9C25 13.2 24.9 14.5 24.8 15.7C24.7 16.5 24.6 17.3 24.4 18.1L24.3 18.5C23.7 21.1 22.1 23.2 19.8 24.6C19.5 24.8 19.3 24.9 19 25C16.9 26 14.6 26.3 12.3 25.7H12.2C11.6 25.5 10.9 25.3 10.3 25C8.8 24.4 7.4 23.7 6 22.9V21C6 14.4 11.4 9 18 9C20.5 9 22.9 9.8 24.9 11.2L25 11C25 11.1 25 11.2 25 11.2C25 11.4 25 11.7 25 11.9Z" fill="#00FF86"/>
    <path d="M30 36H6C5.4 36 5 35.6 5 35V31.8C3.8 31.4 3 30.3 3 29V27C3 25.7 3.8 24.6 5 24.2V21C5 13.8 10.8 8 18 8C20.2 8 22.3 8.5 24.2 9.6C24.1 9.4 24.1 9.2 24.1 9C24.1 8.5 24.3 8 24.7 7.6L27.3 5C28.1 4.2 29.4 4.2 30.1 5L33.7 8.6C34.1 9 34.3 9.5 34.3 10C34.3 10.5 34.1 11 33.7 11.4L31.1 14C30.7 14.4 30.1 14.6 29.5 14.6L29.4 14.7C30.5 16.6 31 18.8 31 21V24.2C32.2 24.6 33 25.7 33 27V29C33 30.3 32.2 31.4 31 31.8V35C31 35.6 30.6 36 30 36ZM7 34H29V32H7V34ZM6 26C5.4 26 5 26.4 5 27V29C5 29.6 5.4 30 6 30H30C30.6 30 31 29.6 31 29V27C31 26.4 30.6 26 30 26H6ZM7 24H29V21C29 19.3 28.6 17.7 27.9 16.2L26.7 17.4C27 18.2 27.3 19 27.5 19.8C27.6 20.3 27.3 20.9 26.7 21C26.2 21.1 25.6 20.8 25.5 20.2C24.8 16.7 22 13.9 18.5 13.2C18 13.1 17.6 12.6 17.7 12C17.8 11.5 18.3 11.1 18.9 11.2C20 11.4 21 11.8 22 12.3L23.1 11.2C21.6 10.4 19.8 10 18.1 10C12 10 7.1 14.9 7.1 21V24H7ZM23.6 13.5C24.4 14.1 25 14.8 25.6 15.7L26.8 14.5C26.2 13.7 25.5 13 24.7 12.4L23.6 13.5ZM26.4 9.3L29.7 12.6L32.3 10L28.7 6.4L26.1 9L26.4 9.3ZM1.5 17C0.7 17 0 16.3 0 15.5C0 14.7 0.7 14 1.5 14C2.3 14 3 14.7 3 15.5C3 16.3 2.3 17 1.5 17ZM1.5 15C1.2 15 1 15.2 1 15.5C1 15.8 1.2 16 1.5 16C1.8 16 2 15.8 2 15.5C2 15.2 1.8 15 1.5 15ZM21.5 4C20.7 4 20 3.3 20 2.5C20 1.7 20.7 1 21.5 1C22.3 1 23 1.7 23 2.5C23 3.3 22.3 4 21.5 4ZM21.5 2C21.2 2 21 2.2 21 2.5C21 2.8 21.2 3 21.5 3C21.8 3 22 2.8 22 2.5C22 2.2 21.8 2 21.5 2ZM6.5 3C5.7 3 5 2.3 5 1.5C5 0.7 5.7 0 6.5 0C7.3 0 8 0.7 8 1.5C8 2.3 7.3 3 6.5 3ZM6.5 1C6.2 1 6 1.2 6 1.5C6 1.8 6.2 2 6.5 2C6.8 2 7 1.8 7 1.5C7 1.2 6.8 1 6.5 1Z" fill="#0F0017"/>
  </svg>
</template>
