<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M18 2.5C15.9773 5.23048 14.444 7.48134 13 7C7.6967 5.23223 3.50002 10 3.50002 10C2.49982 14 2.11695 18.7689 5.50002 21.1899C8.2246 23.1397 11.2327 24.6186 14.3999 25.6H14.4999C17.0999 26.2 19.6999 25.8 21.9999 24.5C24.2999 23.1 25.8999 21 26.4999 18.4L26.5999 18C27.5884 12.5251 27.1893 6.70321 25.1825 1.19394C24.8414 0.257526 18.8586 1.34095 18 2.5Z" fill="#00FF86"/>
    <path d="M13.1 40C12.2 40 11.3 39.6 10.5 38.9L0.999951 29.4C-0.400049 28 -0.400049 25.7 0.999951 24.3L5.19995 20.1C5.59995 19.7 6.19995 19.7 6.59995 20.1C6.99995 20.5 6.99995 21.1 6.59995 21.5L2.39995 25.7C1.69995 26.4 1.69995 27.3 2.39995 28L11.9 37.5C12.6 38.2 13.5 38.2 14.2 37.5L18.4 33.3C18.7999 32.9 19.4 32.9 19.7999 33.3C20.1999 33.7 20.1999 34.3 19.7999 34.7L15.6 38.9C15 39.6 14 40 13.1 40Z" fill="#0F0017"/>
    <path d="M22.4 36.5C21 36.5 19.6 35.9 18.5 34.8L16 32.4C15.6 32 15.6 31.4 16 31C16.4 30.6 17 30.6 17.4 31L19.9 33.5C21.3 34.9 23.5 34.9 24.9 33.5L35.4 23C35.8 22.6 36.4 22.6 36.7999 23C37.1999 23.4 37.1999 24 36.7999 24.4L26.3 34.8C25.2 35.9 23.8 36.5 22.4 36.5Z" fill="#0F0017"/>
    <path d="M13.2 8.09999C12.9 8.09999 12.7 7.99999 12.5 7.79999C12.1 7.39999 12.1 6.79999 12.5 6.39999L15.7 3.19999C16.1 2.79999 16.7 2.79999 17.1 3.19999C17.5 3.59999 17.5 4.19999 17.1 4.59999L13.9 7.79999C13.7 7.99999 13.4 8.09999 13.2 8.09999Z" fill="#0F0017"/>
    <path d="M29.2999 27.4C26.5999 27.4 23.7999 26.4 21.6999 24.3L18.4999 21.1C18.0999 20.7 18.0999 20.1 18.4999 19.7C18.8999 19.3 19.4999 19.3 19.8999 19.7L23.0999 22.9C26.4999 26.3 31.9999 26.3 35.4999 22.9C38.8999 19.5 38.8999 14 35.4999 10.5L29.4999 4.5C26.0999 1.1 20.5999 1.1 17.0999 4.5C16.1999 5.5 15.4999 6.5 15.0999 7.6C14.8999 8.1 14.2999 8.4 13.7999 8.2C13.2999 8 12.9999 7.4 13.1999 6.9C13.6999 5.6 14.4999 4.4 15.5999 3.2C17.6999 1.1 20.3999 0 23.2999 0C26.1999 0 28.8999 1.1 30.8999 3.1L36.8999 9.1C41.0999 13.3 41.0999 20.1 36.8999 24.3C34.7999 26.4 31.9999 27.4 29.2999 27.4Z" fill="#0F0017"/>
    <path d="M6.70005 22.7C6.40005 22.7 6.20005 22.6 6.00005 22.4L4.10005 20.4C0.700049 17 0.700049 11.5 4.10005 8.10001C7.50005 4.60001 13 4.60001 16.4 8.00001L21.4 13C22.3 13.9 22.8 15.2 22.8 16.5C22.8 17.8 22.3 19 21.4 20C19.5 21.9 16.4 21.9 14.5 20L10.7 16C10.3 15.6 10.3 15 10.7 14.6C11.1 14.2 11.7 14.2 12.1 14.6L16 18.5C17.1 19.6 19 19.6 20.1 18.5C20.6 18 20.9 17.2 20.9 16.5C20.9 15.8 20.6 15 20.1 14.5L15.1 9.50001C12.4 6.90001 8.20005 6.90001 5.60005 9.60001C2.90005 12.3 2.90005 16.4 5.60005 19.1L7.50005 21.1C7.90005 21.5 7.90005 22.1 7.50005 22.5C7.20005 22.6 7.00005 22.7 6.70005 22.7Z" fill="#0F0017"/>
    <path d="M22.3 30C22 30 21.8 29.9 21.6 29.7C21.2 29.3 21.2 28.7 21.6 28.3C22.7 27.2 22.7 25.3 21.6 24.2C21.2 23.8 21.2 23.2 21.6 22.8C22 22.4 22.6 22.4 23 22.8C24.9 24.7 24.9 27.8 23 29.7C22.8 29.9 22.5 30 22.3 30Z" fill="#0F0017"/>
  </svg>
</template>
