<template>
  <svg
    width="40"
    height="34"
    viewBox="0 0 40 34"
  >
    <path d="M40 3.5C40 1.57 38.43 0 36.5 0C34.57 0 33 1.57 33 3.5C33 4.46 33.39 5.32 34.01 5.95C34.01 5.97 34.01 5.98 34.01 6V32C34.01 32.55 34.46 33 35.01 33H38.01C38.56 33 39.01 32.55 39.01 32V6C39.01 6 39.01 5.97 39.01 5.95C39.63 5.32 40.02 4.45 40.02 3.5H40ZM36.5 2C37.33 2 38 2.67 38 3.5C38 4.33 37.33 5 36.5 5C35.67 5 35 4.33 35 3.5C35 2.67 35.67 2 36.5 2ZM37 31H36V7H37V31Z" fill="#0F0017"/>
    <path d="M7 3.5C7 1.57 5.43 0 3.5 0C1.57 0 0 1.57 0 3.5C0 4.46 0.39 5.32 1.01 5.95C1.01 5.97 1.01 5.98 1.01 6V32C1.01 32.55 1.46 33 2.01 33H5.01C5.56 33 6.01 32.55 6.01 32V6C6.01 6 6.01 5.97 6.01 5.95C6.63 5.32 7.02 4.45 7.02 3.5H7ZM3.5 2C4.33 2 5 2.67 5 3.5C5 4.33 4.33 5 3.5 5C2.67 5 2 4.33 2 3.5C2 2.67 2.67 2 3.5 2ZM4 31H3V7H4V31Z" fill="#0F0017"/>
    <path d="M19.9999 16.7499C25.2699 16.7499 28.4099 14.6899 32.5999 11.5499C33.0399 11.2199 33.1299 10.5899 32.7999 10.1499C32.4699 9.70991 31.8399 9.61991 31.3999 9.94991C27.4199 12.9399 24.6599 14.7499 19.9999 14.7499C15.3399 14.7499 12.5799 12.9399 8.59991 9.94991C8.15991 9.61991 7.52991 9.70991 7.19991 10.1499C6.86991 10.5899 6.95991 11.2199 7.39991 11.5499C11.5899 14.6899 14.7299 16.7499 19.9999 16.7499Z" fill="#0F0017"/>
    <path d="M26.8601 21.49C26.6801 21.19 26.3501 21 26.0001 21H14.0001C13.6501 21 13.3201 21.18 13.1401 21.49L7.14011 31.49C6.95011 31.8 6.95011 32.18 7.13011 32.5C7.31011 32.81 7.64011 33.01 8.00011 33.01H32.0001C32.3601 33.01 32.6901 32.82 32.8701 32.5C33.0501 32.19 33.0401 31.8 32.8601 31.49L26.8601 21.49ZM9.77011 31L14.5701 23H25.4401L30.2401 31H9.77011Z" fill="#0F0017"/>
    <path d="M3.5 5C4.32843 5 5 4.32843 5 3.5C5 2.67157 4.32843 2 3.5 2C2.67157 2 2 2.67157 2 3.5C2 4.32843 2.67157 5 3.5 5Z" fill="#00FF86"/>
    <path d="M4 7H3V15.59C3.33 15.9 3.66 16.2 4 16.49V7Z" fill="#00FF86"/>
  </svg>
</template>
