<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M19.3461 38.9931C19.3461 38.389 19.7414 37.9863 20.3344 37.9863C20.8285 37.9863 21.3226 38.389 21.3226 38.9931C21.3226 39.5973 20.9273 40 20.3344 40C19.7414 40 19.3461 39.4966 19.3461 38.9931ZM10.3533 37.2815C9.85921 36.9794 9.76039 36.3753 9.95803 35.8719C10.2545 35.3684 10.8474 35.2677 11.3415 35.4691C11.8357 35.7712 11.9345 36.3753 11.7368 36.8787C11.5392 37.1808 11.2427 37.3822 10.8474 37.3822C10.6498 37.3822 10.551 37.3822 10.3533 37.2815ZM28.8331 36.8787C28.5366 36.3753 28.7343 35.7712 29.2284 35.4691C29.7225 35.1671 30.3154 35.3684 30.6119 35.8719C30.9084 36.3753 30.7107 36.9794 30.2166 37.2815C30.019 37.3822 29.9201 37.3822 29.7225 37.3822C29.426 37.3822 29.0307 37.1808 28.8331 36.8787ZM3.13929 29.8307C2.84283 29.3272 3.04047 28.7231 3.53458 28.4211C4.02869 28.119 4.62163 28.3204 4.91809 28.8238C5.21456 29.3272 5.01692 29.9314 4.52281 30.2334C4.42398 30.3341 4.22634 30.3341 4.02869 30.3341C3.63341 30.3341 3.23812 30.1327 3.13929 29.8307ZM36.0471 30.1327C35.553 29.8307 35.4542 29.2266 35.6518 28.7231C35.9483 28.2197 36.5412 28.119 37.0353 28.3204C37.5294 28.6224 37.7271 29.2266 37.4306 29.73C37.233 30.032 36.9365 30.2334 36.5412 30.2334C36.4424 30.2334 36.2448 30.2334 36.0471 30.1327ZM38.0236 19.6614C38.0236 19.0572 38.4188 18.6545 39.0118 18.6545C39.6047 18.6545 40 19.0572 40 19.6614C40 20.2655 39.6047 20.6682 39.0118 20.6682C38.5177 20.6682 38.0236 20.1648 38.0236 19.6614ZM35.553 10.4989C35.2565 9.99546 35.4542 9.39134 35.8495 9.08928C36.3436 8.78722 36.9365 8.9886 37.233 9.39134C37.5294 9.89477 37.3318 10.4989 36.9365 10.801C36.7389 10.9016 36.64 10.9016 36.4424 10.9016C36.0471 11.0023 35.7506 10.9016 35.553 10.4989ZM28.9319 3.95428C28.4378 3.65222 28.2402 3.0481 28.5366 2.54467C28.8331 2.04124 29.426 1.83986 29.9201 2.14192C30.4142 2.44398 30.6119 3.0481 30.3154 3.55153C30.1178 3.85359 29.8213 4.05496 29.426 4.05496C29.2284 4.15565 29.0307 4.05496 28.9319 3.95428Z" fill="#0F0017"/>
    <path d="M18.5287 22.0957H12.2864C11.6919 22.0957 11.2955 21.6993 11.2955 21.1048C11.2955 20.5103 11.6919 20.114 12.2864 20.114H18.5287C18.925 20.114 20.0149 20.0149 20.0149 18.8259V8.62028C20.0149 8.02578 20.4112 7.62945 21.0058 7.62945C21.6003 7.62945 21.9966 8.02578 21.9966 8.62028V18.8259C22.0957 21.2039 20.0149 22.0957 18.5287 22.0957ZM0.990837 20.8076C0.396335 20.8076 0 20.4112 0 19.8167C0 8.91754 8.91754 0 19.8167 0C20.4112 0 20.8076 0.396335 20.8076 0.990837C20.8076 1.58534 20.4112 1.98167 19.8167 1.98167C10.0075 1.98167 1.98167 10.0075 1.98167 19.8167C1.98167 20.4112 1.58534 20.8076 0.990837 20.8076Z" fill="#0F0017"/>
  </svg>
</template>
