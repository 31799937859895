<script setup lang="ts">
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { TemperIcon } from '@temperworks/icons'
import { Typography, DimmingOverlay, DatePickerInner, vClickOutside } from '@temperworks/components'
import { TypographyType, IconNames, IconColors, IconPosition, DimmingOverlayVariant, DatePickerVartiant } from '@temperworks/types'
const { t } = useI18n()
const emit = defineEmits(['selected'])

interface Props {
  datePreFilled: string | string[],
  multipleSelect: boolean,
  intervalSelect: boolean,
  error: boolean,
  activeWeek: boolean,
  allowPast: boolean,
  allowFuture: boolean,
  allowReset: boolean,
  monthsOnly: boolean,
  disabled: boolean,
  dateRange: number,
  variant: string,
}
const props = withDefaults(defineProps<Props>(), {
  datePreFilled: undefined,
  multipleSelect: false,
  intervalSelect: false,
  error: false,
  activeWeek: false,
  allowPast: false,
  allowFuture: true,
  allowReset: false,
  monthsOnly: false,
  disabled: false,
  dateRange: 0,
  variant: DatePickerVartiant.Default,
})

const DateOptions = computed<Intl.DateTimeFormatOptions>(() => ({
  month: 'long',
  weekday: props.monthsOnly ? undefined : 'long',
  day: props.monthsOnly ? undefined : 'numeric',
  year: props.allowPast ? 'numeric' : undefined
}))

const locale = document.cookie.match(RegExp('(?:^|;\\s*)' + 'i18n' + '=([^;]*)'))
const localeSelected = locale ? locale[1] : 'nl_NL'
const showPicker = ref<boolean>(false)

function togglePicker () {
  if (props.disabled) return
  showPicker.value = !showPicker.value
}

function closePicker () {
  showPicker.value = false
}

function setDate (e: string) {
  if (!props.multipleSelect && !props.intervalSelect) {
    showPicker.value = false
  }
  emit('selected', e)
}

const composedlabel = computed(() => {
  if (!props.datePreFilled?.length) {
    return t('controls.selectDate')
  }

  if (Array.isArray(props.datePreFilled)) {
    const dates = props.datePreFilled.map((date) => {
      // format date to dd-mm-yyyy
      return new Intl.DateTimeFormat(
        'en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }
      ).format(new Date(date))
    }).join(' - ')
    return dates
  }

  return new Intl.DateTimeFormat(
    localeSelected.replace(/_/g, '-'), DateOptions.value
  ).format(new Date(props.datePreFilled))
})

</script>

<template>
  <div
    class="datepicker-wrapper"
    :class="[
      'datepicker-wrapper',
      { 'table-filter-date-picker-wrap': props.variant === DatePickerVartiant.TableFilter }
    ]"
    v-click-outside="closePicker"
  >
    <div
      class="datepicker"
      :class="[{
        'errored': props.error,
        'active': showPicker,
        'disabled': props.disabled,
        'table-filter-date-picker': props.variant === DatePickerVartiant.TableFilter,
        'value-set': props.datePreFilled?.length
      }]"
      @click="togglePicker"
    >
      <TemperIcon
        v-if="props.variant === DatePickerVartiant.TableFilter"
        :name="IconNames.filter"
        :color="IconColors.grey800"
        :position="IconPosition.left"
      />
      <Typography
        :variant="TypographyType.body"
        :content="composedlabel"
      />
      <TemperIcon
        v-if="props.variant !== DatePickerVartiant.TableFilter"
        :name="IconNames.dropdownCaretsR"
        :color="IconColors.grey800"
      />
    </div>
    <DatePickerInner
      v-if="showPicker"
      :multiple-select="props.multipleSelect"
      :interval-select="props.intervalSelect"
      :active-week="props.activeWeek"
      :preset-date="props.datePreFilled"
      :allow-past="props.allowPast"
      :allow-future="props.allowFuture"
      :allow-reset="props.allowReset"
      :months-only="props.monthsOnly"
      :disabled="props.disabled"
      :date-range="props.dateRange"
      @selected="setDate($event)"
    />
    <DimmingOverlay
      v-if="showPicker"
      :variant="DimmingOverlayVariant.bright"
      @close="showPicker = false"
    />
  </div>
</template>

<style lang="scss" scoped>
@use './DatePicker.scss';
</style>
