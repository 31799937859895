<template>
  <svg
    width="28"
    height="40"
    viewBox="0 0 28 40"
  >
    <path d="M19 5H3C1.9 5 1 4.1 1 3C1 2.5 1.2 2 1.6 1.6C2 1.2 2.5 1 3 1H17.2C17.9 2.3 18.5 3.6 19 5Z" fill="#00FF86"/>
    <path d="M25 40H3C1.3 40 0 38.7 0 37C0 35.7 0.8 34.6 2 34.2V32.3C2 32.2 2 32.1 2 32.1C2 32 2 31.9 2 31.9C2.1 29.7 3 27.6 4.6 26L9.8 20.8C10.2 20.4 10.2 19.8 9.8 19.4L4.6 14.2C2.9 12.4 2 10.1 2 7.7V5.8C0.8 5.4 0 4.3 0 3C0 1.3 1.3 0 3 0H25C26.7 0 28 1.3 28 3C28 4.3 27.2 5.4 26 5.8V7.7C26 10.1 25.1 12.4 23.4 14.1L18.2 19.3C18 19.5 17.9 19.7 17.9 20C17.9 20.3 18 20.5 18.2 20.7L23.4 25.9C25 27.5 25.9 29.6 26 31.8C26 31.9 26 32 26 32C26 32.1 26 32.2 26 32.2V34.1C27.2 34.5 28 35.6 28 36.9C28 38.7 26.7 40 25 40ZM3 36C2.4 36 2 36.4 2 37C2 37.6 2.4 38 3 38H25C25.6 38 26 37.6 26 37C26 36.4 25.6 36 25 36H3ZM4 34H24V32.8C22.8 32.5 20.7 32 19.4 31.4C18.6 31 17.6 30.6 16.6 30.4C15.6 30.2 14.7 30 14.1 30C13.5 30 12.6 30.1 11.6 30.4C10.6 30.6 9.6 31.1 8.8 31.4C7.3 32 5.2 32.5 4 32.8V34ZM6.4 13L11.3 17.9C12.5 19.1 12.5 21 11.3 22.1L6 27.4C5.1 28.3 4.4 29.5 4.1 30.7C5.2 30.4 6.8 30 7.7 29.6C8.6 29.2 9.7 28.7 10.9 28.4C12.1 28.1 13.1 28 13.9 28C14.7 28 15.7 28.1 16.9 28.4C18.1 28.7 19.2 29.2 20.1 29.6C21 30 22.6 30.5 23.7 30.7C23.4 29.4 22.8 28.3 21.8 27.4L16.6 22.2C16 21.6 15.7 20.9 15.7 20.1C15.7 19.3 16 18.5 16.6 18L21.5 13.1H6.4V13ZM4 6V7.7C4 8.9 4.3 10 4.8 11C4.9 11 4.9 11 5 11H23C23.1 11 23.1 11 23.2 11C23.7 10 24 8.8 24 7.7V6H4ZM3 2C2.4 2 2 2.4 2 3C2 3.6 2.4 4 3 4H25C25.6 4 26 3.6 26 3C26 2.4 25.6 2 25 2H3ZM14 27C13.4 27 13 26.6 13 26V20C13 19.4 13.5 19 14 19C14.6 19 15 19.4 15 20V26C15 26.6 14.6 27 14 27Z" fill="#0F0017"/>
  </svg>
</template>
