<template>
  <svg
    width="23"
    height="40"
    viewBox="0 0 23 40"
  >
    <path d="M7 14C8.4 15.8 9.4 18.1 9 21H5L4.6 20C4.6 20 2 11 1 10C1.4 9.8 4.7 11.2 7 14ZM10.6 10.6C10.8 11.4 11 12.2 11 13C9.6 17 9.3 20.8 9.6 20.8V21H9C9.4 18.1 8.4 15.8 7 14C6.8 13.5 4 5.9 2 3C2 3 6 4.3 8.7 7.3C8.7 7.3 9.8 8.5 10.5 10.1C10.5 10.3 10.6 10.5 10.6 10.6ZM10.6 10.6C10.9 11.4 11 12.2 11 13C11 12.1 10.9 11.3 10.6 10.6ZM14 1L12 10L11 13C11 12.1 10.9 11.3 10.6 10.6C10.6 10.5 10.5 10.4 10.5 10.2C10.1 9.1 9.5 8.2 8.7 7.3V6.6C8.7 5.6 11 1 14 1ZM9.6 20.8C9.4 20.8 9.6 17 11 13C12 10.1 13.6 7 16.1 5.2C16.5 6.2 16.8 7.2 17.1 8.3C16 13 14.2 20.1 13.6 20.7V21H9.6V20.8ZM17.6 21C17.5 21.4 17.5 21.7 17.4 22.1L17.3 22.5C16.7 25.1 15.1 27.2 12.8 28.6C10.5 30 7.9 30.4 5.3 29.7H5.2C3.8 29.2 2.3 28.7 1 28V21H5H9H9.6H13.6H17H17.6ZM17.7 20.4C17.7 20.6 17.7 20.8 17.6 21H17C17.2 20.9 17.5 20.7 17.7 20.4ZM17.8 12.2C17.9 13.4 18 14.6 18 15.8C18 17.3 17.9 18.8 17.7 20.3C17.5 20.6 17.3 20.8 17 20.9H13.5V20.6C14.2 20.1 16 13 17 8.2C17.4 9.6 17.6 10.9 17.8 12.2Z" fill="#00FF86"/>
    <path d="M15 40H7C3.1 40 0 36.9 0 33V21C0 20.4 0.4 20 1 20H3.5C2.3 16 0.8 11.3 0.2 10.7C0.1 10.5 0 10.1 0 9.8C0 9.5 0.2 9.2 0.5 9.1C1.4 8.6 3.5 9.8 4.4 10.4C3.5 8 2.2 5.1 1.2 3.6C1 3.2 1 2.8 1.2 2.4C1.4 2 1.9 1.9 2.3 2.1C2.5 2.2 5.5 3.2 8 5.4C8.9 3.2 11.1 0 14 0C14.3 0 14.6 0.1 14.8 0.4C15 0.6 15 0.9 15 1.2L14 5.7C15 4.6 16.2 3.7 17.6 3.1C17.9 2.9 18.3 3 18.6 3.2C18.9 3.4 19 3.8 18.9 4.2C18.4 6.5 17.9 8.9 17.4 11.1L20.3 8.2C20.6 7.9 21.1 7.8 21.5 8C21.9 8.2 22.1 8.6 22 9C21.7 11 20.7 16.8 19.1 19.9H21C21.6 19.9 22 20.3 22 20.9V32.9C22 36.9 18.9 40 15 40ZM2 22V33C2 35.8 4.2 38 7 38H15C17.8 38 20 35.8 20 33V22H17H2ZM15.1 20H16.7C17.6 19.2 18.7 15.7 19.5 11.9L16.7 14.7C16.7 14.7 16.6 14.8 16.5 14.8C16 16.9 15.5 18.7 15.1 20ZM5.7 20H8.1C8.2 18.1 7.6 16.3 6.3 14.7C6.3 14.7 6.3 14.7 6.3 14.6C5.3 13.4 4.1 12.4 3.1 11.8C4.1 14.2 5.1 17.8 5.6 19.6L5.7 20ZM10.5 20H13C13.6 18.5 15.1 12.3 16.5 6.1C12.2 9.6 10.7 17.4 10.5 20ZM7.9 13.5C8.4 14.1 8.9 14.9 9.2 15.7C9.4 14.9 9.7 13.9 10 13C10 9.2 6.8 6.6 4.4 5.3C5.9 8.4 7.5 12.6 7.9 13.5ZM9.7 6.9C10.3 7.6 10.8 8.3 11.2 9.2L12.7 2.4C11.1 3.4 9.8 6 9.8 6.6C9.7 6.7 9.7 6.8 9.7 6.9Z" fill="#0F0017"/>
  </svg>
</template>
