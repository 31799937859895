<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M23.3 1C25.6 5.1 26.8 9.6 27 14H18.6C17.2 14 16 12.9 16 11.4V3.6C16 2.1 17.2 1 18.6 1H23.3ZM21.4 26C22.4 26 23.2 26.5 23.6 27.3C23 27.9 22.4 28.4 21.7 28.8C19.4 30.1 16.8 30.5 14.2 29.9H14.1C11.3 29 8.70005 27.7 6.30005 26.1H21.4V26Z" fill="#00FF86"/>
    <path d="M21.4 40H3.6C1.6 40 0 38.4 0 36.4V28.6C0 26.6 1.6 25 3.6 25H21.4C22.7 25 23.9 25.7 24.5 26.8C24.8 27.3 25 28 25 28.6V36.4C25 38.4 23.4 40 21.4 40ZM3.6 27C2.7 27 2 27.7 2 28.6V36.4C2 37.3 2.7 38 3.6 38H21.4C22.3 38 23 37.3 23 36.4V28.6C23 28.3 22.9 28 22.8 27.8C22.5 27.3 22 27 21.4 27H3.6ZM29 35C28.6 35 28.2 34.7 28 34.3C27.9 33.8 28.2 33.2 28.7 33.1C29 33 35.2 31.1 35.1 21.5L34.6 22C34.2 22.4 33.6 22.4 33.2 22.1C32.8 21.7 32.8 21.1 33.1 20.7L35.2 18.4C35.4 18.2 35.6 18.1 35.8 18.1C36.1 18.1 36.4 18.2 36.6 18.4L38.6 20.4C39 20.8 39 21.4 38.6 21.8C38.2 22.2 37.6 22.2 37.2 21.8L37 21.6C37 32.7 29.2 35 29.1 35C29.2 35 29.1 35 29 35ZM4 22C3.9 22 3.8 22 3.7 21.9C3.6 21.9 3.5 21.8 3.4 21.7L1.4 19.7C1 19.3 1 18.7 1.4 18.3C1.8 17.9 2.4 17.9 2.8 18.3L3 18.5C2.9 7.4 10.6 5.1 10.7 5C11.2 4.9 11.8 5.2 11.9 5.7C12 6.2 11.7 6.8 11.2 6.9C11 7 4.7 9 4.9 18.7L5.3 18.3C5.7 17.9 6.3 17.9 6.7 18.3C7.1 18.7 7.1 19.3 6.7 19.7L4.7 21.7C4.6 21.8 4.6 21.8 4.5 21.8C4.4 21.9 4.2 22 4 22ZM36.4 15H18.6C16.6 15 15 13.4 15 11.4V3.6C15 1.6 16.6 0 18.6 0H36.4C38.4 0 40 1.6 40 3.6V11.4C40 13.4 38.4 15 36.4 15ZM18.6 2C17.7 2 17 2.7 17 3.6V11.4C17 12.3 17.7 13 18.6 13H36.4C37.3 13 38 12.3 38 11.4V3.6C38 2.7 37.3 2 36.4 2H18.6Z" fill="#0F0017"/>
  </svg>
</template>
