<template>
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
  >
    <path d="M0.246643 2C0.246643 0.89543 1.14207 0 2.24664 0H4.24664C4.52279 0 4.74664 0.223858 4.74664 0.5C4.74664 0.776142 4.52279 1 4.24664 1H2.24664C1.69436 1 1.24664 1.44772 1.24664 2V4C1.24664 4.27614 1.02279 4.5 0.746643 4.5C0.470501 4.5 0.246643 4.27614 0.246643 4V2ZM9.74664 0.5C9.74664 0.223858 9.9705 0 10.2466 0H12.2466C13.3512 0 14.2466 0.895431 14.2466 2V4C14.2466 4.27614 14.0228 4.5 13.7466 4.5C13.4705 4.5 13.2466 4.27614 13.2466 4V2C13.2466 1.44772 12.7989 1 12.2466 1H10.2466C9.9705 1 9.74664 0.776142 9.74664 0.5ZM0.746643 9.5C1.02279 9.5 1.24664 9.72386 1.24664 10V12C1.24664 12.5523 1.69436 13 2.24664 13H4.24664C4.52279 13 4.74664 13.2239 4.74664 13.5C4.74664 13.7761 4.52279 14 4.24664 14H2.24664C1.14207 14 0.246643 13.1046 0.246643 12V10C0.246643 9.72386 0.470501 9.5 0.746643 9.5ZM13.7466 9.5C14.0228 9.5 14.2466 9.72386 14.2466 10V12C14.2466 13.1046 13.3512 14 12.2466 14H10.2466C9.9705 14 9.74664 13.7761 9.74664 13.5C9.74664 13.2239 9.9705 13 10.2466 13H12.2466C12.7989 13 13.2466 12.5523 13.2466 12V10C13.2466 9.72386 13.4705 9.5 13.7466 9.5Z" />
    <path d="M7.99664 11.25C7.99664 10.8358 7.66086 10.5 7.24664 10.5C6.83243 10.5 6.49664 10.8358 6.49664 11.25C6.49664 11.6642 6.83243 12 7.24664 12C7.66086 12 7.99664 11.6642 7.99664 11.25Z" />
    <path d="M9.74664 5C9.74664 3.61929 8.62736 2.5 7.24664 2.5C5.86593 2.5 4.74664 3.61929 4.74664 5C4.74664 5.27614 4.9705 5.5 5.24664 5.5C5.52279 5.5 5.74664 5.27614 5.74664 5C5.74664 4.17157 6.41822 3.5 7.24664 3.5C8.07507 3.5 8.74664 4.17157 8.74664 5C8.74664 5.42206 8.57164 5.81435 8.27225 6.09468L8.15425 6.19439L7.90759 6.37874C7.77866 6.47988 7.66313 6.5832 7.552 6.70162C7.03976 7.24747 6.74664 7.98796 6.74664 9C6.74664 9.27614 6.9705 9.5 7.24664 9.5C7.52279 9.5 7.74664 9.27614 7.74664 9C7.74664 8.24223 7.94291 7.74641 8.2812 7.38592C8.33018 7.33373 8.38144 7.28509 8.43729 7.23718L8.52481 7.16553L8.74331 7.00243L8.89794 6.8771C9.43149 6.40774 9.74664 5.72959 9.74664 5Z" />
  </svg>
</template>
