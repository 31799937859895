<template>
    <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
    >
        <path
            d="M6.03494 0.923688C6.24411 0.402848 6.83006 -0.156634 7.5806 0.0519642C8.1709 0.216026 8.55437 0.57295 8.77353 1.04843C8.97781 1.49163 9.02797 2.0133 9.03753 2.51044C9.04781 3.0452 8.93612 3.68677 8.80466 4.24788C8.74339 4.50942 8.67586 4.76153 8.60995 4.98817H9.99492C11.3298 4.98817 12.29 6.27086 11.9139 7.55163L10.5497 12.1977C10.1555 13.5401 8.73654 14.2977 7.40179 13.8785L2.04591 12.1962C1.45891 12.0118 0.989444 11.5675 0.773038 10.9915L0.253045 9.60757C-0.0885815 8.69833 0.272443 7.6751 1.109 7.18156L2.98089 6.07721C2.98462 6.07459 2.99002 6.07073 2.99704 6.06557C3.01761 6.05041 3.05204 6.02398 3.09835 5.98471C3.19093 5.90622 3.33128 5.77616 3.5035 5.58191C3.84755 5.19384 4.32141 4.54662 4.79565 3.5374C5.00052 3.10142 5.17287 2.7587 5.32941 2.44744C5.58243 1.94432 5.79411 1.5234 6.03494 0.923688ZM3.51946 6.92006C3.51378 6.92371 3.50805 6.92726 3.50224 6.93069L1.61713 8.04284C1.19885 8.28961 1.01834 8.80123 1.18915 9.25585L1.70914 10.6398C1.81735 10.9278 2.05208 11.1499 2.34558 11.2421L7.70146 12.9244C8.50231 13.176 9.35371 12.7214 9.5902 11.916L10.9544 7.2699C11.1424 6.62952 10.6623 5.98817 9.99492 5.98817H7.92587C7.76349 5.98817 7.61122 5.90931 7.51751 5.77668C7.42381 5.64406 7.40035 5.47419 7.4546 5.32113C7.55458 5.03905 7.70672 4.55035 7.83103 4.01977C7.95711 3.48162 8.04556 2.93746 8.03771 2.52967C8.02871 2.06136 7.97907 1.71372 7.86536 1.46703C7.76654 1.25263 7.61384 1.09911 7.31282 1.01544C7.26971 1.00346 7.21948 1.00696 7.15353 1.05026C7.08149 1.09755 7.00781 1.18454 6.96291 1.29635C6.70922 1.92808 6.46651 2.41194 6.19787 2.94748C6.04311 3.25601 5.87974 3.58169 5.7007 3.96269C5.18681 5.05631 4.66067 5.78409 4.25176 6.24531C4.04749 6.47571 3.87312 6.63887 3.74509 6.74743C3.68109 6.80169 3.62875 6.84225 3.59013 6.8707C3.57082 6.88493 3.55495 6.89612 3.54277 6.90448L3.5273 6.91492L3.52171 6.9186L3.51946 6.92006ZM2.97612 6.08055L2.97492 6.08133L2.97612 6.08055Z"
            fill="#0F0017"
        />
    </svg>
</template>
