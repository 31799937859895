<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M4 15L6 1H23.2C25.4 5.1 26.7 9.6 26.9 14C26.9 14.6 27 15.2 27 15.9C27 16.5 27 17.1 26.9 17.7V18.4C26.8 19.6 26.6 20.8 26.4 22L26.3 22.4C25.7 25 24.1 27.1 21.8 28.5C19.5 29.9 16.9 30.3 14.3 29.6H14.2C11.3 28.7 8.5 27.4 6 25.6V19L6.1 18.5C4.9 17.8 4 16.5 4 15Z" fill="#00FF86"/>
    <path d="M39 40H1C0.4 40 0 39.6 0 39C0 38.4 0.4 38 1 38H5V19C3.8 18.1 3 16.6 3 15V14.9L5 0.9C5.1 0.4 5.5 0 6 0H34C34.5 0 34.9 0.4 35 0.9L37 14.9V15C37 16.6 36.2 18.1 35 19V38H39C39.6 38 40 38.4 40 39C40 39.6 39.6 40 39 40ZM7 38H33V34H7V38ZM7 32H33V19.9C32.7 20 32.3 20 32 20C30.4 20 28.9 19.2 28 18C27.1 19.2 25.6 20 24 20C22.4 20 20.9 19.2 20 18C19.1 19.2 17.6 20 16 20C14.4 20 12.9 19.2 12 18C11.1 19.2 9.6 20 8 20C7.7 20 7.3 20 7 19.9V32ZM29 15C29 16.7 30.3 18 32 18C33.6 18 35 16.7 35 15.1V15H29ZM21 15C21 16.7 22.3 18 24 18C25.7 18 27 16.7 27 15H21ZM13 15C13 16.7 14.3 18 16 18C17.7 18 19 16.7 19 15H13ZM5 15.1C5 16.7 6.4 18 8 18C9.7 18 11 16.7 11 15L5 15.1ZM29 13H34.7L33.1 2H29V13ZM21 13H27V2H21V13ZM13 13H19V2H13V13ZM5.3 13H11V2H6.9L5.3 13Z" fill="#0F0017"/>
  </svg>
</template>
