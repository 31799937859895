<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path d="M20 39C27.1797 39 33 33.1797 33 26C33 18.8203 27.1797 13 20 13C12.8203 13 7 18.8203 7 26C7 33.1797 12.8203 39 20 39Z" stroke="#0F0017" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M20.0001 39C23.8274 39 26.9301 33.1797 26.9301 26C26.9301 18.8203 23.8274 13 20.0001 13C16.1727 13 13.0701 18.8203 13.0701 26C13.0701 33.1797 16.1727 39 20.0001 39Z" stroke="#0F0017" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M20 39V0" stroke="#0F0017" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M33 26H7" stroke="#0F0017" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M31.27 19.93H8.72998" stroke="#0F0017" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M31.27 32.07H8.72998" stroke="#0F0017" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M24 10H16V13H24V10Z" fill="#0F0017"/>
    <path d="M36 24H39" stroke="#0F0017" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M34 16L38 14" stroke="#0F0017" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M28.6399 9.77997L31.3599 6.21997" stroke="#0F0017" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.3599 9.77997L8.63989 6.21997" stroke="#0F0017" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M35 33.13L39 34.13" stroke="#0F0017" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 24H1" stroke="#0F0017" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 16L2 14" stroke="#0F0017" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5 33.13L1 34.13" stroke="#0F0017" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
