<template>
  <svg
    width="40"
    height="35"
    viewBox="0 0 40 35"
  >
    <path d="M30.61 19.1501V18.8901C30.61 14.9601 25.78 11.8901 19.61 11.8901C13.44 11.8901 8.61 14.9601 8.61 18.8901V19.1501L2.98 15.3001C2.69 15.1001 2.69 14.6701 2.98 14.4701L19.61 3.10006L36.24 14.4801C36.53 14.6801 36.53 15.1101 36.24 15.3101L30.61 19.1601V19.1501ZM19.61 32.8901C14.73 32.8901 10.61 30.6001 10.61 27.8901C10.61 25.1801 14.73 22.8901 19.61 22.8901C24.49 22.8901 28.61 25.1801 28.61 27.8901C28.61 30.6001 24.49 32.8901 19.61 32.8901ZM28.61 23.8301C26.64 22.0401 23.38 20.8901 19.61 20.8901C15.84 20.8901 12.58 22.0401 10.61 23.8301V18.8901C10.61 16.1801 14.73 13.8901 19.61 13.8901C24.49 13.8901 28.61 16.1801 28.61 18.8901V23.8301ZM38.57 13.6601L20.46 1.26006C19.95 0.910059 19.27 0.910059 18.77 1.26006L0.65 13.6601C0.24 13.9401 0 14.4001 0 14.9001C0 15.4001 0.24 15.8601 0.65 16.1401L8.61 21.5901V27.9101C8.61 31.8301 13.44 34.9101 19.61 34.9101C25.78 34.9101 30.61 31.8301 30.61 27.9101V21.6001L33.61 19.5501V24.9201C33.61 25.4701 34.06 25.9201 34.61 25.9201C35.16 25.9201 35.61 25.4701 35.61 24.9201V18.1801L38.57 16.1501C38.98 15.8701 39.23 15.4101 39.23 14.9101C39.23 14.4101 38.98 13.9501 38.58 13.6701L38.57 13.6601Z" fill="#0F0017"/>
    <path d="M2.98996 14.4801C2.69996 14.6801 2.69996 15.1101 2.98996 15.3101L8.61996 19.1601V18.9001C8.61996 14.9701 13.45 11.9001 19.62 11.9001C22.61 11.9001 25.29 12.6301 27.25 13.8201C27.43 12.0201 27.45 10.1901 27.31 8.37011L19.62 3.11011L2.98996 14.4801Z" fill="#00FF86"/>
    <path d="M10.6199 18.8901V22.9801C10.8399 23.0901 11.0699 23.1801 11.2999 23.2901C13.2999 21.8201 16.2599 20.9001 19.6199 20.9001C21.3699 20.9001 22.9999 21.1601 24.4599 21.6001C25.5299 20.4101 26.2999 18.9701 26.6999 17.3701C26.6999 17.3701 26.6999 17.3601 26.6999 17.3501L26.7899 16.9601C26.8499 16.6601 26.8999 16.3601 26.9499 16.0501C25.2999 14.7601 22.6099 13.9001 19.6199 13.9001C14.7399 13.9001 10.6199 16.1901 10.6199 18.9001V18.8901Z" fill="#00FF86"/>
  </svg>
</template>
