<template>
  <svg
    width="40"
    height="36"
    viewBox="0 0 40 36"
  >
    <path d="M27 11.8999C27 13.9999 26.8 16.0999 26.4 18.0999L26.3 18.4999C25.7 21.0999 24.1 23.1999 21.8 24.5999C19.5 25.9999 16.9 26.3999 14.3 25.6999H14.2C10.1 24.3999 6.3 22.2999 3 19.3999V8.9999C3 7.8999 3.9 6.9999 5 6.9999H25V2.9999C25 2.5999 25.1 2.1999 25.3 1.8999C25.9 3.5999 26.3 5.2999 26.6 6.9999C26.9 8.5999 27 10.1999 27 11.8999Z" fill="#00FF86"/>
    <path d="M37 36H3C1.3 36 0 34.7 0 33V29C0 27.6 0.9 26.5 2.2 26.1C2.1 25.8 2 25.4 2 25V9C2 7.3 3.3 6 5 6H24V3C24 1.3 25.3 0 27 0H35C36.7 0 38 1.3 38 3V25C38 25.4 37.9 25.8 37.8 26.1C39.1 26.4 40 27.6 40 29V33C40 34.7 38.7 36 37 36ZM3 28C2.4 28 2 28.4 2 29V33C2 33.6 2.4 34 3 34H37C37.6 34 38 33.6 38 33V29C38 28.4 37.6 28 37 28H3ZM5 26H35C35.6 26 36 25.6 36 25V9C36 8.4 35.6 8 35 8H5C4.4 8 4 8.4 4 9V25C4 25.6 4.4 26 5 26ZM35 6H36V3C36 2.4 35.6 2 35 2H27C26.4 2 26 2.4 26 3V6H35ZM22 32H18C17.4 32 17 31.6 17 31C17 30.4 17.4 30 18 30H22C22.6 30 23 30.4 23 31C23 31.6 22.6 32 22 32ZM17 22H9C8.4 22 8 21.6 8 21V13C8 12.4 8.4 12 9 12H17C17.6 12 18 12.4 18 13V21C18 21.6 17.6 22 17 22ZM10 20H16V14H10V20Z" fill="#0F0017"/>
  </svg>
</template>
