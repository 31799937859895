import mixpanel from 'mixpanel-browser'
import { App } from 'vue'

export default {
  install: (app: App, options: { country: String }) => {
    const mixpanelExtended = {
      ...mixpanel, 
      track: (name: string, properties?: { [key: string]: any }) => {
        try {
          mixpanel.track(name, {
            ...properties,
            platform_country: options.country
          })
        } catch (error) {
          // Leaving this empty on purpose, as we can ignore Mixpanel if it fails to execute
        }
      }
    }

    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$mixpanel = mixpanelExtended
    app.provide('mixpanel', mixpanelExtended)
  }
}
