<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
  >
    <path d="M10 0.5C15.523 0.5 20 4.978 20 10.5C20 16.022 15.523 20.5 10 20.5C4.477 20.5 0 16.022 0 10.5C0 4.978 4.477 0.5 10 0.5ZM10 2.167C5.405 2.167 1.667 5.905 1.667 10.5C1.667 15.095 5.405 18.833 10 18.833C14.595 18.833 18.333 15.095 18.333 10.5C18.333 5.905 14.595 2.167 10 2.167Z" />
    <path d="M7.39447 14.58H12.5879C12.9506 14.58 13.1984 14.3412 13.1984 13.9873C13.1984 13.6245 12.9506 13.3945 12.5879 13.3945H9.01354C9.44706 13.0141 9.73018 12.377 9.73018 11.6869C9.73018 11.4127 9.69479 11.1738 9.64171 10.9438H11.7385C12.0482 10.9438 12.2517 10.7491 12.2517 10.4572C12.2517 10.1563 12.0482 9.9617 11.7385 9.9617H9.42052C9.3409 9.65205 9.27896 9.32469 9.27896 8.9531C9.27896 8.05952 9.91598 7.45789 11.0131 7.45789C11.7385 7.45789 12.057 7.64369 12.3313 7.64369C12.6764 7.64369 12.871 7.44905 12.871 7.13054C12.871 6.87397 12.7471 6.67048 12.4198 6.53777C12.0217 6.36082 11.5174 6.29004 10.898 6.29004C9.04009 6.29004 7.92531 7.33403 7.92531 8.94425C7.92531 9.2893 7.98725 9.63435 8.07572 9.9617H7.31485C7.01403 9.9617 6.8017 10.1563 6.8017 10.4572C6.8017 10.7491 7.01403 10.9438 7.31485 10.9438H8.2969C8.35884 11.2003 8.41192 11.4569 8.41192 11.6869C8.41192 12.5628 7.88993 13.1468 7.29715 13.3414C6.96095 13.483 6.8017 13.6688 6.8017 13.9961C6.8017 14.35 7.04058 14.58 7.39447 14.58Z" />
  </svg>
</template>
