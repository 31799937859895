<template>
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.6606 6.414L12.8316 0.586C12.8167 0.571048 12.7998 0.55808 12.7829 0.545195C12.7705 0.535674 12.7581 0.526198 12.7466 0.516C12.6756 0.452 12.6056 0.389 12.5276 0.336C12.5024 0.318941 12.4747 0.305475 12.4472 0.292071C12.4311 0.284259 12.4151 0.276469 12.3996 0.268C12.3829 0.258594 12.3663 0.248967 12.3496 0.239326C12.2954 0.20797 12.241 0.176475 12.1836 0.152C11.9866 0.0699999 11.7746 0.0289999 11.5596 0.0139999C11.5399 0.0127423 11.5204 0.0100789 11.5008 0.00740928C11.4737 0.00371057 11.4465 0 11.4186 0H5.24664C4.14264 0 3.24664 0.896 3.24664 2V9.9012C3.72287 9.72518 4.22537 9.60335 4.74664 9.54319V2C4.74664 1.725 4.97064 1.5 5.24664 1.5H11.2466V6C11.2466 7.104 12.1426 8 13.2466 8H17.7466V18C17.7466 18.275 17.5226 18.5 17.2466 18.5H11.5018C11.2763 19.0409 10.9797 19.5448 10.6238 20H17.2466C18.3506 20 19.2466 19.104 19.2466 18V7.828C19.2466 7.298 19.0356 6.789 18.6606 6.414ZM12.7466 2.621L16.6246 6.5H13.2466C12.9706 6.5 12.7466 6.275 12.7466 6V2.621Z" />
    <path d="M0 16.0001C0 12.9626 2.46243 10.5001 5.5 10.5001C8.53757 10.5001 11 12.9626 11 16.0001C11 19.0377 8.53757 21.5001 5.5 21.5001C2.46243 21.5001 0 19.0377 0 16.0001ZM6 17.793V13.0001C6 12.724 5.77614 12.5001 5.5 12.5001C5.22386 12.5001 5 12.724 5 13.0001V17.793L3.35355 16.1466C3.15829 15.9513 2.84171 15.9513 2.64645 16.1466C2.45118 16.3418 2.45118 16.6584 2.64645 16.8537L5.14645 19.3537C5.34171 19.5489 5.65829 19.5489 5.85355 19.3537L8.35355 16.8537C8.54882 16.6584 8.54882 16.3418 8.35355 16.1466C8.15829 15.9513 7.84171 15.9513 7.64645 16.1466L6 17.793Z" />

  </svg>
</template>