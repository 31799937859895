<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M27 15.9C27 17.3 26.9 18.7 26.7 20C26.6 20.7 26.5 21.4 26.4 22.1L26.3 22.5C26 23.8 25.4 25 24.6 26C23.8 27 22.9 27.9 21.8 28.6C19.5 30 16.9 30.4 14.3 29.7H14.2C9.3 28.2 4.7 25.4 1 21.5C1 21 1 20.5 1 20C1 9.5 9.5 1 20 1C21.1 1 22.3 1.1 23.4 1.3C25.5 5.3 26.7 9.7 27 14C27 14.6 27 15.3 27 15.9Z" fill="#00FF86"/>
    <path d="M20 40C16.1 40 12.3 38.9 9 36.7C8.5 36.7 7.1 37.4 6.2 37.8C3.9 38.8 2.3 39.6 1.3 38.6C0.4 37.7 1.1 36 2.1 33.7C2.5 32.8 3.1 31.4 3.2 30.9C1.4 28.1 0.3 24.9 0 21.5C0 21.1 0 20.5 0 20C0 9 9 0 20 0C21.2 0 22.4 0.1 23.5 0.3C33.1 2 40 10.3 40 20C40 31 31 40 20 40ZM9.1 34.7C9.4 34.7 9.7 34.8 10 35C13 37 16.4 38 20 38C29.9 38 38 29.9 38 20C38 11.3 31.8 3.8 23.2 2.3C22.2 2.1 21.1 2 20 2C10.1 2 2 10.1 2 20C2 20.5 2 21 2.1 21.4C2.3 24.5 3.4 27.4 5.1 30C5.7 30.9 5.2 32.3 4.1 34.6C3.8 35.3 3.3 36.3 3.1 37C3.8 36.8 4.8 36.3 5.5 36C7.1 35.2 8.2 34.7 9.1 34.7ZM30 27H9C8.4 27 8 26.6 8 26C8 25.4 8.4 25 9 25H30C30.6 25 31 25.4 31 26C31 26.6 30.6 27 30 27ZM30.2 21H9.1C8.5 21 8.1 20.6 8.1 20C8.1 19.4 8.5 19 9.1 19H30.2C30.8 19 31.2 19.4 31.2 20C31.2 20.6 30.8 21 30.2 21ZM30 15H9C8.4 15 8 14.6 8 14C8 13.4 8.4 13 9 13H30C30.6 13 31 13.4 31 14C31 14.6 30.6 15 30 15Z" fill="#0F0017"/>
  </svg>
</template>
