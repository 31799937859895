<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M27 15.9C27 18 26.8 20.1 26.4 22.1L26.3 22.5C25.8 24.6 24.6 26.4 23 27.7C22.6 28 22.2 28.3 21.8 28.6C20.3 29.5 18.7 30 17 30C16.1 30 15.2 29.9 14.3 29.7H14.2C13.4 29.5 12.7 29.2 12 28.9C10.7 28.4 9.4 27.7 8.1 27C5.5 25.5 3.1 23.7 1 21.4V5C1 3.9 1.9 3 3 3H24.2C25.4 5.6 26.2 8.3 26.6 11C26.9 12.6 27 14.3 27 15.9Z" fill="#00FF86"/>
    <path d="M37 40H3C1.3 40 0 38.7 0 37V5C0 3.3 1.3 2 3 2H10V1C10 0.4 10.4 0 11 0C11.6 0 12 0.4 12 1V2H28V1C28 0.4 28.4 0 29 0C29.6 0 30 0.4 30 1V2H37C38.7 2 40 3.3 40 5V37C40 38.7 38.7 40 37 40ZM2 15V37C2 37.6 2.4 38 3 38H37C37.6 38 38 37.6 38 37V13C38 12.4 37.6 12 37 12H3C2.4 12 2 12.4 2 13V15ZM2 9C2 9.6 2.4 10 3 10H37C37.6 10 38 9.6 38 9V5C38 4.4 37.6 4 37 4H30V5C30 5.6 29.6 6 29 6C28.4 6 28 5.6 28 5V4H12V5C12 5.6 11.6 6 11 6C10.4 6 10 5.6 10 5V4H3C2.4 4 2 4.4 2 5V9ZM34 34H28C27.4 34 27 33.6 27 33V27C27 26.4 27.4 26 28 26H34C34.6 26 35 26.4 35 27V33C35 33.6 34.6 34 34 34ZM29 32H33V28H29V32ZM23 34H17C16.4 34 16 33.6 16 33V27C16 26.4 16.4 26 17 26H23C23.6 26 24 26.4 24 27V33C24 33.6 23.6 34 23 34ZM18 32H22V28H18V32ZM12 34H6C5.4 34 5 33.6 5 33V27C5 26.4 5.4 26 6 26H12C12.6 26 13 26.4 13 27V33C13 33.6 12.6 34 12 34ZM7 32H11V28H7V32ZM34 23H28C27.4 23 27 22.6 27 22V16C27 15.4 27.4 15 28 15H34C34.6 15 35 15.4 35 16V22C35 22.6 34.6 23 34 23ZM29 21H33V17H29V21ZM23 23H17C16.4 23 16 22.6 16 22V16C16 15.4 16.4 15 17 15H23C23.6 15 24 15.4 24 16V22C24 22.6 23.6 23 23 23ZM18 21H22V17H18V21ZM12 23H6C5.4 23 5 22.6 5 22V16C5 15.4 5.4 15 6 15H12C12.6 15 13 15.4 13 16V22C13 22.6 12.6 23 12 23ZM7 21H11V17H7V21Z" fill="#0F0017"/>
  </svg>
</template>
