<template>
  <svg
    width="27"
    height="40"
    viewBox="0 0 27 40"
  >
    <path d="M10.5 16.5L5.89697 15.5V1.60437L7.5 2V5.5L13 5L18.8857 2.50001C20.1008 3.93674 21 5.19585 21 8.5C21 16 15.0951 15.6247 10.5 16.5Z" fill="#00FF86"/>
    <path d="M9.90005 17.7C8.60005 17.7 7.20005 17.4 5.80005 16.6C5.50005 16.4 5.30005 16.1 5.30005 15.7V4.90001C5.30005 4.50001 5.50005 4.20001 5.80005 4.00001C6.10005 3.80001 6.50005 3.80001 6.80005 4.00001C9.60005 5.70001 12.5 4.50001 15.5 3.30001C18.7 2.00001 22.4 0.600006 26 2.80001C26.3 3.00001 26.5 3.30001 26.5 3.70001V14.5C26.5 14.9 26.3 15.2 26 15.4C25.7001 15.6 25.3 15.6 25 15.4C22.2001 13.7 19.3 14.9 16.3 16.1C14.3 16.9 12.1 17.7 9.90005 17.7ZM7.40005 15.2C10 16.5 12.8 15.4 15.6 14.2C18.4 13.1 21.5 11.9 24.6 12.9V4.30001C22 3.00001 19.2 4.10001 16.4 5.30001C13.6 6.40001 10.5 7.60001 7.40005 6.60001V15.2Z" fill="#0F0017"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3001 8.90001C16.3001 9.50001 16.3001 10.2 16.3001 10.8C16.3001 11.5 16.5001 11.7 16.8001 11.5C17.0001 11.4 17.2001 11.2 17.4001 11.1C17.5001 11.5 17.6001 11.7 17.7001 12.1L17.6001 12.2C17.3001 12.4 17.0001 12.6 16.6001 12.7C16.1001 12.9 15.7001 12.9 15.4001 12.7C15.1001 12.5 15.0001 12.1 15.0001 11.5C15.0001 10.8 15.0001 10.1 15.0001 9.40001C14.7001 9.50001 14.4001 9.70001 14.1001 9.80001C14.1001 9.40001 14.1001 9.10001 14.1001 8.70001C14.4001 8.60001 14.7001 8.50001 15.0001 8.30001C15.0001 8.00001 15.0001 7.80001 15.0001 7.50001L15.1001 7.40001C15.4001 7.00001 15.7001 6.70001 16.0001 6.40001C16.1001 6.30001 16.2001 6.20001 16.3001 6.10001C16.3001 6.60001 16.3001 7.20001 16.3001 7.60001C16.7001 7.40001 17.1001 7.30001 17.4001 7.20001C17.4001 7.60001 17.4001 7.90001 17.4001 8.30001C17.1001 8.60001 16.6001 8.70001 16.3001 8.90001Z" fill="#0F0017"/>
    <path d="M6.3999 36.8C5.7999 36.8 5.3999 36.4 5.3999 35.8V4.29999C5.3999 3.69999 5.7999 3.29999 6.3999 3.29999C6.9999 3.29999 7.3999 3.69999 7.3999 4.29999V35.8C7.3999 36.4 6.8999 36.8 6.3999 36.8Z" fill="#0F0017"/>
    <path d="M6.3999 4.8C4.9999 4.8 3.8999 3.7 3.8999 2.4C3.8999 1.1 4.9999 0 6.3999 0C7.7999 0 8.7999 1.1 8.7999 2.4C8.7999 3.7 7.6999 4.8 6.3999 4.8ZM6.3999 2C6.0999 2 5.8999 2.2 5.8999 2.4C5.8999 2.6 6.0999 2.8 6.2999 2.8C6.4999 2.8 6.6999 2.6 6.6999 2.4C6.6999 2.2 6.5999 2 6.3999 2Z" fill="#0F0017"/>
    <path d="M6.4 40C2.7 40 0 38.6 0 36.6C0 35.4 1.1 34.3 3 33.7C3.5 33.5 4.1 33.8 4.3 34.4C4.5 35 4.2 35.5 3.6 35.7C2.4 36 2 36.5 2 36.6C2 37 3.5 38 6.4 38C9.2 38 10.8 37 10.8 36.6C10.8 36.5 10.4 36 9.2 35.6C8.7 35.4 8.4 34.9 8.5 34.3C8.6 33.7 9.2 33.5 9.8 33.6C11.7 34.2 12.8 35.2 12.8 36.5C12.7 38.6 10 40 6.4 40Z" fill="#0F0017"/>
  </svg>
</template>
