<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <g clip-path="url(#clip0_5567_201196)">
      <path d="M27 15.9002C27 18.0002 26.8 20.1002 26.4 22.1002L26.3 22.5002C25.9 24.2002 25 25.8002 23.8 27.0002C23.2 27.6002 22.6 28.1002 21.8 28.6002C19.5 30.0002 16.9 30.4002 14.3 29.7002H14.2C13.8 29.6002 13.4 29.4002 13 29.3002V25.0002C9.4 22.6002 7 18.6002 7 14.0002C7 6.80024 12.8 1.00024 20 1.00024C21.2 1.00024 22.3 1.20024 23.4 1.50024C25.8 6.00024 27 11.0002 27 15.9002Z" fill="#D1CCFF"/>
      <path d="M20 40.0002C17.5 40.0002 15.5 38.2002 15.1 35.9002C13.3 35.5002 12 33.9002 12 32.0002V25.5002C8.2 22.8002 6 18.6002 6 14.0002C6 6.30024 12.3 0.000244141 20 0.000244141C21.3 0.000244141 22.5 0.200244 23.7 0.500244C29.8 2.20024 34 7.70024 34 14.0002C34 18.6002 31.8 22.8002 28.1 25.4002L28 32.0002C28 33.9002 26.7 35.5002 24.9 35.9002C24.5 38.2002 22.5 40.0002 20 40.0002ZM17.2 36.0002C17.6 37.2002 18.7 38.0002 20 38.0002C21.3 38.0002 22.4 37.2002 22.8 36.0002H17.2ZM14 32.0002C14 33.1002 14.9 34.0002 16 34.0002H24C25.1 34.0002 26 33.1002 26 32.0002H14ZM14 30.0002H26V28.0002H14V30.0002ZM21 26.0002H26.1V24.9002C26.1 24.6002 26.3 24.3002 26.6 24.1002C30 21.8002 32 18.1002 32 14.0002C32 8.60024 28.4 3.80024 23.2 2.40024C22.1 2.10024 21.1 2.00024 20 2.00024C13.4 2.00024 8 7.40024 8 14.0002C8 18.1002 10.1 21.9002 13.5 24.1002C13.8 24.3002 14 24.6002 14 24.9002V26.0002H19V17.9002C17.3 17.5002 16 15.9002 16 14.0002C16 11.8002 17.8 10.0002 20 10.0002C22.2 10.0002 24 11.8002 24 14.0002C24 15.9002 22.7 17.4002 21 17.9002V26.0002ZM20 12.0002C18.9 12.0002 18 12.9002 18 14.0002C18 15.1002 18.9 16.0002 20 16.0002C21.1 16.0002 22 15.1002 22 14.0002C22 12.9002 21.1 12.0002 20 12.0002Z" fill="#0F0017"/>
    </g>
    <defs>
      <clipPath id="clip0_5567_201196">
        <rect width="40" height="40" fill="white" transform="translate(0 0.000244141)"/>
      </clipPath>
    </defs>
  </svg>
</template>
