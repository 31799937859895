<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path d="M4.07877 8.06726H0.649722C0.345346 8.06726 0.139893 7.86941 0.139893 7.56504C0.139893 7.28349 0.276862 7.12369 0.566019 7.00194C1.07585 6.83453 1.5248 6.33231 1.5248 5.57898C1.5248 5.38114 1.47915 5.16047 1.42588 4.9398H0.581237C0.322518 4.9398 0.139893 4.77239 0.139893 4.52128C0.139893 4.26256 0.322518 4.09515 0.581237 4.09515H1.23565C1.15955 3.81361 1.10629 3.51684 1.10629 3.22007C1.10629 1.83516 2.06507 0.937256 3.66304 0.937256C4.1957 0.937256 4.62943 0.998131 4.97186 1.15032C5.2534 1.26446 5.35994 1.43948 5.35994 1.66015C5.35994 1.93409 5.19253 2.10149 4.89576 2.10149C4.80595 2.10149 4.71062 2.07833 4.59255 2.04964C4.40052 2.00297 4.14836 1.9417 3.76196 1.9417C2.8184 1.9417 2.27052 2.45913 2.27052 3.22768C2.27052 3.54728 2.32379 3.82882 2.39227 4.09515H4.38593C4.65226 4.09515 4.82728 4.26256 4.82728 4.52128C4.82728 4.77239 4.65226 4.9398 4.38593 4.9398H2.58251C2.62816 5.13764 2.6586 5.34309 2.6586 5.57898C2.6586 6.17252 2.4151 6.72039 2.04224 7.0476H4.35661C4.23272 7.37378 4.13898 7.7148 4.07877 8.06726Z" />
    <path d="M12.682 12.1819C10.9246 13.9393 8.07538 13.9393 6.31802 12.1819C4.56066 10.4246 4.56066 7.57533 6.31802 5.81797C8.07538 4.06061 10.9246 4.06061 12.682 5.81797C14.4393 7.57533 14.4393 10.4246 12.682 12.1819ZM11.4142 7.5863L11.4142 7.58273C11.4138 7.51603 11.4004 7.45243 11.3763 7.39434C11.3522 7.33624 11.3168 7.28175 11.2699 7.23431L11.2678 7.23218L11.2656 7.23007C11.2182 7.18318 11.1637 7.14771 11.1056 7.12367C11.0467 7.09923 10.982 7.08573 10.9142 7.08573H8.08579C7.80964 7.08573 7.58579 7.30959 7.58579 7.58573C7.58579 7.86188 7.80964 8.08573 8.08579 8.08573H9.70711L7.73223 10.0606C7.53697 10.2559 7.53697 10.5725 7.73223 10.7677C7.9275 10.963 8.24408 10.963 8.43934 10.7677L10.4142 8.79284L10.4142 10.4142C10.4142 10.6903 10.6381 10.9142 10.9142 10.9142C11.1904 10.9142 11.4142 10.6903 11.4142 10.4142L11.4142 7.5863Z" />
  </svg>
</template>
