<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M15 20C14.45 20 14 20.45 14 21C14 21.55 14.45 22 15 22C15.55 22 16 21.55 16 21C16 20.45 15.55 20 15 20Z" fill="#0F0017"/>
    <path d="M26 21C26 20.45 25.55 20 25 20C24.45 20 24 20.45 24 21C24 21.55 24.45 22 25 22C25.55 22 26 21.55 26 21Z" fill="#0F0017"/>
    <path d="M38 16H34V14.37C34 5.18004 25.66 -2.01996 16.19 0.510041C14.94 0.840042 13.74 1.37004 12.66 2.06004C8.41 4.76004 6.01 9.17004 6.01 14V16H2C1.63 16 1.29 16.11 0.99 16.28C0.4 16.63 0 17.26 0 18V20V27C0 28.1 0.9 29 2 29H6C7.1 29 8 28.1 8 27V14.36C8 11.05 9.21 7.79004 11.57 5.46004C13.98 3.08004 17.19 1.85004 20.58 2.01004C26.98 2.31004 32 7.74004 32 14.36V32C32 33.66 30.66 35 29 35H23.86C23.34 32.99 21.31 31.59 19.05 32.11C17.61 32.44 16.45 33.61 16.11 35.04C15.5 37.66 17.48 40 20 40C21.86 40 23.41 38.72 23.86 37H29C31.76 37 34 34.76 34 32V29H38C39.1 29 40 28.1 40 27V18C40 16.9 39.1 16 38 16ZM6 19V27H3C2.45 27 2 26.55 2 26V20V19C2 18.45 2.45 18 3 18H6V19ZM20 38C18.9 38 18 37.1 18 36C18 34.9 18.9 34 20 34C21.1 34 22 34.9 22 36C22 37.1 21.1 38 20 38ZM38 25V26C38 26.55 37.55 27 37 27H34V18H37C37.55 18 38 18.45 38 19V25Z" fill="#0F0017"/>
  </svg>
</template>
