<template>
  <svg
    width="40"
    height="34"
    viewBox="0 0 40 34"
  >
    <path d="M27 12.9C27 15 26.8 17.1 26.4 19.1L26.3 19.5C25.7 22.1 24.1 24.2 21.8 25.6C21.5 25.8 21.3 25.9 21 26C20.4 26.3 19.7 26.6 19 26.7C17.5 27.1 15.9 27.1 14.3 26.7H14.2C9.3 25.1 4.7 22.3 1 18.4V4C1 2.3 2.3 1 4 1H24.6C26.2 4.8 27 8.9 27 12.9Z" fill="#00FF86"/>
    <path d="M10 34C9.8 34 9.7 34 9.5 33.9C9.2 33.7 9 33.4 9 33V27H4C1.8 27 0 25.2 0 23V4C0 1.8 1.8 0 4 0H36C38.2 0 40 1.8 40 4V23C40 25.2 38.2 27 36 27H20.3L10.6 33.8C10.4 33.9 10.2 34 10 34ZM4 2C2.9 2 2 2.9 2 4V23C2 24.1 2.9 25 4 25H10C10.6 25 11 25.4 11 26V31.1L19.4 25.2C19.6 25.1 19.8 25 20 25H36C37.1 25 38 24.1 38 23V4C38 2.9 37.1 2 36 2H4Z" fill="#0F0017"/>
  </svg>
</template>
