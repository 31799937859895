<template>
  <svg
    width="40"
    height="34"
    viewBox="0 0 40 34"
  >
    <path d="M7.00005 13C7.60005 6.3 13.2001 1 20.0001 1C21.8001 1 23.5001 1.4 25.0001 2C26.3001 5.5 27.0001 9.2 27.0001 12.9C27.0001 15 26.8001 17.1 26.4001 19.1L26.3001 19.5C26.0001 20.8 25.4001 22 24.6001 23L24.4001 23.3C23.7001 24.2 22.8001 25 21.8001 25.6C19.5001 27 16.9001 27.4 14.3001 26.7H14.2001C11.3001 25.8 8.50005 24.5 6.00005 22.7V21C6.00005 20.5 5.60005 20 5.00005 20H4.70005C2.80005 20 1.10005 18.6 1.00005 16.7C0.900051 14.7 2.50005 13 4.50005 13H4.70005C5.40005 13 6.20005 13.1 6.80005 13.4L7.00005 13Z" fill="#00FF86"/>
    <path d="M31 34C30.4 34 30 33.6 30 33V31H9.99998V33C9.99998 33.6 9.59998 34 8.99998 34C8.39998 34 7.99998 33.6 7.99998 33V31C6.29998 31 4.99998 29.7 4.99998 28V21H4.69998C2.19998 21 0.099984 19.1 -1.60392e-05 16.7C-0.100016 15.5 0.399984 14.3 1.19998 13.4C1.99998 12.5 3.19998 12 4.49998 12H4.69998C5.19998 12 5.59998 12 6.09998 12.1C6.99998 5.3 13 0 20 0C27 0 33 5.3 33.9 12.1C34.3 12 34.8 12 35.3 12H35.5C36.7 12 37.9 12.5 38.8 13.4C39.7 14.3 40.1 15.5 40 16.7C39.9 19.1 37.8 21 35.3 21H35V28C35 29.7 33.7 31 32 31V33C32 33.6 31.6 34 31 34ZM4.49998 14C3.79998 14 3.19998 14.3 2.69998 14.8C2.19998 15.3 1.99998 15.9 1.99998 16.6C2.09998 17.9 3.29998 19 4.69998 19H4.99998C6.09998 19 6.99998 19.9 6.99998 21V28C6.99998 28.6 7.39998 29 7.99998 29H32C32.6 29 33 28.6 33 28V21C33 19.9 33.9 19 35 19H35.3C36.7 19 37.9 17.9 38 16.6C38 15.9 37.8 15.2 37.3 14.7C36.8 14.2 36.2 13.9 35.5 13.9H35.3C34.1 13.9 32.9 14.3 32.2 15.1C31.4 16 31 16.9 31 18C31 23.2 27.9 24 26 24H14C12.1 24 8.99998 23.2 8.99998 18C8.99998 16.9 8.59998 16 7.89998 15.2C7.09998 14.4 5.99998 14 4.69998 14H4.49998ZM8.09998 12.9C8.59998 13.2 8.99998 13.5 9.29998 13.9C10.4 15 11 16.5 11 18.1C11 21.7 12.7 22.1 14 22.1H26C27.3 22.1 29 21.7 29 18.1C29 16.5 29.6 15 30.7 13.9C31.1 13.5 31.5 13.2 31.9 13C31.4 6.8 26.2 2 20 2C13.8 2 8.59998 6.7 8.09998 12.9Z" fill="#0F0017"/>
  </svg>
</template>
