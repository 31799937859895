import { verification, errorItem } from '../components'

export default {
  install: (app) => {
    function validation(items:Array<verification>, error:Array<errorItem>, text:any) {
      for (let x = 0; x < items.length; x++) {
        if (!items[x].name) {
          const i = error.findIndex(i => i.type === items[x].type)
          if (i !== -1) {
            error.splice(i, 1)
          }
          error.push({
            type: items[x].type,
            message: (items[x].fieldTitle || text.t(`signUpPage.${items[x].type}`)) + text.t('forms.fieldsRequired')
          })
        } else if (items[x].type === 'photos') {
          if (items[x].name.length === 0) {
            const i = error.findIndex(i => i.type === items[x].type)
            if (i !== -1) {
              error.splice(i, 1)
            }
            error.push({
              type: items[x].type,
              message: items[x].fieldTitle + text.t('forms.fieldsRequired')
            })
          }
        } else if (items[x].type === 'description') {
          if (items[x].name.length > 0 && items[x].name.length < 20) {
            const i = error.findIndex(i => i.type === items[x].type)
            if (i !== -1) {
              error.splice(i, 1)
            }
            error.push({
              type: items[x].type,
              message: text.t('forms.textareaInputTooShort')
            })
          }
          if (items[x].name.length > 400) {
            const i = error.findIndex(i => i.type === items[x].type)
            if (i !== -1) {
              error.splice(i, 1)
            }
            error.push({
              type: items[x].type,
              message: text.t('forms.textareaInputTooLong')
            })
          }
        } else if (items[x].type === 'title') {
          if (items[x].name.length > 0 && items[x].name.length < 5) {
            const i = error.findIndex(i => i.type === items[x].type)
            if (i !== -1) {
              error.splice(i, 1)
            }
            error.push({
              type: items[x].type,
              message: text.t('createShiftPage.sections.locationJobDetails.JobAddMode.title.errorMessage')
            })
          }
          if (items[x].name.length > 40) {
            const i = error.findIndex(i => i.type === items[x].type)
            if (i !== -1) {
              error.splice(i, 1)
            }
            error.push({
              type: items[x].type,
              message: text.t('createShiftPage.sections.locationJobDetails.JobAddMode.title.errorMessageTooLong')
            })
          }
        } else if (items[x].type === 'briefing') {
          if (items[x].name.length > 0 && items[x].name.length < 50) {
            const i = error.findIndex(i => i.type === items[x].type)
            if (i !== -1) {
              error.splice(i, 1)
            }
            error.push({
              type: items[x].type,
              message: text.t('forms.textareaInputTooShortExtended')
            })
          }
          if (items[x].name.length > 7500) {
            const i = error.findIndex(i => i.type === items[x].type)
            if (i !== -1) {
              error.splice(i, 1)
            }
            error.push({
              type: items[x].type,
              message: text.t('forms.textareaInputTooLongExtended')
            })
          }
        } else if (items[x].name.length === 1 && !Array.isArray(items[x].name)) {
          const i = error.findIndex(i => i.type === items[x].type)
          if (i !== -1) {
            error.splice(i, 1)
          }
          error.push({
            type: items[x].type,
            message: items[x].fieldTitle + text.t('forms.fieldsInputTooShort')
          })
        } else if (items[x].name.length <= 2 && !Array.isArray(items[x].name) && items[x].name.match(/ /)) {
          const i = error.findIndex(i => i.type === items[x].type)
          if (i !== -1) {
            error.splice(i, 1)
          }
          error.push({
            type: items[x].type,
            message: items[x].fieldTitle + text.t('forms.fieldsRequired')
          })
        } else if (items[x].name.length >= 255) {
          const i = error.findIndex(i => i.type === items[x].type)
          if (i !== -1) {
            error.splice(i, 1)
          }
          error.push({
            type: items[x].type,
            message: items[x].fieldTitle + text.t('forms.fieldsInputTooLong')
          })
        } else if (
          typeof (items[x].name) === 'string' &&
            items[x].name.match(/^.^[\p{L}\p{N}\p{P}\p{Sc}+=^`|~]*$.*$/) &&
            items[x].type !== 'password' &&
            items[x].type !== 'companyName' &&
            items[x].type !== 'startTime' &&
            items[x].type !== 'endTime' &&
            items[x].type !== 'date'
        ) {
          const i = error.findIndex(i => i.type === items[x].type)
          if (i !== -1) {
            error.splice(i, 1)
          }
          error.push({ type: items[x].type, message: text.t('forms.fieldsInputCharacters') })
        } else if (items[x].type === 'email') {
          if (items[x].name.length <= 7) {
            const i = error.findIndex(i => i.type === items[x].type)
            if (i !== -1) {
              error.splice(i, 1)
            }
            error.push({ type: items[x].type, message: text.t('forms.emailNotValid') })
          } else if (!items[x].name.toLowerCase().match(/@/)) {
            const i = error.findIndex(i => i.type === items[x].type)
            if (i !== -1) {
              error.splice(i, 1)
            }
            error.push({ type: items[x].type, message: text.t('forms.emailNotValid') })
          } else if (!items[x].name.toLowerCase().match(/./)) {
            const i = error.findIndex(i => i.type === items[x].type)
            if (i !== -1) {
              error.splice(i, 1)
            }
            error.push({ type: items[x].type, message: text.t('forms.emailNotValid') })
          } else if (items[x].name.length >= 255) {
            const i = error.findIndex(i => i.type === items[x].type)
            if (i !== -1) {
              error.splice(i, 1)
            }
            error.push({ type: items[x].type, message: text.t('forms.emailNotValid') })
          }
        } else if (items[x].name.length >= 2 && items[x].name.length < 255 && error.length > 0) {
          const i = error.findIndex(i => i.type === items[x].type)
          if (i !== -1) {
            error.splice(i, 1)
          }
        }
      }
    }
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$validation = validation
    app.provide('validation', validation)
  }
}
