<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <path d="M3.25 18C1.45507 18 1.27207e-07 16.5449 2.84124e-07 14.75L1.28949e-06 3.25C1.4464e-06 1.45507 1.45508 -1.4464e-06 3.25 -1.28949e-06L14.75 -2.84124e-07C16.5449 -1.27207e-07 18 1.45507 18 3.25L18 14.75C18 16.5449 16.5449 18 14.75 18L3.25 18ZM3.25 16.5L14.75 16.5C15.7165 16.5 16.5 15.7165 16.5 14.75L16.5 3.25C16.5 2.2835 15.7165 1.5 14.75 1.5L3.25 1.5C2.2835 1.5 1.5 2.2835 1.5 3.25L1.5 14.75C1.5 15.7165 2.2835 16.5 3.25 16.5Z" />
    <path d="M0.750001 9.00684L17.25 9.00685L17.3518 9C17.7178 8.95034 18 8.63654 18 8.25685C18 7.84263 17.6642 7.50685 17.25 7.50685L0.750001 7.50684L0.648231 7.51369C0.282155 7.56335 8.84968e-07 7.87715 8.51774e-07 8.25684C8.15562e-07 8.67106 0.335788 9.00684 0.750001 9.00684Z" />
    <path d="M4.75 14.9972L13.25 14.9972L13.3518 14.9904C13.7178 14.9407 14 14.6269 14 14.2472C14 13.833 13.6642 13.4972 13.25 13.4972L4.75 13.4972L4.64823 13.5041C4.28216 13.5537 4 13.8675 4 14.2472C4 14.6614 4.33579 14.9972 4.75 14.9972Z" />
    <path d="M4.75 12.0019L13.25 12.0019L13.3518 11.9951C13.7178 11.9454 14 11.6316 14 11.2519C14 10.8377 13.6642 10.5019 13.25 10.5019L4.75 10.5019L4.64823 10.5088C4.28216 10.5584 4 10.8722 4 11.2519C4 11.6661 4.33579 12.0019 4.75 12.0019Z" />
  </svg>
</template>
