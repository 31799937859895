<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
  >
    <path d="M10 0.5C15.523 0.5 20 4.978 20 10.5C20 16.022 15.523 20.5 10 20.5C4.477 20.5 0 16.022 0 10.5C0 4.978 4.477 0.5 10 0.5ZM10 2.167C5.405 2.167 1.667 5.905 1.667 10.5C1.667 15.095 5.405 18.833 10 18.833C14.595 18.833 18.333 15.095 18.333 10.5C18.333 5.905 14.595 2.167 10 2.167Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.60104 3.39896L17.601 16.399L16.399 17.601L3.39896 4.60104L4.60104 3.39896Z" />
    <path d="M12.1929 14.407C11.9731 14.5212 11.6479 14.5828 11.1909 14.5828C9.25732 14.5828 7.67529 13.572 7.20947 11.6296H6.31299C6.07568 11.6296 5.8999 11.4539 5.8999 11.2166C5.8999 10.9705 6.07568 10.8035 6.31299 10.8035H7.09521C7.09521 10.7156 7.08643 10.5837 7.08643 10.4343C7.08643 10.2937 7.08643 10.1794 7.09521 10.074H6.31299C6.07568 10.074 5.8999 9.8894 5.8999 9.6521C5.8999 9.40601 6.07568 9.23901 6.31299 9.23901H7.20947C7.6665 7.29663 9.25732 6.29468 11.1909 6.29468C11.6479 6.29468 11.9731 6.33862 12.1841 6.44409C12.4653 6.57593 12.5532 6.76929 12.5532 6.9978C12.5532 7.29663 12.3511 7.4812 12.0786 7.50757C11.8853 7.55151 11.5601 7.50757 11.1909 7.51636C9.99561 7.52515 9.00244 8.10522 8.64209 9.23901H11.2964C11.5425 9.23901 11.7095 9.40601 11.7095 9.6521C11.7095 9.8894 11.5425 10.074 11.2964 10.074H8.45752C8.44873 10.1794 8.44873 10.3025 8.44873 10.4343C8.44873 10.5662 8.44873 10.7068 8.46631 10.8035H11.2964C11.5425 10.8035 11.7095 10.9705 11.7095 11.2166C11.7095 11.4539 11.5425 11.6296 11.2964 11.6296H8.64209C9.00244 12.781 9.98682 13.3611 11.1821 13.3611C11.5601 13.3699 11.8677 13.3347 12.0786 13.3699C12.3599 13.3962 12.5532 13.5808 12.5532 13.8796C12.5532 14.1169 12.439 14.2927 12.1929 14.407Z" />
  </svg>
</template>
