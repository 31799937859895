<template>
  <svg
    width="36"
    height="40"
    viewBox="0 0 36 40"
  >
    <path d="M4.08462 11.5692L2.60769 27.5692C8.14615 31.8769 14.3 33.2308 18.6077 30.5231C23.7769 27.2 24.7615 19.0769 21.6846 10.4615H5.19231C4.57692 10.5846 4.20769 10.9538 4.08462 11.5692Z" fill="#00FF86"/>
    <path d="M32.8846 11.4462C32.7615 9.84615 31.4077 8.61539 29.8077 8.61539H25.2538V8C25.2538 3.56923 21.6846 0 17.2538 0C16.6385 0 16.1462 1.74229e-07 15.6538 0.123077C11.9615 0.861539 9.25385 4.18462 9.25385 8V8.61539H5.19231C3.59231 8.61539 2.36154 9.84615 2.11538 11.4462L0.884615 26.0923L0.0230769 35.4462C-0.1 36.5538 0.26923 37.7846 1.13077 38.6462C1.99231 39.5077 2.97692 40 4.20769 40H31.0385C32.1462 40 33.2538 39.5077 34.1154 38.6462C34.8538 37.7846 35.2231 36.6769 35.2231 35.4462L32.8846 11.4462ZM11.1 8C11.1 4.67692 13.6846 1.96923 17.0077 1.84615C17.1308 1.84615 17.1308 1.84615 17.2538 1.84615C20.5769 1.84615 23.4077 4.55385 23.4077 8V8.61539H11.1V8ZM32.5154 37.2923C32.1462 37.7846 31.5308 38.0308 30.9154 38.0308H4.08462C3.46923 38.0308 2.85385 37.7846 2.48462 37.2923C2.11538 36.8 1.86923 36.1846 1.86923 35.5692L2.60769 27.5692L4.08462 11.5692C4.08462 10.9538 4.57692 10.5846 5.19231 10.5846H29.8077C30.4231 10.5846 30.9154 11.0769 30.9154 11.5692L33.1308 35.5692C33.1308 36.1846 32.8846 36.8 32.5154 37.2923Z" fill="#0F0017"/>
  </svg>
</template>
