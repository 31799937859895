<template>
  <svg
    width="19"
    height="22"
    viewBox="0 0 19 22"
  >
    <path d="M19 16C19 19.0376 16.5376 21.5 13.5 21.5C10.4624 21.5 8 19.0376 8 16C8 12.9624 10.4624 10.5 13.5 10.5C16.5376 10.5 19 12.9624 19 16ZM16.8536 13.6464C16.6583 13.4512 16.3417 13.4512 16.1464 13.6464L12.5 17.2929L10.8536 15.6464C10.6583 15.4512 10.3417 15.4512 10.1464 15.6464C9.95118 15.8417 9.95118 16.1583 10.1464 16.3536L12.1464 18.3536C12.3417 18.5488 12.6583 18.5488 12.8536 18.3536L16.8536 14.3536C17.0488 14.1583 17.0488 13.8417 16.8536 13.6464Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8531 13.9398C15.5283 13.0975 14.711 12.4999 13.7542 12.4999H9.25722C8.88861 12.9462 8.58908 13.4517 8.37497 13.9999H13.7542C14.1678 13.9999 14.503 14.3352 14.503 14.7488V15.2898L15.8531 13.9398ZM16.003 15.2041L12.8536 18.3536L12.8527 18.3544C12.6577 18.5485 12.3423 18.5485 12.1473 18.3544L12.1464 18.3536L12.0309 18.238C11.1472 18.6502 10.0966 18.8965 8.87277 18.9742C9.21095 19.4993 9.63605 19.963 10.1277 20.3452C12.2414 20.0146 13.9083 19.1573 15.1019 17.7627C15.6835 17.0833 16.003 16.2185 16.003 15.3242V15.2041ZM8.02186 12.4999H2.25242C1.0104 12.4999 0.00354004 13.5068 0.00354004 14.7488V15.3265C0.00354004 16.2193 0.322059 17.0828 0.90182 17.7617C2.46812 19.5959 4.85414 20.5011 8 20.5011C8.26943 20.5011 8.53329 20.4944 8.79156 20.4812C8.37263 20.0411 8.01476 19.5424 7.73151 18.9987C5.13276 18.9511 3.25447 18.2069 2.04251 16.7876C1.69465 16.3802 1.50354 15.8622 1.50354 15.3265V14.7488C1.50354 14.3352 1.83882 13.9999 2.25242 13.9999H7.31352C7.48586 13.4665 7.72529 12.9631 8.02186 12.4999ZM13 5.50464C13 2.74321 10.7614 0.504639 8 0.504639C5.23857 0.504639 3 2.74321 3 5.50464C3 8.26606 5.23857 10.5046 8 10.5046C10.7614 10.5046 13 8.26606 13 5.50464ZM4.5 5.50464C4.5 3.57164 6.067 2.00464 8 2.00464C9.93299 2.00464 11.5 3.57164 11.5 5.50464C11.5 7.43764 9.93299 9.00464 8 9.00464C6.067 9.00464 4.5 7.43764 4.5 5.50464Z" />
  </svg>
</template>
