<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
  >
    <path d="M6 0.5C9.31371 0.5 12 3.18629 12 6.5C12 9.81371 9.31371 12.5 6 12.5C2.68629 12.5 0 9.81371 0 6.5C0 3.18629 2.68629 0.5 6 0.5ZM6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5Z" />
    <path d="M4.13937 9.46004H7.84806C8.1071 9.46004 8.284 9.28945 8.284 9.03673C8.284 8.77769 8.1071 8.61342 7.84806 8.61342H5.29557C5.60516 8.34175 5.80733 7.88685 5.80733 7.39404C5.80733 7.19818 5.78206 7.0276 5.74415 6.86333H7.24153C7.46266 6.86333 7.60797 6.72433 7.60797 6.51583C7.60797 6.30102 7.46266 6.16202 7.24153 6.16202H5.5862C5.52934 5.94089 5.48511 5.70713 5.48511 5.44177C5.48511 4.80365 5.94001 4.37402 6.72345 4.37402C7.24153 4.37402 7.46898 4.5067 7.66484 4.5067C7.91124 4.5067 8.05024 4.3677 8.05024 4.14025C8.05024 3.95703 7.96178 3.81171 7.72802 3.71694C7.44371 3.59058 7.08358 3.54004 6.64132 3.54004C5.31453 3.54004 4.51846 4.28557 4.51846 5.43545C4.51846 5.68185 4.56268 5.92826 4.62586 6.16202H4.08251C3.8677 6.16202 3.71606 6.30102 3.71606 6.51583C3.71606 6.72433 3.8677 6.86333 4.08251 6.86333H4.78381C4.82804 7.04655 4.86595 7.22977 4.86595 7.39404C4.86595 8.01953 4.49318 8.43652 4.06987 8.57551C3.82979 8.6766 3.71606 8.80928 3.71606 9.04305C3.71606 9.29577 3.88665 9.46004 4.13937 9.46004Z" />
  </svg>
</template>
