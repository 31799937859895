<template>
  <svg
    width="6"
    height="12"
    viewBox="0 0 6 12"
  >
    <path d="M0.0760887 8.11732C0.23087 7.74364 0.595506 7.5 0.999968 7.5H4.99997C5.40443 7.5 5.76907 7.74364 5.92385 8.11732C6.07863 8.49099 5.99307 8.92111 5.70707 9.20711L3.70707 11.2071C3.31655 11.5976 2.68339 11.5976 2.29286 11.2071L0.292861 9.20711C0.00686342 8.92111 -0.0786923 8.49099 0.0760887 8.11732Z" />
    <path d="M5.92385 3.88268C5.76907 4.25636 5.40443 4.5 4.99997 4.5L0.999971 4.5C0.595509 4.5 0.230872 4.25636 0.0760913 3.88268C-0.0786895 3.50901 0.0068661 3.07889 0.292864 2.79289L2.29286 0.792893C2.68339 0.402369 3.31655 0.402369 3.70708 0.792893L5.70708 2.79289C5.99308 3.07889 6.07863 3.50901 5.92385 3.88268Z" />
  </svg>
</template>
