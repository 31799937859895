<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8064 5C13.7242 4.68051 13.6805 4.34558 13.6805 4.00044C13.6805 4.00029 13.6805 4.00015 13.6805 4H2.5C2.22386 4 2 4.22386 2 4.5C2 4.77614 2.22386 5 2.5 5H13.8064ZM2.5 9C2.22386 9 2 9.22386 2 9.5C2 9.77614 2.22386 10 2.5 10H17.5C17.7761 10 18 9.77614 18 9.5C18 9.22386 17.7761 9 17.5 9H2.5ZM2 14.5C2 14.2239 2.22386 14 2.5 14H17.5C17.7761 14 18 14.2239 18 14.5C18 14.7761 17.7761 15 17.5 15H2.5C2.22386 15 2 14.7761 2 14.5Z" />
    <path d="M20 3.99995C20 4.05812 19.9978 4.11579 19.9936 4.17287C19.9863 4.27066 19.9728 4.36674 19.9536 4.46072L19.9514 4.47112C19.7342 5.50777 18.8149 6.28628 17.7137 6.28628C16.7182 6.28628 15.8713 5.65006 15.5575 4.76206C15.4732 4.52369 15.4274 4.26717 15.4274 3.99995C15.4274 3.92103 15.4314 3.84305 15.4392 3.76619L15.4413 3.74591C15.5677 2.60276 16.5369 1.71362 17.7137 1.71362C18.9764 1.71362 20 2.73725 20 3.99995Z" class="x"/>
  </svg>
</template>

<style lang="scss" scoped>
.x {
  fill: #00FF86 !important
}
</style>