<template>
  <svg
    width="40"
    height="38"
    viewBox="0 0 40 38"
  >
    <path d="M24 19C24 21 23.8 22.075 23.4 24.075L23.3 24.475C22.7 27.075 21.1 29.175 18.8 30.575C16.5 31.975 13.9 32.375 11.3 31.675H11.2C7.9 30.675 4.7 29.075 1.9 26.975C1.3 25.375 1 23.675 1 21.975V17.875C1 17.075 7.5 22.5 7.5 22.5L12 16.5L15.5 20C15.5 20 19.8 14.4 20 14.5C21.3013 15.8013 24 19 24 19Z" fill="#00FF86"/>
    <path d="M19.2001 30.1L16.4001 27.3C16.0001 26.9 15.9001 26.3 16.2001 25.8L19.0001 21.2C19.5001 20.4 20.7001 20.4 21.2001 21.2L24.0001 25.8C24.3001 26.3 24.2001 26.9 23.8001 27.3L21.0001 30.1C20.4001 30.6 19.7001 30.6 19.2001 30.1Z" fill="#0F0017"/>
    <path d="M4.60003 37.5C4.00003 37.5 3.50003 37.3 3.00003 37C2.50003 36.6 2.10003 36 2.10003 35.3C1.80003 32.6 1.40003 29.9 1.10003 27.2C0.700031 24.5 0.400031 21.8 0.100031 19.1C3.11658e-05 18.4 0.400031 17.6 1.00003 17.2C1.60003 16.9 2.30003 16.9 2.80003 17.3C5.50003 19.6 8.30003 22.1 11.5 25.1C14.1 21 16.4 17.5 18.7 14.4C19.4 13.5 20.8 13.5 21.5 14.4L22.8 16.4C24.7 19.3 26.7 22.2 28.7 25.2C31.6 22.5 34.5 19.9 37.3 17.4C37.8 17 38.5 16.9 39.1 17.3C39.8 17.7 40.1 18.4 40 19.2C39.7 21.9 39.3 24.6 39 27.3C38.6 30 38.3 32.7 38 35.4C37.8 36 37.5 36.6 37 37C36.4 37.4 35.7 37.6 34.9 37.4C34.7 37.4 34.6 37.4 34.5 37.3C34.4 37.3 34.3 37.3 34.2 37.3C29.9 36.4 26 35.9 22.3 35.8C20.7 35.7 19.2 35.7 17.7 35.8C13.5 36 9.50003 36.5 5.80003 37.3C5.70003 37.3 5.70003 37.3 5.60003 37.3C5.50003 37.3 5.30003 37.4 5.10003 37.4C4.90003 37.4 4.80003 37.5 4.60003 37.5ZM2.10003 19.3C2.40003 21.8 2.70003 24.4 3.10003 26.9C3.50003 29.6 3.80003 32.3 4.20003 35.1C4.20003 35.3 4.30003 35.4 4.30003 35.4C4.40003 35.5 4.60003 35.5 4.90003 35.5C5.00003 35.5 5.00003 35.5 5.10003 35.5C5.20003 35.5 5.40003 35.4 5.50003 35.4C9.30003 34.6 13.4 34.1 17.7 33.9C19.2 33.8 20.8 33.8 22.5 33.9C26.3 34 30.3 34.5 34.7 35.4C34.9 35.4 35 35.4 35.1 35.5C35.2 35.5 35.3 35.5 35.3 35.5C35.5 35.5 35.7 35.5 35.9 35.4C36 35.4 36 35.3 36 35.1C36.3 32.4 36.7 29.6 37.1 26.9C37.4 24.4 37.8 21.8 38.1 19.3C35.3 21.8 32.3 24.5 29.3 27.3C29.1 27.5 28.8 27.6 28.5 27.6C28.2 27.6 27.9 27.4 27.8 27.1C25.7 23.8 23.4 20.5 21.3 17.3L20.3 15.8C18 19.1 15.5 22.8 12.8 27.1C12.6 27.4 12.4 27.5 12.1 27.6C11.8 27.6 11.5 27.5 11.3 27.3C7.60003 24.2 4.80003 21.6 2.10003 19.3Z" fill="#0F0017"/>
    <path d="M32.5 23.8C32.2 23.8 31.8 23.6 31.6 23.3C31.1 22.5 30.7 21.8 30.2 21C29.5 19.9 28.9 18.9 28.2 17.8C27.8 18.4 27.4 18.9 27 19.4C26.5 20.1 26 20.7 25.5 21.4C25.2 21.8 24.5 21.9 24.1 21.6C23.7 21.3 23.6 20.6 23.9 20.2C24.4 19.5 24.9 18.9 25.4 18.2C25.9 17.5 26.4 16.8 26.9 16.2C27.5 15.4 29 15.4 29.6 16.3C29.6 16.3 29.6 16.4 29.7 16.4C30.4 17.6 31.1 18.7 31.9 19.9C32.4 20.6 32.8 21.4 33.3 22.2C33.6 22.7 33.4 23.3 33 23.6C32.9 23.8 32.7 23.8 32.5 23.8Z" fill="#0F0017"/>
    <path d="M7.70007 23.8C7.50007 23.8 7.30007 23.8 7.20007 23.7C6.70007 23.4 6.60007 22.8 6.90007 22.3C7.40007 21.5 7.90007 20.8 8.30007 20C9.00007 18.8 9.80007 17.7 10.5001 16.5C10.5001 16.5 10.5001 16.4 10.6001 16.4C11.3001 15.5 12.7001 15.5 13.3001 16.3C14.1001 17.3 14.9001 18.4 15.7001 19.5L16.4001 20.4C16.7001 20.8 16.6001 21.5 16.2001 21.8C15.8001 22.1 15.1001 22 14.8001 21.6L14.0001 20.7C13.3001 19.7 12.6001 18.8 11.9001 17.9C11.3001 19 10.6001 20 9.90007 21.1C9.40007 21.8 8.90007 22.6 8.50007 23.4C8.30007 23.6 8.00007 23.8 7.70007 23.8ZM11.6001 17.5C11.6001 17.5 11.7001 17.5 11.6001 17.5C11.7001 17.5 11.6001 17.5 11.6001 17.5Z" fill="#0F0017"/>
    <path d="M20 7C19.4 7 19 6.6 19 6V1C19 0.4 19.4 0 20 0C20.6 0 21 0.4 21 1V6C21 6.5 20.6 7 20 7Z" fill="#0F0017"/>
    <path d="M13.7 11C13.3 11 12.9 10.8 12.8 10.4L9.80003 3.30001C9.60003 2.80001 9.80003 2.20001 10.3 2.00001C10.8 1.80001 11.4 2.00001 11.6 2.50001L14.6 9.60001C14.8 10.1 14.6 10.7 14.1 10.9C13.9 11 13.8 11 13.7 11Z" fill="#0F0017"/>
    <path d="M26.4 11C26.3 11 26.1 11 26 10.9C25.5 10.7 25.3 10.1 25.5 9.60001L28.5 2.50001C28.8 2.00001 29.4 1.80001 29.9 2.00001C30.4 2.20001 30.6 2.80001 30.4 3.30001L27.4 10.4C27.2 10.8 26.8 11 26.4 11Z" fill="#0F0017"/>
    <path d="M34.4 12.2C34.1 12.2 33.9 12.1 33.7 11.9C33.3 11.5 33.3 10.9 33.7 10.5L36.7 7.50001C37.1 7.10001 37.7 7.10001 38.1 7.50001C38.5 7.90001 38.5 8.50001 38.1 8.90001L35.1 11.9C34.9 12.1 34.7 12.2 34.4 12.2Z" fill="#0F0017"/>
    <path d="M5.70007 12.2C5.40007 12.2 5.20007 12.1 5.00007 11.9L2.00007 8.90001C1.60007 8.50001 1.60007 7.90001 2.00007 7.50001C2.40007 7.10001 3.00007 7.10001 3.40007 7.50001L6.40007 10.5C6.80007 10.9 6.80007 11.5 6.40007 11.9C6.20007 12.1 5.90007 12.2 5.70007 12.2Z" fill="#0F0017"/>
  </svg>
</template>
