<template>
  <svg
    width="40"
    height="38"
    viewBox="0 0 40 38"
  >
    <path d="M27 14.9C27 17 26.8 19.1 26.4 21.1L26.3 21.5C25.7 24.1 24.1 26.2 21.8 27.6C19.5 29 16.9 29.4 14.3 28.7H14.2C11.4 27.8 8.6 26.5 6.1 24.8C3.2 20.9 1 16.5 1 12C1 5.9 5.5 1 11 1C15 1 18.4 3.5 20 7.2C20.9 5.1 22.4 3.4 24.3 2.3C26.1 6.3 27 10.6 27 14.9Z" fill="#00FF86"/>
    <path d="M20 38C17.8 38 0 24.8 0 12C0 5.4 4.9 0 11 0C14.6 0 18 2 20 5.1C22 2 25.4 0 29 0C35.1 0 40 5.4 40 12C40 24.8 22.2 38 20 38ZM11 2C6 2 2 6.5 2 12C2 23.6 17.8 35.1 20 36C22.2 35.1 38 23.7 38 12C38 6.5 34 2 29 2C25.5 2 22.4 4.1 20.9 7.6C20.8 8 20.4 8.2 20 8.2C19.6 8.2 19.2 8 19.1 7.6C17.6 4.1 14.5 2 11 2Z" fill="#0F0017"/>
  </svg>
</template>
