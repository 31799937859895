<template>
  <svg
    width="32"
    height="40"
    viewBox="0 0 32 40"
  >
    <path d="M20 23H22.92C22.19 25.3 20.68 27.24 18.59 28.5C16.32 29.87 13.66 30.27 11.09 29.64L11 29.61C9.98 29.28 8.98 28.91 8 28.48V23H20Z" fill="#00FF86"/>
    <path d="M18 19.5H14V21H18V19.5Z" fill="#00FF86"/>
    <path d="M31 35H30V14C30 12.86 29.85 11.75 29.59 10.68C29.58 10.66 29.58 10.63 29.57 10.6C28.34 5.73 24.57 1.86 19.75 0.52C18.57 0.18 17.32 0 16.03 0H16C9.45 0 3.96 4.52 2.43 10.6C2.42 10.63 2.42 10.66 2.41 10.68C2.15 11.75 2 12.85 2 14V35H1C0.45 35 0 35.45 0 36C0 36.55 0.45 37 1 37H3.14C3.59 38.7 5.11 39.97 6.94 40H7C8.86 40 10.41 38.72 10.86 37H21.14C21.59 38.7 23.11 39.97 24.94 40H25C26.86 40 28.41 38.72 28.86 37H31C31.55 37 32 36.55 32 36C32 35.45 31.55 35 31 35ZM16 2C17.82 2 19.54 2.41 21.08 3.15C23.97 4.49 26.23 6.97 27.3 10H4.7C6.35 5.35 10.79 2 16 2ZM7 38C5.9 38 5 37.1 5 36C5 34.9 5.9 34 7 34C8.1 34 9 34.9 9 36C9 37.1 8.1 38 7 38ZM21.14 35H10.86C10.49 33.6 9.4 32.51 8 32.14V23H24V32.14C22.6 32.51 21.51 33.6 21.14 35ZM14 21V19.5H18V21H14ZM25 38C23.9 38 23 37.1 23 36C23 34.9 23.89 34.01 24.98 34H25.02C26.11 34.01 27 34.9 27 36C27 37.1 26.1 38 25 38ZM28 33.38C27.48 32.79 26.79 32.35 26 32.14V22.5C26 21.67 25.33 21 24.5 21H20V19C20 18.17 19.33 17.5 18.5 17.5H13.5C12.67 17.5 12 18.17 12 19V21H7.5C6.67 21 6 21.67 6 22.5V32.14C5.21 32.35 4.52 32.79 4 33.38V14C4 13.32 4.07 12.65 4.18 12H27.82C27.93 12.65 28 13.32 28 14V33.38Z" fill="#0F0017"/>
  </svg>
</template>
