<template>
  <svg
    width="40"
    height="36"
    viewBox="0 0 40 36"
  >
    <path d="M27 13.9C27 16 26.8 18.1 26.4 20.1L26.3 20.5C26 21.7 25.5 22.7 24.9 23.7C24.1 24.9 23.1 25.8 21.8 26.6C19.5 28 16.9 28.4 14.3 27.7H14.2C12.4 27.1 10.5 26.4 8.8 25.4C8.2 25.1 7.6 24.7 7 24.3C4.8 22.9 2.8 21.3 1 19.4C1.3 9.2 9.7 2 20 2H20.1C21.7 2 23.3 2.2 24.9 2.6C25.1 3.1 25.3 3.7 25.5 4.2C26.5 7.4 27 10.6 27 13.9Z" fill="#00FF86"/>
    <path d="M37.1 35.8C36.6 35.8 36.2 35.7 35.7 35.5C33.7 34.4 26.4 31 20 31C13.6 31 6.3 34.4 4.2 35.5C3.3 35.9 2.3 35.9 1.5 35.5C0.7 35.1 0.2 34.4 0 33.5C0 33.3 0 33.2 0 33C0 32.9 0 32.9 0 32.8L1 26.8C1 26.7 1 26.7 1 26.7C0.4 26 0 25 0 24V20C0 19.8 0 19.6 0 19.4C0.3 9.6 7.9 2 18 1.1V1C18 0.4 18.4 0 19 0H21C21.6 0 22 0.4 22 1V1.1C23.1 1.2 24.1 1.4 25.1 1.6C34 3.7 40 11.1 40 20V24C40 25 39.6 26 39 26.7V26.8L40 32.8C40 32.9 40 32.9 40 33C40 33.2 40 33.3 40 33.5C39.9 34.4 39.3 35.1 38.5 35.5C38.1 35.7 37.6 35.8 37.1 35.8ZM20 29C26.7 29 33.9 32.3 36.7 33.7C37 33.8 37.3 33.8 37.6 33.7C37.8 33.6 38 33.5 38 33.2V33.1L37.1 27.9C37 27.9 36.9 27.9 36.8 28C36.3 28.1 35.7 28.1 35.2 27.9C33.8 27.4 28.9 25.7 24.6 24.8C22.7 24.2 21.1 24 20 24C17.9 24 14 24.8 9.1 26.4C8.6 26.6 8.1 26.7 7.6 26.9L7.4 27C7.3 27 7.2 27.1 7.2 27.1C6.1 27.5 5.3 27.7 4.8 27.9C4.2 28 3.7 28 3.1 27.9C3 27.9 2.9 27.9 2.8 27.8L2 33.1V33.2C2 33.5 2.2 33.6 2.4 33.7C2.7 33.8 3 33.8 3.3 33.7C6.1 32.3 13.3 29 20 29ZM35 25.6C35.4 25.7 35.7 25.8 35.9 25.9C36.1 26 36.3 26 36.4 25.9C37.3 25.7 38 24.9 38 23.9V19.9C38 13.2 34.2 7.4 28.2 4.7C31.7 7.2 35 10.7 35 15V25.6ZM13 4.3C6.6 6.7 2.2 12.5 2 19.5C2 19.7 2 19.9 2 20V24C2 24.9 2.7 25.8 3.6 26C3.8 26 3.9 26 4.1 26C4.5 25.9 5.2 25.6 6 25.4V15C6 10.4 9.6 6.7 13 4.3ZM20 22C21.3 22 23 22.2 25.1 22.7C27.9 23.3 30.8 24.2 33 24.9V15C33 10.7 28.6 7.2 24.9 5.1C23.5 4.3 22.3 3.7 21.5 3.3C21.1 3.1 20.6 3 20.1 3C19.5 3 19 3.1 18.4 3.4C16 4.6 8 9 8 15V24.6C8.2 24.5 8.4 24.5 8.5 24.4C12.1 23.3 17 22 20 22Z" fill="#0F0017"/>
  </svg>
</template>
