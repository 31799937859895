<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M27 15.9C27 18 26.8 20.1 26.4 22.1L26.3 22.4V22.5C25.7 25.1 24.1 27.2 21.8 28.6C19.5 30 16.9 30.4 14.3 29.7H14.2C12.1 29 10 28.1 8 27C5.5 25.5 3.1 23.7 1 21.5C1 21 1 20.5 1 20C1 9.5 9.5 1 20 1C21.2 1 22.3 1.1 23.4 1.3C25.8 5.9 27 10.9 27 15.9Z" fill="#00FF86"/>
    <path d="M20 40C14.2 40 8.7 37.5 4.9 33.1C4.7 33 4.6 32.8 4.5 32.6C1.9 29.4 0.3 25.6 0 21.5C0 21.1 0 20.5 0 20C0 9 9 0 20 0C21.2 0 22.4 0.1 23.5 0.3C33.1 2 40 10.3 40 20C40 31 31 40 20 40ZM6.6 32C10 35.8 14.9 38 20 38C25.3 38 30.1 35.7 33.4 32C31.6 26.1 26.2 22 20 22C13.8 22 8.3 26.1 6.6 32ZM20 2C10.1 2 2 10.1 2 20C2 20.5 2 21 2.1 21.4C2.3 24.5 3.4 27.5 5.2 30.1C7.1 25.4 11 21.9 15.8 20.6C14.1 19.3 13 17.3 13 15C13 11.1 16.1 8 20 8C23.9 8 27 11.1 27 15C27 17.3 25.9 19.3 24.2 20.6C29 21.9 33 25.4 34.8 30.1C36.8 27.2 37.9 23.7 37.9 20C37.9 11.3 31.7 3.8 23.1 2.3C22.2 2.1 21.1 2 20 2ZM20 10C17.2 10 15 12.2 15 15C15 17.8 17.2 20 20 20C22.8 20 25 17.8 25 15C25 12.2 22.8 10 20 10Z" fill="#0F0017"/>
  </svg>
</template>
