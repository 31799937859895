<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M26.7 20C26.6 20.7 26.5 21.4 26.4 22.1L26.3 22.5C25.9 23.9 25.3 25.2 24.4 26.4C23.7 27.3 22.8 28 21.8 28.6C19.5 30 16.9 30.4 14.3 29.7H14.2C9.3 28.1 4.7 25.4 1.1 21.5C1 21 1 20.5 1 20C1 9.5 9.5 1 20 1C21.2 1 22.3 1.1 23.4 1.3C25.5 5.2 26.7 9.5 27 13.7C27 14.4 27.1 15.2 27.1 15.9C27 17.3 26.9 18.6 26.7 20Z" fill="#00FF86"/>
    <path d="M20 40C9 40 0 31 0 20C0 19.7 0 19.5 0 19.2V19.1C0 19 0 18.9 0 18.7C0.7 8.3 9.4 0 20 0C31 0 40 9 40 20C40 31 31 40 20 40ZM18.1 37.9C18.7 38 19.4 38 20 38C28.6 38 35.8 32 37.6 23.9C37.5 23.9 37.5 23.8 37.4 23.8L34.9 21.9C34.5 21.6 34 21.6 33.4 21.7C32.9 21.8 32.6 22.1 32.4 22.5C32.4 22.6 32.4 22.5 32.4 22.5C32.4 22.6 32.3 23.1 31.3 30.1C31.1 31.3 30.5 32.3 29.5 32.9C28.5 33.5 27.4 33.6 26.4 33.3H26.3C25 32.8 24.1 31.6 24.1 30.2V28.1C24.1 27.6 23.8 27.1 23.3 26.9C22.1 26.5 20.3 25.5 18 23.2C16 21.2 15.5 18.3 16.6 14.8C16.9 13.8 17.7 13 18.6 12.7C19.6 12.4 20.6 12.5 21.5 13.1C22 13.4 22.7 13.4 23.1 12.9C24.8 11.2 26.3 5.9 26.9 3.4C24.8 2.5 22.4 2 20 2C16.6 2 13.3 3 10.6 4.7C11.2 5.2 11.7 6.2 12 7.8C12.2 9.2 11.9 10.6 11 11.8C9.9 13.3 9.1 15 8.4 17.4C8.2 18.3 7.4 19.7 4.8 19.7L2 19.9C2 19.9 2 19.9 2 20C2 21.7 2.2 23.4 2.7 25C3.5 24.4 5.6 23.2 7.4 23.7C8.1 23.9 9 24.5 9.5 26C9.5 26.1 9.6 26.2 9.6 26.3C9.6 27.5 10.2 28.5 11.2 29.1C12.9 30.1 15.3 31.6 16.9 33.2C18.4 34.7 18.5 36.5 18.1 37.9ZM3.4 26.9C5.6 32.2 10.4 36.3 16.1 37.6C16.3 37.2 16.7 35.8 15.5 34.7C14.1 33.3 11.9 31.9 10.2 30.9C8.6 30 7.6 28.3 7.6 26.5C7.3 25.7 6.9 25.6 6.8 25.6C5.8 25.3 4.1 26.4 3.6 26.8C3.5 26.8 3.5 26.9 3.4 26.9ZM19.7 14.5C19.6 14.5 19.4 14.5 19.3 14.6C18.9 14.7 18.6 15 18.5 15.4C17.9 17.3 17.6 20 19.4 21.8C21.4 23.8 23 24.7 24 25C25.3 25.5 26.1 26.7 26.1 28V30.1C26.1 30.6 26.4 31.1 26.9 31.3H27C27.5 31.5 28 31.4 28.5 31.1C29 30.8 29.3 30.3 29.4 29.7C29.9 26.2 30.4 22.4 30.5 22.2C30.5 22 30.6 21.8 30.7 21.5C31.2 20.6 32 19.9 33 19.6C34.1 19.3 35.2 19.5 36.1 20.1L37.9 21.5C38 21.1 38 20.6 38 20C38 13.2 34.3 7.3 28.7 4.3C28.1 6.7 26.6 12.3 24.5 14.4C23.4 15.5 21.7 15.7 20.4 14.8C20.2 14.6 20 14.5 19.7 14.5ZM8.7 6C5.1 8.9 2.6 13.1 2.1 18L4.7 17.8C6.2 17.8 6.4 17.2 6.4 16.9C7.1 14.3 8 12.3 9.3 10.6C9.8 9.9 10.1 9 9.9 8.2C9.7 6.5 9.2 6 9 6C8.9 6 8.8 6 8.7 6Z" fill="#0F0017"/>
  </svg>
</template>
