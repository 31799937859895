<template>
  <svg
    width="11"
    height="14"
    viewBox="0 0 11 14"
  >
    <path d="M2.24664 0H8.24664C9.35121 0 10.2466 0.89543 10.2466 2V11.4969C10.2466 11.7731 10.0228 11.9969 9.74664 11.9969H1.24664V12C1.24664 12.5523 1.69436 13 2.24664 13H9.74664C10.0228 13 10.2466 13.2239 10.2466 13.5C10.2466 13.7761 10.0228 14 9.74664 14H2.24664C1.14207 14 0.246643 13.1046 0.246643 12V2C0.246643 0.895429 1.14207 0 2.24664 0ZM1.24664 2V10.9969H9.24664V2C9.24664 1.44772 8.79893 1 8.24664 1H2.24664C1.69436 1 1.24664 1.44771 1.24664 2Z" />
    <path d="M6.60347 4.35683C6.60347 5.10619 5.996 5.71366 5.24664 5.71366C4.49729 5.71366 3.88981 5.10619 3.88981 4.35683C3.88981 3.60747 4.49729 3 5.24664 3C5.996 3 6.60347 3.60747 6.60347 4.35683Z" />
    <path d="M2.98526 7.2503C2.98526 6.77632 3.3695 6.39207 3.84349 6.39207H6.6498C7.12378 6.39207 7.50803 6.77632 7.50803 7.2503C7.50803 8.01668 6.98645 8.6847 6.24296 8.87057L6.18958 8.88392C5.57048 9.03869 4.9228 9.03869 4.30371 8.88392L4.25033 8.87057C3.50684 8.6847 2.98526 8.01668 2.98526 7.2503Z" />
  </svg>
</template>
