<template>
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
  >
    <path d="M17.5 18C17.5 18.275 17.276 18.5 17 18.5H11.2678C10.9806 19.051 10.6168 19.5557 10.1904 20H17C18.104 20 19 19.104 19 18V7.828C19 7.298 18.789 6.789 18.414 6.414L12.585 0.586C12.57 0.571048 12.5531 0.55808 12.5363 0.545195C12.5238 0.535674 12.5115 0.526198 12.5 0.516C12.429 0.452 12.359 0.389 12.281 0.336C12.2557 0.318941 12.2281 0.305475 12.2005 0.292071C12.1845 0.284259 12.1685 0.276469 12.153 0.268C12.1363 0.258594 12.1197 0.248967 12.103 0.239326C12.0488 0.20797 11.9944 0.176475 11.937 0.152C11.74 0.0699999 11.528 0.029 11.313 0.0139999C11.2933 0.0127423 11.2738 0.0100789 11.2542 0.00740933C11.2271 0.00371051 11.1999 0 11.172 0H5C3.896 0 3 0.896 3 2V9.49816C3.47417 9.30042 3.97679 9.15724 4.5 9.07645V2C4.5 1.725 4.724 1.5 5 1.5H11V6C11 7.104 11.896 8 13 8H17.5V18ZM12.5 2.621L16.378 6.5H13C12.724 6.5 12.5 6.275 12.5 6V2.621Z" />
    <path d="M11 15.5C11 18.5376 8.53757 21 5.5 21C2.46243 21 0 18.5376 0 15.5C0 12.4624 2.46243 10 5.5 10C8.53757 10 11 12.4624 11 15.5ZM8.85355 13.1464C8.65829 12.9512 8.34171 12.9512 8.14645 13.1464L4.5 16.7929L2.85355 15.1464C2.65829 14.9512 2.34171 14.9512 2.14645 15.1464C1.95118 15.3417 1.95118 15.6583 2.14645 15.8536L4.14645 17.8536C4.34171 18.0488 4.65829 18.0488 4.85355 17.8536L8.85355 13.8536C9.04882 13.6583 9.04882 13.3417 8.85355 13.1464Z" />
  </svg>
</template>
