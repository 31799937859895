<template>
  <svg
    width="27"
    height="40"
    viewBox="0 0 27 40"
  >
    <path d="M13.7276 9.64818C12.1501 9.64818 11.2035 9.2254 10.4432 8.70166C7.69195 8.75845 5.49286 10.9986 5.4834 13.7498V23.5652C6.43623 23.9911 7.38906 24.335 8.34189 24.6758H8.42708C8.7205 24.7547 9.56606 24.8146 9.85633 24.8619C10.4148 23.4958 15.0937 23.7987 16.0056 22.8679C17.3686 21.7605 18.2583 20.2019 18.7221 18.4982L18.8073 18.1543C19.1512 16.4379 19.3215 14.6364 19.3215 12.8348C19.3215 11.6643 19.2458 10.4937 19.0975 9.33267C18.4066 8.95091 17.6178 8.72375 16.7722 8.70166C15.8951 9.35792 14.8193 9.69235 13.7244 9.64818H13.7276ZM16.0245 15.2705L12.901 20.6026C12.8032 20.7635 12.5918 20.8171 12.4277 20.7162C12.3047 20.6405 12.2416 20.4953 12.27 20.3565L12.7306 17.0847L11.1751 17.1131C10.9858 17.1131 10.8312 16.9648 10.8281 16.7755C10.8281 16.7124 10.8439 16.6493 10.8754 16.5956L13.9485 11.2667C14.0526 11.1058 14.264 11.0617 14.4249 11.1658C14.5321 11.2352 14.5921 11.3614 14.5795 11.4876L14.4217 14.7373H15.7405C15.9298 14.7373 16.0844 14.8919 16.0844 15.0812C16.0844 15.1506 16.0655 15.2169 16.0245 15.2705Z" fill="#00FF86"/>
    <path d="M14.8256 35.8322V37.5674C14.8256 38.0218 14.2546 38.4225 13.6141 38.4225C12.9673 38.4225 12.3962 38.0218 12.3962 37.5706V35.8322H14.8256ZM11.3298 34.2546C11.049 34.2546 10.8218 34.4534 10.8218 34.6963V37.5706C10.825 38.9115 12.0744 39.9968 13.6141 40C15.1538 39.9968 16.4032 38.9115 16.4063 37.5706V34.6963C16.4063 34.4502 16.1792 34.2546 15.8984 34.2546H11.3298Z" fill="#0F0017"/>
    <path d="M15.7406 14.7373H14.4218L14.5795 11.4876C14.5922 11.3583 14.5322 11.2352 14.4249 11.1658C14.264 11.0617 14.0526 11.109 13.9485 11.2668L10.8755 16.5957C10.8439 16.6493 10.8282 16.7124 10.8282 16.7755C10.8313 16.9648 10.9859 17.1163 11.1752 17.1131L12.7307 17.0847L12.27 20.3565C12.2416 20.4985 12.3047 20.6436 12.4278 20.7162C12.5887 20.814 12.8001 20.7635 12.901 20.6026L16.0246 15.2705C16.0656 15.2169 16.0845 15.1507 16.0845 15.0812C16.0845 14.8919 15.9299 14.7373 15.7406 14.7373Z" fill="#0F0017"/>
    <path d="M9.66078 33.3207C9.14019 33.3207 8.71425 32.8948 8.71425 32.3742V27.0106C8.71425 26.4868 8.81837 25.9757 9.01714 25.4867C9.64184 23.9533 11.1153 22.9595 12.7685 22.9531H14.4565C15.037 22.9531 15.6049 23.0794 16.1381 23.3254C17.5769 23.9849 18.5076 25.433 18.5108 27.0106V32.3742C18.5108 32.8948 18.0848 33.3207 17.5642 33.3207H9.66078ZM16.6209 31.4277V27.0106C16.6209 25.9063 15.7879 24.9787 14.69 24.8588C14.6269 24.8525 14.5606 24.8462 14.4943 24.8462H12.7685C12.0176 24.8493 11.333 25.2343 10.9354 25.8747C10.7209 26.2186 10.6073 26.613 10.6073 27.0106V31.4277H16.6209Z" fill="#0F0017"/>
    <path d="M20.4921 3.9533C19.3815 3.9533 18.3845 3.33807 17.8135 2.38523C16.9585 0.955986 15.3967 0 13.6078 0C11.8188 0 10.2602 0.955986 9.40206 2.38523C8.83099 3.33807 7.83399 3.9533 6.7234 3.9533H2.00026C1.48914 3.9533 1.0285 4.33822 1.0001 4.84935C0.971707 5.39517 1.40395 5.84635 1.94662 5.84635H6.72971C7.8403 5.84635 8.8373 6.46159 9.40837 7.41442C9.43992 7.46805 9.47463 7.51854 9.50933 7.56902C7.67623 7.98864 6.24699 8.72377 5.24683 9.77757C3.82074 11.2762 3.7955 12.8569 3.80181 13.1472V25.3131C3.80181 28.402 6.25014 30.7903 6.35426 30.8913C6.72971 31.2541 7.32918 31.2415 7.69201 30.8661C8.05484 30.4906 8.04222 29.8911 7.66677 29.5283C7.64784 29.5094 5.69485 27.569 5.69485 25.3163V13.1283C5.69485 13.1283 5.69485 13.0999 5.69485 13.0935C5.69485 13.0494 5.68538 12.0334 6.65715 11.0396C7.60367 10.0741 9.16858 9.44944 11.2446 9.18757C11.945 9.57564 12.7527 9.79965 13.6141 9.79965C14.4754 9.79965 15.28 9.57564 15.9835 9.18757C18.0627 9.44944 19.6308 10.0741 20.5773 11.0396C21.5522 12.0334 21.5428 13.0525 21.5428 13.0809C21.5428 13.0967 21.5428 13.1125 21.5428 13.1283V25.31C21.5428 27.5816 19.5898 29.5031 19.5709 29.522C19.1954 29.8848 19.1828 30.4843 19.5456 30.8598C19.7318 31.0522 19.981 31.15 20.2271 31.15C20.4732 31.15 20.7004 31.0617 20.8834 30.885C20.9875 30.784 23.4358 28.3956 23.4358 25.3068V13.1472C23.4453 12.8569 23.42 11.2794 21.9908 9.77757C20.9875 8.72377 19.5582 7.98864 17.722 7.56902C17.7567 7.51854 17.7882 7.46805 17.8198 7.41442C18.3909 6.46159 19.3879 5.84635 20.4984 5.84635H25.2721C25.8116 5.84635 26.247 5.39517 26.2186 4.84935C26.1902 4.33822 25.7327 3.9533 25.2184 3.9533H20.4921ZM14.122 7.86559C13.2102 8.01704 12.3584 7.75201 11.721 7.23458C10.9196 6.58463 10.459 5.534 10.6483 4.3887C10.8534 3.14245 11.8567 2.13914 13.103 1.93406C15.1569 1.59331 16.9174 3.35384 16.5767 5.40779C16.4568 6.13661 16.0624 6.78025 15.5071 7.23142C15.1127 7.55324 14.6395 7.77725 14.122 7.86244V7.86559Z" fill="#0F0017"/>
  </svg>
</template>
