<template>
  <svg
    width="29"
    height="40"
    viewBox="0 0 29 40"
  >
    <path d="M21.9688 15.95C21.9688 18.05 21.7687 20.15 21.3687 22.15L21.2687 22.55C21.1687 23.15 20.9688 23.65 20.6688 24.15C20.3688 24.85 19.9688 25.55 19.4688 26.15C18.7688 27.15 17.8687 27.95 16.7687 28.55C16.4687 28.75 16.2688 28.85 15.9688 28.95C15.4688 29.15 14.9688 29.35 14.4688 29.55L3.96875 12.05L15.9688 1.04999L19.7687 4.54999C20.7687 6.94999 21.3688 9.54999 21.6688 12.05C21.8688 13.35 21.9688 14.65 21.9688 15.95Z" fill="#00FF86"/>
    <path d="M0.96875 40.05C0.76875 40.05 0.56875 40.05 0.46875 39.95C-0.03125 39.65 -0.13125 39.05 0.16875 38.55C2.16875 35.25 3.86875 33.95 8.26875 35.15C10.5688 35.75 12.6687 35.55 13.8687 34.65C14.5687 34.15 14.9688 33.35 14.9688 32.35L3.16875 12.55C3.06875 12.45 2.96875 12.35 2.96875 12.15C2.96875 12.05 2.96875 12.05 2.96875 11.95C2.96875 11.75 3.06875 11.55 3.16875 11.45C3.16875 11.35 3.26875 11.35 3.26875 11.25L15.2688 0.25L15.3687 0.15C15.6687 -0.05 16.1688 -0.05 16.4688 0.15C16.4688 0.15 16.4688 0.15 16.5688 0.15C16.5688 0.15 16.6688 0.15 16.6688 0.25L28.6688 11.25C28.7688 11.35 28.7687 11.35 28.7687 11.45C28.8687 11.55 28.9688 11.75 28.9688 11.95V12.05C28.9688 12.25 28.8687 12.35 28.8687 12.45L16.9688 32.35C16.8687 33.95 16.2688 35.25 15.0688 36.15C13.3688 37.45 10.5688 37.75 7.66875 36.95C4.46875 36.05 3.46875 36.75 1.76875 39.55C1.66875 39.85 1.26875 40.05 0.96875 40.05ZM5.76875 13.05L14.9688 28.45V13.05H5.76875ZM16.9688 13.05V28.45L26.1688 13.05H16.9688ZM16.9688 11.05H25.3687L16.9688 3.35V11.05ZM6.56875 11.05H14.9688V3.35L6.56875 11.05Z" fill="#0F0017"/>
  </svg>
</template>
