<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M27 15.9C27 18 26.8 20.1 26.4 22.1L26.3 22.5C25.7 25.1 24.1 27.2 21.8 28.6C19.5 30 16.9 30.4 14.3 29.7H14.2C13.1 29.4 12 28.9 11 28.5V20H2C1.5 20 1 19.6 1 19V13C1 11.3 2.3 10 4 10C5.7 10 7 11.3 7 13V14C7 14.6 7.4 15 8 15H11V10C11 5 15 1 20 1C21.3 1 22.5 1.3 23.6 1.8C25.9 6.2 27 11.1 27 15.9Z" fill="#00FF86"/>
    <path d="M27 40H13C11.3 40 10 38.7 10 37V21H2C0.9 21 0 20.1 0 19V13C0 10.8 1.8 9 4 9C6.2 9 8 10.8 8 13V14H10V10C10 4.5 14.5 0 20 0C21.4 0 22.7 0.3 24 0.8C27.6 2.4 30 6 30 10V21H32V20C32 17.8 33.8 16 36 16C38.2 16 40 17.8 40 20V26C40 27.1 39.1 28 38 28H30V37C30 38.7 28.7 40 27 40ZM12 20V37C12 37.6 12.4 38 13 38H27C27.6 38 28 37.6 28 37V10C28 6.8 26.1 3.9 23.2 2.7C22.2 2.2 21.1 2 20 2C15.6 2 12 5.6 12 10V20ZM30 26H38V20C38 18.9 37.1 18 36 18C34.9 18 34 18.9 34 20V21C34 22.1 33.1 23 32 23H30V26ZM4 11C2.9 11 2 11.9 2 13V19H10V16H8C6.9 16 6 15.1 6 14V13C6 11.9 5.1 11 4 11ZM20 36C19.4 36 19 35.6 19 35V33C19 32.4 19.4 32 20 32C20.6 32 21 32.4 21 33V35C21 35.6 20.6 36 20 36ZM16 28C15.4 28 15 27.6 15 27V25C15 24.4 15.4 24 16 24C16.6 24 17 24.4 17 25V27C17 27.6 16.6 28 16 28ZM24 22C23.4 22 23 21.6 23 21V19C23 18.4 23.4 18 24 18C24.6 18 25 18.4 25 19V21C25 21.6 24.6 22 24 22ZM4 18C3.4 18 3 17.6 3 17V15C3 14.4 3.4 14 4 14C4.6 14 5 14.4 5 15V17C5 17.6 4.6 18 4 18ZM17 16C16.4 16 16 15.6 16 15V13C16 12.4 16.4 12 17 12C17.6 12 18 12.4 18 13V15C18 15.6 17.6 16 17 16ZM22 9C21.4 9 21 8.6 21 8V6C21 5.4 21.4 5 22 5C22.6 5 23 5.4 23 6V8C23 8.6 22.6 9 22 9Z" fill="#0F0017"/>
  </svg>
</template>
