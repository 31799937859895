<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.58608 6.00004C2.58608 5.61032 2.65077 5.23663 2.76636 4.8862L0.744177 3.34204C0.350052 4.1422 0.128052 5.04388 0.128052 6.00004C0.128052 6.95545 0.349864 7.85648 0.743333 8.65617L2.7644 7.10901C2.64993 6.76017 2.58608 6.38788 2.58608 6.00004Z" fill="#FBBC05"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.13655 2.45456C6.9832 2.45456 7.74792 2.75456 8.34876 3.24544L10.0966 1.5C9.03155 0.572719 7.66598 0 6.13655 0C3.76205 0 1.7213 1.35787 0.744141 3.342L2.76623 4.88616C3.23217 3.47184 4.56033 2.45456 6.13655 2.45456Z" fill="#EA4335"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.13655 9.54536C4.56042 9.54536 3.23227 8.52808 2.76633 7.11377L0.744141 8.65764C1.7213 10.6421 3.76205 11.9999 6.13655 11.9999C7.60205 11.9999 9.00127 11.4795 10.0514 10.5045L8.13192 9.02064C7.59033 9.3618 6.9083 9.54536 6.13655 9.54536Z" fill="#34A853"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8719 6.00005C11.8719 5.64549 11.8173 5.26365 11.7353 4.90918H6.1366V7.22734H9.35935C9.19819 8.01774 8.75963 8.62534 8.13197 9.02077L10.0514 10.5046C11.1545 9.4809 11.8719 7.95577 11.8719 6.00005Z" fill="#4285F4"/>
  </svg>
</template>
