<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
  >
    <path d="M8 0.5C12.4183 0.5 16 4.08172 16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5ZM8 1.5C4.13401 1.5 1 4.63401 1 8.5C1 12.366 4.13401 15.5 8 15.5C11.866 15.5 15 12.366 15 8.5C15 4.63401 11.866 1.5 8 1.5Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.3889 2.6111L13.8889 13.1111L13.1111 13.8889L2.61108 3.38892L3.3889 2.6111Z" />
    <path d="M5.75903 12.0627H10.2257C10.5377 12.0627 10.7508 11.8573 10.7508 11.5529C10.7508 11.2409 10.5377 11.0431 10.2257 11.0431H7.15155C7.52441 10.7159 7.76791 10.168 7.76791 9.57447C7.76791 9.33858 7.73748 9.13313 7.69182 8.93528H9.49524C9.76157 8.93528 9.93659 8.76788 9.93659 8.51677C9.93659 8.25805 9.76157 8.09064 9.49524 8.09064H7.50158C7.4331 7.82431 7.37983 7.54277 7.37983 7.22317C7.37983 6.45462 7.92771 5.93718 8.87127 5.93718C9.49524 5.93718 9.76918 6.09698 10.0051 6.09698C10.3018 6.09698 10.4692 5.92957 10.4692 5.65564C10.4692 5.43496 10.3627 5.25995 10.0812 5.14581C9.73875 4.99362 9.30501 4.93274 8.77235 4.93274C7.17438 4.93274 6.2156 5.83065 6.2156 7.21556C6.2156 7.51233 6.26886 7.80909 6.34496 8.09064H5.69055C5.43183 8.09064 5.2492 8.25805 5.2492 8.51677C5.2492 8.76788 5.43183 8.93528 5.69055 8.93528H6.53519C6.58846 9.15596 6.63411 9.37663 6.63411 9.57447C6.63411 10.3278 6.18516 10.83 5.67533 10.9974C5.38617 11.1192 5.2492 11.279 5.2492 11.5605C5.2492 11.8649 5.45466 12.0627 5.75903 12.0627Z" />
  </svg>
</template>
