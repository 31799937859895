export enum companyIdentificationNumberStatus {
  not_found = 'not_found',
  inactive = 'inactive',
  active = 'active',
  non_public = 'non_public',
  name_mismatch = 'name_mismatch',
  rejected = 'rejected'
}

export enum CompleteProfileSections {
  startEarningProfile = 'freeflexer.onboarding.section.start-earning-profile',
  optimizeProfile = 'freeflexer.onboarding.section.optimize-profile',
  afterOneShift = 'freeflexer.onboarding.section.after-one-shift'
}

export enum CompleteProfileSteps {
  idVerification = 'freeflexer.onboarding.steps.id-verification',
  ssn = 'freeflexer.onboarding.steps.ssn',
  nino = 'freeflexer.onboarding.steps.nino',
  responsibilitiesTaken = 'freeflexer.onboarding.steps.responsibilities-taken',
  addWorkExperience = 'freeflexer.onboarding.steps.add-work-experience',
  addBio = 'freeflexer.onboarding.steps.add-bio',
  addLanguages = 'freeflexer.onboarding.steps.add-languages',
  uploadPicture = 'freeflexer.onboarding.steps.upload-picture',
  addVatId = 'freeflexer.onboarding.steps.vat-id-added',
  companyIdentificationNumber = 'freeflexer.onboarding.steps.company-identification-number'
}
