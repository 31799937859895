<template>
  <svg
    width="38"
    height="40"
    viewBox="0 0 38 40"
  >
    <path d="M26 15.9C26 18 25.8 20.1 25.4 22.1L25.3 22.5C24.7 25.1 23.1 27.2 20.8 28.6C18.5 30 15.9 30.4 13.3 29.7H13.2C13 29.6 12.7 29.5 12.5 29.5C15.6 28.4 18.4 26.5 20.7 23.8C24.4 19.4 25.8 13.8 25 8.5C25.7 10.9 26 13.4 26 15.9Z" fill="#00FF86"/>
    <path d="M17.5 40C12.8 40 8.20005 38.4 4.30005 35.3C2.70005 34 1.30005 32.4 0.200049 30.6C4.88311e-05 30.3 4.88311e-05 29.8 0.200049 29.5C0.400049 29.2 0.900049 29 1.30005 29.1C4.90005 30 8.70005 29.8 12.2 28.5C15.2 27.4 17.9 25.5 20 23.1C23.4 19.1 24.9 13.8 24.1 8.60001C23.7001 6.10001 22.8 3.60001 21.4 1.50001C21.2 1.20001 21.2 0.700012 21.4 0.400012C21.6 0.100012 22.1 -0.099988 22.5 1.19824e-05C25.5 0.800012 28.3001 2.10001 30.6 4.10001C34.8 7.60001 37.4001 12.5 37.9001 17.9C38.4001 23.3 36.7001 28.6 33.2001 32.7C29.1 37.5 23.3 40 17.5 40ZM3.30005 31.5C4.00005 32.3 4.80005 33 5.60005 33.7C13.4 40.2 25.1 39.2 31.7001 31.4C34.9001 27.7 36.4001 22.9 36 18.1C35.6 13.2 33.3 8.80001 29.5 5.60001C28 4.30001 26.3 3.40001 24.5 2.60001C25.3 4.40001 25.9001 6.30001 26.2001 8.20001C27.1 13.9 25.4 19.8 21.6 24.3C19.3001 27 16.3 29.1 12.9 30.3C9.80005 31.5 6.50005 31.9 3.30005 31.5Z" fill="#0F0017"/>
  </svg>
</template>
