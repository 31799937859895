<template>
  <svg
    width="26"
    height="39"
    viewBox="0 0 26 39"
  >
    <path d="M19 13.9C18.9 12.3 17.6 11 16 11C14.5 11 13.2 12.2 13 13.7V12C13 10.4 11.6 9 10 9C8.4 9 7 10.4 7 12V4C7 2.3 5.6 1 4 1C2.4 1 1 2.4 1 4V22V25.9C3 27 5.1 28 7.2 28.6H7.3C9.9 29.2 12.5 28.8 14.8 27.5C16.3 26.6 17.5 25.3 18.4 23.8C18.6 23.4 18.8 22.9 19 22.5C19 22.3 19.1 22.1 19.1 22V14C19 14 19 13.9 19 13.9ZM13 22V21.9V22C13 22.1 13 22 13 22Z" fill="#00FF86"/>
    <path d="M13 39C5.8 39 0 33.2 0 26V22C0 22 0 22 0 21.9V4C0 1.8 1.8 0 4 0C6.2 0 8 1.8 8 4V8.5C8.6 8.2 9.3 8 10 8C11.7 8 13.2 9.1 13.8 10.7C15 9.9 16.8 9.8 18 10.6V8C18 5.8 19.8 4 22 4C24.2 4 26 5.8 26 8V20V20.1V26C26 33.2 20.2 39 13 39ZM2 22V26C2 32.1 6.9 37 13 37C19.1 37 24 32.1 24 26V16V15.9V8C24 6.9 23.1 6 22 6C20.9 6 20 6.9 20 8V13.7C20 13.8 20 13.9 20 14V22C20 24.2 18.2 26 16 26C14.8 26 13.7 25.5 13 24.6C12.3 25.4 11.2 26 10 26H6C5.4 26 5 25.6 5 25C5 24.4 5.4 24 6 24H10C11.1 24 11.9 23.2 12 22.2C12 22.1 12 22.1 12 22V21.8C11.9 20.8 11 20 10 20H7.1C7 20 7 20 6.9 20H4C2.9 20 2 20.9 2 22ZM14 22.2C14.1 23.2 14.9 24 16 24C17.1 24 18 23.1 18 22V20.1V20V13.8C17.9 12.8 17 12 16 12C15 12 14.1 12.8 14 13.7V19C14 19.1 14 19.1 14 19.2V21.7C14 21.8 14 21.9 14 22C14 22.1 14 22.1 14 22.2ZM4 2C2.9 2 2 2.9 2 4V18.5C2.6 18.2 3.3 18 4 18H6V4C6 2.9 5.1 2 4 2ZM8 18H10C10.7 18 11.4 18.2 12 18.5V14C12 13.9 12 13.7 12 13.6V12C12 10.9 11.1 10 10 10C8.9 10 8 10.9 8 12V18Z" fill="#0F0017"/>
  </svg>
</template>
