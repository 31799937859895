<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M27 15.9C27 18 26.8 20.1 26.4 22.1L26.3 22.5C25.7 25.1 24.1 27.2 21.8 28.6C19.5 30 16.9 30.4 14.3 29.7H14.2C13.8 29.6 13.4 29.4 13 29.3C8.5 27.7 4.4 25 1 21.4V3C1 1.9 1.9 1 3 1H23.2C24.6 3.6 25.6 6.3 26.2 9C26.7 11.3 27 13.6 27 15.9Z" fill="#00FF86"/>
    <path d="M37 40H3C1.3 40 0 38.7 0 37V3C0 1.3 1.3 0 3 0H37C38.7 0 40 1.3 40 3V37C40 38.7 38.7 40 37 40ZM15 38H37C37.6 38 38 37.6 38 37V26C38 25.4 37.6 25 37 25H15C14.4 25 14 25.4 14 26V37C14 37.6 14.4 38 15 38ZM2 37C2 37.6 2.4 38 3 38H11C11.6 38 12 37.6 12 37V11C12 10.4 11.6 10 11 10H3C2.4 10 2 10.4 2 11V37ZM14 22C14 22.6 14.4 23 15 23H37C37.6 23 38 22.6 38 22V11C38 10.4 37.6 10 37 10H15C14.4 10 14 10.4 14 11V22ZM2 7C2 7.6 2.4 8 3 8H37C37.6 8 38 7.6 38 7V3C38 2.4 37.6 2 37 2H3C2.4 2 2 2.4 2 3V7Z" fill="#0F0017"/>
  </svg>
</template>
