<template>
  <svg
    width="40"
    height="38"
    viewBox="0 0 40 38"
  >
    <path d="M27 13.9C27 16 26.8 18.1 26.4 20.1L26.3 20.5C25.7 22.7 24.5 24.7 22.6 26C22.3 26.2 22 26.4 21.8 26.6C19.5 28 16.9 28.4 14.3 27.7H14.2C12.8 27.2 11.4 26.7 10 26C6.7 24.4 3.6 22.2 1 19.4V3C1 1.9 1.9 1 3 1H24.2C26.1 5.1 27 9.5 27 13.9Z" fill="#00FF86"/>
    <path d="M27 38H13C12.4 38 12 37.6 12 37C12 36.4 12.4 36 13 36H14.2L14.8 33H3C1.3 33 0 31.7 0 30V3C0 1.3 1.3 0 3 0H37C38.7 0 40 1.3 40 3V30C40 31.7 38.7 33 37 33H25.2L25.8 36H27C27.6 36 28 36.4 28 37C28 37.6 27.6 38 27 38ZM16.2 36H23.8L23.2 33H16.8L16.2 36ZM2 29V30C2 30.6 2.4 31 3 31H37C37.6 31 38 30.6 38 30V28C38 27.4 37.6 27 37 27H3C2.4 27 2 27.4 2 28V29ZM3 25H37C37.6 25 38 24.6 38 24V3C38 2.4 37.6 2 37 2H3C2.4 2 2 2.4 2 3V24C2 24.6 2.4 25 3 25ZM20 6C19.4 6 19 5.6 19 5C19 4.4 19.4 4 20 4C20.6 4 21 4.4 21 5C21 5.6 20.6 6 20 6Z" fill="#0F0017"/>
  </svg>
</template>
