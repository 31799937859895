<template>
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
  >
    <path d="M0.563457 6.76443C0.69244 6.45303 0.996301 6.25 1.33335 6.25H4.66665C5.0037 6.25 5.30756 6.45303 5.43654 6.76443C5.56553 7.07582 5.49423 7.43425 5.2559 7.67258L3.58925 9.33923C3.26382 9.66466 2.73618 9.66466 2.41075 9.33923L0.7441 7.67258C0.50577 7.43425 0.434475 7.07582 0.563457 6.76443Z" />
    <path d="M5.43654 3.23557C5.30756 3.54697 5.0037 3.75 4.66665 3.75L1.33335 3.75C0.996301 3.75 0.69244 3.54697 0.563457 3.23557C0.434474 2.92418 0.50577 2.56575 0.7441 2.32742L2.41075 0.660774C2.73618 0.33534 3.26382 0.33534 3.58925 0.660774L5.2559 2.32742C5.49423 2.56575 5.56553 2.92418 5.43654 3.23557Z" />
  </svg>
</template>
