<template>
  <svg
    width="40"
    height="31"
    viewBox="0 0 40 31"
  >
    <path d="M2 1C2 5.5 2 10 2 14.5C4.2 15.9 6.6 16.9 9 17.7H9.1C11.7 18.3 14.3 17.9 16.6 16.6C18.9 15.2 20.4 13.1 21.1 10.5L21.2 10.1C21.5 8.7 21.8 6.5 21.8 3.9C21.8 2.3 21.9 1.5 21.7 1C22 1 2 1 2 1Z" fill="#00FF86"/>
    <path d="M39.7 11.3L34.7 6.3C34.5 6.1 34.3 6 34 6H25V1C25 0.4 24.6 0 24 0H1C0.4 0 0 0.4 0 1V26C0 26.6 0.4 27 1 27H4.6C5.1 29.3 7.1 31 9.5 31C11.9 31 13.9 29.3 14.4 27H26.1C26.6 29.3 28.6 31 31 31C33.4 31 35.4 29.3 35.9 27H39C39.6 27 40 26.6 40 26V12C40 11.7 39.9 11.5 39.7 11.3ZM33.6 8L38 12.4V16H25V8H33.6ZM2 2H23V25H14.4C13.9 22.7 11.9 21 9.5 21C7.1 21 5.1 22.7 4.6 25H2V2ZM9.5 29C7.8 29 6.5 27.7 6.5 26C6.5 24.3 7.8 23 9.5 23C11.2 23 12.5 24.3 12.5 26C12.5 27.7 11.2 29 9.5 29ZM31 29C29.3 29 28 27.7 28 26C28 24.3 29.3 23 31 23C32.7 23 34 24.3 34 26C34 27.7 32.7 29 31 29ZM35.9 25C35.4 22.7 33.4 21 31 21C28.6 21 26.6 22.7 26.1 25H25V18H38V25H35.9Z" fill="#0F0017"/>
  </svg>
</template>
