<template>
  <svg
    width="36"
    height="40"
    viewBox="0 0 36 40"
  >
    <path d="M9 20V28.6C6.1 27.3 3.4 25.6 1 23.4V20H9ZM22 10V26.9C21.3 27.6 20.6 28.2 19.7 28.7C17.9 29.7 16 30.2 14 30.1V10H22Z" fill="#00FF86"/>
    <path d="M35 40H27C26.4 40 26 39.6 26 39V1C26 0.4 26.4 0 27 0H35C35.6 0 36 0.4 36 1V39C36 39.6 35.6 40 35 40ZM28 38H34V2H28V38ZM22 40H14C13.4 40 13 39.6 13 39V10C13 9.4 13.4 9 14 9H22C22.6 9 23 9.4 23 10V39C23 39.6 22.6 40 22 40ZM15 38H21V11H15V38ZM9 40H1C0.4 40 0 39.6 0 39V20C0 19.4 0.4 19 1 19H9C9.6 19 10 19.4 10 20V39C10 39.6 9.6 40 9 40ZM2 38H8V21H2V38Z" fill="#0F0017"/>
  </svg>
</template>
