<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 3.5C5.89543 3.5 5 4.39543 5 5.5C5 6.60457 5.89543 7.5 7 7.5C8.10457 7.5 9 6.60457 9 5.5C9 4.39543 8.10457 3.5 7 3.5ZM6 5.5C6 4.94772 6.44772 4.5 7 4.5C7.55228 4.5 8 4.94772 8 5.5C8 6.05228 7.55228 6.5 7 6.5C6.44772 6.5 6 6.05228 6 5.5Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2C0 1.17157 0.671573 0.5 1.5 0.5H12.5C13.3284 0.5 14 1.17157 14 2V5.70703C13.6777 5.61588 13.3434 5.55337 13 5.52242V4.5L12 4.5C10.8954 4.5 10 3.60457 10 2.5V1.5H4V2.5C4 3.60457 3.10457 4.5 2 4.5L1 4.5V6.5H2C3.10457 6.5 4 7.39543 4 8.5V9.5H7.20703C7.11588 9.82228 7.05337 10.1566 7.02242 10.5H1.5C0.671573 10.5 0 9.82843 0 9V2ZM1.5 1.5C1.22386 1.5 1 1.72386 1 2V3.5L2 3.5C2.55228 3.5 3 3.05228 3 2.5V1.5H1.5ZM12.5 1.5H11V2.5C11 3.05228 11.4477 3.5 12 3.5L13 3.5V2C13 1.72386 12.7761 1.5 12.5 1.5ZM1.5 9.5H3V8.5C3 7.94772 2.55228 7.5 2 7.5H1V9C1 9.27614 1.22386 9.5 1.5 9.5Z" />
    <path d="M7.02242 11.5H2.08545C2.29137 12.0826 2.84699 12.5 3.5001 12.5H7.20703C7.11587 12.1777 7.05336 11.8434 7.02242 11.5Z" />
    <path d="M16.0001 6.75724V3.99997C16.0001 3.34686 15.5827 2.79125 15.0001 2.58533V6.09976C15.3579 6.28266 15.693 6.5036 16.0001 6.75724Z" />
    <path d="M17 11C17 13.4853 14.9853 15.5 12.5 15.5C10.0147 15.5 8 13.4853 8 11C8 8.51472 10.0147 6.5 12.5 6.5C14.9853 6.5 17 8.51472 17 11ZM14.8536 9.14645C14.6583 8.95118 14.3417 8.95118 14.1464 9.14645L11.5 11.7929L10.8536 11.1464C10.6583 10.9512 10.3417 10.9512 10.1464 11.1464C9.95118 11.3417 9.95118 11.6583 10.1464 11.8536L11.1464 12.8536C11.3417 13.0488 11.6583 13.0488 11.8536 12.8536L14.8536 9.85355C15.0488 9.65829 15.0488 9.34171 14.8536 9.14645Z" />
  </svg>
</template>
