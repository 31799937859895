<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M21 1C23.5 1 26.1 1.7 28.4 3C30.2 8.3 30.5 13.8 29.4 19H21V1ZM17 4V23H26.2C25.5 25.4 23.9 27.5 21.7 28.8C19.4 30.2 16.8 30.5 14.2 29.9H14.1C9.2 28.2 4.6 25.4 1 21.5V21.4C1 12.1 7.9 4.7 17 4Z" fill="#00FF86"/>
    <path d="M18.6 40C9.1 40 1.1 32.8 0.1 23.4C0 22.7 0 22.1 0 21.5V21.4C0 11.6 7.3 3.7 16.9 3C17.2 3 17.5 3.1 17.7 3.3C17.9 3.5 18 3.7 18 4V22H36C36.3 22 36.5 22.1 36.7 22.3C36.9 22.5 37 22.8 37 23.1C36.3 32.7 28.4 40 18.6 40ZM16 5.1C7.9 6.2 2 13 2 21.4V21.5C2 22 2 22.6 2.1 23.2C3 31.6 10.1 38 18.6 38C26.9 38 33.7 32.1 34.8 24H17C16.4 24 16 23.6 16 23V5.1ZM39 20H21C20.4 20 20 19.6 20 19V1C20 0.4 20.4 0 21 0C30.6 0 40 9.4 40 19C40 19.6 39.6 20 39 20ZM22 18H38C37.4 10.2 29.8 2.7 22 2V18Z" fill="#0F0017"/>
  </svg>
</template>
