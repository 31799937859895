<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M20.5835 0.999904C20.5835 2.3999 17.7835 0.199904 17.5835 1.4999C17.4835 2.1999 14.1835 2.2999 14.0835 2.9999L9.98698 6.40936C9.68698 7.70936 7.0835 11.4999 7.5835 12.9999C6.7835 13.9999 8.1835 17.7999 7.0835 18.4999C7.0835 19.9999 6.5835 19.9999 7.0835 21.9999L7.5835 24.9999C2.6835 23.4999 1.5835 22.4999 0 20.4999C1.5835 16.9999 6.5835 16.9999 6.5835 16.4999C8.0835 7.9999 10.167 4 19.0835 0.5C20.1835 0.5 21.3 0.0999038 22.4 0.299904C23.0835 1.4999 22.0835 1.4999 21.0835 1.4999C21.0835 2.0999 20.5835 0.399904 20.5835 0.999904Z" fill="#00FF86"/>
    <path d="M20 40C17.8 40 16 38.2 16 36C16 33.8 17.8 32 20 32C21.9 32 23.4 33.3 23.9 35H29C30.7 35 32 33.7 32 32V14C32 7.4 26.6 2 20 2C13.4 2 8 7.4 8 14V28C8 28.6 7.6 29 7 29H3C1.3 29 0 27.7 0 26V19C0 17.3 1.3 16 3 16H6V14C6 6.3 12.3 0 20 0C27.7 0 34 6.3 34 14V16H37C38.7 16 40 17.3 40 19V26C40 27.7 38.7 29 37 29H34V32C34 34.8 31.8 37 29 37H23.9C23.4 38.7 21.9 40 20 40ZM20 34C18.9 34 18 34.9 18 36C18 37.1 18.9 38 20 38C21.1 38 22 37.1 22 36C22 34.9 21.1 34 20 34ZM34 27H37C37.6 27 38 26.6 38 26V19C38 18.4 37.6 18 37 18H34V27ZM3 18C2.4 18 2 18.4 2 19V26C2 26.6 2.4 27 3 27H6V18H3Z" fill="#0F0017"/>
    <path d="M25 22.0001C24.7 22.0001 24.5 21.9001 24.3 21.7001C24.1 21.5001 24 21.3001 24 21.0001C24 20.9001 24 20.7001 24.1 20.6001C24.1 20.5001 24.2 20.4001 24.3 20.3001C24.3 20.2001 24.4 20.2001 24.4 20.2001C24.4 20.2001 24.5 20.1001 24.6 20.1001C24.7 20.1001 24.7 20.1001 24.8 20.1001C25 20.1001 25.2 20.1001 25.4 20.1001C25.5 20.2001 25.6 20.2001 25.7 20.3001C25.8 20.4001 25.9 20.5001 25.9 20.6001C25.9 20.7001 26 20.8001 26 21.0001C26 21.3001 25.9 21.5001 25.7 21.7001C25.5 21.9001 25.3 22.0001 25 22.0001Z" fill="#0F0017"/>
    <path d="M15 22.0001C14.7 22.0001 14.5 21.9001 14.3 21.7001C14.1 21.5001 14 21.3001 14 21.0001C14 20.9001 14 20.7001 14.1 20.6001C14.1 20.5001 14.2 20.4001 14.3 20.3001C14.3 20.2001 14.4 20.2001 14.4 20.2001C14.4 20.2001 14.5 20.1001 14.6 20.1001C14.7 20.1001 14.7 20.1001 14.8 20.1001C15 20.1001 15.2 20.1001 15.4 20.1001C15.5 20.2001 15.6 20.2001 15.7 20.3001C15.8 20.4001 15.9 20.5001 15.9 20.6001C15.9 20.7001 16 20.8001 16 21.0001C16 21.3001 15.9 21.5001 15.7 21.7001C15.5 21.9001 15.3 22.0001 15 22.0001Z" fill="#0F0017"/>
  </svg>
</template>
