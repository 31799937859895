<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M12.25 30C10.6 30 9.25 31.35 9.25 33C9.25 34.65 10.6 36 12.25 36C13.9 36 15.25 34.65 15.25 33C15.25 31.35 13.9 30 12.25 30ZM12.25 34C11.7 34 11.25 33.55 11.25 33C11.25 32.45 11.7 32 12.25 32C12.8 32 13.25 32.45 13.25 33C13.25 33.55 12.8 34 12.25 34Z" fill="#0F0017"/>
    <path d="M39.25 34H36.25V32C36.25 31.45 35.8 31 35.25 31V8C35.25 3.59 31.66 0 27.25 0C22.84 0 19.25 3.59 19.25 8V18.5C19.25 18.78 19.03 19 18.75 19H16.33C16.33 19 16.29 19.01 16.26 19.01C15 18.57 13.65 18.27 12.25 18.12V18C12.25 17.45 11.8 17 11.25 17H7.25C6.7 17 6.25 17.45 6.25 18V18.14L3 18.25C1.35 18.25 0 19.6 0 21.25V32.25C0 33.9 1.35 35.25 3 35.25H6.69C7.58 37.45 9.74 39 12.25 39C14.76 39 16.92 37.45 17.81 35.25H23C24.65 35.25 26 33.9 26 32.25V31.25C26 27.93 24.53 24.91 22.13 22.58C23.43 21.66 24.25 20.14 24.25 18.5V8C24.25 6.35 25.6 5 27.25 5C28.9 5 30.25 6.35 30.25 8V31C29.7 31 29.25 31.45 29.25 32V34H26.25C25.7 34 25.25 34.45 25.25 35V39C25.25 39.55 25.7 40 26.25 40H39.25C39.8 40 40.25 39.55 40.25 39V35C40.25 34.45 39.8 34 39.25 34ZM12.25 37C10.04 37 8.25 35.21 8.25 33C8.25 30.79 10.04 29 12.25 29C14.46 29 16.25 30.79 16.25 33C16.25 35.21 14.46 37 12.25 37ZM24 31.25V32.25C24 32.8 23.55 33.25 23 33.25H18.24C18.24 33.17 18.25 33.08 18.25 33C18.25 29.69 15.56 27 12.25 27C8.94 27 6.25 29.69 6.25 33C6.25 33.08 6.25 33.17 6.26 33.25H3C2.45 33.25 2 32.8 2 32.25V21.25C2 20.7 2.45 20.25 3.03 20.25L10.25 20C17.83 20 24 25.05 24 31.25ZM27.25 3C24.49 3 22.25 5.24 22.25 8V18.5C22.25 19.67 21.55 20.74 20.51 21.22C20.29 21.06 20.05 20.9 19.82 20.75C20.66 20.35 21.25 19.49 21.25 18.5V8C21.25 4.69 23.94 2 27.25 2C30.56 2 33.25 4.69 33.25 8V31H32.25V8C32.25 5.24 30.01 3 27.25 3ZM31.25 33H34.25V34H31.25V33ZM38.25 38H27.25V36H38.25V38Z" fill="#0F0017"/>
    <path d="M10.04 27.43C10.73 27.16 11.47 27 12.25 27C14.43 27 16.34 28.17 17.39 29.92C19.29 29.99 21.17 29.51 22.84 28.5C23.05 28.37 23.25 28.23 23.44 28.1C21.77 23.43 16.49 20 10.25 20L3.03 20.25C2.44 20.25 2 20.7 2 21.25V21.33C4.36 23.82 7.08 25.87 10.04 27.43Z" fill="#00FF86"/>
    <path d="M21.2501 7.99994V18.4999C21.2501 19.4999 20.6601 20.3499 19.8201 20.7499C20.0601 20.8999 20.2901 21.0599 20.5101 21.2199C21.5501 20.7399 22.2501 19.6699 22.2501 18.4999V7.99994C22.2501 5.88994 23.5701 4.08994 25.4201 3.35994C25.2901 3.04994 25.1501 2.74994 25.0001 2.43994C22.8001 3.32994 21.2401 5.48994 21.2401 7.99994H21.2501Z" fill="#00FF86"/>
  </svg>
</template>
