<template>
  <svg
    width="40"
    height="28"
    viewBox="0 0 40 28"
  >
    <path d="M36 16C36 16 35.92 16 35.88 15.99L34.88 15.87L34.68 16.74C33.41 22.19 28.61 26 23.01 26H17.01C11.41 26 6.61 22.19 5.34 16.74L5.14 15.87L4.25 15.97H4.14C4.14 15.97 4.06 16 4.02 16C2.92 16 2.02 15.1 2.02 14C2.02 12.9 2.92 12 4.02 12C4.06 12 4.1 12 4.14 12.01L5.14 12.13L5.34 11.26C6.6 5.81 11.4 2 17 2H23C28.6 2 33.4 5.81 34.67 11.26L34.87 12.13L35.76 12.03H35.87C35.87 12.03 35.95 12 35.99 12C37.09 12 37.99 12.9 37.99 14C37.99 15.1 37.09 16 35.99 16H36ZM36.41 10.02C34.67 4.08 29.26 0 23 0H17C10.74 0 5.33 4.08 3.59 10.02C1.58 10.23 0 11.93 0 14C0 16.07 1.57 17.77 3.59 17.98C5.34 23.92 10.74 28 17 28H23C29.26 28 34.67 23.92 36.41 17.98C38.42 17.77 40 16.07 40 14C40 11.93 38.43 10.23 36.41 10.02Z" fill="#0F0017"/>
    <path d="M11 10C10.45 10 10 10.45 10 11C10 11.55 10.45 12 11 12C11.55 12 12 11.55 12 11C12 10.45 11.55 10 11 10Z" fill="#0F0017"/>
    <path d="M15 9C14.45 9 14 9.45 14 10C14 10.55 14.45 11 15 11C15.55 11 16 10.55 16 10C16 9.45 15.55 9 15 9Z" fill="#0F0017"/>
    <path d="M12 6C11.45 6 11 6.45 11 7C11 7.55 11.45 8 12 8C12.55 8 13 7.55 13 7C13 6.45 12.55 6 12 6Z" fill="#0F0017"/>
    <path d="M5.32996 11.26C6.59996 5.81 11.4 2 17 2H23C24.69 2 26.31 2.35 27.77 2.99C27.79 3.26 27.79 3.53 27.79 3.8C27.79 5.89 27.58 7.96 27.17 10L27.08 10.39V10.42C26.44 12.98 24.85 15.14 22.59 16.5C20.32 17.87 17.66 18.27 15.09 17.64L15 17.61C11.29 16.44 7.78996 14.58 4.70996 12.08L5.11996 12.13L5.32996 11.26ZM11 10C10.45 10 9.99996 10.45 9.99996 11C9.99996 11.55 10.45 12 11 12C11.55 12 12 11.55 12 11C12 10.45 11.55 10 11 10ZM12 8C12.55 8 13 7.55 13 7C13 6.45 12.55 6 12 6C11.45 6 11 6.45 11 7C11 7.55 11.45 8 12 8ZM14 10C14 10.55 14.45 11 15 11C15.55 11 16 10.55 16 10C16 9.45 15.55 9 15 9C14.45 9 14 9.45 14 10Z" fill="#00FF86"/>
  </svg>
</template>
