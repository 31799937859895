<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M27 15.9C27 17.4 26.9 18.9 26.7 20.3C26.6 20.9 26.5 21.5 26.4 22.1L26.3 22.5C26.2 22.8 26.1 23.2 26 23.5C25.9 23.7 25.8 24 25.7 24.2C24.9 26 23.5 27.5 21.8 28.6C21.6 28.7 21.5 28.8 21.3 28.9C19.4 29.9 17.2 30.3 15 29.9C14.8 29.9 14.5 29.8 14.3 29.8H14.2C9.3 28.1 4.7 25.3 1 21.4V5C1 3.9 1.9 3 3 3H24.2C25.4 5.6 26.2 8.3 26.6 11C26.9 12.6 27 14.3 27 15.9Z" fill="#00FF86"/>
    <path d="M37 40H3C1.3 40 0 38.7 0 37V5C0 3.3 1.3 2 3 2H10V1C10 0.4 10.4 0 11 0C11.6 0 12 0.4 12 1V2H28V1C28 0.4 28.4 0 29 0C29.6 0 30 0.4 30 1V2H37C38.7 2 40 3.3 40 5V37C40 38.7 38.7 40 37 40ZM2 15V37C2 37.6 2.4 38 3 38H37C37.6 38 38 37.6 38 37V13C38 12.4 37.6 12 37 12H3C2.4 12 2 12.4 2 13V15ZM2 9C2 9.6 2.4 10 3 10H37C37.6 10 38 9.6 38 9V5C38 4.4 37.6 4 37 4H30V5C30 5.6 29.6 6 29 6C28.4 6 28 5.6 28 5V4H12V5C12 5.6 11.6 6 11 6C10.4 6 10 5.6 10 5V4H3C2.4 4 2 4.4 2 5V9ZM24.5 32C22 32 20 30 20 27.5C20 26 20.7 24.6 21.9 23.8C21.3 23.2 21 22.4 21 21.5C21 19.6 22.6 18 24.5 18C26.4 18 28 19.6 28 21.5C28 22.4 27.7 23.2 27.1 23.8C28.2 24.6 29 26 29 27.5C29 30 27 32 24.5 32ZM24.5 25C23.1 25 22 26.1 22 27.5C22 28.9 23.1 30 24.5 30C25.9 30 27 28.9 27 27.5C27 26.1 25.9 25 24.5 25ZM24.5 20C23.7 20 23 20.7 23 21.5C23 22.3 23.7 23 24.5 23C25.3 23 26 22.3 26 21.5C26 20.7 25.3 20 24.5 20ZM17 32H13C12.4 32 12 31.6 12 31C12 30.4 12.4 30 13 30H14V20.6L13.4 20.9C12.9 21.1 12.3 20.9 12.1 20.5C11.9 20 12.1 19.4 12.5 19.2L14.5 18.2C14.8 18 15.2 18.1 15.5 18.2C15.8 18.4 16 18.7 16 19.1V30.1H17C17.6 30.1 18 30.5 18 31.1C18 31.7 17.6 32 17 32Z" fill="#0F0017"/>
  </svg>
</template>
