<template>
  <svg
    width="40"
    height="18"
    viewBox="0 0 40 18"
  >
    <path d="M8.8 17.6233C8.1 17.6233 7.4 17.5233 6.7 17.3233C6.2 17.2233 5.8 16.6233 6 16.1233C6.2 15.6233 6.7 15.2233 7.2 15.4233C8.9 15.8233 10.8 15.6233 12.3 14.7233C12.8 14.4233 13.4 14.6233 13.7 15.1233C14 15.6233 13.8 16.2233 13.3 16.5233C11.9 17.2233 10.4 17.6233 8.8 17.6233ZM3.5 15.5233C3.3 15.5233 3 15.4233 2.8 15.2233L2.6 15.0233C1 13.5233 0.1 11.4233 0 9.22334C0 8.62334 0.4 8.22334 0.9 8.12334C1.5 8.12334 1.9 8.52334 2 9.02334C2.1 10.7233 2.8 12.3233 4 13.5233L4.2 13.7233C4.6 14.1233 4.6 14.7233 4.2 15.1233C4 15.4233 3.7 15.5233 3.5 15.5233ZM15.7 13.9233C15.4 13.9233 15.2 13.8233 15 13.6233C14.6 13.2233 14.6 12.6233 15 12.2233L16.8 10.4233C17.2 10.0233 17.8 10.0233 18.2 10.4233C18.6 10.8233 18.6 11.4233 18.2 11.8233L16.4 13.6233C16.3 13.8233 16 13.9233 15.7 13.9233ZM1.8 6.32334C1.7 6.32334 1.5 6.32334 1.4 6.22334C0.8 6.02334 0.6 5.42334 0.9 4.92334C1.3 4.02334 1.9 3.22334 2.6 2.52334C3.5 1.62334 4.6 0.923341 5.8 0.523341C6.3 0.323341 6.9 0.52334 7.1 1.02334C7.3 1.52334 7 2.12334 6.5 2.32334C5.5 2.72334 4.7 3.22334 4 3.92334C3.4 4.42334 3 5.12334 2.7 5.72334C2.5 6.12334 2.1 6.32334 1.8 6.32334ZM15.1 5.02334C14.8 5.02334 14.6 4.92334 14.4 4.72334L13.6 3.92334C12.6 2.92334 11.3 2.22334 9.8 2.02334C9.4 1.92334 9 1.42334 9.1 0.92334C9.2 0.32334 9.7 -0.0766595 10.2 0.0233405C12.1 0.323341 13.8 1.12334 15.1 2.52334L15.9 3.32334C16.3 3.72334 16.3 4.32334 15.9 4.72334C15.7 4.92334 15.4 5.02334 15.1 5.02334Z" fill="#0F0017"/>
    <path d="M20 9.82332C19.7 9.82332 19.5 9.72332 19.3 9.52332L16.8 7.02332C16.4 6.62332 16.4 6.02332 16.8 5.62332C17.2 5.22332 17.8 5.22332 18.2 5.62332L20 7.42332L25 2.42332C25.4 2.02332 26 2.02332 26.4 2.42332C26.8 2.82332 26.8 3.42332 26.4 3.82332L20.7 9.52332C20.5 9.72332 20.2 9.82332 20 9.82332Z" fill="#0F0017"/>
    <path d="M31.1 17.7233C28.7 17.7233 26.5 16.8233 24.8 15.1233L21.6 11.9233C21.2 11.5233 21.2 10.9233 21.6 10.5233C22 10.1233 22.6 10.1233 23 10.5233L26.2 13.7233C27.5 15.0233 29.2 15.7233 31 15.7233C32.8 15.7233 34.6 15.0233 35.9001 13.7233C38.5 11.1233 38.5 6.62333 35.9001 4.02333C34.1 2.22333 31.4 1.52333 28.9 2.32333C28.4 2.52333 27.8 2.22333 27.6 1.72333C27.4 1.22333 27.7 0.623331 28.2 0.423331C31.4 -0.576669 34.8 0.223331 37.2 2.52333C40.7001 6.02333 40.7001 11.6233 37.2 15.0233C35.8 16.8233 33.5 17.7233 31.1 17.7233Z" fill="#0F0017"/>
  </svg>
</template>
