<template>
  <svg
    width="16"
    height="40"
    viewBox="0 0 16 40"
  >
    <path d="M13 7H2V3C2 2.45 2.45 2 3 2H12.54C13.11 3.12 13.59 4.27 14 5.43V7H13Z" fill="#00FF86"/>
    <path d="M8.89 24.93C8.81 24.94 8.74 24.95 8.66 24.96C8.56 24.96 8.45 24.98 8.34 24.98C8.22 24.99 8.11 24.99 8 24.99C7.89 24.99 7.78 24.99 7.66 24.98C7.55 24.98 7.44 24.97 7.34 24.96C7.26 24.95 7.19 24.94 7.11 24.93C4.49 24.52 2.41 22.28 2.06 19.53C2.04 19.33 2.01 19.13 2.01 18.93C2.01 18.85 2 18.78 2 18.71V9H14V18.71C14 18.78 13.99 18.85 13.99 18.93C13.99 19.13 13.96 19.33 13.94 19.53C13.59 22.28 11.51 24.52 8.89 24.93Z" fill="#00FF86"/>
    <path d="M15.96 1.6C15.77 0.69 14.97 0 14 0H2C1.03 0 0.23 0.69 0.04 1.6C0.01 1.73 0 1.87 0 2V18.78C0 18.78 0 18.86 0 18.91C0 19.19 0.03 19.46 0.06 19.72C0.21 21.22 0.71 22.66 1.63 23.85C2.89 25.48 4.64 26.51 6.57 26.85C6.71 26.88 6.84 26.91 6.98 26.93V38H1C0.59 38 0.23 38.25 0.08 38.61C0.03 38.73 0 38.86 0 39C0 39.14 0.03 39.27 0.08 39.39C0.23 39.75 0.59 40 1 40H15C15.41 40 15.77 39.75 15.92 39.39C15.97 39.27 16 39.14 16 39C16 38.86 15.97 38.73 15.92 38.61C15.77 38.25 15.41 38 15 38H9V26.93C9.14 26.91 9.28 26.88 9.41 26.85C11.34 26.51 13.09 25.48 14.35 23.85C15.27 22.66 15.77 21.21 15.92 19.72C15.95 19.45 15.97 19.18 15.98 18.91C15.98 18.87 15.98 18.83 15.98 18.78V2C15.98 1.86 15.97 1.73 15.94 1.6H15.96ZM13 2C13.55 2 14 2.45 14 3V7H2V3C2 2.45 2.45 2 3 2H13ZM13.99 18.93C13.99 19.13 13.96 19.33 13.94 19.53C13.59 22.28 11.5 24.52 8.89 24.93C8.82 24.94 8.74 24.95 8.67 24.96C8.56 24.97 8.45 24.98 8.34 24.99C8.23 24.99 8.12 24.99 8 24.99C7.89 24.99 7.78 24.99 7.66 24.99C7.55 24.99 7.44 24.98 7.33 24.96C7.26 24.96 7.18 24.94 7.11 24.93C4.49 24.52 2.41 22.29 2.06 19.53C2.04 19.33 2.02 19.13 2.01 18.93C2.01 18.86 2 18.78 2 18.71V9H14V18.71C14 18.78 14 18.86 13.99 18.93Z" fill="#0F0017"/>
  </svg>
</template>
