<template>
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
  >
    <path d="M7.96365 4.11846C7.60248 4.30651 7.2648 4.53345 6.95616 4.79375C6.98466 4.70087 7 4.60222 7 4.5C7 3.94772 6.55228 3.5 6 3.5C5.44772 3.5 5 3.94772 5 4.5C5 5.05228 5.44772 5.5 6 5.5C6.10222 5.5 6.20087 5.48466 6.29375 5.45616C6.03345 5.7648 5.80651 6.10248 5.61846 6.46365C4.69638 6.28554 4 5.4741 4 4.5C4 3.39543 4.89543 2.5 6 2.5C6.9741 2.5 7.78554 3.19638 7.96365 4.11846Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 3.52242V3.5H10.5C9.67157 3.5 9 2.82843 9 2V1.5H3V2C3 2.82843 2.32843 3.5 1.5 3.5H1V5.5H1.5C2.32843 5.5 3 6.17157 3 7V7.5H5.20703C5.11588 7.82228 5.05337 8.15659 5.02242 8.5H1.25C0.559644 8.5 0 7.94036 0 7.25V1.75C0 1.05964 0.559644 0.5 1.25 0.5H10.75C11.4404 0.5 12 1.05964 12 1.75V3.70703C11.6777 3.61588 11.3434 3.55337 11 3.52242ZM1 1.75C1 1.61193 1.11193 1.5 1.25 1.5H2V2C2 2.27614 1.77614 2.5 1.5 2.5H1V1.75ZM1.25 7.5C1.11193 7.5 1 7.38807 1 7.25V6.5H1.5C1.77614 6.5 2 6.72386 2 7V7.5H1.25ZM11 1.75V2.5H10.5C10.2239 2.5 10 2.27614 10 2V1.5H10.75C10.8881 1.5 11 1.61193 11 1.75Z" />
    <path d="M5.02242 9.5H2.5C2.35444 9.5 2.21179 9.48756 2.07304 9.46369C2.26836 10.0652 2.8334 10.5 3.5 10.5H5.20703C5.11588 10.1777 5.05337 9.84341 5.02242 9.5Z" />
    <path d="M14 4.75716C13.6929 4.50353 13.3578 4.28261 13 4.09971V2.58535C13.5826 2.79127 14 3.34689 14 4V4.75716Z" />
    <path d="M10.5 4.5C12.9853 4.5 15 6.51472 15 9C15 11.4853 12.9853 13.5 10.5 13.5C8.01472 13.5 6 11.4853 6 9C6 6.51472 8.01472 4.5 10.5 4.5ZM8.47402 6.97429C8.31426 7.13405 8.31426 7.39308 8.47402 7.55284L9.92155 9.00082L8.47614 10.4461C8.31638 10.6059 8.31638 10.8649 8.47614 11.0247C8.6359 11.1844 8.89492 11.1844 9.05468 11.0247L10.5 9.57927L11.9477 11.0265C12.1074 11.1863 12.3665 11.1863 12.5262 11.0265C12.686 10.8667 12.686 10.6077 12.5262 10.448L11.0793 9.00082L12.5285 7.55288C12.6883 7.39312 12.6883 7.13409 12.5285 6.97433C12.3687 6.81457 12.1097 6.81457 11.95 6.97433L10.5008 8.42236L9.05256 6.97429C8.8928 6.81453 8.63378 6.81453 8.47402 6.97429Z" />
  </svg>
</template>
