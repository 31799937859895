<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M21 1.6L25.9 13.5L26.9 13.6C27 14.4 27 15.1 27 15.9C27 18 26.8 20.1 26.4 22.1L26.3 22.5C25.7 25.1 24.1 27.2 21.8 28.6C19.5 30 16.9 30.4 14.3 29.7H14.2C12.8 29.3 11.5 28.8 10.2 28.2L11 25L1.3 16.5C1.1 16.2 1 16 1 15.7C1 15.2 1.3 14.7 1.9 14.6L14.5 13.4L19 1.6C19.4 0.8 20.6 0.8 21 1.6Z" fill="#00FF86"/>
    <path d="M31.1001 40C30.8001 40 30.4001 39.9 30.1001 39.7L20.0001 33.2L10.0001 39.7C9.20006 40.1 8.40006 40.1 7.80006 39.6C7.10006 39.1 6.80006 38.4 7.00006 37.6L10.0001 25.3L0.800061 17.2C0.200061 16.6 6.07818e-05 15.8 0.300061 15.1C0.500061 14.4 1.20006 13.8 1.90006 13.7L13.9001 12.6L18.2001 1.3C18.5001 0.5 19.2001 0 20.0001 0C20.8001 0 21.5001 0.5 21.9001 1.2L26.6001 12.5L38.1001 13.6C39.0001 13.7 39.6001 14.3 39.9001 15C40.1001 15.7 40.0001 16.5 39.4001 17.1L30.1001 25.3L33.1001 37.5C33.2001 38.4 32.9001 39.1 32.3001 39.6C31.9001 39.9 31.5001 40 31.1001 40ZM20.0001 31C20.2001 31 20.4001 31.1 20.5001 31.2L31.1001 38L28.0001 25.2C27.9001 24.8 28.0001 24.5 28.3001 24.2L38.0001 15.7C37.9001 15.7 37.9001 15.7 37.8001 15.7L25.7001 14.5C25.3001 14.5 25.0001 14.2 24.9001 13.9L20.0001 2L15.4001 13.9C15.3001 14.3 14.9001 14.5 14.6001 14.5L2.00006 15.7L11.7001 24.3C12.0001 24.5 12.1001 24.9 12.0001 25.3L8.90006 38L19.5001 31.2C19.6001 31.1 19.8001 31 20.0001 31Z" fill="#0F0017"/>
  </svg>
</template>
