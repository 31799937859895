<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M26.7 20C26.6 20.7 26.5 21.4 26.4 22.1L26.3 22.5C25.9 23.9 25.3 25.2 24.4 26.4C23.7 27.3 22.8 28 21.8 28.6C19.5 30 16.9 30.4 14.3 29.7H14.2C9.3 28.1 4.7 25.4 1.1 21.5C1 21 1 20.5 1 20C1 9.5 9.5 1 20 1C21.2 1 22.3 1.1 23.4 1.3C25.5 5.2 26.7 9.5 27 13.7C27 14.4 27.1 15.2 27.1 15.9C27 17.3 26.9 18.6 26.7 20Z" fill="#00FF86"/>
    <path d="M20 40C16.1 40 12.3 38.9 9 36.7C8.5 36.7 7.1 37.4 6.2 37.8C3.9 38.8 2.3 39.6 1.3 38.6C0.4 37.7 1.1 36 2.1 33.7C2.5 32.8 3.1 31.4 3.2 30.9C1.1 27.7 0 23.9 0 20C0 9 9 0 20 0C31 0 40 9 40 20C40 31 31 40 20 40ZM9.1 34.7C9.4 34.7 9.7 34.8 10 35C13 37 16.4 38 20 38C29.9 38 38 29.9 38 20C38 10.1 29.9 2 20 2C10.1 2 2 10.1 2 20C2 23.6 3 27 5 30C5.6 30.9 5.1 32.3 4 34.6C3.7 35.3 3.2 36.3 3 37C3.7 36.8 4.7 36.3 5.4 36C7.1 35.2 8.2 34.7 9.1 34.7ZM20 36C19.4 36 19 35.6 19 35V30H12.2C12.4 30.6 12.6 31.1 12.9 31.7C13.1 32.1 13 32.7 12.5 33C12 33.3 11.4 33.2 11.1 32.7L11 32.6C10.6 31.8 10.3 31 10 30.1H7C6.4 30.1 6 29.7 6 29.1C6 28.5 6.4 28.1 7 28.1H9.5C8.9 25.8 8.5 23.5 8.5 21.1H5C4.4 21.1 4 20.7 4 20.1C4 19.5 4.4 19.1 5 19.1H8.5C8.6 16.7 8.9 14.4 9.6 12.1H7C6.4 12.1 6 11.7 6 11.1C6 10.5 6.4 10.1 7 10.1H10.1C10.4 9.3 10.7 8.5 11 7.7C11.2 7.2 11.8 7 12.3 7.2C12.8 7.4 13 8 12.8 8.5C12.6 9 12.4 9.5 12.2 10.1H19V5C19 4.4 19.4 4 20 4C20.6 4 21 4.4 21 5V10H27.7C27.5 9.6 27.4 9.1 27.2 8.7L27.1 8.4C26.9 7.9 27.1 7.3 27.6 7.1C28.1 6.9 28.7 7.1 28.9 7.6L29 7.9C29.3 8.6 29.6 9.3 29.9 10H33C33.6 10 34 10.4 34 11C34 11.6 33.6 12 33 12H30.5C31.1 14.3 31.5 16.6 31.6 19H35C35.6 19 36 19.4 36 20C36 20.6 35.6 21 35 21H31.6C31.5 23.4 31.2 25.7 30.5 28H33C33.6 28 34 28.4 34 29C34 29.6 33.6 30 33 30H29.9C29.6 30.8 29.3 31.6 28.9 32.3C28.7 32.8 28.1 33 27.6 32.8C27.1 32.6 26.9 32 27.1 31.5C27.3 31 27.5 30.5 27.7 30H21V35C21 35.6 20.6 36 20 36ZM21 28H28.4C29.1 25.7 29.5 23.4 29.6 21H21V28ZM11.6 28H19V21H10.5C10.5 23.4 10.9 25.7 11.6 28ZM21 19H29.6C29.5 16.6 29.1 14.3 28.4 12H21V19ZM10.5 19H19V12H11.6C10.9 14.3 10.5 16.6 10.5 19Z" fill="#0F0017"/>
  </svg>
</template>
