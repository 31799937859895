<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <path d="M14.85 0H3.15C1.4103 0 0 1.4103 0 3.15V14.85C0 16.5897 1.4103 18 3.15 18H14.85C16.5897 18 18 16.5897 18 14.85V3.15C18 1.4103 16.5897 0 14.85 0Z" fill="#0F0017"/>
    <path d="M5.06825 6.62061H2.52856V15.8231H5.06825V6.62061Z" fill="white"/>
    <path d="M13.5563 6.62053C11.6832 5.90615 10.3275 7.11553 9.74817 7.79053V6.62053H7.21692V15.823H9.74817V10.9686C9.73376 10.3534 9.95859 9.75651 10.3754 9.30365C10.4943 9.16362 10.6409 9.04963 10.8059 8.96881C10.9709 8.88799 11.1509 8.84209 11.3344 8.83396C11.5397 8.83002 11.7437 8.8676 11.9341 8.94445C12.1245 9.02129 12.2975 9.13582 12.4425 9.28115C12.7571 9.60989 12.929 10.0497 12.9207 10.5046V15.823H15.7782V10.1249C15.7782 10.1249 16.096 7.57115 13.5563 6.62053Z" fill="white"/>
    <path d="M3.79694 5.34926C4.67301 5.34926 5.38319 4.63907 5.38319 3.76301C5.38319 2.88695 4.67301 2.17676 3.79694 2.17676C2.92088 2.17676 2.21069 2.88695 2.21069 3.76301C2.21069 4.63907 2.92088 5.34926 3.79694 5.34926Z" fill="white"/>
  </svg>
</template>
