<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M26.4001 22.3L26.3001 22.7C26.2001 23.1 26.0001 23.6 25.9001 24C25.1001 26 23.7001 27.7 21.8001 28.8C19.5001 30.2 16.9001 30.5 14.3001 29.9H14.2001H14.1001V24H10.1001L7.4001 26.7C6.6001 26.2 5.8001 25.7 5.1001 25.1C4.6001 24.7 4.2001 24.4 3.7001 24C2.8001 23.2 1.9001 22.4 1.1001 21.5C1.1001 21.3 1.1001 21.2 1.1001 21V4C1.1001 2.3 2.4001 1 4.1001 1H23.4001C26.9001 7.7 27.8001 15.3 26.4001 22.3Z" fill="#00FF86"/>
    <path d="M35 40C34.7 40 34.5 39.9 34.3 39.7L29.6 35H17C14.8 35 13 33.2 13 31V25H10.4L5.7 29.7C5.4 30 5 30.1 4.6 29.9C4.2 29.8 4 29.4 4 29V25C3.8 25 3.6 25 3.5 25C1.8 24.8 0.4 23.5 0.1 21.7C0 21.4 0 21.2 0 21V4C0 1.8 1.8 0 4 0H31C33.2 0 35 1.8 35 4V15H36C38.2 15 40 16.8 40 19V31C40 33.2 38.2 35 36 35V39C36 39.4 35.8 39.8 35.4 39.9C35.3 40 35.1 40 35 40ZM15 25V31C15 32.1 15.9 33 17 33H30C30.3 33 30.5 33.1 30.7 33.3L34 36.6V34C34 33.4 34.4 33 35 33H36C37.1 33 38 32.1 38 31V19C38 17.9 37.1 17 36 17H35V21C35 23.2 33.2 25 31 25H15ZM4 2C2.9 2 2 2.9 2 4V21C2 21.1 2 21.2 2 21.3C2.2 22.2 2.9 22.9 3.7 23C3.8 23 3.9 23 3.9 23H4.9C5.5 23 5.9 23.4 5.9 24V26.6L9.2 23.3C9.5 23.1 9.7 23 10 23H31C32.1 23 33 22.1 33 21V4C33 2.9 32.1 2 31 2H4ZM18 19C17.4 19 17 18.6 17 18C17 17.4 17.4 17 18 17C18.6 17 19 17.4 19 18C19 18.6 18.6 19 18 19ZM18 15C17.4 15 17 14.6 17 14V7C17 6.4 17.4 6 18 6C18.6 6 19 6.4 19 7V14C19 14.6 18.6 15 18 15Z" fill="#0F0017"/>
  </svg>
</template>
