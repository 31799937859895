<template>
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
  >
    <path d="M3 2C3 0.895431 3.89543 0 5 0H8.58579C8.98361 0 9.36514 0.158035 9.64645 0.43934L12.5607 3.35355C12.842 3.63486 13 4.01639 13 4.41421V12C13 13.1046 12.1046 14 11 14H7.66308C8.06621 13.7161 8.42948 13.3794 8.74284 13H11C11.5523 13 12 12.5523 12 12V5H9.5C8.67157 5 8 4.32843 8 3.5V1H5C4.44771 1 4 1.44772 4 2V4.02242C3.65659 4.05337 3.32228 4.11588 3 4.20703V2ZM9.5 4H11.7929L9 1.20711V3.5C9 3.77614 9.22386 4 9.5 4Z" />
    <path d="M9 9.5C9 11.9853 6.98528 14 4.5 14C2.01472 14 0 11.9853 0 9.5C0 7.01472 2.01472 5 4.5 5C6.98528 5 9 7.01472 9 9.5ZM6.85355 7.64645C6.65829 7.45118 6.34171 7.45118 6.14645 7.64645L3.5 10.2929L2.85355 9.64645C2.65829 9.45118 2.34171 9.45118 2.14645 9.64645C1.95118 9.84171 1.95118 10.1583 2.14645 10.3536L3.14645 11.3536C3.34171 11.5488 3.65829 11.5488 3.85355 11.3536L6.85355 8.35355C7.04882 8.15829 7.04882 7.84171 6.85355 7.64645Z" />
  </svg>
</template>
