<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M27 15.9C27 18 26.8 20.1 26.4 22.1L26.3 22.5C25.9 24.2 25 25.8 23.8 27C23.2 27.6 22.6 28.1 21.8 28.6C19.5 30 16.9 30.4 14.3 29.7H14.2C12.1 29 10 28.1 8.1 27C5.5 25.5 3.1 23.7 1 21.4V3C1 1.9 1.9 1 3 1H23.2C25.8 5.7 27 10.8 27 15.9Z" fill="#00FF86"/>
    <path d="M39 40C38.8 40 38.7 40 38.5 39.9L32 36.2L26.5 39.9C26.2 40.1 25.7 40.1 25.4 39.9L20 36.2L14.6 39.8C14.3 40 13.8 40 13.5 39.8L8 36.2L1.5 39.9C1.2 40.1 0.8 40.1 0.5 39.9C0.2 39.7 0 39.4 0 39V3C0 1.3 1.3 0 3 0H37C38.7 0 40 1.3 40 3V39C40 39.4 39.8 39.7 39.5 39.9C39.3 40 39.2 40 39 40ZM20 34C20.2 34 20.4 34.1 20.6 34.2L26 37.8L31.4 34.2C31.7 34 32.1 34 32.5 34.2L38 37.3V3C38 2.4 37.6 2 37 2H3C2.4 2 2 2.4 2 3V37.3L7.5 34.2C7.8 34 8.2 34 8.6 34.2L14 37.8L19.4 34.2C19.6 34.1 19.8 34 20 34ZM33 28H30C29.4 28 29 27.6 29 27C29 26.4 29.4 26 30 26H33C33.6 26 34 26.4 34 27C34 27.6 33.6 28 33 28ZM24 28H7C6.4 28 6 27.6 6 27C6 26.4 6.4 26 7 26H24C24.6 26 25 26.4 25 27C25 27.6 24.6 28 24 28ZM33 23H30C29.4 23 29 22.6 29 22C29 21.4 29.4 21 30 21H33C33.6 21 34 21.4 34 22C34 22.6 33.6 23 33 23ZM24 23H7C6.4 23 6 22.6 6 22C6 21.4 6.4 21 7 21H24C24.6 21 25 21.4 25 22C25 22.6 24.6 23 24 23ZM33 18H30C29.4 18 29 17.6 29 17C29 16.4 29.4 16 30 16H33C33.6 16 34 16.4 34 17C34 17.6 33.6 18 33 18ZM24 18H7C6.4 18 6 17.6 6 17C6 16.4 6.4 16 7 16H24C24.6 16 25 16.4 25 17C25 17.6 24.6 18 24 18ZM33 13H30C29.4 13 29 12.6 29 12C29 11.4 29.4 11 30 11H33C33.6 11 34 11.4 34 12C34 12.6 33.6 13 33 13ZM24 13H7C6.4 13 6 12.6 6 12C6 11.4 6.4 11 7 11H24C24.6 11 25 11.4 25 12C25 12.6 24.6 13 24 13ZM33 8H30C29.4 8 29 7.6 29 7C29 6.4 29.4 6 30 6H33C33.6 6 34 6.4 34 7C34 7.6 33.6 8 33 8ZM24 8H7C6.4 8 6 7.6 6 7C6 6.4 6.4 6 7 6H24C24.6 6 25 6.4 25 7C25 7.6 24.6 8 24 8Z" fill="#0F0017"/>
  </svg>
</template>
