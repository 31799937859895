<template>
  <svg
    width="29"
    height="40"
    viewBox="0 0 29 40"
  >
    <path d="M20.9999 15.9C20.9999 16.9 20.8999 18 20.7999 19C20.6999 20 20.4999 21.1 20.2999 22.1L20.1999 22.5C20.0999 23 19.8999 23.5 19.6999 24C18.8999 25.9 17.4999 27.5 15.6999 28.6C15.3999 28.8 15.1999 28.9 14.8999 29C12.7999 30 10.4999 30.3 8.1999 29.7H8.0999C7.4999 29.5 6.7999 29.3 6.1999 29C4.3999 28.3 2.5999 27.4 0.899902 26.3V3C0.899902 1.9 1.7999 1 2.8999 1H16.0999C16.5999 1 16.9999 1.2 17.3999 1.5C18.9999 4.6 20.0999 7.8 20.5999 11C20.8999 12.6 20.9999 14.3 20.9999 15.9Z" fill="#00FF86"/>
    <path d="M25 40H3C1.3 40 0 38.7 0 37V3C0 1.3 1.3 0 3 0H16.2C16.9 0 17.5 0.2 18.1 0.7C18.2 0.8 18.3 0.8 18.4 0.9L27.2 9.7C27.8 10.3 28.1 11 28.1 11.8V37C28 38.7 26.7 40 25 40ZM3 2C2.4 2 2 2.4 2 3V37C2 37.6 2.4 38 3 38H25C25.6 38 26 37.6 26 37V12H19C17.3 12 16 10.7 16 9V2H3ZM18 3.4V9C18 9.6 18.4 10 19 10H24.6L18 3.4ZM22 35H6C5.4 35 5 34.6 5 34C5 33.4 5.4 33 6 33H22C22.6 33 23 33.4 23 34C23 34.6 22.6 35 22 35ZM22 30H6C5.4 30 5 29.6 5 29C5 28.4 5.4 28 6 28H22C22.6 28 23 28.4 23 29C23 29.6 22.6 30 22 30ZM22 25H6C5.4 25 5 24.6 5 24C5 23.4 5.4 23 6 23H22C22.6 23 23 23.4 23 24C23 24.6 22.6 25 22 25ZM22 20H6C5.4 20 5 19.6 5 19C5 18.4 5.4 18 6 18H22C22.6 18 23 18.4 23 19C23 19.6 22.6 20 22 20Z" fill="#0F0017"/>
  </svg>
</template>
