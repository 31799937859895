<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M24.2762 2.73707C24.1762 2.63707 23.9762 2.53707 23.7762 2.43707C17.9762 -0.462934 10.6762 1.23707 6.57623 6.33707C4.67623 8.73707 3.67623 11.4371 3.47623 14.1371C3.27623 17.5371 4.27623 21.0371 6.37623 23.8371C6.97623 24.4371 6.97623 25.3371 6.67623 26.1371C8.47623 27.3371 10.4762 28.3371 12.5762 29.1371C13.0762 28.8371 13.6762 28.7371 14.2762 28.8371C16.9762 29.5371 19.6762 29.3371 22.2762 28.4371C22.9762 28.0371 23.5762 27.5371 24.0762 26.9371C25.2762 25.7371 26.0762 24.2371 26.4762 22.6371L26.5762 22.2371C26.9762 20.2371 27.2762 18.1371 27.2762 16.0371C27.1762 11.5371 26.2762 7.03707 24.2762 2.73707Z" fill="#00FF86"/>
    <path d="M35.5529 39.4674C33.0823 39.4674 31.1058 37.491 31.1058 35.0204C31.1058 32.5498 33.0823 30.5733 35.5529 30.5733C38.0235 30.5733 40 32.5498 40 35.0204C40 37.491 38.0235 39.4674 35.5529 39.4674ZM35.5529 32.5498C34.1694 32.5498 33.0823 33.6368 33.0823 35.0204C33.0823 36.4039 34.1694 37.491 35.5529 37.491C36.9365 37.491 38.0235 36.4039 38.0235 35.0204C38.0235 33.6368 36.9365 32.5498 35.5529 32.5498ZM4.91744 39.4674C4.3245 39.4674 3.73155 39.2698 3.23743 38.9733L1.4586 37.7874C-0.023764 36.898 -0.41906 34.9215 0.470357 33.4392L5.60921 25.4344C5.90568 25.0391 5.80686 24.4462 5.51039 24.1497C1.26095 18.6156 1.35977 11.0061 5.70803 5.57077C10.0563 0.135446 17.7646 -1.54456 23.9905 1.51898C27.8446 3.49547 30.6117 6.75666 31.7976 10.9073C32.8847 14.9591 32.2917 19.4061 29.9199 22.9638C26.4611 28.3003 19.9387 30.8697 13.8116 29.3874C13.3175 29.2886 12.8234 29.4862 12.5269 29.8815L7.58569 37.8863C7.09157 38.578 6.3998 39.0721 5.60921 39.2698C5.31274 39.4674 5.11509 39.4674 4.91744 39.4674ZM4.42332 15.4532C4.52215 18.1214 5.41156 20.6909 7.09157 22.865C7.88216 23.9521 7.98099 25.3356 7.28922 26.5215L2.15037 34.5262C1.85389 35.0204 1.95272 35.8109 2.54566 36.1074L4.3245 37.2933C4.62097 37.491 4.91744 37.491 5.21391 37.491C5.51039 37.3921 5.80686 37.2933 5.90568 36.9968L10.8469 28.9921C11.5387 27.8062 12.9222 27.3121 14.3057 27.6085C17.0728 28.3003 20.0375 28.0038 22.5081 26.9168L4.42332 15.4532ZM4.52214 13.1802L24.4846 25.7309C25.967 24.7426 27.2517 23.4579 28.2399 21.8768C30.2164 18.8132 30.8094 14.9591 29.8211 11.4014C28.8329 7.84373 26.4611 4.879 23.0023 3.19899C17.5669 0.530742 10.9457 2.0131 7.1904 6.65784C5.70803 8.73314 4.81862 10.9073 4.52214 13.1802Z" fill="#0F0017"/>
  </svg>
</template>
