<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 3.5C5.89543 3.5 5 4.39543 5 5.5C5 6.60457 5.89543 7.5 7 7.5C8.10457 7.5 9 6.60457 9 5.5C9 4.39543 8.10457 3.5 7 3.5ZM6 5.5C6 4.94772 6.44772 4.5 7 4.5C7.55228 4.5 8 4.94772 8 5.5C8 6.05228 7.55228 6.5 7 6.5C6.44772 6.5 6 6.05228 6 5.5Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2C0 1.17157 0.671573 0.5 1.5 0.5H12.5C13.3284 0.5 14 1.17157 14 2V6.20703C13.6777 6.11588 13.3434 6.05337 13 6.02242V4.5L12 4.5C10.8954 4.5 10 3.60457 10 2.5V1.5H4V2.5C4 3.60457 3.10457 4.5 2 4.5L1 4.5V6.5H2C3.10457 6.5 4 7.39543 4 8.5V9.5H7.37494C7.25003 9.81986 7.15419 10.1543 7.09069 10.5H1.5C0.671573 10.5 0 9.82843 0 9V2ZM1.5 1.5C1.22386 1.5 1 1.72386 1 2V3.5L2 3.5C2.55228 3.5 3 3.05228 3 2.5V1.5H1.5ZM12.5 1.5H11V2.5C11 3.05228 11.4477 3.5 12 3.5L13 3.5V2C13 1.72386 12.7761 1.5 12.5 1.5ZM1.5 9.5H3V8.5C3 7.94772 2.55228 7.5 2 7.5H1V9C1 9.27614 1.22386 9.5 1.5 9.5Z" />
    <path d="M7 11.5H2.08545C2.29137 12.0826 2.84699 12.5 3.5001 12.5H7.09068C7.03112 12.1757 7 11.8415 7 11.5C7 11.5 7 11.5 7 11.5Z" />
    <path d="M16.0001 7.25724V3.99997C16.0001 3.34686 15.5827 2.79125 15.0001 2.58533V6.59976C15.3579 6.78266 15.693 7.0036 16.0001 7.25724Z" />
    <path d="M12.5 7C14.9853 7 17 9.01472 17 11.5C17 13.9853 14.9853 16 12.5 16C10.0147 16 8 13.9853 8 11.5C8 9.01472 10.0147 7 12.5 7ZM10.474 9.47429C10.3143 9.63405 10.3143 9.89308 10.474 10.0528L11.9215 11.5008L10.4761 12.9461C10.3164 13.1059 10.3164 13.3649 10.4761 13.5247C10.6359 13.6844 10.8949 13.6844 11.0547 13.5247L12.5 12.0793L13.9477 13.5265C14.1074 13.6863 14.3665 13.6863 14.5262 13.5265C14.686 13.3667 14.686 13.1077 14.5262 12.948L13.0793 11.5008L14.5285 10.0529C14.6883 9.89312 14.6883 9.63409 14.5285 9.47433C14.3687 9.31457 14.1097 9.31457 13.95 9.47433L12.5008 10.9224L11.0526 9.47429C10.8928 9.31454 10.6338 9.31454 10.474 9.47429Z" />
  </svg>
</template>
