<template>
  <svg
    width="40"
    height="30"
    viewBox="0 0 40 30"
  >
    <path d="M27 10.9C27 11.4 27 11.9 27 12.3C26.9 13.9 26.7 15.5 26.4 17.1L26.3 17.5C26.2 17.8 26.1 18.1 26 18.4C25.2 20.5 23.8 22.4 21.8 23.6C19.5 25 16.9 25.4 14.3 24.7H14.2C12.4 24.1 10.6 23.4 8.9 22.5C6 20.9 3.3 18.9 1 16.5V3C1 1.9 1.9 1 3 1H25.4C26.5 4.2 27 7.6 27 10.9Z" fill="#00FF86"/>
    <path d="M37 30H3C1.3 30 0 28.7 0 27V3C0 1.3 1.3 0 3 0H37C38.7 0 40 1.3 40 3V27C40 28.7 38.7 30 37 30ZM3.6 28H36.4L22.9 17.2C21.1 18.2 18.8 18.2 17 17.2L3.6 28ZM24.5 15.9L38 26.7V3.4L24.5 15.9ZM2 3.4V26.8L15.5 16L2 3.4ZM17.6 15.2C19 16.2 20.9 16.2 22.3 15.2L36.5 2H3.5L17.6 15.2Z" fill="#0F0017"/>
  </svg>
</template>
