<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      d="M9 9H2.75C2.33579 9 2 9.33579 2 9.75C2 10.1642 2.33579 10.5 2.75 10.5H9H10.5H16.75C17.1642 10.5 17.5 10.1642 17.5 9.75C17.5 9.33579 17.1642 9 16.75 9H10.5H9Z"
      fill="#0F0017"
    />
  </svg>
</template>
