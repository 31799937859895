<template>
  <svg
    width="36"
    height="40"
    viewBox="0 0 36 40"
  >
    <path d="M25 15.9C25 18 24.8 20.1 24.4 22.1L24.3 22.5C24.2 22.8 24.1 23.1 24 23.4C22.2 24.4 20.2 25 18 25C11.4 25 6 19.6 6 13V4C6 2.3 7.3 1 9 1H21.2C23.8 5.7 25 10.8 25 15.9Z" fill="#00FF86"/>
    <path d="M27 40H9C8.4 40 8 39.6 8 39C8 38.4 8.4 38 9 38H10.1C10.6 34.4 13.4 31.5 17 31.1V26C12.4 25.6 8.5 22.9 6.5 19C2.9 18.7 0 15.7 0 12V9C0 6.8 1.8 5 4 5H5V4C5 1.8 6.8 0 9 0H27C29.2 0 31 1.8 31 4V5H32C34.2 5 36 6.8 36 9V12C36 15.7 33.1 18.7 29.5 19C28.4 21.2 26.7 23 24.5 24.3C22.8 25.3 20.9 25.9 19 26V31.1C22.6 31.6 25.5 34.4 25.9 38H27C27.6 38 28 38.4 28 39C28 39.6 27.6 40 27 40ZM12.1 38H23.9C23.4 35.2 21 33 18 33C15 33 12.6 35.2 12.1 38ZM9 2C7.9 2 7 2.9 7 4V13C7 19.1 11.9 24 18 24C19.9 24 21.8 23.5 23.5 22.5C26.9 20.5 29 16.9 29 13V4C29 2.9 28.1 2 27 2H9ZM31 7V13C31 14.3 30.8 15.6 30.4 16.8C32.5 16.2 34 14.3 34 12V9C34 7.9 33.1 7 32 7H31ZM4 7C2.9 7 2 7.9 2 9V12C2 14.3 3.5 16.2 5.6 16.8C5.2 15.6 5 14.3 5 13V7H4Z" fill="#0F0017"/>
  </svg>
</template>
