<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M27 15.9C27 18 26.8 20.1 26.4 22.1L26.3 22.5C25.7 25.1 24.1 27.2 21.8 28.6C19.5 30 16.9 30.4 14.3 29.7H14.2C12.4 29.1 10.7 28.4 9 27.5V18L3 16C1 15 1 13 1 13C2.4 8.7 5.4 4.9 7 3.1C7.6 2.4 8.5 1.9 9.4 1.8L15 1C15 3.8 17.2 6 20 6C21.9 6 23.5 5 24.4 3.4C26.1 7.4 27 11.7 27 15.9Z" fill="#00FF86"/>
    <path d="M29 40H11C9.3 40 8 38.7 8 37V18.7L2.7 16.9C2.7 16.9 2.6 16.9 2.6 16.8C0 15.6 0 13.1 0 13C0 12.9 0 12.8 0.1 12.7C1.5 8.2 4.6 4.4 6.2 2.5C7 1.6 8.1 1 9.3 0.8L14.9 0C15.2 0 15.5 0 15.7 0.2C15.9 0.4 16 0.7 16 1C16 3.2 17.8 5 20 5C21.5 5 22.8 4.2 23.5 2.9C23.8 2.3 24 1.7 24 1C24 0.7 24.1 0.4 24.3 0.2C24.6 0.1 24.9 0 25.1 0L30.7 0.8C31.9 1 33 1.6 33.7 2.5C35.4 4.4 38.4 8.3 39.9 12.7C39.9 12.8 40 12.9 40 13C40 13.1 40 15.6 37.4 16.9C37.4 16.9 37.3 16.9 37.3 17L32 18.7V37C32 38.7 30.7 40 29 40ZM10 18V37C10 37.6 10.4 38 11 38H29C29.6 38 30 37.6 30 37V18V14C30 13.4 30.4 13 31 13C31.6 13 32 13.4 32 14V16.6L36.6 15.1C37.7 14.5 37.9 13.5 38 13.2C36.6 9.1 33.8 5.6 32.3 3.9C31.8 3.4 31.2 3 30.5 2.9L25.9 2.2C25.8 2.8 25.6 3.4 25.3 4C24.2 5.8 22.2 7 20 7C17.1 7 14.6 4.9 14.1 2.1L9.6 2.8C8.9 2.9 8.2 3.2 7.8 3.8C6.2 5.5 3.4 9.1 2 13.1C2 13.5 2.3 14.5 3.4 15L8 16.6V14C8 13.4 8.4 13 9 13C9.6 13 10 13.4 10 14V18Z" fill="#0F0017"/>
  </svg>
</template>
