<template>
  <svg
    width="30"
    height="40"
    viewBox="0 0 30 40"
  >
    <path d="M22.7901 15.8C22.7901 17.89 22.5801 19.96 22.1701 22L22.0801 22.39V22.42C21.4401 24.98 19.8501 27.14 17.5901 28.5C15.3201 29.87 12.6601 30.27 10.0901 29.64L10.0001 29.61C8.42009 29.11 6.88009 28.49 5.40009 27.74L4.09009 12H22.5601C22.7101 13.27 22.7901 14.53 22.7901 15.8Z" fill="#00FF86"/>
    <path d="M8 4H4V3C4 2.45 4.45 2 5 2H19.54C19.87 2.66 20.18 3.33 20.46 4H17.11L16.87 4.66C16.59 5.46 15.84 6 15 6C14.16 6 13.41 5.46 13.13 4.66L12.89 4H8Z" fill="#00FF86"/>
    <path d="M22.24 10H2V7C2 6.45 2.45 6 3 6H11.55C11.68 6.22 11.83 6.43 12 6.62C12.74 7.47 13.83 8 15 8C16.44 8 17.75 7.22 18.45 6H21.2C21.64 7.32 21.99 8.66 22.24 10Z" fill="#00FF86"/>
    <path d="M28 4V2C28 0.9 27.1 0 26 0H4C2.9 0 2 0.9 2 2V4C1.03 4 0.23 4.69 0.04 5.6C0.01 5.73 0 5.86 0 6V10C0 11.1 0.9 12 2 12H2.08L3.93 34.17C3.99 34.87 4.41 35.46 5 35.77V38.01C5 39.11 5.9 40.01 7 40.01H23C24.1 40.01 25 39.11 25 38.01V35.77C25.59 35.46 26.01 34.87 26.07 34.17L27.92 12H28C29.1 12 30 11.1 30 10V6C30 4.9 29.1 4 28 4ZM4 3C4 2.45 4.45 2 5 2H25C25.55 2 26 2.45 26 3V4H17.11L16.87 4.66C16.59 5.46 15.83 6 15 6C14.17 6 13.41 5.46 13.13 4.66L12.89 4H4V3ZM23 37C23 37.55 22.55 38 22 38H8C7.45 38 7 37.55 7 37V36H23V37ZM17 34H6.84C6.32 34 5.89 33.6 5.84 33.08L4.08 12H24.83C24.89 12 24.94 12.03 25 12.04V12H25.91L25.83 13H25.81C25.81 13 25.82 13.05 25.82 13.08L24.15 33.08C24.11 33.6 23.67 33.99 23.15 33.99H16.99L17 34ZM28 10H2V7C2 6.45 2.45 6 3 6H11.55C11.68 6.22 11.83 6.43 12 6.62C12.74 7.48 13.83 8 15 8C16.44 8 17.75 7.22 18.45 6H27C27.55 6 28 6.45 28 7V10Z" fill="#0F0017"/>
  </svg>
</template>
