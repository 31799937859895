<template>
  <svg
    width="18"
    height="40"
    viewBox="0 0 18 40"  
  >
    <path d="M17 5C14.79 5 13 3.21 13 1C13 0.45 12.55 0 12 0H8.5C3.81 0 0 3.81 0 8.5V25.75C0 28.51 2.24 30.75 5 30.75H6V37.87C6 38.97 6.9 39.87 8 39.87H10C11.1 39.87 12 38.97 12 37.87V30.75H13C15.76 30.75 18 28.51 18 25.75V6C18 5.45 17.55 5 17 5ZM8.5 2H11.08C11.5 4.51 13.49 6.49 16 6.92V11.88H10C8.9 11.88 8 12.78 8 13.88V17.63C8 18.18 7.55 18.63 7 18.63C6.45 18.63 6 18.18 6 17.63V13.88C6 12.78 5.1 11.88 4 11.88H2V8.5C2 4.92 4.92 2 8.5 2ZM10 35.88V36.88C10 37.43 9.55 37.88 9 37.88C8.45 37.88 8 37.43 8 36.88V30.76H10V35.88ZM13 28.76H5C3.35 28.76 2 27.41 2 25.76V13.88H4V17.63C4 19.28 5.35 20.63 7 20.63C8.65 20.63 10 19.28 10 17.63V13.88H16V25.76C16 27.41 14.65 28.76 13 28.76Z" fill="#0F0017"/>
    <path d="M4 17.6201C4 19.2701 5.35 20.6201 7 20.6201C8.65 20.6201 10 19.2701 10 17.6201V13.8701H16V22.5901C15.31 25.0201 13.76 27.0601 11.59 28.3701C11.36 28.5101 11.12 28.6401 10.88 28.7501H5C3.35 28.7501 2 27.4001 2 25.7501V13.8801H4V17.6301V17.6201Z" fill="#00FF86"/>
    <path d="M6 13.88C6 12.78 5.1 11.88 4 11.88H2V8.5C2 4.92 4.92 2 8.5 2H11.08C11.48 4.34 13.23 6.23 15.5 6.81C15.68 7.44 15.85 8.08 16 8.72V11.88H10C8.9 11.88 8 12.78 8 13.88V17.63C8 18.18 7.55 18.63 7 18.63C6.45 18.63 6 18.18 6 17.63V13.88Z" fill="#00FF86"/>
  </svg>
</template>
