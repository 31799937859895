<template>
  <svg
    width="4"
    height="8"
    viewBox="0 0 4 8"
  >
    <path d="M0.050766 5.41154C0.153952 5.16243 0.397041 5 0.66668 5H3.33332C3.60296 5 3.84605 5.16243 3.94923 5.41154C4.05242 5.66065 3.99538 5.9474 3.80472 6.13806L2.4714 7.47138C2.21105 7.73173 1.78895 7.73173 1.5286 7.47138L0.19528 6.13806C0.00461626 5.9474 -0.0524204 5.66065 0.050766 5.41154Z" />
    <path d="M3.94923 2.58846C3.84605 2.83757 3.60296 3 3.33332 3L0.66668 3C0.397041 3 0.153952 2.83757 0.050766 2.58846C-0.0524201 2.33935 0.0046161 2.0526 0.19528 1.86194L1.5286 0.528619C1.78895 0.268272 2.21105 0.268272 2.4714 0.528619L3.80472 1.86194C3.99538 2.0526 4.05242 2.33935 3.94923 2.58846Z" />
  </svg>
</template>
