<template>
  <svg
    width="84"
    height="22"
    viewBox="0 0 84 22"
  >
    <path
      d="M16.5488 3.92017C12.9793 3.92017 10.1534 6.80077 10.1534 10.5049C10.1581 14.4525 12.8452 17.3403 17.0769 17.3403C20.2849 17.3403 21.8492 16.0036 21.8492 16.0036L21.1132 13.1081C21.1132 13.1081 19.3901 14.4911 17.2404 14.4911C13.7333 14.4911 13.3104 11.5827 13.3104 11.5827H22.3815C23.0375 7.43918 20.8812 3.92017 16.5488 3.92017ZM13.4398 8.95889C13.8421 7.7919 14.9562 6.80076 16.6169 6.80076C18.1301 6.80076 19.1039 7.77747 19.4097 8.95889H13.4398Z"
    />
    <path
      d="M67.1478 3.92017C63.5783 3.92017 60.7524 6.80077 60.7524 10.5049C60.7524 14.4525 63.4442 17.3403 67.6755 17.3403C70.8829 17.3403 72.4472 16.0036 72.4472 16.0036L71.7113 13.1096C71.7113 13.1096 69.9881 14.4932 67.8395 14.4932C64.3323 14.4932 63.9094 11.5842 63.9094 11.5842H72.981C73.6376 7.43918 71.4802 3.92017 67.1478 3.92017ZM64.0383 8.95889C64.4417 7.7919 65.5547 6.80076 67.2154 6.80076C68.7286 6.80076 69.7024 7.77747 70.0082 8.95889H64.0383Z"
    />
    <path
      d="M8.5463 14.0196C8.5463 14.0196 7.86137 14.4693 6.98459 14.4693C5.86849 14.4693 5.60958 13.606 5.60958 12.0569V7.0579H8.5463V4.19277H5.60958V0.305557L2.53 2.2306V4.19329H0.305542V7.0579H2.53V12.4726C2.53 16.7599 4.95405 17.3556 6.61685 17.3556C8.25644 17.3556 9.23329 16.7517 9.23329 16.7517L8.5463 14.0196Z"
    />
    <path
      d="M42.3676 4.80038C40.6058 3.64216 38.336 3.77984 36.6242 4.36566C35.956 4.5977 35.3182 4.90935 34.7246 5.29389C32.905 3.62308 30.2824 3.70971 28.3617 4.36566C25.9892 5.17838 24.5967 6.87446 24.5967 6.87446V17.0881H27.685V7.65727C27.685 7.65727 28.7011 6.76874 30.6579 6.76874C32.545 6.76874 32.8586 7.80011 32.8586 9.54054V17.0881H35.947V7.65727C35.947 7.65727 36.9646 6.76874 38.9213 6.76874C40.808 6.76874 41.1211 7.80011 41.1211 9.54054V17.0881H44.2094V8.37922C44.2094 6.95851 43.5544 5.58267 42.3676 4.80038Z"
    />
    <path
      d="M46.4895 6.8445C48.0564 5.2923 49.7187 3.92419 52.5997 3.92419C55.9005 3.92419 58.8646 6.47424 58.8646 10.5889C58.8646 13.9697 56.5643 17.3505 52.2319 17.3505V14.538C54.1557 14.538 55.7499 13.1394 55.7499 10.7539C55.7499 8.45704 54.3099 6.78314 52.2319 6.78314C51.2783 6.79281 50.3514 7.09922 49.5799 7.6598V21.6915H46.4895V6.8445Z"
    />
    <path
      d="M82.8266 6.78262H81.1004C80.1464 6.79208 79.2191 7.0985 78.4473 7.65928V17.0792H75.3569V6.8445C76.9243 5.2923 78.5866 3.92419 81.4676 3.92419C81.9246 3.92427 82.3802 3.97354 82.8266 4.07117V6.78262Z"
    />
  </svg>
</template>
