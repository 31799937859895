<template>
  <svg
    width="34"
    height="40"
    viewBox="0 0 34 40"
  >
    <path d="M24 15.9C24 18 23.8 20.1 23.4 22.1L23.3 22.5C22.7 25.1 21.1 27.2 18.8 28.6C16.5 30 13.9 30.4 11.3 29.7H11.2C7.5 28.5 4 26.7 1 24.2V14C1 12.3 2.3 11 4 11H5C5.6 11 6 10.6 6 10V7C4.3 7 3 5.7 3 4C3 2.3 4.3 1 6 1H20.2C21.3 2.9 22.1 5 22.7 7C23.6 9.9 24 12.9 24 15.9Z" fill="#00FF86"/>
    <path d="M31 40H3C1.3 40 0 38.7 0 37V14C0 11.8 1.8 10 4 10H5V7.9C3.3 7.4 2 5.9 2 4C2 1.8 3.8 0 6 0H28C30.2 0 32 1.8 32 4C32 5.9 30.7 7.4 29 7.9V10H30C32.2 10 34 11.8 34 14V37C34 38.7 32.7 40 31 40ZM4 12C2.9 12 2 12.9 2 14V37C2 37.6 2.4 38 3 38H31C31.6 38 32 37.6 32 37V14C32 12.9 31.1 12 30 12H29C27.9 12 27 11.1 27 10V8H7V10C7 11.1 6.1 12 5 12H4ZM6 6H28C29.1 6 30 5.1 30 4C30 2.9 29.1 2 28 2H6C4.9 2 4 2.9 4 4C4 5.1 4.9 6 6 6Z" fill="#0F0017"/>
  </svg>
</template>
