<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
  >
    <path d="M8 0.5C12.4183 0.5 16 4.08172 16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5ZM8 1.5C4.13401 1.5 1 4.63401 1 8.5C1 12.366 4.13401 15.5 8 15.5C11.866 15.5 15 12.366 15 8.5C15 4.63401 11.866 1.5 8 1.5Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.38891 2.61109L13.8889 13.1111L13.1111 13.8889L2.61109 3.38891L3.38891 2.61109Z" />
    <path d="M9.96025 11.9116C9.77134 12.0098 9.49177 12.0627 9.09885 12.0627C7.4365 12.0627 6.07639 11.1937 5.67591 9.52383H4.90519C4.70117 9.52383 4.55005 9.37271 4.55005 9.1687C4.55005 8.95712 4.70117 8.81356 4.90519 8.81356H5.57768C5.57768 8.738 5.57013 8.62465 5.57013 8.4962C5.57013 8.3753 5.57013 8.27707 5.57768 8.1864H4.90519C4.70117 8.1864 4.55005 8.02772 4.55005 7.8237C4.55005 7.61213 4.70117 7.46856 4.90519 7.46856H5.67591C6.06883 5.79866 7.4365 4.93726 9.09885 4.93726C9.49177 4.93726 9.77134 4.97504 9.95269 5.06571C10.1945 5.17905 10.27 5.34529 10.27 5.54175C10.27 5.79866 10.0963 5.95733 9.86202 5.98C9.69578 6.01778 9.4162 5.98 9.09885 5.98756C8.07121 5.99512 7.21737 6.49382 6.90757 7.46856H9.18952C9.40109 7.46856 9.54466 7.61213 9.54466 7.8237C9.54466 8.02772 9.40109 8.1864 9.18952 8.1864H6.74889C6.74133 8.27707 6.74133 8.38285 6.74133 8.49619C6.74133 8.60953 6.74133 8.73044 6.75644 8.81356H9.18952C9.40109 8.81356 9.54466 8.95712 9.54466 9.1687C9.54466 9.37271 9.40109 9.52383 9.18952 9.52383H6.90757C7.21737 10.5137 8.06365 11.0124 9.09129 11.0124C9.4162 11.0199 9.68067 10.9897 9.86202 11.02C10.1038 11.0426 10.27 11.2013 10.27 11.4582C10.27 11.6622 10.1718 11.8133 9.96025 11.9116Z" />
  </svg>
</template>
