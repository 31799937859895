<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M27 15.9C27 18 26.8 20.1 26.4 22.1L26.3 22.5C25.7 25.1 24.1 27.2 21.8 28.6C19.5 30 16.9 30.4 14.3 29.7H14.2C9.4 28.2 5 25.5 1.4 21.9C1.4 21.7999 1.3 21.6 1.3 21.5C1.1 20.6 1 19.6999 1 18.7999C1 10.5999 8.8 2.69995 18.5 2.69995C20.1 2.69995 21.6 2.69995 23.1 2.69995C23.1 2.69995 23.4 2.49995 23.9 2.19995C26 6.59995 27 11.3 27 15.9Z" fill="#00FF86"/>
    <path d="M28 40H22C20.9 40 20 39.1 20 38V34.6C19 34.7 18 34.7 17 34.6V38C17 39.1 16.1 40 15 40H9C7.9 40 7 39.1 7 38V33.8C7 33.2 6.9 32.7 6.6 32.2C6 31.1 5 29.8 4.2 28.8C4.2 28.8 4.2 28.8 4.1 28.7C4 28.6 4 28.6 3.9 28.5C3.9 28.5 3.9 28.5 3.8 28.4L3.7 28.3C2.1 26.5 1 24.4 0.4 22.1C0.4 22 0.3 21.8 0.3 21.7C0.1 20.7 0 19.8 0 18.8C0 9.70001 8.6 1.70001 18.5 1.70001C19.3 1.70001 20.1 1.70001 20.9 1.70001C21.5 1.70001 22.2 1.70001 22.8 1.70001C22.9 1.60001 23.1 1.50001 23.4 1.40001C23.6 1.30001 23.9 1.10001 24.2 1.00001C26.2 0.100011 29.2 -0.699989 32 0.800011C32.5 1.00001 32.7 1.60001 32.4 2.10001C31.7 3.40001 30.9 5.90001 31.2 7.20001C32.9 8.60001 34.3 10.3 35.2 12C35.4 12 35.7 12 35.9 12C36.1 12 36.3 12 36.5 12H38C39.1 12 40 13 40 14.1V22.3C40 23.4 39.1 24.3 38 24.3H36.5C36.3 24.3 35.6 24.9 35.3 25.4C34.8 26.4 34 27.4 33.2 28.4C33.1 28.5 33 28.6 32.9 28.7C32 29.7 31 31.1 30.4 32.2C30.1 32.7 30 33.2 30 33.8V38C30 39.1 29.1 40 28 40ZM22 34.3V38H28V33.8C28 33.3 28.1 32.8 28.2 32.3C26.3 33.3 24.2 34 22 34.3ZM8.8 32.3C8.9 32.8 9 33.3 9 33.8V38H15V34.3C12.8 34 10.7 33.3 8.8 32.3ZM5.6 27.4C5.7 27.5 5.7 27.6 5.8 27.6C9 30.7 13.6 32.5 18.5 32.5C23.3 32.5 27.9 30.7 31.1 27.6C31.1 27.6 31.1 27.6 31.2 27.5C31.2 27.5 31.2 27.5 31.3 27.4C31.4 27.3 31.5 27.2 31.5 27.2C31.6 27.1 31.7 27 31.8 26.9C32.5 26.1 33.1 25.2 33.6 24.3C33.9 23.7 35.1 22.1 36.5 22.1H38V13.9V13.8H36.6C36.5 13.8 36.3 13.8 36.2 13.8C35.5 13.9 34.3 14 33.7 12.9C32.9 11.3 31.5 9.70001 29.8 8.40001C29.7 8.30001 29.6 8.10001 29.5 8.00001C28.8 6.20001 29.7 3.50001 30.3 2.00001C28.4 1.40001 26.5 2.00001 25.1 2.60001C24.9 2.70001 24.6 2.80001 24.5 2.90001C24.1 3.10001 23.8 3.30001 23.8 3.30001C23.6 3.40001 23.4 3.50001 23.2 3.50001C22.5 3.50001 21.8 3.50001 21 3.50001C20.2 3.50001 19.4 3.50001 18.6 3.50001C9.7 3.70001 2 10.8 2 18.8C2 19.6 2.1 20.4 2.3 21.2C2.3 21.3 2.4 21.4 2.4 21.6C2.9 23.6 3.8 25.4 5.3 27L5.4 27.1C5.5 27.2 5.5 27.3 5.6 27.4Z" fill="#0F0017"/>
  </svg>
</template>
