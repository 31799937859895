<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M14.9239 13.3827C14.7691 13.7564 14.4045 14 14 14L10 14C9.59557 14 9.23093 13.7564 9.07615 13.3827C8.92137 13.009 9.00692 12.5789 9.29292 12.2929L11.2929 10.2929C11.6834 9.90237 12.3166 9.90237 12.7071 10.2929L14.7071 12.2929C14.9931 12.5789 15.0787 13.009 14.9239 13.3827Z"
      fill="#0F0017"
    />
  </svg>
</template>
