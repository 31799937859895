<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"  
  >
    <path opacity="0.85" fill-rule="evenodd" clip-rule="evenodd" d="M4.5 8.99998C4.5 8.50318 4.0968 8.09998 3.6 8.09998H0.9C0.4032 8.09998 0 8.50318 0 8.99998C0 9.49678 0.4032 9.89998 0.9 9.89998H3.6C4.0968 9.89998 4.5 9.49678 4.5 8.99998Z" />
    <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd" d="M17.1 8.09998H14.4C13.9032 8.09998 13.5 8.50318 13.5 8.99998C13.5 9.49678 13.9032 9.89998 14.4 9.89998H17.1C17.5968 9.89998 18 9.49678 18 8.99998C18 8.50318 17.5968 8.09998 17.1 8.09998Z" />
    <path opacity="0.55" fill-rule="evenodd" clip-rule="evenodd" d="M8.99998 13.5C8.50318 13.5 8.09998 13.9032 8.09998 14.4V17.1C8.09998 17.5977 8.50318 18 8.99998 18C9.49678 18 9.89998 17.5977 9.89998 17.1V14.4C9.89998 13.9032 9.49678 13.5 8.99998 13.5Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99998 0C8.50318 0 8.09998 0.4032 8.09998 0.9V3.6C8.09998 4.0968 8.50318 4.5 8.99998 4.5C9.49678 4.5 9.89998 4.0968 9.89998 3.6V0.9C9.89998 0.4032 9.49678 0 8.99998 0Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.72942 1.53538C5.48102 1.10518 4.93112 0.95668 4.50002 1.20598C4.06892 1.45438 3.92222 2.00518 4.17062 2.43538L5.52062 4.77358C5.76902 5.20378 6.31892 5.35138 6.75002 5.10298C7.18112 4.85458 7.32782 4.30378 7.07942 3.87358L5.72942 1.53538Z" />
    <path opacity="0.45" fill-rule="evenodd" clip-rule="evenodd" d="M12.4794 13.2264C12.231 12.7962 11.6811 12.6486 11.25 12.897C10.8189 13.1454 10.6722 13.6962 10.9206 14.1264L12.2706 16.4646C12.519 16.8948 13.0689 17.0433 13.5 16.794C13.9311 16.5456 14.0778 15.9948 13.8294 15.5646L12.4794 13.2264Z" />
    <path opacity="0.75" fill-rule="evenodd" clip-rule="evenodd" d="M5.10298 11.25C4.85458 10.8189 4.30378 10.6722 3.87358 10.9206L1.53538 12.2706C1.10518 12.519 0.95668 13.0689 1.20598 13.5C1.45438 13.9311 2.00518 14.0778 2.43538 13.8294L4.77358 12.4794C5.20378 12.231 5.35138 11.6811 5.10298 11.25Z" />
    <path opacity="0.15" fill-rule="evenodd" clip-rule="evenodd" d="M12.897 6.75002C13.1454 7.18112 13.6962 7.32782 14.1264 7.07942L16.4646 5.72942C16.8948 5.48102 17.0433 4.93112 16.794 4.50002C16.5456 4.06892 15.9948 3.92222 15.5646 4.17062L13.2264 5.52062C12.7962 5.76902 12.6486 6.31892 12.897 6.75002Z" />
    <path opacity="0.95" fill-rule="evenodd" clip-rule="evenodd" d="M1.53538 5.72942L3.87358 7.07942C4.30378 7.32782 4.85458 7.18112 5.10298 6.75002C5.35138 6.31892 5.20378 5.76902 4.77358 5.52062L2.43538 4.17062C2.00518 3.92222 1.45438 4.06892 1.20598 4.50002C0.95668 4.93112 1.10518 5.48102 1.53538 5.72942Z" />
    <path opacity="0.35" fill-rule="evenodd" clip-rule="evenodd" d="M16.4646 12.2706L14.1264 10.9206C13.6962 10.6722 13.1454 10.8189 12.897 11.25C12.6486 11.6811 12.7962 12.231 13.2264 12.4794L15.5646 13.8294C15.9948 14.0778 16.5456 13.9311 16.794 13.5C17.0433 13.0689 16.8948 12.519 16.4646 12.2706Z" />
    <path opacity="0.65" fill-rule="evenodd" clip-rule="evenodd" d="M6.75002 12.897C6.31892 12.6486 5.76902 12.7962 5.52062 13.2264L4.17062 15.5646C3.92222 15.9948 4.06892 16.5456 4.50002 16.794C4.93112 17.0433 5.48102 16.8948 5.72942 16.4646L7.07942 14.1264C7.32782 13.6962 7.18112 13.1454 6.75002 12.897Z" />
    <path opacity="0.05" fill-rule="evenodd" clip-rule="evenodd" d="M11.25 5.10298C11.6811 5.35138 12.231 5.20378 12.4794 4.77358L13.8294 2.43538C14.0778 2.00518 13.9311 1.45438 13.5 1.20598C13.0689 0.95668 12.519 1.10518 12.2706 1.53538L10.9206 3.87358C10.6722 4.30378 10.8189 4.85458 11.25 5.10298Z" />
  </svg>
</template>
