<template>
  <svg
    width="36"
    height="40"
    viewBox="0 0 36 40"
  >
    <path d="M24.6 23.87C23.24 22.88 22.29 21.31 22.06 19.53C22.04 19.33 22.01 19.13 22.01 18.93C22.01 18.85 22 18.78 22 18.71V9H25.04C25.54 11.25 25.79 13.53 25.79 15.8C25.79 17.89 25.58 19.96 25.17 22L25.08 22.39V22.42C24.96 22.92 24.79 23.4 24.6 23.87Z" fill="#00FF86"/>
    <path d="M22 5.00009V3.00009C22 2.60009 22.24 2.25009 22.58 2.09009C23.38 3.69009 24.02 5.33009 24.52 7.00009H22V5.00009Z" fill="#00FF86"/>
    <path d="M14 6V7H2V3C2 2.45 2.45 2 3 2H13C13.55 2 14 2.45 14 3V6Z" fill="#00FF86"/>
    <path d="M13 9H14V18.71C14 18.78 13.99 18.85 13.99 18.93C13.99 19.13 13.96 19.33 13.94 19.53C13.59 22.28 11.51 24.52 8.89 24.93C8.81 24.94 8.74 24.95 8.66 24.96C8.56 24.96 8.45 24.98 8.34 24.98C8.22 24.99 8.11 24.99 8 24.99C7.89 24.99 7.78 24.99 7.66 24.98C7.55 24.98 7.44 24.97 7.34 24.96C7.26 24.95 7.19 24.94 7.11 24.93C4.49 24.52 2.41 22.28 2.06 19.53C2.04 19.33 2.01 19.13 2.01 18.93C2.01 18.85 2 18.78 2 18.71V9H13Z" fill="#00FF86"/>
    <path d="M35.96 1.6C35.77 0.69 34.97 0 34 0H22C21.03 0 20.23 0.69 20.04 1.6C20.01 1.73 20 1.87 20 2V18.78C20 18.78 20 18.86 20 18.91C20 19.19 20.03 19.46 20.06 19.72C20.21 21.22 20.71 22.66 21.63 23.85C22.89 25.48 24.64 26.51 26.57 26.85C26.71 26.88 26.84 26.91 26.98 26.93V38H20.98C20.57 38 20.21 38.25 20.06 38.61C20.01 38.73 19.98 38.86 19.98 39C19.98 39.14 20.01 39.27 20.06 39.39C20.21 39.75 20.57 40 20.98 40H34.98C35.39 40 35.75 39.75 35.9 39.39C35.95 39.27 35.98 39.14 35.98 39C35.98 38.86 35.95 38.73 35.9 38.61C35.75 38.25 35.39 38 34.98 38H28.98V26.93C29.12 26.91 29.26 26.88 29.39 26.85C31.32 26.51 33.07 25.48 34.33 23.85C35.25 22.66 35.75 21.21 35.9 19.72C35.93 19.45 35.95 19.18 35.96 18.91C35.96 18.87 35.96 18.83 35.96 18.78V2C35.96 1.86 35.95 1.73 35.92 1.6H35.96ZM33 2C33.55 2 34 2.45 34 3V7H22V3C22 2.45 22.45 2 23 2H33ZM33.99 18.93C33.99 19.13 33.96 19.33 33.94 19.53C33.59 22.28 31.5 24.52 28.89 24.93C28.82 24.94 28.74 24.95 28.67 24.96C28.56 24.97 28.45 24.98 28.34 24.99C28.23 24.99 28.12 24.99 28 24.99C27.89 24.99 27.78 24.99 27.66 24.99C27.55 24.99 27.44 24.98 27.33 24.96C27.26 24.96 27.18 24.94 27.11 24.93C24.49 24.52 22.41 22.29 22.06 19.53C22.04 19.33 22.02 19.13 22.01 18.93C22.01 18.86 22 18.78 22 18.71V9H34V18.71C34 18.78 34 18.86 33.99 18.93Z" fill="#0F0017"/>
    <path d="M15.96 1.6C15.77 0.69 14.97 0 14 0H2C1.03 0 0.23 0.69 0.04 1.6C0.01 1.73 0 1.87 0 2V18.78C0 18.78 0 18.86 0 18.91C0 19.19 0.03 19.46 0.06 19.72C0.21 21.22 0.71 22.66 1.63 23.85C2.89 25.48 4.64 26.51 6.57 26.85C6.71 26.88 6.84 26.91 6.98 26.93V38H1C0.59 38 0.23 38.25 0.08 38.61C0.03 38.73 0 38.86 0 39C0 39.14 0.03 39.27 0.08 39.39C0.23 39.75 0.59 40 1 40H15C15.41 40 15.77 39.75 15.92 39.39C15.97 39.27 16 39.14 16 39C16 38.86 15.97 38.73 15.92 38.61C15.77 38.25 15.41 38 15 38H9V26.93C9.14 26.91 9.28 26.88 9.41 26.85C11.34 26.51 13.09 25.48 14.35 23.85C15.27 22.66 15.77 21.21 15.92 19.72C15.95 19.45 15.97 19.18 15.98 18.91C15.98 18.87 15.98 18.83 15.98 18.78V2C15.98 1.86 15.97 1.73 15.94 1.6H15.96ZM13 2C13.55 2 14 2.45 14 3V7H2V3C2 2.45 2.45 2 3 2H13ZM13.99 18.93C13.99 19.13 13.96 19.33 13.94 19.53C13.59 22.28 11.5 24.52 8.89 24.93C8.82 24.94 8.74 24.95 8.67 24.96C8.56 24.97 8.45 24.98 8.34 24.99C8.23 24.99 8.12 24.99 8 24.99C7.89 24.99 7.78 24.99 7.66 24.99C7.55 24.99 7.44 24.98 7.33 24.96C7.26 24.96 7.18 24.94 7.11 24.93C4.49 24.52 2.41 22.29 2.06 19.53C2.04 19.33 2.02 19.13 2.01 18.93C2.01 18.86 2 18.78 2 18.71V9H14V18.71C14 18.78 14 18.86 13.99 18.93Z" fill="#0F0017"/>
  </svg>
</template>
