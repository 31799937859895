<template>
  <svg
    width="24"
    height="37"
    viewBox="0 0 24 37"
  >
    <path d="M18.4001 20.5L18.3001 21C17.7001 23.6 16.1001 25.7 13.8001 27.1C11.5001 28.5 8.9001 28.9 6.3001 28.2H6.2001C5.1001 27.9 4.1001 27.5 3.1001 27L12.0001 4L18.4001 20.5Z" fill="#00FF86"/>
    <path d="M12 37C6.2 37 0 35.3 0 32.5C0 32.4 0 32.3 0.1 32.1L10.5 5.1L8.6 4.4C8.1 4.2 7.9 3.6 8.1 3.1C8.3 2.6 8.9 2.3 9.4 2.5L11 3.2V1C11 0.4 11.4 0 12 0C12.6 0 13 0.4 13 1V3.2L14.6 2.5C15.1 2.3 15.7 2.5 15.9 3.1C16.1 3.6 15.9 4.2 15.3 4.4L13.5 5.1L23.9 32.1C23.9 32.2 24 32.3 24 32.5C24 35.3 17.8 37 12 37ZM2 32.6C2.5 33.3 6.2 35 12 35C17.8 35 21.5 33.4 22 32.6L19.8 27C15.5 32.3 7.9 31.6 3.1 29.6L2 32.6ZM3.9 27.8C7.2 29.2 15.2 30.9 19 24.8L15.8 16.4C13.1 19.4 11 19.5 7.2 19.5L3.9 27.8ZM12 6.8L7.9 17.5C11.2 17.5 12.6 17.3 14.9 14.3L12 6.8Z" fill="#0F0017"/>
  </svg>
</template>
