<template>
  <svg
    width="35"
    height="40"
    viewBox="0 0 35 40"
  >
    <path d="M24.9999 15.9C24.9999 18 24.7999 20.1 24.3999 22.1L24.2999 22.5C23.6999 25.1 22.0999 27.2 19.7999 28.6C17.4999 30 14.8999 30.4 12.2999 29.7H12.1999C9.9999 29 7.8999 28.1 5.8999 26.9C6.5999 25 6.9999 22.7 6.9999 20V18C6.9999 12.9 10.4999 8.6 15.1999 7.3C15.5999 7.2 15.8999 7.1 16.2999 7.1C16.3999 7.1 16.5999 7.1 16.7999 7C16.8999 7 17.0999 7 17.1999 7C17.3999 7 17.6999 7 17.9999 7C18.2999 7 18.4999 7 18.7999 7C18.8999 7 19.0999 7 19.1999 7C19.3999 7 19.4999 7 19.6999 7.1C20.0999 7.1 20.3999 7.2 20.7999 7.3H20.8999C22.0999 7.6 23.1999 8.1 24.1999 8.8C24.6999 11.2 24.9999 13.6 24.9999 15.9Z" fill="#00FF86"/>
    <path d="M17.9958 40C15.1965 40 12.997 37.8 12.997 35H1.99966C1.59976 35 1.19985 34.8 1.09988 34.4C0.899924 34 0.9999 33.6 1.29983 33.3C1.29983 33.3 5.99871 28.4 5.99871 20V18C5.99871 12.8 9.29792 8.3 13.9968 6.7V4C13.9968 1.8 15.7964 0 17.9958 0C20.1953 0 21.9949 1.8 21.9949 4V6.7C26.6938 8.4 29.993 12.8 29.993 18V20C29.993 28.4 34.6919 33.2 34.6919 33.3C34.9918 33.6 35.0918 34 34.8918 34.4C34.7918 34.8 34.3919 35 33.992 35H22.9947C22.9947 37.8 20.7952 40 17.9958 40ZM14.9966 35C14.9966 36.7 16.2963 38 17.9958 38C19.6954 38 20.9951 36.7 20.9951 35H14.9966ZM4.09916 33H31.8925C30.2929 30.7 27.9935 26.3 27.9935 20V18C27.9935 12.8 23.9944 8.5 18.9956 8C18.6957 8 18.3958 8 18.0958 8C17.7959 8 17.496 8 17.196 8C11.9973 8.5 7.99823 12.8 7.99823 18V20C7.99823 26.3 5.69878 30.7 4.09916 33ZM19.6954 6.1C19.7954 6.1 19.8954 6.1 19.9954 6.1V4C19.9954 2.9 19.0956 2 17.9958 2C16.8961 2 15.9963 2.9 15.9963 4V6.2C16.0963 6.2 16.1963 6.2 16.2963 6.2C16.7961 6 17.396 6 17.8959 6C18.5957 6 19.1956 6 19.6954 6.1Z" fill="#0F0017"/>
  </svg>
</template>
