<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M11.9999 9.1L18.4999 11.7L11.5999 28.7C9.3999 27.8 7.3999 26.7 5.3999 25.3L8.3999 18L10.2999 13.4L11.9999 9.1ZM23.1999 1C23.8999 2.3 24.4999 3.6 24.9999 4.9C25.5999 6.5 26.0999 8.2 26.3999 9.9C26.7999 11.9 26.9999 13.9 26.9999 15.8C26.9999 17.9 26.7999 20 26.3999 22L26.2999 22.4C25.6999 25 24.0999 27.1 21.7999 28.5C21.1999 28.8 20.5999 29.1 19.9999 29.3V1H23.1999Z" fill="#00FF86"/>
    <path d="M38.9999 40H31.9999C31.3999 40 30.9999 39.6 30.9999 39V5C30.9999 4.4 31.3999 4 31.9999 4H38.9999C39.5999 4 39.9999 4.4 39.9999 5V39C39.9999 39.6 39.5999 40 38.9999 40ZM32.9999 38H37.9999V35.9H32.9999V38ZM32.9999 33.9H37.9999V30.9H32.9999V33.9ZM32.9999 28.9H37.9999V14.9H32.9999V28.9ZM32.9999 12.9H37.9999V9.9H32.9999V12.9ZM32.9999 7.9H37.9999V6H32.9999V7.9ZM26.9999 40H19.9999C19.3999 40 18.9999 39.6 18.9999 39V13.2L8.3999 39.4C8.1999 39.9 7.5999 40.2 7.0999 40L0.599902 37.4C0.399902 37.3 0.199902 37.1 0.0999023 36.9C-9.76548e-05 36.5 -9.76548e-05 36.3 0.0999023 36L1.5999 32.3C1.5999 32.3 1.5999 32.3 1.5999 32.2L7.4999 17.7C7.4999 17.6 7.4999 17.6 7.5999 17.5L9.3999 13C9.3999 12.9 9.4999 12.9 9.4999 12.8L11.0999 8.8C11.1999 8.6 11.3999 8.4 11.5999 8.3C11.7999 8.2 12.0999 8.2 12.3999 8.3L18.8999 10.9C18.8999 10.9 18.9999 10.9 18.9999 11V1C18.9999 0.4 19.3999 0 19.9999 0H26.9999C27.5999 0 27.9999 0.4 27.9999 1V39C27.9999 39.6 27.5999 40 26.9999 40ZM20.9999 38H25.9999V35.9H20.9999V38ZM2.2999 35.8L6.8999 37.7L7.5999 35.8L3.0999 34L2.2999 35.8ZM3.7999 32.1L8.3999 34L9.4999 31.2L4.8999 29.3L3.7999 32.1ZM20.9999 33.9H25.9999V30.9H20.9999V33.9ZM5.6999 27.5L10.2999 29.4L13.5999 21.2L8.9999 19.3L5.6999 27.5ZM20.9999 28.9H25.9999V10.9H20.9999V28.9ZM9.6999 17.4L14.2999 19.3L15.3999 16.5L10.7999 14.6L9.6999 17.4ZM11.5999 12.8L16.1999 14.7L17.0999 12.4L12.4999 10.5L11.5999 12.8ZM20.9999 8.9H25.9999V5.9H20.9999V8.9ZM20.9999 3.9H25.9999V2H20.9999V3.9Z" fill="#0F0017"/>
  </svg>
</template>
