<template>
  <svg
    width="30"
    height="32"
    viewBox="0 0 30 32"
  >
    <path d="M21.9999 7.9C21.9999 8.9 21.9999 10 21.7999 11C21.6999 12 21.4999 13.1 21.2999 14.1L21.1999 14.5C21.1999 14.7 21.0999 14.8 21.0999 15C20.9999 15.2 20.9999 15.3 20.8999 15.5C20.0999 17.6 18.6999 19.4 16.6999 20.6C14.3999 22 11.7999 22.4 9.1999 21.7H9.0999C6.1999 20.8 3.3999 19.5 0.899902 17.7V3C0.899902 1.9 1.7999 1 2.8999 1H21.0999C21.3999 2.3 21.5999 3.7 21.6999 5C21.9999 6 21.9999 6.9 21.9999 7.9Z" fill="#00FF86"/>
    <path d="M27 32H3C1.3 32 0 30.7 0 29V3C0 1.3 1.3 0 3 0H27C28.7 0 30 1.3 30 3V29C30 30.7 28.7 32 27 32ZM3 2C2.4 2 2 2.4 2 3V29C2 29.6 2.4 30 3 30H27C27.6 30 28 29.6 28 29V3C28 2.4 27.6 2 27 2H3ZM25 28H21C20.4 28 20 27.6 20 27V23C20 22.4 20.4 22 21 22H25C25.6 22 26 22.4 26 23V27C26 27.6 25.6 28 25 28ZM22 26H24V24H22V26ZM17 28H13C12.4 28 12 27.6 12 27V23C12 22.4 12.4 22 13 22H17C17.6 22 18 22.4 18 23V27C18 27.6 17.6 28 17 28ZM14 26H16V24H14V26ZM9 28H5C4.4 28 4 27.6 4 27V23C4 22.4 4.4 22 5 22H9C9.6 22 10 22.4 10 23V27C10 27.6 9.6 28 9 28ZM6 26H8V24H6V26ZM25 20H21C20.4 20 20 19.6 20 19V15C20 14.4 20.4 14 21 14H25C25.6 14 26 14.4 26 15V19C26 19.6 25.6 20 25 20ZM22 18H24V16H22V18ZM17 20H13C12.4 20 12 19.6 12 19V15C12 14.4 12.4 14 13 14H17C17.6 14 18 14.4 18 15V19C18 19.6 17.6 20 17 20ZM14 18H16V16H14V18ZM9 20H5C4.4 20 4 19.6 4 19V15C4 14.4 4.4 14 5 14H9C9.6 14 10 14.4 10 15V19C10 19.6 9.6 20 9 20ZM6 18H8V16H6V18ZM23.5 12H6.5C5.1 12 4 10.9 4 9.5V6.5C4 5.1 5.1 4 6.5 4C23.3 4 23.5 4 23.6 4C25 4.2 26 5.2 26 6.5V9.5C26 10.9 24.9 12 23.5 12ZM20.2 6C16.7 6 10.8 6 6.5 6C6.2 6 6 6.2 6 6.5V9.5C6 9.8 6.2 10 6.5 10H23.5C23.8 10 24 9.8 24 9.5V6.5C24 6.1 23.4 6 23.4 6C23.2 6 22 6 20.2 6Z" fill="#0F0017"/>
  </svg>
</template>
