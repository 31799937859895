<template>
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
  >
    <path d="M35.46 13.44C35.43 12.91 34.99 12.5 34.46 12.5H7.96C7.75 10.53 6.33 9 4.6 9H1C0.45 9 0 9.45 0 10C0 10.55 0.45 11 1 11H4.6C5.28 11 5.87 11.75 5.98 12.69C5.73 12.86 5.56 13.12 5.54 13.44L4.51 31.94C4.49 32.21 4.59 32.48 4.78 32.68C4.97 32.88 5.23 32.99 5.51 32.99H6.01V33.99C6.01 35.64 7.36 36.99 9.01 36.99C10.66 36.99 12.01 35.64 12.01 33.99V32.99H29V33.99C29 35.64 30.35 36.99 32 36.99C33.65 36.99 35 35.64 35 33.99V32.99H35.5C35.78 32.99 36.04 32.88 36.23 32.68C36.42 32.48 36.52 32.21 36.5 31.94L35.47 13.44H35.46ZM10 34C10 34.55 9.55 35 9 35C8.45 35 8 34.55 8 34V33H10V34ZM6.56 31L7.48 14.5H33.52L34.44 31H6.56ZM33 34C33 34.55 32.55 35 32 35C31.45 35 31 34.55 31 34V33H33V34Z" fill="#0F0017"/>
    <path d="M13 26.61L13.09 26.64C15.66 27.27 18.32 26.87 20.59 25.5C22.85 24.14 24.44 21.98 25.08 19.42C25.08 19.42 25.08 19.41 25.08 19.4L25.17 19.01C25.47 17.53 25.66 16.02 25.74 14.51H7.47995L6.94995 23.97C8.86995 25.06 10.9 25.95 13 26.61Z" fill="#00FF86"/>
    <path d="M30 10H29.92C29.49 6.18 26.66 3.07 22.97 2.23C22.97 2.15 22.99 2.08 22.99 2C22.99 0.9 22.09 0 20.99 0C19.89 0 18.99 0.9 18.99 2C18.99 2.08 19 2.15 19.01 2.22C15.31 3.05 12.46 6.16 12.03 10H11.99C11.44 10 10.99 10.45 10.99 11C10.99 11.55 11.44 12 11.99 12H28.82C28.87 12 28.92 12.03 28.98 12.03C29.04 12.03 29.09 12.01 29.15 12H30C30.55 12 31 11.55 31 11C31 10.45 30.55 10 30 10ZM21.01 4C24.52 4.01 27.41 6.63 27.9 10H14.06C14.55 6.6 17.48 3.99 21.01 4Z" fill="#0F0017"/>
    <path d="M21.0101 4.00003C17.4801 3.99003 14.5501 6.60003 14.0601 10H25.6501C25.5001 8.38003 25.2401 6.76003 24.8301 5.17003C23.7301 4.44003 22.4201 4.01003 21.0101 4.00003Z" fill="#00FF86"/>
  </svg>
</template>
