<template>
  <svg
    width="28"
    height="40"
    viewBox="0 0 28 40"
  >
    <path d="M21 15.9C21 18 20.8 20.1 20.4 22.1L20.3 22.5C19.9 24.2 19 25.8 17.8 27C17.2 27.6 16.6 28.1 15.8 28.6C13.5 30 10.9 30.4 8.3 29.7H8.2C7.8 29.6 7.4 29.4 7 29.3V25C3.4 22.6 1 18.6 1 14C1 6.8 6.8 1 14 1C15.2 1 16.3 1.2 17.4 1.5C19.8 6 21 11 21 15.9Z" fill="#00FF86"/>
    <path d="M14 40C11.5 40 9.5 38.2 9.1 35.9C7.3 35.5 6 33.9 6 32V25.5C2.2 22.8 0 18.6 0 14C0 6.3 6.3 0 14 0C15.3 0 16.5 0.2 17.7 0.5C23.8 2.2 28 7.7 28 14C28 18.6 25.8 22.8 22.1 25.4L22 32C22 33.9 20.7 35.5 18.9 35.9C18.5 38.2 16.5 40 14 40ZM11.2 36C11.6 37.2 12.7 38 14 38C15.3 38 16.4 37.2 16.8 36H11.2ZM8 32C8 33.1 8.9 34 10 34H18C19.1 34 20 33.1 20 32H8ZM8 30H20V28H8V30ZM15 26H20.1V24.9C20.1 24.6 20.3 24.3 20.6 24.1C24 21.8 26 18.1 26 14C26 8.6 22.4 3.8 17.2 2.4C16.1 2.1 15.1 2 14 2C7.4 2 2 7.4 2 14C2 18.1 4.1 21.9 7.5 24.1C7.8 24.3 8 24.6 8 24.9V26H13V17.9C11.3 17.5 10 15.9 10 14C10 11.8 11.8 10 14 10C16.2 10 18 11.8 18 14C18 15.9 16.7 17.4 15 17.9V26ZM14 12C12.9 12 12 12.9 12 14C12 15.1 12.9 16 14 16C15.1 16 16 15.1 16 14C16 12.9 15.1 12 14 12Z" fill="#0F0017"/>
  </svg>
</template>
