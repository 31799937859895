<template>
  <svg
    width="35"
    height="40"
    viewBox="0 0 35 40"  
  >
    <path d="M11.7376 29.575C14.4376 30.275 17.0376 29.875 19.3376 28.475C21.2376 27.375 22.6376 25.575 23.4376 23.575L22.4376 22.775H22.7376H23.6376C23.6376 22.6749 23.7376 22.575 23.7376 22.375L23.8376 21.975C24.1376 20.475 24.3376 18.975 24.4376 17.475L21.8376 14.475H24.4376C24.3376 13.075 24.2376 11.675 23.9376 10.275L16.9376 0.574951L7.0376 14.475H12.1376H12.2376L5.0376 22.775H11.1376H11.5376L11.4376 22.875L6.3376 27.275C8.0376 28.275 9.8376 28.975 11.7376 29.575Z" fill="#00FF86"/>
    <path d="M33.7375 31.475L25.2375 24.075H29.0375C29.4375 24.075 29.7375 23.875 29.9375 23.475C30.1375 23.075 30.0375 22.675 29.7375 22.375L24.0375 15.775H27.0375C27.4375 15.775 27.7375 15.575 27.9375 15.275C28.1375 14.975 28.0375 14.575 27.8375 14.275L17.8375 0.375C17.4375 -0.125 16.6375 -0.125 16.2375 0.375L6.2375 14.275C6.0375 14.575 5.9375 14.975 6.1375 15.275C6.3375 15.575 6.6375 15.775 7.0375 15.775H10.0375L4.2375 22.375C3.9375 22.675 3.9375 23.075 4.0375 23.475C4.2375 23.875 4.5375 24.075 4.9375 24.075H8.8375L0.3375 31.475C0.0375 31.775 -0.0625 32.175 0.0375 32.575C0.2375 32.975 0.6375 33.275 1.0375 33.275H14.0375V37.975C14.0375 39.075 14.9375 39.975 16.0375 39.975H18.0375C19.1375 39.975 20.0375 39.075 20.0375 37.975V33.275H33.0375C33.4375 33.275 33.8375 32.975 33.9375 32.575C34.1375 32.175 34.0375 31.775 33.7375 31.475ZM18.0375 37.975H16.0375V33.275H18.0375V37.975ZM3.7375 31.275L12.2375 23.875C12.5375 23.575 12.6375 23.175 12.5375 22.775C12.4375 22.375 12.0375 22.075 11.6375 22.075H7.2375L13.0375 15.375C13.3375 15.075 13.3375 14.675 13.1375 14.275C12.9375 13.875 12.6375 13.675 12.2375 13.675H9.0375L17.0375 2.575L25.0375 13.675H21.8375C21.4375 13.675 21.1375 13.875 20.9375 14.275C20.7375 14.675 20.8375 15.075 21.1375 15.375L26.9375 21.975H22.5375C22.1375 21.975 21.7375 22.275 21.6375 22.675C21.5375 23.075 21.6375 23.475 21.9375 23.775L30.4375 31.175H3.7375V31.275Z" fill="#0F0017"/>
  </svg>
</template>
