<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M27.2 15.9C27.2 18 27 20.1 26.6 22.1L26.5 22.5C25.9 25.1 24.3 27.2 22 28.6C21.8 28.7 21.5 28.9 21.3 29C19.2 30 16.8 30.3 14.5 29.8H14.4C14.1 29.7 13.8 29.6 13.5 29.5C8.8 27.8 4.5 25 1 21.3V18L5 14.4V10C5 8.9 5.9 8 7 8H12L13 7.3L20 1L25.5 5.9C25.7 6.6 26 7.3 26.1 8C26.8 10.6 27.2 13.3 27.2 15.9Z" fill="#00FF86"/>
    <path d="M37 40H3C1.3 40 0 38.7 0 37V18C0 17.8 0.1 17.6 0.2 17.4L0.3 17.3L4 14V10C4 8.3 5.3 7 7 7H11.7L12.4 6.5L19.3 0.3C19.4 0.2 19.5 0.2 19.5 0.1C19.7 0 19.9 0 20 0C20.1 0 20.3 0 20.4 0.1C20.5 0.1 20.6 0.2 20.6 0.3L27.6 6.6L28.3 7H33C34.7 7 36 8.3 36 10V14L39.7 17.3L39.8 17.4C39.9 17.6 40 17.8 40 18V37C40 38.7 38.7 40 37 40ZM2.5 37.9C2.6 38 2.8 38 3 38H37C37.2 38 37.4 38 37.5 37.9C37.5 37.9 37.5 37.9 37.4 37.9L25.7 30H14.3L2.5 37.9C2.5 37.8 2.5 37.9 2.5 37.9ZM27.7 28.9L38 35.8V20.2L27.7 28.9ZM2 20.2V35.8L12.3 28.9L2 20.2ZM20 28H25.6L34 20.9V14.4V10C34 9.4 33.6 9 33 9H28H12H7C6.4 9 6 9.4 6 10V14.4V20.9L14.4 28H20ZM36 16.7V19.3L37.5 18.1L36 16.7ZM2.5 18L4 19.2V16.6L2.5 18ZM14.8 7H25.2L20 2.3L14.8 7Z" fill="#0F0017"/>
  </svg>
</template>
