<template>
  <svg
    width="40"
    height="26"
    viewBox="0 0 40 26"
  >
    <path d="M27 1.9C27 4 26.8 6 26.4 8.1L26.3 8.5C25.7 11.1 24.1 13.2 21.8 14.6C19.5 16 16.9 16.4 14.3 15.7H14.2C10.5 14.5 7.1 12.7 4 10.2V3C4 1.9 4.9 1 6 1H27C27 1.3 27 1.6 27 1.9Z" fill="#00FF86"/>
    <path d="M39 26H1C0.4 26 0 25.6 0 25C0 24.4 0.4 24 1 24H3.2C3.1 23.7 3 23.4 3 23V3C3 1.3 4.3 0 6 0H34C35.7 0 37 1.3 37 3V23C37 23.4 36.9 23.7 36.8 24H39C39.6 24 40 24.4 40 25C40 25.6 39.6 26 39 26ZM6 24H34C34.6 24 35 23.6 35 23V3C35 2.4 34.6 2 34 2H6C5.4 2 5 2.4 5 3V23C5 23.6 5.4 24 6 24Z" fill="#0F0017"/>
  </svg>
</template>
