<template>
  <svg
    width="32"
    height="40"
    viewBox="0 0 32 40"
  >
    <path d="M23 17C22.9 18.7 22.8 20.4 22.4 22.1L22.3 22.5C21.7 25.1 20.1 27.2 17.8 28.6C16.4 29.5 14.8 30 13.1 30L14 23H1L17 1L15 17H23Z" fill="#00FF86"/>
    <path d="M12.0041 40C11.9042 40 11.7042 40 11.6043 39.8998C11.2044 39.6992 11.0045 39.2982 11.0045 38.8972L12.9037 23.9599H1.00861C0.608768 23.9599 0.308891 23.7594 0.108972 23.4586C-0.0909459 23.1579 0.00901331 22.7569 0.208932 22.4561L16.2024 0.401002C16.5023 0 17.0021 -0.100251 17.4019 0.100251C17.8017 0.100251 18.0017 0.601504 18.0017 1.00251L16.1024 15.9398H30.9964C31.3962 15.9398 31.6961 16.1404 31.896 16.5414C32.0959 16.9424 31.996 17.3434 31.6961 17.6441L12.7038 39.6992C12.6039 39.8997 12.304 40 12.0041 40ZM3.00779 21.9549H14.0033C14.3032 21.9549 14.6031 22.0551 14.703 22.2556C14.803 22.4561 15.0029 22.7569 14.9029 23.0576L14.0033 30.0752L13.3036 35.7895L28.7973 17.9449H15.0029C14.703 17.9449 14.4031 17.8446 14.3032 17.6441C14.1033 17.4436 14.0033 17.1429 14.1033 16.8421L15.6026 4.71178L3.00779 21.9549Z" fill="#0F0017"/>
  </svg>
</template>
