<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path d="M8.5 9C8.77614 9 9 8.77614 9 8.5C9 8.22386 8.77614 8 8.5 8L3.5 8C3.22386 8 3 8.22386 3 8.5C3 8.77614 3.22386 9 3.5 9L8.5 9ZM2.18557e-07 9.5C9.78513e-08 10.8807 1.11929 12 2.5 12L9.5 12C10.8807 12 12 10.8807 12 9.5L12 2.5C12 1.11929 10.8807 -9.78513e-08 9.5 -2.18557e-07L2.5 -8.30516e-07C1.11929 -9.51222e-07 9.51222e-07 1.11929 8.30516e-07 2.5L2.18557e-07 9.5ZM11 2.5L11 9.5C11 10.3284 10.3284 11 9.5 11L2.5 11C1.67157 11 1 10.3284 1 9.5L1 2.5C1 1.67157 1.67157 0.999999 2.5 0.999999L9.5 1C10.3284 1 11 1.67157 11 2.5Z" />
    <path d="M12 5.5C12 5.77614 11.7761 6 11.5 6L0.500001 6C0.223858 6 5.44107e-07 5.77614 5.68248e-07 5.5C5.92389e-07 5.22386 0.223858 5 0.500001 5L11.5 5C11.7761 5 12 5.22386 12 5.5Z" />
  </svg>
</template>
