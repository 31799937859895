<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.87919 8.99994C3.87919 8.41536 3.97622 7.85483 4.14961 7.32917L1.11633 5.01294C0.525139 6.21317 0.192139 7.56571 0.192139 8.99994C0.192139 10.433 0.524857 11.7846 1.11506 12.9841L4.14665 10.6634C3.97495 10.1401 3.87919 9.58171 3.87919 8.99994Z" fill="#0F0017"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.20494 3.68184C10.4749 3.68184 11.622 4.13184 12.5233 4.86816L15.1451 2.25C13.5474 0.859078 11.4991 0 9.20494 0C5.64319 0 2.58207 2.03681 1.11633 5.013L4.14947 7.32923C4.84838 5.20777 6.84061 3.68184 9.20494 3.68184Z" fill="#0F0017"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.20494 14.3183C6.84075 14.3183 4.84852 12.7924 4.14961 10.6709L1.11633 12.9867C2.58207 15.9633 5.64319 18.0001 9.20494 18.0001C11.4032 18.0001 13.502 17.2195 15.0772 15.757L12.198 13.5312C11.3856 14.0429 10.3626 14.3183 9.20494 14.3183Z" fill="#0F0017"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8078 9.00008C17.8078 8.46824 17.7259 7.89547 17.603 7.36377H9.20483V10.841H14.039C13.7972 12.0266 13.1394 12.938 12.1979 13.5312L15.0771 15.757C16.7316 14.2213 17.8078 11.9337 17.8078 9.00008Z" fill="#0F0017"/>
  </svg>
</template>
