<template>
  <svg
    width="15"
    height="12"
    viewBox="0 0 15 12"
  >
    <path d="M1.99664 1C1.58243 1 1.24664 1.33579 1.24664 1.75V3.5C1.24664 3.77614 1.02279 4 0.746643 4C0.470501 4 0.246643 3.77614 0.246643 3.5V1.75C0.246643 0.783502 1.03014 0 1.99664 0H3.74664C4.02279 0 4.24664 0.223858 4.24664 0.5C4.24664 0.776142 4.02279 1 3.74664 1H1.99664ZM10.2466 0.5C10.2466 0.223858 10.4705 0 10.7466 0H12.4966C13.4631 0 14.2466 0.783502 14.2466 1.75V3.5C14.2466 3.77614 14.0228 4 13.7466 4C13.4705 4 13.2466 3.77614 13.2466 3.5V1.75C13.2466 1.33579 12.9109 1 12.4966 1H10.7466C10.4705 1 10.2466 0.776142 10.2466 0.5ZM0.746643 8C1.02279 8 1.24664 8.22386 1.24664 8.5V10.25C1.24664 10.6642 1.58243 11 1.99664 11H3.74664C4.02279 11 4.24664 11.2239 4.24664 11.5C4.24664 11.7761 4.02279 12 3.74664 12H1.99664C1.03014 12 0.246643 11.2165 0.246643 10.25V8.5C0.246643 8.22386 0.470501 8 0.746643 8ZM13.7466 8C14.0228 8 14.2466 8.22386 14.2466 8.5V10.25C14.2466 11.2165 13.4631 12 12.4966 12H10.7466C10.4705 12 10.2466 11.7761 10.2466 11.5C10.2466 11.2239 10.4705 11 10.7466 11H12.4966C12.9109 11 13.2466 10.6642 13.2466 10.25V8.5C13.2466 8.22386 13.4705 8 13.7466 8Z" />
    <path d="M8.24664 4.5C8.24664 4.22386 8.4705 4 8.74664 4H11.7466C12.0228 4 12.2466 4.22386 12.2466 4.5C12.2466 4.77614 12.0228 5 11.7466 5H8.74664C8.4705 5 8.24664 4.77614 8.24664 4.5Z" />
    <path d="M8.24664 7.5C8.24664 7.22386 8.4705 7 8.74664 7H11.7466C12.0228 7 12.2466 7.22386 12.2466 7.5C12.2466 7.77614 12.0228 8 11.7466 8H8.74664C8.4705 8 8.24664 7.77614 8.24664 7.5Z" />
    <path d="M5.86486 4.35683C5.86486 5.10619 5.25738 5.71366 4.50803 5.71366C3.75867 5.71366 3.1512 5.10619 3.1512 4.35683C3.1512 3.60747 3.75867 3 4.50803 3C5.25738 3 5.86486 3.60747 5.86486 4.35683Z" />
    <path d="M2.24664 7.2503C2.24664 6.77632 2.63089 6.39207 3.10487 6.39207H5.91118C6.38517 6.39207 6.76941 6.77632 6.76941 7.2503C6.76941 8.01668 6.24783 8.6847 5.50434 8.87057L5.45096 8.88392C4.83186 9.03869 4.18419 9.03869 3.56509 8.88392L3.51171 8.87057C2.76822 8.6847 2.24664 8.01668 2.24664 7.2503Z" />
  </svg>
</template>
