<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M21 18H26.9C26.8 19.4 26.6 20.7 26.4 22.1L26.3 22.5C26 23.8 25.4 25 24.6 26H21V29C19.1 29.9 17.1 30.2 15 29.9V19H4L1 15L4 11H15V4C15 2.3 16.3 1 18 1C19.7 1 21 2.3 21 4V7H25.7C26.5 9.6 26.9 12.3 27 15H21V18Z" fill="#00FF86"/>
    <path d="M23.0001 40H13.0001C12.4001 40 12.0001 39.6 12.0001 39C12.0001 38.4 12.4001 38 13.0001 38H14.0001V20H4.0001C3.7001 20 3.4001 19.9 3.2001 19.6L0.200098 15.6C-0.0999023 15.2 -0.0999023 14.8 0.200098 14.4L3.2001 10.4C3.4001 10.1 3.7001 10 4.0001 10H14.0001V4C14.0001 1.8 15.8001 0 18.0001 0C20.2001 0 22.0001 1.8 22.0001 4V6H36.0001C36.3001 6 36.6001 6.1 36.8001 6.4L39.8001 10.4C40.1001 10.8 40.1001 11.2 39.8001 11.6L36.8001 15.6C36.6001 15.9 36.3001 16 36.0001 16H22.0001V17H30.0001C30.3001 17 30.6001 17.1 30.8001 17.4L33.8001 21.4C34.1001 21.8 34.1001 22.2 33.8001 22.6L30.8001 26.6C30.6001 26.9 30.3001 27 30.0001 27H22.0001V38H23.0001C23.6001 38 24.0001 38.4 24.0001 39C24.0001 39.6 23.6001 40 23.0001 40ZM16.0001 38H20.0001V4C20.0001 2.9 19.1001 2 18.0001 2C16.9001 2 16.0001 2.9 16.0001 4V38ZM22.0001 25H29.5001L31.7001 22L29.5001 19H22.0001V25ZM4.5001 18H14.0001V12H4.5001L2.3001 15L4.5001 18ZM22.0001 14H35.5001L37.7001 11L35.5001 8H22.0001V14Z" fill="#0F0017"/>
  </svg>
</template>
