import { App as Application } from 'vue'

export default {
  install: (app: Application, { router }: { router: any }) => {
    function navigate(to: string|object, target = '_self') {
      if (typeof to === 'object') { return router.push(to) }
      const cases = {
        external: {
          _self: () => (window.location.href = to),
          _blank: () => window.open(to)
        },
        internal: {
          _self: () => router.push(to),
          _blank: () => window.open([window.location.origin, to].map(p => p.replace(/^\/|\/$/g, '')).join('/'))
        }
      }
      if (/^(http:\/\/|https:\/\/|mailto:|tel:)/.test(to)) {
        return cases.external[target]()
      }
      return cases.internal[target]()
    }
    app.provide('navigate', navigate)
  }
}
