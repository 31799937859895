<template>
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
  >
    <path d="M3.49664 0C2.11593 0 0.996643 1.11929 0.996643 2.5V17.5C0.996643 18.8807 2.11593 20 3.49664 20H16.7466C17.1609 20 17.4966 19.6642 17.4966 19.25C17.4966 18.8358 17.1609 18.5 16.7466 18.5H3.49664C2.94436 18.5 2.49664 18.0523 2.49664 17.5H16.7466C17.1609 17.5 17.4966 17.1642 17.4966 16.75V2.5C17.4966 1.11929 16.3774 0 14.9966 0H3.49664ZM15.9966 16H2.49664V2.5C2.49664 1.94772 2.94436 1.5 3.49664 1.5H14.9966C15.5489 1.5 15.9966 1.94772 15.9966 2.5V16Z" />
    <path d="M11.7466 9.99782C11.7466 9.58361 11.4109 9.24782 10.9966 9.24782H7.49664C7.08243 9.24782 6.74664 9.58361 6.74664 9.99782V10.4896L6.75451 10.5979C6.91712 11.7115 7.84878 12.2488 9.24659 12.2488C10.6443 12.2488 11.576 11.712 11.7387 10.5991L11.7466 10.4906V9.99782Z" />
    <path d="M10.7466 6.74997C10.7466 5.92156 10.0751 5.25 9.24664 5.25C8.41823 5.25 7.74667 5.92156 7.74667 6.74997C7.74667 7.57839 8.41823 8.24995 9.24664 8.24995C10.0751 8.24995 10.7466 7.57839 10.7466 6.74997Z" />
  </svg>
</template>
