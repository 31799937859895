<template>
  <svg
    width="35"
    height="40"
    viewBox="0 0 35 40"  
  >
    <path d="M10 13C9.45 13 9 12.55 9 12V4C9 3.45 9.45 3 10 3C10.55 3 11 3.45 11 4V12C11 12.55 10.55 13 10 13Z" fill="#0F0017"/>
    <path d="M14 12C13.45 12 13 11.55 13 11V5C13 4.45 13.45 4 14 4C14.55 4 15 4.45 15 5V11C15 11.55 14.55 12 14 12Z" fill="#0F0017"/>
    <path d="M6 12C5.45 12 5 11.55 5 11V5C5 4.45 5.45 4 6 4C6.55 4 7 4.45 7 5V11C7 11.55 6.55 12 6 12Z" fill="#0F0017"/>
    <path d="M34 0C27.93 0 23 4.93 23 11V27C23 27.55 23.45 28 24 28H29V37C29 38.65 30.35 40 32 40C33.65 40 35 38.65 35 37V1C35 0.45 34.55 0 34 0ZM25 11C25 6.38 28.51 2.55 33 2.06V26H25V11ZM33 37C33 37.55 32.55 38 32 38C31.45 38 31 37.55 31 37V28H33V37Z" fill="#0F0017"/>
    <path d="M18.74 0.33C18.55 0.12 18.2801 0 18 0H2.00005C1.72005 0 1.45005 0.12 1.26005 0.33C1.07005 0.54 0.980049 0.82 1.00005 1.1L1.97005 10.73C2.35005 14.54 5.30005 17.48 9.00005 17.93V24C9.00005 24.06 9.02005 24.11 9.03005 24.17C7.85005 24.57 7.00005 25.68 7.00005 27V37C7.00005 38.65 8.35005 40 10 40C11.65 40 13 38.65 13 37V27C13 25.69 12.14 24.58 10.97 24.17C10.9801 24.11 11 24.06 11 24V17.93C14.71 17.48 17.6501 14.54 18.0301 10.73L18.99 1.1C19.0201 0.82 18.93 0.54 18.74 0.33ZM11 37C11 37.55 10.55 38 10 38C9.45005 38 9.00005 37.55 9.00005 37V27C9.00005 26.45 9.45005 26 10 26C10.55 26 11 26.45 11 27V37ZM16.04 10.53C15.73 13.65 13.13 16 10 16C6.87005 16 4.27005 13.65 3.96005 10.53L3.10005 2H16.9L16.05 10.53H16.04Z" fill="#0F0017"/>
    <path d="M9.99998 16C13.13 16 15.73 13.65 16.04 10.53L16.89 2H3.09998L3.94998 10.53C4.25998 13.65 6.85998 16 9.98998 16H9.99998ZM13 5C13 4.45 13.45 4 14 4C14.55 4 15 4.45 15 5V11C15 11.55 14.55 12 14 12C13.45 12 13 11.55 13 11V5ZM8.99998 4C8.99998 3.45 9.44998 3 9.99998 3C10.55 3 11 3.45 11 4V12C11 12.55 10.55 13 9.99998 13C9.44998 13 8.99998 12.55 8.99998 12V4ZM4.99998 11V5C4.99998 4.45 5.44998 4 5.99998 4C6.54998 4 6.99998 4.45 6.99998 5V11C6.99998 11.55 6.54998 12 5.99998 12C5.44998 12 4.99998 11.55 4.99998 11Z" fill="#00FF86"/>
    <path d="M10 26C9.45 26 9 26.45 9 27V28.02C9.66 28.33 10.32 28.63 11 28.89V27C11 26.45 10.55 26 10 26Z" fill="#00FF86"/>
  </svg>
</template>
