<template>
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 6.5C5.5 4.84315 6.84315 3.5 8.5 3.5C10.1569 3.5 11.5 4.84315 11.5 6.5C11.5 8.15685 10.1569 9.5 8.5 9.5C6.84315 9.5 5.5 8.15685 5.5 6.5ZM8.5 5C7.67157 5 7 5.67157 7 6.5C7 7.32843 7.67157 8 8.5 8C9.32843 8 10 7.32843 10 6.5C10 5.67157 9.32843 5 8.5 5Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 0.5C1.00736 0.5 0 1.50736 0 2.75V10.25C0 11.4926 1.00736 12.5 2.25 12.5H9.01894C9.05846 11.9805 9.15903 11.4782 9.3135 11H4.5V10.25C4.5 9.00736 3.49264 8 2.25 8H1.5V5H2.25C3.49264 5 4.5 3.99264 4.5 2.75V2H12.5V2.75C12.5 3.99264 13.5074 5 14.75 5H15.5V6.5C16.0163 6.5 16.5185 6.56019 17 6.67393V2.75C17 1.50736 15.9926 0.5 14.75 0.5H2.25ZM1.5 2.75C1.5 2.33579 1.83579 2 2.25 2H3V2.75C3 3.16421 2.66421 3.5 2.25 3.5H1.5V2.75ZM15.5 2.75V3.5H14.75C14.3358 3.5 14 3.16421 14 2.75V2H14.75C15.1642 2 15.5 2.33579 15.5 2.75ZM2.25 11C1.83579 11 1.5 10.6642 1.5 10.25V9.5H2.25C2.66421 9.5 3 9.83579 3 10.25V11H2.25Z" />
    <path d="M9.07645 14H2.40137C2.92008 14.8967 3.8896 15.5 5.00002 15.5H9.49817C9.30043 15.0258 9.15724 14.5232 9.07645 14Z" />
    <path d="M20 8.30959C19.5558 7.88325 19.051 7.51941 18.5 7.23222V2.90137C19.3967 3.42008 20 4.3896 20 5.50002V8.30959Z" />
    <path d="M21 13C21 16.0376 18.5376 18.5 15.5 18.5C12.4624 18.5 10 16.0376 10 13C10 9.96243 12.4624 7.5 15.5 7.5C18.5376 7.5 21 9.96243 21 13ZM18.8536 10.6464C18.6583 10.4512 18.3417 10.4512 18.1464 10.6464L14.5 14.2929L12.8536 12.6464C12.6583 12.4512 12.3417 12.4512 12.1464 12.6464C11.9512 12.8417 11.9512 13.1583 12.1464 13.3536L14.1464 15.3536C14.3417 15.5488 14.6583 15.5488 14.8536 15.3536L18.8536 11.3536C19.0488 11.1583 19.0488 10.8417 18.8536 10.6464Z" />
  </svg>
</template>
