<template>
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
  >
    <path d="M0.246643 2C0.246643 0.89543 1.14207 0 2.24664 0H4.24664C4.52279 0 4.74664 0.223858 4.74664 0.5C4.74664 0.776142 4.52279 1 4.24664 1H2.24664C1.69436 1 1.24664 1.44772 1.24664 2V4C1.24664 4.27614 1.02279 4.5 0.746643 4.5C0.470501 4.5 0.246643 4.27614 0.246643 4V2ZM11.7466 0.5C11.7466 0.223858 11.9705 0 12.2466 0H14.2466C15.3512 0 16.2466 0.895431 16.2466 2V4C16.2466 4.27614 16.0228 4.5 15.7466 4.5C15.4705 4.5 15.2466 4.27614 15.2466 4V2C15.2466 1.44772 14.7989 1 14.2466 1H12.2466C11.9705 1 11.7466 0.776142 11.7466 0.5ZM0.746643 9.5C1.02279 9.5 1.24664 9.72386 1.24664 10V12C1.24664 12.5523 1.69436 13 2.24664 13H4.24664C4.52279 13 4.74664 13.2239 4.74664 13.5C4.74664 13.7761 4.52279 14 4.24664 14H2.24664C1.14207 14 0.246643 13.1046 0.246643 12V10C0.246643 9.72386 0.470501 9.5 0.746643 9.5ZM15.7466 9.5C16.0228 9.5 16.2466 9.72386 16.2466 10V12C16.2466 13.1046 15.3512 14 14.2466 14H12.2466C11.9705 14 11.7466 13.7761 11.7466 13.5C11.7466 13.2239 11.9705 13 12.2466 13H14.2466C14.7989 13 15.2466 12.5523 15.2466 12V10C15.2466 9.72386 15.4705 9.5 15.7466 9.5Z" />
    <path d="M4.74664 7C5.57507 7 6.24664 6.32843 6.24664 5.5C6.24664 4.67157 5.57507 4 4.74664 4C3.91822 4 3.24664 4.67157 3.24664 5.5C3.24664 6.32843 3.91822 7 4.74664 7Z"/>
    <path d="M3.19543 7.75C2.67143 7.75 2.24664 8.17479 2.24664 8.69879C2.24664 9.54603 2.82326 10.2845 3.6452 10.49L3.70421 10.5048C4.38863 10.6759 5.10465 10.6759 5.78907 10.5048L5.84809 10.49C6.67003 10.2845 7.24664 9.54603 7.24664 8.69879C7.24664 8.17479 6.82186 7.75 6.29785 7.75H3.19543Z" />
    <path d="M9.24664 5.5C9.24664 5.22386 9.4705 5 9.74664 5H12.7466C13.0228 5 13.2466 5.22386 13.2466 5.5C13.2466 5.77614 13.0228 6 12.7466 6H9.74664C9.4705 6 9.24664 5.77614 9.24664 5.5Z" />
    <path d="M9.24664 8.5C9.24664 8.22386 9.4705 8 9.74664 8H12.7466C13.0228 8 13.2466 8.22386 13.2466 8.5C13.2466 8.77614 13.0228 9 12.7466 9H9.74664C9.4705 9 9.24664 8.77614 9.24664 8.5Z" />
  </svg>
</template>
