<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
  >
    <path d="M4 4.5C4 3.94772 4.44772 3.5 5 3.5C5.55229 3.5 6 3.94772 6 4.5C6 5.05228 5.55229 5.5 5 5.5C4.44772 5.5 4 5.05228 4 4.5Z" />
    <path d="M5 10.5C4.44772 10.5 4 10.9477 4 11.5C4 12.0523 4.44772 12.5 5 12.5C5.55229 12.5 6 12.0523 6 11.5C6 10.9477 5.55229 10.5 5 10.5Z" />
    <path d="M4 8C4 7.44771 4.44772 7 5 7C5.55229 7 6 7.44771 6 8C6 8.55229 5.55229 9 5 9C4.44772 9 4 8.55229 4 8Z" />
    <path d="M8.5 3.5C7.94771 3.5 7.5 3.94772 7.5 4.5C7.5 5.05228 7.94771 5.5 8.5 5.5C9.05229 5.5 9.5 5.05228 9.5 4.5C9.5 3.94772 9.05229 3.5 8.5 3.5Z" />
    <path d="M7.5 11.5C7.5 10.9477 7.94771 10.5 8.5 10.5C9.05229 10.5 9.5 10.9477 9.5 11.5C9.5 12.0523 9.05229 12.5 8.5 12.5C7.94771 12.5 7.5 12.0523 7.5 11.5Z" />
    <path d="M7.5 8C7.5 7.44771 7.94771 7 8.5 7C9.05229 7 9.5 7.44771 9.5 8C9.5 8.55229 9.05229 9 8.5 9C7.94771 9 7.5 8.55229 7.5 8Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 2.25C0.5 1.00736 1.50736 0 2.75 0H10.75C11.9926 0 13 1.00736 13 2.25V7.4993H14.2528C15.2555 7.4993 16.1049 8.15513 16.3956 9.0612C16.1029 9.02085 15.8039 9 15.5 9C15.1823 9 14.8698 9.0228 14.5643 9.06685C14.4694 9.02348 14.3639 8.9993 14.2528 8.9993H12.25C11.8358 8.9993 11.5 8.66352 11.5 8.2493V2.25C11.5 1.83579 11.1642 1.5 10.75 1.5H2.75C2.33579 1.5 2 1.83579 2 2.25V18H4V15.25C4 14.8358 4.33579 14.5 4.75 14.5H9.07645C9.02612 14.826 9 15.1599 9 15.5C9 16.1214 9.08719 16.7224 9.25 17.2914V18H9.49816C9.72372 18.5409 10.0203 19.0448 10.3762 19.5H1.25C0.835786 19.5 0.5 19.1642 0.5 18.75V2.25ZM7.75 18V16H5.5V18H7.75Z" />
    <path d="M15.5004 10C18.5379 10 21.0004 12.4624 21.0004 15.5C21.0004 18.5376 18.5379 21 15.5004 21C12.4628 21 10.0004 18.5376 10.0004 15.5C10.0004 12.4624 12.4628 10 15.5004 10ZM15.5004 12L15.4105 12.0081C15.2064 12.0451 15.0455 12.206 15.0084 12.4101L15.0004 12.5L14.9994 15H12.5043L12.4144 15.0081C12.2103 15.0451 12.0494 15.206 12.0123 15.4101L12.0043 15.5L12.0123 15.5899C12.0494 15.794 12.2103 15.9549 12.4144 15.992L12.5043 16H14.9994L15.0004 18.5L15.0084 18.5899C15.0455 18.794 15.2064 18.9549 15.4105 18.9919L15.5004 19L15.5902 18.9919C15.7943 18.9549 15.9553 18.794 15.9923 18.5899L16.0004 18.5L15.9994 16H18.5043L18.5941 15.992C18.7982 15.9549 18.9592 15.794 18.9962 15.5899L19.0043 15.5L18.9962 15.4101C18.9592 15.206 18.7982 15.0451 18.5941 15.0081L18.5043 15H15.9994L16.0004 12.5L15.9923 12.4101C15.9553 12.206 15.7943 12.0451 15.5902 12.0081L15.5004 12Z" />
  </svg>
</template>
