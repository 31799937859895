<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M20 11H13H11H4L3.88338 11.0067C3.38604 11.0645 3 11.4872 3 12L3.00673 12.1166C3.06449 12.614 3.48716 13 4 13H11H13H20L20.1166 12.9933C20.614 12.9355 21 12.5128 21 12L20.9933 11.8834C20.9355 11.386 20.5128 11 20 11Z"
      fill="#0F0017"
    />
  </svg>
</template>
