<template>
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
  >
    <path d="M16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5C8.08495 16.5 8.16959 16.4987 8.2539 16.496C7.99891 16.1867 7.77706 15.849 7.5938 15.4884C3.91685 15.278 1 12.2296 1 8.5C1 4.63401 4.13401 1.5 8 1.5C11.7296 1.5 14.778 4.41685 14.9884 8.0938C15.349 8.27706 15.6867 8.49891 15.996 8.7539C15.9987 8.66959 16 8.58495 16 8.5Z" />
    <path d="M9.81017 8.2015C9.73342 8.13062 9.62521 8.09076 9.49525 8.09076H7.50159C7.4331 7.82443 7.37984 7.54288 7.37984 7.22329C7.37984 6.45474 7.92771 5.9373 8.87128 5.9373C9.25767 5.9373 9.50984 5.99858 9.70186 6.04524C9.81993 6.07393 9.91526 6.0971 10.0051 6.0971C10.3018 6.0971 10.4692 5.92969 10.4692 5.65575C10.4692 5.43508 10.3627 5.26007 10.0812 5.14592C9.73875 4.99374 9.30501 4.93286 8.77236 4.93286C7.17438 4.93286 6.2156 5.83077 6.2156 7.21568C6.2156 7.51245 6.26887 7.80921 6.34496 8.09076H5.69055C5.43183 8.09076 5.24921 8.25817 5.24921 8.51688C5.24921 8.76799 5.43183 8.9354 5.69055 8.9354H6.53519C6.58846 9.15607 6.63412 9.37675 6.63412 9.57459C6.63412 10.3279 6.18516 10.8301 5.67533 10.9975C5.38618 11.1193 5.24921 11.2791 5.24921 11.5606C5.24921 11.865 5.45466 12.0629 5.75904 12.0629H7.07952C7.14003 11.7104 7.23408 11.3694 7.35828 11.0432H7.15155C7.52442 10.716 7.76792 10.1681 7.76792 9.57459C7.76792 9.3387 7.73748 9.13325 7.69182 8.9354H8.79467C9.10309 8.65409 9.44364 8.4074 9.81017 8.2015Z" />
    <path d="M15.682 16.1819C13.9246 17.9393 11.0754 17.9393 9.31802 16.1819C7.56066 14.4246 7.56066 11.5753 9.31802 9.81797C11.0754 8.06061 13.9246 8.06061 15.682 9.81797C17.4393 11.5753 17.4393 14.4246 15.682 16.1819ZM14.4142 11.5863L14.4141 11.5828C14.4138 11.5161 14.4003 11.4524 14.3762 11.3944C14.3522 11.3363 14.3167 11.2818 14.2698 11.2343L14.2677 11.2322L14.2656 11.2301C14.2181 11.1832 14.1636 11.1477 14.1055 11.1237C14.0466 11.0992 13.9819 11.0858 13.9142 11.0858H11.0857C10.8096 11.0858 10.5857 11.3096 10.5857 11.5858C10.5857 11.8619 10.8096 12.0858 11.0857 12.0858H12.707L10.7322 14.0606C10.5369 14.2559 10.5369 14.5725 10.7322 14.7677C10.9274 14.963 11.244 14.963 11.4393 14.7677L13.4142 12.7929L13.4142 14.4142C13.4142 14.6903 13.638 14.9142 13.9142 14.9142C14.1903 14.9142 14.4142 14.6903 14.4142 14.4142V11.5863Z" />
  </svg>
</template>
