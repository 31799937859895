<template>
    <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
    >
        <path
            d="M8.57708 8.99976C8.59384 9.13321 8.61133 9.28501 8.62777 9.45027C8.70131 10.1895 8.7587 11.243 8.61402 12.1276C8.5422 12.5668 8.41317 13.0143 8.17218 13.3638C7.91554 13.736 7.52549 13.9998 6.99961 13.9998C6.48302 13.9998 6.16852 13.63 5.97561 13.3086C5.78329 12.9882 5.61472 12.5532 5.44216 12.108L5.42903 12.0741C4.88232 10.664 4.13467 8.75732 2.12266 7.41598C1.81572 7.21136 1.52425 7.0641 1.26934 6.9584C0.572753 6.66954 -0.052736 5.90129 0.112033 5.0219L0.336008 3.82651C0.476512 3.07662 1.03141 2.472 1.76651 2.2678L6.71658 0.892781C8.69282 0.343827 10.7154 1.60416 11.0934 3.62009L11.5469 6.03904C11.8354 7.57757 10.6551 8.99976 9.08976 8.99976H8.57708ZM10.1105 3.80437C9.84053 2.36442 8.39582 1.46419 6.98423 1.8563L2.03416 3.23132C1.6666 3.33342 1.38916 3.63573 1.3189 4.01067L1.09493 5.20606C1.0418 5.48959 1.25605 5.87032 1.65238 6.03467C1.96167 6.16292 2.31143 6.33998 2.67736 6.58393C4.96425 8.10852 5.80593 10.2797 6.35333 11.6918L6.36142 11.7127C6.55096 12.2016 6.69012 12.5559 6.83304 12.794C6.9022 12.9092 6.95384 12.9646 6.98464 12.9889C6.99274 12.9952 6.99807 12.9984 7.00069 12.9998C7.14256 12.9995 7.24588 12.9456 7.34895 12.7961C7.46794 12.6236 7.56524 12.3447 7.62714 11.9662C7.7499 11.2157 7.70429 10.2692 7.63268 9.54925C7.60429 9.26388 7.57259 9.02118 7.54807 8.85041C7.53582 8.76509 7.5254 8.69793 7.51812 8.65259L7.50974 8.60147L7.50764 8.58908L7.50717 8.58634C7.48173 8.44088 7.52181 8.29118 7.6168 8.17812C7.66829 8.11684 7.73303 8.06959 7.80505 8.03917C7.86592 8.01346 7.93197 7.99976 7.99961 7.99976H9.08976C10.029 7.99976 10.7372 7.14645 10.5641 6.22333L10.1105 3.80437Z"
            fill="#0F0017"
        />
    </svg>
</template>
