<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M27 15.9C27 18 26.8 20.1 26.3 22.1L26.2 22.5C25.5 25.1 23.9 27.2 21.7 28.5C21.1 28.9 20.4 29.2 19.7 29.4C17.9 30 16.1 30 14.2 29.6H14.1C9.20001 28 4.70001 25.2 1.00001 21.3C1.00001 20.8 0.900011 20.3 1.00001 19.8C1.10001 9.30003 9.70001 0.900029 20.2 1.00003C21.4 1.00003 22.5 1.10003 23.6 1.30003C25.2 4.40003 26.3 7.70003 26.8 11.1C26.9 12.7 27.1 14.3 27 15.9Z" fill="#00FF86"/>
    <path d="M20 40C19.9 40 19.9 40 19.8 40C9.50001 39.9 0.800011 31.7 1.08518e-05 21.4C1.08518e-05 20.9 -0.0999891 20.4 1.08518e-05 19.8C1.08518e-05 14.5 2.20001 9.5 6.00001 5.7C9.80001 2 14.8 -0.100002 20.2 -1.80095e-06C21.4 -1.80095e-06 22.6 0.0999982 23.7 0.299998C33.2 2.1 40.1 10.5 40 20.2C39.9 31.1 30.9 40 20 40ZM20 2C15.3 2 10.8 3.8 7.40001 7.2C4.00001 10.5 2.00001 15 2.00001 19.8C2.00001 20.3 2.00001 20.8 2.00001 21.2C2.60001 30.5 10.5 37.8 19.8 37.9C19.9 37.9 19.9 37.9 20 37.9C29.9 37.9 37.9 29.9 38 20.1C38.1 11.4 31.9 3.9 23.3 2.3C22.3 2.1 21.2 2 20.1 2C20.1 2 20.1 2 20 2ZM30 30.5C29.4 30.5 29 30 29 29.5C29 27.7 28.1 25.8 26.5 24.3C24.8 22.8 22.5 21.9 20.1 21.9C17.7 21.9 15.4 22.7 13.7 24.2C12 25.6 11 27.5 11 29.3C11 29.8 10.5 30.3 10 30.3C9.40001 30.3 9.00001 29.8 9.00001 29.3C9.00001 26.9 10.2 24.5 12.3 22.7C14.4 20.9 17.1 19.9 19.9 19.9H20C22.9 19.9 25.6 21 27.7 22.8C29.8 24.6 30.9 27.1 30.9 29.5C31 30.1 30.5 30.5 30 30.5ZM30 16.1C29.4 16.1 29 15.6 29 15.1C29 14.3 28.7 13.5 28.1 13C27.5 12.4 26.8 12.1 26 12.1C24.3 12.1 23 13.4 23 15.1C23 15.6 22.5 16.1 22 16.1C21.4 16.1 21 15.6 21 15.1C21 12.3 23.3 10.1 26 10.1C27.3 10.1 28.6 10.6 29.5 11.6C30.4 12.6 30.9 13.8 30.9 15.1C31 15.6 30.6 16.1 30 16.1ZM17 16C16.4 16 16 15.5 16 15C16 13.3 14.7 12 13 12C11.3 12 10 13.3 10 15C10 15.5 9.50001 16 9.00001 16C8.40001 16 8.00001 15.5 8.00001 15C8.00001 12.2 10.3 10 13 10C15.8 10 18 12.3 18 15C18 15.5 17.6 16 17 16Z" fill="#0F0017"/>
  </svg>
</template>
