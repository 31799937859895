<template>
  <svg
    width="32"
    height="40"
    viewBox="0 0 32 40"
  >
    <path d="M19 22H22.4C22.4 22 22.4 22 22.4 22.1L22.3 22.5C21.7 25.1 20.1 27.2 17.8 28.6C15.5 30 12.9 30.4 10.3 29.7H10.2C9.1 29.4 8 28.9 7 28.5V23.5C7 22.7 7.7 22 8.5 22H13" fill="#00FF86"/>
    <path d="M25 40C23.1 40 21.6 38.7 21.1 37H10.9C10.5 38.7 8.9 40 7 40C5.1 40 3.6 38.7 3.1 37H3H1C0.4 37 0 36.6 0 36C0 35.4 0.4 35 1 35H2V14C2 6.3 8.3 0 16 0C17.3 0 18.5 0.2 19.7 0.5C25.8 2.2 30 7.7 30 14V35H31C31.6 35 32 35.4 32 36C32 36.6 31.6 37 31 37H29H28.9C28.4 38.7 26.9 40 25 40ZM25 34C23.9 34 23 34.9 23 36C23 37.1 23.9 38 25 38C26.1 38 27 37.1 27 36C27 34.9 26.1 34 25 34ZM7 34C5.9 34 5 34.9 5 36C5 37.1 5.9 38 7 38C8.1 38 9 37.1 9 36C9 34.9 8.1 34 7 34ZM10.9 35H21.2C21.6 33.6 22.7 32.5 24.1 32.1V23.5C24.1 23.2 23.9 23 23.6 23H8.6C8.2 23 8 23.2 8 23.5V32.1C9.4 32.5 10.5 33.6 10.9 35ZM26 32.1C26.8 32.3 27.5 32.7 28 33.3V14C28 13.3 27.9 12.7 27.8 12H4.2C4.1 12.7 4 13.3 4 14V33.4C4.5 32.8 5.2 32.4 6 32.2V23.6C6 22.1 7.1 21 8.5 21H12V19.8C12 18.4 13.1 17.3 14.5 17.3H17.5C18.9 17.3 20 18.4 20 19.8V21H23.5C24.9 21 26 22.1 26 23.5V32.1ZM14 21H18V19.8C18 19.5 17.8 19.3 17.5 19.3H14.5C14.2 19.3 14 19.5 14 19.8V21ZM4.7 10H27.3C26 6.4 23 3.5 19.2 2.4C18.1 2.1 17.1 2 16 2C10.8 2 6.3 5.3 4.7 10Z" fill="#0F0017"/>
  </svg>
</template>
