<template>
  <svg
    width="34"
    height="40"
    viewBox="0 0 34 40"
    fill="none"
  >
    <path d="M33 39H1V18H32.9412L33 39Z" stroke="#0F0017" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 18L13 12" stroke="#0F0017" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M33 18L21 12" stroke="#0F0017" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 39V32L14.5 35L17 32L19.5 35L22 32V39" stroke="#0F0017" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.07 10L17 1M20 7L17.07 10L14 7" stroke="#0F0017" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
