<template>
  <svg
    width="34"
    height="40"
    viewBox="0 0 34 40"
  >
    <path d="M20.9999 15.9C20.9999 16.9 20.8999 18 20.7999 19C20.6999 20 20.4999 21.1 20.2999 22.1L20.1999 22.5C20.0999 23 19.8999 23.5 19.6999 24C18.8999 25.9 17.4999 27.5 15.6999 28.6C15.3999 28.8 15.1999 28.9 14.8999 29C12.7999 30 10.4999 30.3 8.1999 29.7H8.0999C7.4999 29.5 6.7999 29.3 6.1999 29C4.2999 28.3 2.5999 27.4 0.899902 26.3V3C0.899902 1.9 1.7999 1 2.8999 1H16.0999C16.5999 1 16.9999 1.2 17.3999 1.5C18.9999 4.6 20.0999 7.8 20.5999 11C20.8999 12.6 20.9999 14.3 20.9999 15.9Z" fill="#00FF86"/>
    <path d="M27 40C27 40 26.9 40 27 40H3C1.3 40 0 38.7 0 37V3C0 1.3 1.3 0 3 0H16.2C16.9 0 17.5 0.2 18.1 0.7C18.2 0.8 18.3 0.8 18.4 0.9L27.2 9.7C27.8 10.3 28.1 11 28.1 11.8V26C31.5 26.5 34.1 29.4 34.1 32.9C34.1 34.8 33.3 36.6 32 37.8C30.7 39 28.9 40 27 40ZM26.9 38C28.3 38 29.6 37.4 30.5 36.5C31.4 35.6 32 34.4 32 33C32 30.2 29.8 28 27 28C24.2 28 22 30.2 22 33C22 35.7 24.2 38 26.9 38ZM3 2C2.4 2 2 2.4 2 3V37C2 37.6 2.4 38 3 38H22.1C21.3 37.2 20.6 36.2 20.3 35H6C5.4 35 5 34.6 5 34C5 33.4 5.4 33 6 33H20C20 31.9 20.2 30.9 20.7 30H6C5.4 30 5 29.6 5 29C5 28.4 5.4 28 6 28H22H22.1C23.1 27 24.5 26.3 26 26.1V12H19C17.3 12 16 10.7 16 9V2H3ZM18 9C18 9.6 18.4 10 19 10H24.6L18 3.4V9ZM28.4 35.4C28.1 35.4 27.9 35.3 27.7 35.1L27 34.4L26.3 35.1C25.9 35.5 25.3 35.5 24.9 35.1C24.5 34.7 24.5 34.1 24.9 33.7L25.6 33L24.9 32.3C24.5 31.9 24.5 31.3 24.9 30.9C25.3 30.5 25.9 30.5 26.3 30.9L27 31.6L27.7 30.9C28.1 30.5 28.7 30.5 29.1 30.9C29.5 31.3 29.5 31.9 29.1 32.3L28.4 33L29.1 33.7C29.5 34.1 29.5 34.7 29.1 35.1C28.9 35.3 28.7 35.4 28.4 35.4ZM22 25H6C5.4 25 5 24.6 5 24C5 23.4 5.4 23 6 23H22C22.6 23 23 23.4 23 24C23 24.6 22.6 25 22 25ZM22 20H6C5.4 20 5 19.6 5 19C5 18.4 5.4 18 6 18H22C22.6 18 23 18.4 23 19C23 19.6 22.6 20 22 20Z" fill="#0F0017"/>
  </svg>
</template>
