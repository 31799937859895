<template>
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
  >
    <path d="M3.5 2.5C3.5 2.77614 3.27614 3 3 3C2.72386 3 2.5 2.77614 2.5 2.5C2.5 2.22386 2.72386 2 3 2C3.27614 2 3.5 2.22386 3.5 2.5Z" />
    <path d="M3 5.5C3.27614 5.5 3.5 5.27614 3.5 5C3.5 4.72386 3.27614 4.5 3 4.5C2.72386 4.5 2.5 4.72386 2.5 5C2.5 5.27614 2.72386 5.5 3 5.5Z" />
    <path d="M3.00558 7.99997L3 8C2.72386 8 2.5 7.77614 2.5 7.5C2.5 7.22386 2.72386 7 3 7C3.04794 7 3.09431 7.00675 3.13821 7.01935C3.06568 7.33664 3.0206 7.66439 3.00558 7.99997Z" />
    <path d="M3.05071 9H3C2.72386 9 2.5 9.22386 2.5 9.5V11H1.5V1.5C1.5 1.22386 1.72386 1 2 1H6C6.27614 1 6.5 1.22386 6.5 1.5V3.12494C6.81986 3.00003 7.15427 2.90419 7.5 2.84069V1.5C7.5 0.671573 6.82843 0 6 0H2C1.17157 0 0.5 0.671573 0.5 1.5V11.5C0.5 11.7761 0.723858 12 1 12H4.4766C4.19376 11.6967 3.94482 11.3613 3.7358 11H3.5V10.5445C3.27871 10.0631 3.12491 9.5442 3.05071 9Z" />
    <path d="M5 3C5.27614 3 5.5 2.77614 5.5 2.5C5.5 2.22386 5.27614 2 5 2C4.72386 2 4.5 2.22386 4.5 2.5C4.5 2.77614 4.72386 3 5 3Z" />
    <path d="M8.5 12.75C10.9853 12.75 13 10.7353 13 8.25C13 5.76472 10.9853 3.75 8.5 3.75C6.01472 3.75 4 5.76472 4 8.25C4 10.7353 6.01472 12.75 8.5 12.75ZM8.5 5.75C8.77614 5.75 9 5.97386 9 6.25V7.75H10.5C10.7761 7.75 11 7.97386 11 8.25C11 8.52614 10.7761 8.75 10.5 8.75H9V10.25C9 10.5261 8.77614 10.75 8.5 10.75C8.22386 10.75 8 10.5261 8 10.25V8.75H6.5C6.22386 8.75 6 8.52614 6 8.25C6 7.97386 6.22386 7.75 6.5 7.75H8V6.25C8 5.97386 8.22386 5.75 8.5 5.75Z" />
  </svg>
</template>
