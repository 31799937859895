<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M28.078 22.7527C28.712 20.2169 28.9656 17.5544 28.9656 14.8918C29.0923 12.8631 28.8388 10.8345 28.712 8.80583C28.5089 7.42498 27.4385 2.85066 27.0835 1.5C26.8745 0.70477 24.5279 2 24.5279 2L19.7099 1.5L13.5001 1L8.00011 2L5.50011 7L1.70567 17V25.1617C1.70567 25.1617 1.599 26.7967 4.24148 28.4083C6.88385 30.02 9.69077 31.3089 12.6096 32.262H12.7364C15.1454 32.7692 17.4277 32.7692 19.7099 32.0084C20.5974 31.7549 21.4849 31.3745 22.2457 30.8673C25.0351 29.219 27.0637 26.5565 27.9512 23.2599L28.078 22.7527Z" fill="#00FF86"/>
    <path d="M30.888 25.1499C28.9192 25.1499 27.3178 23.5926 27.3178 21.677C27.3178 19.7615 28.9192 18.2051 30.888 18.2051C32.8568 18.2051 34.4582 19.7624 34.4582 21.677C34.4582 23.5916 32.8568 25.1499 30.888 25.1499ZM30.888 20.0613C29.9718 20.0613 29.2265 20.7861 29.2265 21.677C29.2265 22.568 29.9718 23.2937 30.888 23.2937C31.8042 23.2937 32.5495 22.5689 32.5495 21.677C32.5495 20.7852 31.8042 20.0613 30.888 20.0613Z" fill="#0F0017"/>
    <path d="M9.38111 25.1499C7.41232 25.1499 5.81095 23.5926 5.81095 21.677C5.81095 19.7615 7.41232 18.2051 9.38111 18.2051C11.3499 18.2051 12.9513 19.7624 12.9513 21.677C12.9513 23.5916 11.3499 25.1499 9.38111 25.1499ZM9.38111 20.0613C8.46495 20.0613 7.71962 20.7861 7.71962 21.677C7.71962 22.568 8.46495 23.2937 9.38111 23.2937C10.2973 23.2937 11.0426 22.5689 11.0426 21.677C11.0426 20.7852 10.2973 20.0613 9.38111 20.0613Z" fill="#0F0017"/>
    <path d="M3.34972 40H6.8025C8.64914 40 10.1522 38.5383 10.1522 36.7425V33.3086H29.844V33.9573L29.8468 36.7415C29.8468 38.5383 31.3499 40 33.1965 40H36.6493C38.496 40 39.999 38.5383 39.999 36.7425V17.7708C40 16.2515 39.1716 14.8186 37.8365 14.0306L37.6027 13.8923L36.8497 10.9819H38.9149L39.101 10.9698C39.604 10.8928 39.9819 10.4715 39.9914 9.9768L39.979 9.79861C39.8988 9.30302 39.4551 8.93179 38.9378 8.93179H36.3201L35.3094 5.00139C34.5498 2.05661 31.8404 0 28.7198 0H11.2755C8.15193 0 5.44162 2.05847 4.68483 5.0051L3.67133 8.93179H1.07649L0.890395 8.94385C0.388414 9.01995 0.00954335 9.4413 0 9.93596L0.0124064 10.1142C0.0925705 10.6097 0.536336 10.981 1.05359 10.981H3.14644L2.39634 13.8942L2.16157 14.0325C0.808322 14.8316 0 16.2292 0 17.7708V36.7425C0 38.5392 1.50308 40 3.34972 40ZM37.89 33.9582V36.7425C37.89 37.4088 37.3326 37.9499 36.6484 37.9499H33.1956C32.5104 37.9499 31.954 37.4079 31.954 36.7425L31.9521 33.3086H37.8919V33.9582H37.89ZM6.72615 5.50255C7.24818 3.46914 9.11772 2.04919 11.2726 2.04919H28.7198C30.8718 2.04919 32.7404 3.46729 33.2643 5.49698L35.2999 13.3958H4.70105L6.72615 5.50255ZM2.10813 17.7708C2.10813 16.4891 3.17984 15.4469 4.49778 15.4469H35.4994C36.8192 15.4469 37.8919 16.4891 37.8919 17.7708V31.2585H2.10813V17.7708ZM8.04314 33.3086L8.04504 33.9582V36.7425C8.04504 37.4088 7.48771 37.9499 6.80345 37.9499H3.34972C2.6645 37.9499 2.10813 37.4079 2.10813 36.7425L2.10622 33.3086H8.04314Z" fill="#0F0017"/>
  </svg>
</template>
