<template>
  <svg
    width="36"
    height="39"
    viewBox="0 0 36 39"
  >
    <path d="M25 17C24.9 18.5 24.7 19.9 24.4 21.3L24.3 21.7C24 22.9 23.4 24 22.7 25C22.5 25.3 22.2 25.6 22 25.9C21.3 26.6 20.6 27.2 19.7 27.7C17.9 28.7 16 29.2 14 29.1C13.4 29.1 12.8 29 12.2 28.8H12.1C10 28.1 8 27.2 6 26V1H21.8C21.9 1.2 21.9 1.3 22 1.5C23.2 4 24 6.5 24.5 9C25 11.7 25.2 14.3 25 17Z" fill="#00FF86"/>
    <path d="M35 39H1C0.4 39 0 38.6 0 38V33C0 32.4 0.4 32 1 32H5V1C5 0.4 5.4 0 6 0H30C30.6 0 31 0.4 31 1V32H35C35.6 32 36 32.4 36 33V38C36 38.6 35.6 39 35 39ZM2 37H34V34H2V37ZM23 32H29V26H23V32ZM15 32H21V26H15V32ZM7 32H13V26H7V32ZM23 24H29V18H23V24ZM15 24H21V18H15V24ZM7 24H13V18H7V24ZM23 16H29V10H23V16ZM15 16H21V10H15V16ZM7 16H13V10H7V16ZM23 8H29V2H23V8ZM15 8H21V2H15V8ZM7 8H13V2H7V8Z" fill="#0F0017"/>
  </svg>
</template>
