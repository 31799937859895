<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
  >
    <path d="M1.74664 2.75C1.74664 2.05964 2.30629 1.5 2.99664 1.5H4.99664C5.41086 1.5 5.74664 1.16421 5.74664 0.75C5.74664 0.335786 5.41086 0 4.99664 0H2.99664C1.47786 0 0.246643 1.23122 0.246643 2.75V4.75C0.246643 5.16421 0.582429 5.5 0.996643 5.5C1.41086 5.5 1.74664 5.16421 1.74664 4.75V2.75ZM1.74664 15.25C1.74664 15.9404 2.30629 16.5 2.99664 16.5H4.99664C5.41086 16.5 5.74664 16.8358 5.74664 17.25C5.74664 17.6642 5.41086 18 4.99664 18H2.99664C1.47786 18 0.246643 16.7688 0.246643 15.25V13.25C0.246643 12.8358 0.582429 12.5 0.996643 12.5C1.41086 12.5 1.74664 12.8358 1.74664 13.25V15.25ZM15.4966 1.5C16.187 1.5 16.7466 2.05964 16.7466 2.75V4.75C16.7466 5.16421 17.0824 5.5 17.4966 5.5C17.9109 5.5 18.2466 5.16421 18.2466 4.75V2.75C18.2466 1.23122 17.0154 0 15.4966 0H13.4966C13.0824 0 12.7466 0.335786 12.7466 0.75C12.7466 1.16421 13.0824 1.5 13.4966 1.5H15.4966ZM16.7466 15.25C16.7466 15.9404 16.187 16.5 15.4966 16.5H13.4966C13.0824 16.5 12.7466 16.8358 12.7466 17.25C12.7466 17.6642 13.0824 18 13.4966 18H15.4966C17.0154 18 18.2466 16.7688 18.2466 15.25V13.25C18.2466 12.8358 17.9109 12.5 17.4966 12.5C17.0824 12.5 16.7466 12.8358 16.7466 13.25V15.25Z" />
    <path d="M10.2466 13.375C10.2466 12.8227 9.79893 12.375 9.24664 12.375C8.69436 12.375 8.24664 12.8227 8.24664 13.375C8.24664 13.9273 8.69436 14.375 9.24664 14.375C9.79893 14.375 10.2466 13.9273 10.2466 13.375Z" />
    <path d="M11.9966 6.375C11.9966 4.85622 10.7654 3.625 9.24664 3.625C7.72786 3.625 6.49664 4.85622 6.49664 6.375C6.49664 6.78921 6.83243 7.125 7.24664 7.125C7.66086 7.125 7.99664 6.78921 7.99664 6.375L8.0031 6.24719C8.06711 5.61687 8.59943 5.125 9.24664 5.125C9.937 5.125 10.4966 5.68464 10.4966 6.375C10.4966 6.95872 10.3382 7.22277 9.71631 7.84467L9.54779 8.01642C8.79419 8.80096 8.49664 9.36425 8.49664 10.375C8.49664 10.7892 8.83243 11.125 9.24664 11.125C9.66086 11.125 9.99664 10.7892 9.99664 10.375C9.99664 9.79128 10.1551 9.52723 10.777 8.90533L10.9455 8.73358C11.6991 7.94904 11.9966 7.38575 11.9966 6.375Z" />
  </svg>
</template>
