<template>
  <svg
    width="28"
    height="34"
    viewBox="0 0 28 34"
  >
    <path d="M21 9.63929C21 11.7393 20.8 13.8393 20.4 15.8393L20.3 16.2393C19.9 17.8393 19.2 19.2393 18.1 20.4393C17.9 20.4393 17.8 20.4393 17.6 20.3393L10.5 19.1393C8.9 18.8393 7.2 18.8393 5.6 19.0393L5 19.1393V22.1393C3.6 21.5393 2.3 20.8393 1 20.0393V1.73929L5.5 1.03929C7.1 0.83929 8.8 0.83929 10.4 1.13929L17.5 2.33929C18.4 2.53929 19.3 2.53929 20.1 2.53929C20.7 4.93929 21 7.23929 21 9.63929Z" fill="#00FF86"/>
    <path d="M3 33.7394C1.3 33.7394 0 32.4394 0 30.7394V1.73939C0 1.23939 0.4 0.839388 0.9 0.739388L5.4 0.139388C7.1 -0.0606123 8.9 -0.0606123 10.6 0.239388L17.7 1.43939C19.2 1.73939 20.8 1.73939 22.3 1.53939L26.9 0.839388C27.2 0.839388 27.5 0.839388 27.7 1.03939C27.9 1.13939 28 1.43939 28 1.73939V19.7394C28 20.2394 27.6 20.6394 27.1 20.7394L22.6 21.3394C21.1 21.5394 19.5 21.5394 18 21.3394C17.8 21.3394 17.6 21.2394 17.4 21.2394L10.3 20.0394C8.9 19.9394 7.4 19.8394 6 20.0394V30.7394C6 32.4394 4.7 33.7394 3 33.7394ZM2 20.6394V30.7394C2 31.3394 2.4 31.7394 3 31.7394C3.6 31.7394 4 31.3394 4 30.7394V20.3394L2 20.6394ZM7.8 17.9394C8.7 17.9394 9.7 18.0394 10.6 18.1394L17.7 19.3394C17.9 19.3394 18 19.4394 18.2 19.4394C19.6 19.6394 21 19.6394 22.3 19.4394L26 18.9394V2.93939L22.6 3.43939C20.9 3.63939 19.1 3.63939 17.4 3.33939L10.3 2.13939C8.8 1.93939 7.2 1.83939 5.7 2.03939L2 2.63939V18.6394L4.9 18.2394L5.4 18.1394C6.2 18.0394 7 17.9394 7.8 17.9394Z" fill="#0F0017"/>
  </svg>
</template>
