<template>
  <svg
    width="40"
    height="38"
    viewBox="0 0 40 38"
  >
    <path d="M11 15C13.2 15 15 16.8 15 19C15 21.4 13.2 23 11 23C8.8 23 7 21.2 7 19C7 16.8 8.8 15 11 15Z" fill="#00FF86"/>
    <path d="M29 38C26.6 38 24.6 36.3 24.1 34H1C0.4 34 0 33.6 0 33C0 32.4 0.4 32 1 32H24.1C24.6 29.7 26.6 28 29 28C31.4 28 33.4 29.7 33.9 32H39C39.6 32 40 32.4 40 33C40 33.6 39.6 34 39 34H33.9C33.4 36.3 31.4 38 29 38ZM29 30C27.3 30 26 31.3 26 33C26 34.7 27.3 36 29 36C30.7 36 32 34.7 32 33C32 31.3 30.7 30 29 30ZM11 24C8.6 24 6.6 22.3 6.1 20H6H1C0.4 20 0 19.6 0 19C0 18.4 0.4 18 1 18H6H6.1C6.6 15.7 8.6 14 11 14C13.4 14 15.4 15.7 15.9 18H39C39.6 18 40 18.4 40 19C40 19.6 39.6 20 39 20H15.9C15.5 22.3 13.5 24 11 24ZM11 16C9.3 16 8 17.3 8 19C8 20.7 9.3 22 11 22C12.8 22 14 20.8 14 19C14 17.3 12.7 16 11 16ZM29 10C26.6 10 24.6 8.3 24.1 6H1C0.4 6 0 5.6 0 5C0 4.4 0.4 4 1 4H24.1C24.6 1.7 26.6 0 29 0C31.4 0 33.4 1.7 33.9 4H39C39.6 4 40 4.4 40 5C40 5.6 39.6 6 39 6H33.9C33.4 8.3 31.4 10 29 10ZM29 2C27.3 2 26 3.3 26 5C26 6.7 27.3 8 29 8C30.7 8 32 6.7 32 5C32 3.3 30.7 2 29 2Z" fill="#0F0017"/>
  </svg>
</template>
