<template>
  <svg
    width="38"
    height="40"
    viewBox="0 0 38 40"
  >
    <path d="M5 40C4.4 40 4 39.6 4 39V31.3V31C4 30.4 4.4 30 5 30H13C13.6 30 14 30.4 14 31C14 31.6 13.6 32 13 32H7C10.2 35.2 14.5 37 19 37C28 37 35.5 29.9 36 20.9V19.9C36 19.3 36.5 18.9 37 18.9C37.6 18.9 38 19.4 38 19.9V20.9C37.4 31 29.1 38.9 19 38.9C14.1 38.9 9.5 37.1 6 33.8V39C6 39.6 5.6 40 5 40ZM1 21C0.4 21 0 20.5 0 20V19C0.6 8.9 8.9 1 19 1C23.8 1 28.5 2.9 32 6.1V1C32 0.4 32.4 0 33 0C33.6 0 34 0.4 34 1V9C34 9.6 33.6 10 33 10H25C24.4 10 24 9.6 24 9C24 8.4 24.4 8 25 8H31C27.8 4.8 23.5 3 19 3C10 3 2.5 10.1 2 19.1V20.1C2 20.6 1.5 21 1 21Z" fill="#0F0017"/>
    <path d="M2.8999 29C3.45219 29 3.8999 28.5523 3.8999 28C3.8999 27.4477 3.45219 27 2.8999 27C2.34762 27 1.8999 27.4477 1.8999 28C1.8999 28.5523 2.34762 29 2.8999 29Z" fill="#0F0017"/>
    <path d="M1.8999 25C2.45219 25 2.8999 24.5523 2.8999 24C2.8999 23.4477 2.45219 23 1.8999 23C1.34762 23 0.899902 23.4477 0.899902 24C0.899902 24.5523 1.34762 25 1.8999 25Z" fill="#0F0017"/>
    <path d="M34.8999 13C35.4522 13 35.8999 12.5523 35.8999 12C35.8999 11.4477 35.4522 11 34.8999 11C34.3476 11 33.8999 11.4477 33.8999 12C33.8999 12.5523 34.3476 13 34.8999 13Z" fill="#0F0017"/>
    <path d="M36.8999 17C37.4522 17 37.8999 16.5523 37.8999 16C37.8999 15.4477 37.4522 15 36.8999 15C36.3476 15 35.8999 15.4477 35.8999 16C35.8999 16.5523 36.3476 17 36.8999 17Z" fill="#0F0017"/>
  </svg>
</template>
