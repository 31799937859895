<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M27 15.9C27 16.4 27 16.9 27 17.3C26.9 18.9 26.7 20.5 26.4 22.1L26.3 22.5C25.7 25.1 24.1 27.2 21.8 28.6C21.7 28.6 21.6 28.7 21.6 28.7C19.4 30 16.8 30.3 14.3 29.7H14.2C12.8 29.2 11.3 28.7 10 28V25L1 19L25.8 7.29999C26.5 9.59999 26.9 12 27 14.3C27 14.8 27 15.3 27 15.9Z" fill="#00FF86"/>
    <path d="M31 40C30.8 40 30.6 39.9 30.5 39.8L19.9 33.1L10.4 36.9C10.1 37 9.7 37 9.5 36.8C9.2 36.6 9 36.3 9 36V25.5L0.4 19.8C0.1 19.6 0 19.3 0 18.9C0 18.5 0.2 18.2 0.6 18.1L38.6 0.0999838C38.9 -0.100016 39.3 -1.62125e-05 39.6 0.199984C39.9 0.399984 40 0.799984 40 1.19998L32 39.2C31.9 39.5 31.7 39.8 31.4 39.9C31.3 40 31.1 40 31 40ZM21.3 31.6L30.3 37.4L37.6 2.79998L3 19.1L10 23.8L28.5 12.1C28.9 11.9 29.4 11.9 29.7 12.2C30 12.5 30.1 13 29.9 13.4L21.3 31.6ZM11 25.6V34.6L19.3 31.3L26.7 15.8L11 25.6Z" fill="#0F0017"/>
  </svg>
</template>
