<template>
  <svg
    width="35"
    height="40"
    viewBox="0 0 35 40"
    fill="none"
  >
    <path d="M9.04004 38.99C10.6969 38.99 12.04 37.6468 12.04 35.99C12.04 34.3331 10.6969 32.99 9.04004 32.99C7.38318 32.99 6.04004 34.3331 6.04004 35.99C6.04004 37.6468 7.38318 38.99 9.04004 38.99Z" stroke="#0F0017" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M11.65 33.5L5.92 1L1 1.87" stroke="#0F0017" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.8201 34.4799L33.4901 30.6599" stroke="#0F0017" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M29.5501 31.3501V31.3601L15.7601 33.7901L15.0901 29.9601L13.3301 20.0001L15.3001 19.6501L24.4401 18.0401L27.1201 17.5701L29.5501 31.3501Z" stroke="#0F0017" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M29.0901 17.2199L27.1201 17.5699L24.4401 18.0399L15.3001 19.6499L12.8701 5.86994L22.3201 4.19994L26.6601 3.43994L29.0901 17.2199Z" stroke="#0F0017" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
