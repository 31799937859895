<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
  >
    <path d="M6 0.5C9.31371 0.5 12 3.18629 12 6.5C12 9.81371 9.31371 12.5 6 12.5C2.68629 12.5 0 9.81371 0 6.5C0 3.18629 2.68629 0.5 6 0.5ZM6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.90125 2.12383L10.4012 10.1238L9.59875 10.8762L2.09876 2.87617L2.90125 2.12383Z" />
    <path d="M7.59961 9.32471C7.44092 9.41357 7.19336 9.46436 6.85059 9.46436C5.42236 9.46436 4.29248 8.72803 3.9624 7.35693H3.35303C3.18164 7.35693 3.06104 7.22998 3.06104 7.06494C3.06104 6.88721 3.18164 6.7666 3.35303 6.7666H3.87988C3.87988 6.70947 3.87354 6.60791 3.87354 6.50635C3.87354 6.40479 3.87354 6.32227 3.87988 6.24609H3.35303C3.18164 6.24609 3.06104 6.11914 3.06104 5.9541C3.06104 5.77637 3.18164 5.66211 3.35303 5.66211H3.9624C4.28613 4.27832 5.41602 3.54199 6.85059 3.54199C7.19336 3.54199 7.44092 3.58643 7.59326 3.66895C7.79004 3.77051 7.85352 3.9165 7.85352 4.08154C7.85352 4.30371 7.72021 4.43701 7.52979 4.46875C7.38379 4.51318 7.12988 4.48779 6.85693 4.49414C6.03174 4.50049 5.33984 4.89404 5.09229 5.66211H6.97754C7.15527 5.66211 7.26953 5.77637 7.26953 5.9541C7.26953 6.11914 7.15527 6.24609 6.97754 6.24609H4.95898C4.95264 6.32227 4.95264 6.41113 4.95264 6.50635C4.95264 6.60156 4.95264 6.70312 4.95898 6.7666H6.97754C7.15527 6.7666 7.26953 6.88721 7.26953 7.06494C7.26953 7.22998 7.15527 7.35693 6.97754 7.35693H5.09229C5.33984 8.11865 6.02539 8.51221 6.85059 8.51855C7.12988 8.51855 7.37109 8.49951 7.52344 8.54395C7.72021 8.57568 7.85352 8.70898 7.85352 8.93115C7.85352 9.10254 7.771 9.23584 7.59961 9.32471Z" />
  </svg>
</template>
