<template>
  <svg
    width="25"
    height="40"
    viewBox="0 0 25 40"
  >
    <path d="M22 26.93V22C22 18.58 18.01 15.57 16 14.41V9C16 8.89 15.97 8.79 15.94 8.69L20.37 5H21.01C21.56 5 22.01 4.55 22.01 4V1C22.01 0.45 21.56 0 21.01 0H6C5.73 0 5.48 0.11 5.29 0.29L0.29 5.29C0.1 5.48 0 5.73 0 6V9C0 9.38 0.21 9.72 0.55 9.89C0.89 10.06 1.3 10.02 1.6 9.8L4.91 7.32L6.13 8.54C6.06 8.68 6 8.83 6 9V14.41C3.99 15.58 0 18.59 0 22V39C0 39.55 0.45 40 1 40H21C21.55 40 22 39.55 22 39V26.93ZM4.4 5.2L2 7V6.41L6.41 2H20V3C19.77 3 19.54 3.08 19.36 3.23L13.64 8H8.42L5.71 5.29C5.36 4.94 4.8 4.9 4.4 5.2ZM7.45 15.89C7.79 15.72 8 15.37 8 15V10H14V15C14 15.38 14.21 15.72 14.55 15.89C16.45 16.84 20 19.61 20 22V25.57C17.57 25.09 15.66 26.28 13.66 27.53C12.23 28.42 10.62 29.43 8.52 30.08C4.27 31.39 2.43 28.87 2 28.17V22.01C2 19.62 5.55 16.85 7.45 15.9V15.89ZM2 38V31.08C3.03 31.85 4.44 32.45 6.24 32.45C7.11 32.45 8.06 32.31 9.11 31.99C11.46 31.27 13.19 30.18 14.72 29.23C16.74 27.97 18.15 27.11 20.01 27.64V38.01H2V38Z" fill="#0F0017"/>
    <path d="M21.54 5.96991C21.44 5.64991 21.19 5.39991 20.87 5.30991C20.55 5.21991 20.2 5.28991 19.95 5.49991L17.01 7.94991C16.59 8.29991 16.53 8.92991 16.88 9.34991L21.23 14.6399C21.42 14.8699 21.7 14.9999 22 14.9999H23C23.32 14.9999 23.62 14.8499 23.81 14.5899C24 14.3299 24.05 13.9999 23.96 13.6999L21.54 5.95991V5.96991ZM19.05 8.84991L20.08 7.99991L21.14 11.3799L19.06 8.84991H19.05Z" fill="#0F0017"/>
    <path d="M23.5 0C22.95 0 22.5 0.45 22.5 1V4C22.5 4.55 22.95 5 23.5 5C24.05 5 24.5 4.55 24.5 4V1C24.5 0.45 24.05 0 23.5 0Z" fill="#0F0017"/>
    <path d="M6.41 2L2 6.41V7L4.4 5.2C4.8 4.9 5.36 4.94 5.71 5.29L8.42 8H13.64L17.7 4.62C17.36 3.74 16.97 2.86 16.54 2H6.41Z" fill="#00FF86"/>
    <path d="M6.99999 29.61L7.08999 29.64C7.70999 29.79 8.32999 29.88 8.94999 29.91C10.83 29.26 12.33 28.35 13.65 27.52C14.96 26.7 16.23 25.92 17.63 25.61C18.29 24.65 18.78 23.57 19.07 22.41C19.07 22.41 19.07 22.4 19.07 22.39L19.16 22C19.28 21.42 19.37 20.84 19.45 20.26C18.39 18.4 15.99 16.62 14.54 15.9C14.2 15.73 13.99 15.38 13.99 15.01V10.01H7.98999V15.01C7.98999 15.39 7.77999 15.73 7.43999 15.9C5.53999 16.85 1.98999 19.62 1.98999 22.01V27.55C3.59999 28.38 5.26999 29.07 6.98999 29.61H6.99999Z" fill="#00FF86"/>
  </svg>
</template>
