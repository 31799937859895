<template>
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
  >
    <path d="M0.752568 17.9997H19.2526C19.6668 17.9997 20.0026 18.3355 20.0026 18.7497C20.0026 19.1294 19.7204 19.4432 19.3543 19.4928L19.2526 19.4997H0.752568C0.338355 19.4997 0.00256824 19.1639 0.00256824 18.7497C0.00256824 18.37 0.284722 18.0562 0.650798 18.0065L0.752568 17.9997H19.2526H0.752568ZM0.752568 11.5027H19.2526C19.6668 11.5027 20.0026 11.8385 20.0026 12.2527C20.0026 12.6324 19.7204 12.9462 19.3543 12.9959L19.2526 13.0027H0.752568C0.338355 13.0027 0.00256824 12.6669 0.00256824 12.2527C0.00256824 11.873 0.284722 11.5592 0.650798 11.5095L0.752568 11.5027H19.2526H0.752568ZM0.751709 5.00293H19.2517C19.6659 5.00293 20.0017 5.33872 20.0017 5.75293C20.0017 6.13263 19.7196 6.44642 19.3535 6.49608L19.2517 6.50293H0.751709C0.337495 6.50293 0.00170898 6.16714 0.00170898 5.75293C0.00170898 5.37323 0.283863 5.05944 0.649939 5.00978L0.751709 5.00293H19.2517H0.751709Z" />
    <path d="M23.9035 5.00289C23.9035 5.11739 23.8993 5.2309 23.8909 5.34326C23.8765 5.53576 23.85 5.72489 23.8121 5.90988L23.8079 5.93034C23.3804 7.97091 21.5707 9.50333 19.4031 9.50333C17.4436 9.50333 15.7765 8.25099 15.1587 6.50303C14.9929 6.03382 14.9026 5.52889 14.9026 5.00289C14.9026 4.84754 14.9105 4.69403 14.9259 4.54274L14.9301 4.50284C15.1789 2.25264 17.0866 0.502441 19.4031 0.502441C21.8886 0.502441 23.9035 2.51736 23.9035 5.00289Z" class="y" />
    <path d="M21.9752 5.00287C21.9752 5.06832 21.9728 5.13319 21.968 5.1974C21.9597 5.30742 21.9446 5.41551 21.9229 5.52124L21.9205 5.53293C21.6762 6.69916 20.6419 7.57496 19.4031 7.57496C18.2832 7.57496 17.3305 6.85923 16.9774 5.86024C16.8826 5.59207 16.831 5.3035 16.831 5.00287C16.831 4.91409 16.8355 4.82636 16.8443 4.73989L16.8467 4.71709C16.9889 3.43106 18.0792 2.43079 19.4031 2.43079C20.8236 2.43079 21.9752 3.58235 21.9752 5.00287Z" class="x"/>
  </svg>
</template>

<style lang="scss" scoped>
.x {
  fill: #00FF86 !important
}
.y {
  fill: none !important
}
</style>