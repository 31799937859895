<template>
  <svg
    width="40"
    height="23"
    viewBox="0 0 40 23"
  >
    <path d="M27 6.9C27 9 26.7999 11.1 26.4 13.1L26.2999 13.5C25.6999 16.1 24.0999 18.2 21.7999 19.6C19.5 21 16.9 21.4 14.3 20.7H14.2C9.59995 19.2 5.29995 16.7 1.69995 13.2C2.69995 11.3 4.69995 10 6.99995 10L7.39995 8.9C8.29995 6.1 11.2 4.4 14.1 4.9L14.9 5C16.6 2.3 19.6 1 23 1C24.2 1 25.4 1.2 26.6 1.6C26.9 3.4 27 5.1 27 6.9Z" fill="#00FF86"/>
    <path d="M32.7182 23H6.98254C3.09227 23 0 19.9 0 16C0 14.8 0.299252 13.7 0.798005 12.7C1.89526 10.6 3.99003 9.3 6.28429 9L6.48379 8.5C7.58105 5.2 10.8728 3.2 14.2643 3.8H14.4638C16.2594 1.4 19.2519 0 22.9426 0C24.3392 0 25.6359 0.2 26.8329 0.7C30.6234 2 33.0175 5.2 33.4165 9C35.1122 9.1 36.7082 9.8 38.005 11.1C39.3017 12.5 40 14.3 40 16.2C39.8005 20 36.5087 23 32.7182 23ZM6.98254 21H32.7182C35.5112 21 37.9052 18.8 38.005 16.1C38.005 14.7 37.5062 13.4 36.6085 12.5C35.611 11.5 34.414 11 33.0175 11H32.5187C31.9202 11 31.5212 10.6 31.5212 10C31.5212 6.6 29.5262 3.7 26.2344 2.6C25.1372 2.2 24.0399 2 22.9426 2C19.7506 2 17.1571 3.3 15.7606 5.5C15.5611 5.8 15.1621 6 14.7631 6L13.9651 5.9C11.4713 5.5 9.17706 6.9 8.37905 9.2L7.98005 10.3C7.98005 10.4 7.98005 10.4 7.8803 10.5C7.78055 10.6 7.6808 10.7 7.58105 10.8C7.4813 10.9 7.2818 11 7.18204 11C7.08229 11 6.98254 11 6.98254 11C5.18703 11 3.49127 12 2.59352 13.6C2.19451 14.4 1.99501 15.2 1.99501 16C1.99501 18.8 4.18953 21 6.98254 21Z" fill="#0F0017"/>
  </svg>
</template>
