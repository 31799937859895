import { DirectiveBinding } from 'vue'
declare global {
  interface HTMLElement {
    clickOutsideEvent?: (event: Event) => void;
  }
}

const vClickOutside = {
  beforeMount: (el: HTMLElement, binding: DirectiveBinding) => {
    Object.assign(el, {
      clickOutsideEvent: (event: Event) => {
        if (!(el === event.target || el.contains(event.target as Node))) {
          binding.value()
        }
      }
    })
    document.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted: (el: HTMLElement) => {
    document.removeEventListener('click', el.clickOutsideEvent)
  },
}

export default vClickOutside
