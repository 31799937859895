<template>
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
  >
    <path d="M1.74664 2.75C1.74664 2.05964 2.30629 1.5 2.99664 1.5H4.99664C5.41086 1.5 5.74664 1.16421 5.74664 0.75C5.74664 0.335786 5.41086 0 4.99664 0H2.99664C1.47786 0 0.246643 1.23122 0.246643 2.75V4.75C0.246643 5.16421 0.582429 5.5 0.996643 5.5C1.41086 5.5 1.74664 5.16421 1.74664 4.75V2.75ZM1.74664 15.25C1.74664 15.9404 2.30629 16.5 2.99664 16.5H4.99664C5.41086 16.5 5.74664 16.8358 5.74664 17.25C5.74664 17.6642 5.41086 18 4.99664 18H2.99664C1.47786 18 0.246643 16.7688 0.246643 15.25V13.25C0.246643 12.8358 0.582429 12.5 0.996643 12.5C1.41086 12.5 1.74664 12.8358 1.74664 13.25V15.25ZM17.4966 1.5C18.187 1.5 18.7466 2.05964 18.7466 2.75V4.75C18.7466 5.16421 19.0824 5.5 19.4966 5.5C19.9109 5.5 20.2466 5.16421 20.2466 4.75V2.75C20.2466 1.23122 19.0154 0 17.4966 0H15.4966C15.0824 0 14.7466 0.335786 14.7466 0.75C14.7466 1.16421 15.0824 1.5 15.4966 1.5H17.4966ZM18.7466 15.25C18.7466 15.9404 18.187 16.5 17.4966 16.5H15.4966C15.0824 16.5 14.7466 16.8358 14.7466 17.25C14.7466 17.6642 15.0824 18 15.4966 18H17.4966C19.0154 18 20.2466 16.7688 20.2466 15.25V13.25C20.2466 12.8358 19.9109 12.5 19.4966 12.5C19.0824 12.5 18.7466 12.8358 18.7466 13.25V15.25Z" />
    <path d="M8.74664 10.25C8.74664 9.8358 8.41086 9.50002 7.99664 9.50002H4.49664C4.08243 9.50002 3.74664 9.8358 3.74664 10.25V10.7418L3.75451 10.8501C3.91712 11.9637 4.84878 12.501 6.24659 12.501C7.64432 12.501 8.57596 11.9642 8.73875 10.8513L8.74664 10.7427V10.25Z" />
    <path d="M15.9966 9.99616H11.4989L11.3972 10.003C11.0311 10.0527 10.7489 10.3665 10.7489 10.7462C10.7489 11.1604 11.0847 11.4962 11.4989 11.4962H15.9966L16.0984 11.4893C16.4645 11.4396 16.7466 11.1259 16.7466 10.7462C16.7466 10.3319 16.4109 9.99616 15.9966 9.99616Z" />
    <path d="M7.74662 7.00217C7.74662 6.17376 7.07506 5.5022 6.24664 5.5022C5.41823 5.5022 4.74667 6.17376 4.74667 7.00217C4.74667 7.83058 5.41823 8.50215 6.24664 8.50215C7.07506 8.50215 7.74662 7.83058 7.74662 7.00217Z" />
    <path d="M15.9966 6.50002H11.4989L11.3972 6.50686C11.0311 6.55653 10.7489 6.87032 10.7489 7.25002C10.7489 7.66423 11.0847 8.00002 11.4989 8.00002H15.9966L16.0984 7.99317C16.4645 7.94351 16.7466 7.62971 16.7466 7.25002C16.7466 6.8358 16.4109 6.50002 15.9966 6.50002Z" />
  </svg>
</template>
