<template>
  <svg
    width="40"
    height="27"
    viewBox="0 0 40 27"
  >
    <path d="M27.22 6.18C27.64 9.56 27.5 12.96 26.84 16.25L26.75 16.64V16.66C26.11 19.22 24.52 21.38 22.26 22.74C20.8 23.62 19.17 24.11 17.51 24.17C16.6 24.2 15.67 24.11 14.76 23.88L14.67 23.85L7.44005 16.43C7.44005 16.43 1.82005 16.87 1.54005 16.85C0.850048 16.79 0.920048 15.59 0.760048 14.87C0.690048 14.56 1.00005 2 1.00005 2L8.69005 3.06L8.32005 5.36L17.51 5.19C17.51 5.19 20.25 1.37 27.23 6.19" fill="#00FF86"/>
    <path d="M6 18H1C0.45 18 0 17.55 0 17V2C0 1.71 0.12 1.44 0.34 1.25C0.56 1.06 0.84 0.970004 1.12 1L9.12 2.01C9.4 2.04 9.65 2.19 9.81 2.42C9.97 2.65 10.03 2.93 9.97 3.21L6.97 17.21C6.87 17.67 6.46 18 5.99 18H6ZM2 16H5.19L7.79 3.86L2 3.14V16Z" fill="#0F0017"/>
    <path d="M39.0001 20H33.3801C32.9201 20 32.5201 19.68 32.4101 19.23L29.0301 5.23C28.9601 4.95 29.0201 4.66 29.1801 4.42C29.3501 4.18 29.6001 4.03 29.8901 4L38.8901 3C39.1701 2.97 39.4501 3.06 39.6701 3.25C39.8801 3.44 40.0001 3.71 40.0001 4V19C40.0001 19.55 39.5501 20 39.0001 20ZM34.1601 18H38.0001V5.12L31.2401 5.87L34.1601 18Z" fill="#0F0017"/>
    <path d="M28.3101 21.98C27.6601 21.98 27.0301 21.74 26.5601 21.25C26.1801 20.85 26.1901 20.22 26.5901 19.84C26.9901 19.46 27.6201 19.47 28.0001 19.87C28.1601 20.04 28.5601 20.03 28.8701 19.73C29.0301 19.58 29.1201 19.39 29.1401 19.2C29.1401 19.1 29.1401 18.97 29.0501 18.87L26.2901 15.98L19.8601 10.2L16.5801 12.92C15.3501 13.99 13.4501 13.86 12.3701 12.62C11.8401 12.02 11.5801 11.24 11.6401 10.44C11.7001 9.64 12.0601 8.91 12.6601 8.38L17.4101 4.03C18.8801 2.68 21.0001 2.34 22.8201 3.15L31.0201 6.8C31.5201 7.02 31.7501 7.62 31.5301 8.12C31.3101 8.62 30.7201 8.85 30.2101 8.63L22.0101 4.98C20.9201 4.49 19.6401 4.7 18.7601 5.51L14.0001 9.88C13.7801 10.07 13.6601 10.32 13.6401 10.58C13.6201 10.85 13.7101 11.1 13.8801 11.31C14.2401 11.72 14.8801 11.77 15.2901 11.41L19.2501 8.12C19.6301 7.8 20.1901 7.81 20.5601 8.15L27.6901 14.56L30.5101 17.51C30.9801 18 31.2201 18.7 31.1501 19.41C31.0901 20.08 30.7801 20.72 30.2701 21.2C29.7101 21.73 29.0101 22 28.3201 22L28.3101 21.98Z" fill="#0F0017"/>
    <path d="M23.33 26.73C22.66 26.73 22.03 26.47 21.58 26L18.82 23.11L16.7 20.99C16.31 20.6 16.31 19.97 16.7 19.58C17.09 19.19 17.72 19.19 18.11 19.58L20.24 21.71L23.02 24.62C23.11 24.72 23.24 24.74 23.35 24.73C23.54 24.73 23.73 24.63 23.89 24.48C24.05 24.33 24.14 24.14 24.16 23.95C24.16 23.85 24.16 23.72 24.06 23.62L19.86 19.29C19.47 18.9 19.47 18.27 19.86 17.88C20.25 17.49 20.88 17.49 21.27 17.88L22.72 19.33L25.5 22.24C25.97 22.73 26.21 23.43 26.14 24.14C26.08 24.81 25.77 25.45 25.26 25.93C24.75 26.41 24.1 26.7 23.43 26.73C23.39 26.73 23.35 26.73 23.31 26.73H23.33Z" fill="#0F0017"/>
    <path d="M25.8201 24.36C25.1501 24.36 24.5201 24.1 24.0701 23.63C23.6901 23.23 23.7001 22.6 24.1001 22.22C24.5001 21.84 25.1301 21.85 25.5101 22.25C25.6101 22.35 25.7401 22.37 25.8401 22.36C26.0301 22.36 26.2201 22.26 26.3801 22.11C26.5401 21.96 26.6301 21.77 26.6501 21.58C26.6501 21.48 26.6501 21.35 26.5501 21.25L22.3201 16.89C21.9301 16.5 21.9301 15.87 22.3201 15.48C22.7101 15.09 23.3401 15.09 23.7301 15.48L25.2201 16.97L28.0001 19.88C28.4701 20.37 28.7101 21.07 28.6401 21.78C28.5801 22.45 28.2701 23.09 27.7601 23.57C27.2501 24.05 26.6001 24.34 25.9301 24.37C25.8901 24.37 25.8501 24.37 25.8101 24.37L25.8201 24.36Z" fill="#0F0017"/>
    <path d="M20.02 25.95C15.06 25.95 7.38002 17.68 5.69002 15.79C5.32002 15.38 5.36002 14.75 5.77002 14.38C6.18002 14.01 6.81002 14.05 7.18002 14.46C14.39 22.52 19.54 24.71 20.9 23.7C21.34 23.37 21.97 23.46 22.3 23.9C22.63 24.34 22.54 24.97 22.1 25.3C21.49 25.75 20.79 25.95 20.02 25.95Z" fill="#0F0017"/>
    <path d="M17.38 6.37001H8.54004C7.99004 6.37001 7.54004 5.92001 7.54004 5.37001C7.54004 4.82001 7.99004 4.37001 8.54004 4.37001H17.37C17.92 4.37001 18.37 4.82001 18.37 5.37001C18.37 5.92001 17.92 6.37001 17.37 6.37001H17.38Z" fill="#0F0017"/>
    <path d="M30.2101 19.63C29.8001 19.63 29.4101 19.37 29.2701 18.96C29.0901 18.44 29.3601 17.87 29.8801 17.68L32.8201 16.64C33.3401 16.46 33.9101 16.73 34.1001 17.25C34.2801 17.77 34.0101 18.34 33.4901 18.53L30.5501 19.57C30.4401 19.61 30.3301 19.63 30.2201 19.63H30.2101Z" fill="#0F0017"/>
  </svg>
</template>
