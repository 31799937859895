<template>
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.64551 3C8.5512 2.68315 8.50055 2.34749 8.50055 2H0.5C0.223858 2 0 2.22386 0 2.5C0 2.77614 0.223858 3 0.5 3H8.64551ZM0.5 6C0.223858 6 0 6.22386 0 6.5C0 6.77614 0.223858 7 0.5 7H11.5C11.7761 7 12 6.77614 12 6.5C12 6.22386 11.7761 6 11.5 6H0.5ZM0.5 10C0.223858 10 0 10.2239 0 10.5C0 10.7761 0.223858 11 0.5 11H11.5C11.7761 11 12 10.7761 12 10.5C12 10.2239 11.7761 10 11.5 10H0.5Z" />
    <path d="M14 2C14 2.05089 13.9981 2.10133 13.9944 2.15126C13.988 2.23681 13.9762 2.32086 13.9594 2.40307L13.9575 2.41216C13.7675 3.31899 12.9633 4 12 4C11.1292 4 10.3884 3.44346 10.1138 2.66667C10.0401 2.45815 10 2.23376 10 2C10 1.93096 10.0035 1.86275 10.0103 1.79551L10.0122 1.77778C10.1227 0.777789 10.9705 0 12 0C13.1046 0 14 0.89543 14 2Z" class="x"/>
  </svg>
</template>

<style lang="scss" scoped>
.x {
  fill: #00FF86 !important
}
</style>