<template>
  <svg
    width="5"
    height="10"
    viewBox="0 0 5 10"
  >
    <path d="M0.0633964 6.76443C0.192379 6.45303 0.49624 6.25 0.833289 6.25H4.16659C4.50364 6.25 4.8075 6.45303 4.93648 6.76443C5.06546 7.07582 4.99417 7.43425 4.75584 7.67258L3.08919 9.33923C2.76376 9.66466 2.23612 9.66466 1.91069 9.33923L0.244039 7.67258C0.00570928 7.43425 -0.0655865 7.07582 0.0633964 6.76443Z" />
    <path d="M4.93654 3.23557C4.80756 3.54697 4.5037 3.75 4.16665 3.75L0.833349 3.75C0.496301 3.75 0.19244 3.54697 0.0634571 3.23557C-0.0655255 2.92418 0.00577031 2.56575 0.2441 2.32742L1.91075 0.660774C2.23618 0.33534 2.76382 0.33534 3.08925 0.660774L4.7559 2.32742C4.99423 2.56575 5.06553 2.92418 4.93654 3.23557Z" />
  </svg>
</template>
