<template>
    <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
    >
        <path
            d="M8.05198 1.2943C8.39129 0.317005 9.68406 -0.133267 10.4829 0.703859C10.6455 0.874284 10.8081 1.05844 10.9176 1.22266C11.2379 1.70317 11.3725 2.33596 11.4218 2.95233C11.4721 3.58046 11.438 4.25459 11.3738 4.86485C11.3093 5.47747 11.2129 6.0396 11.1328 6.44778C11.1294 6.46548 11.1259 6.48289 11.1225 6.50001H12.006C13.8777 6.50001 15.2924 8.19515 14.9576 10.0367L14.2737 13.7984C13.8017 16.3943 11.2078 18.0291 8.66224 17.3348L3.06251 15.8076C2.14894 15.5585 1.45455 14.8145 1.26885 13.886L0.915812 12.1208C0.638089 10.7322 1.69991 9.56245 2.82905 9.11613C3.15163 8.98862 3.44337 8.8268 3.66974 8.62598C5.37583 7.11247 5.99442 5.90288 7.05406 3.77696C7.4084 3.06607 7.77205 2.10056 8.05198 1.2943ZM10.0165 6.87863L10.0169 6.87708L10.0187 6.86974L10.0262 6.83864C10.0328 6.8108 10.0426 6.76905 10.0549 6.71495C10.0793 6.60671 10.1135 6.44931 10.1515 6.25537C10.2277 5.86667 10.3188 5.33505 10.3793 4.76017C10.4401 4.18294 10.4685 3.57581 10.425 3.03207C10.3806 2.47656 10.2652 2.04685 10.0855 1.77736C10.0264 1.68871 9.91385 1.55605 9.75942 1.39422C9.56051 1.18577 9.13137 1.23429 8.99666 1.62229C8.71411 2.43611 8.33339 3.45196 7.94904 4.22306C6.88216 6.3635 6.19326 7.72409 4.33336 9.37405C3.99304 9.67595 3.58878 9.89112 3.19665 10.0461C2.31631 10.3941 1.75035 11.1945 1.89639 11.9247L2.24943 13.6899C2.36085 14.247 2.77748 14.6934 3.32562 14.8429L8.92536 16.3701C10.9052 16.91 12.9227 15.6386 13.2898 13.6195L13.9738 9.85779C14.197 8.63011 13.2538 7.50001 12.006 7.50001H10.5015C10.3476 7.50001 10.2022 7.42908 10.1074 7.30772C10.0127 7.18639 9.97921 7.02797 10.0165 6.87863C10.0165 6.8786 10.0165 6.87867 10.0165 6.87863Z"
            fill="#0F0017"
        />
    </svg>
</template>
