<template>
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
  >
    <path d="M20 10.5C20 4.978 15.523 0.5 10 0.5C4.477 0.5 0 4.978 0 10.5C0 16.022 4.477 20.5 10 20.5C10.2628 20.5 10.5232 20.4899 10.7809 20.47C10.3226 19.9863 9.93785 19.4321 9.64459 18.8255C5.2138 18.6387 1.667 14.9759 1.667 10.5C1.667 5.905 5.405 2.167 10 2.167C14.4759 2.167 18.1387 5.7138 18.3255 10.1446C18.9321 10.4379 19.4862 10.8226 19.9699 11.2809C19.9899 11.0232 20 10.7628 20 10.5Z" />
    <path d="M9.25675 14.1851C9.37606 13.774 9.53489 13.3796 9.72886 13.0064C9.2245 12.7201 8.83958 12.2606 8.64209 11.6296H10.6885C10.9428 11.3498 11.2213 11.0922 11.5205 10.8602C11.4578 10.8236 11.3819 10.8035 11.2964 10.8035H8.46631C8.44873 10.7068 8.44873 10.5662 8.44873 10.4343C8.44873 10.3025 8.44873 10.1794 8.45752 10.074H11.2964C11.5425 10.074 11.7095 9.8894 11.7095 9.6521C11.7095 9.40601 11.5425 9.23901 11.2964 9.23901H8.64209C9.00244 8.10522 9.99561 7.52515 11.1909 7.51636C11.3364 7.51289 11.475 7.51762 11.6014 7.52193C11.7959 7.52855 11.9614 7.5342 12.0786 7.50757C12.3511 7.4812 12.5532 7.29663 12.5532 6.9978C12.5532 6.76929 12.4653 6.57593 12.1841 6.44409C11.9731 6.33862 11.6479 6.29468 11.1909 6.29468C9.25732 6.29468 7.6665 7.29663 7.20947 9.23901H6.31299C6.07568 9.23901 5.8999 9.40601 5.8999 9.6521C5.8999 9.8894 6.07568 10.074 6.31299 10.074H7.09521C7.08643 10.1794 7.08643 10.2937 7.08643 10.4343C7.08643 10.5189 7.08924 10.5978 7.09168 10.6663C7.09356 10.7189 7.09521 10.7653 7.09521 10.8035H6.31299C6.07568 10.8035 5.8999 10.9705 5.8999 11.2166C5.8999 11.4539 6.07568 11.6296 6.31299 11.6296H7.20947C7.5058 12.8653 8.25384 13.7239 9.25675 14.1851Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6109 19.8892C13.7588 22.0371 17.2412 22.0371 19.3891 19.8892C21.537 17.7413 21.537 14.2589 19.3891 12.111C17.2412 9.96313 13.7588 9.96313 11.6109 12.111C9.46303 14.2589 9.46303 17.7413 11.6109 19.8892ZM13.0251 17.7679L16.4142 14.3788H14.0858C13.8096 14.3788 13.5858 14.1549 13.5858 13.8788C13.5858 13.6026 13.8096 13.3788 14.0858 13.3788L17.6213 13.3788C17.8975 13.3788 18.1213 13.6026 18.1213 13.8788V17.4143C18.1213 17.6905 17.8975 17.9143 17.6213 17.9143C17.3452 17.9143 17.1213 17.6905 17.1213 17.4143V15.0859L13.7322 18.475C13.537 18.6702 13.2204 18.6702 13.0251 18.475C12.8299 18.2797 12.8299 17.9631 13.0251 17.7679Z" />
  </svg>
</template>
