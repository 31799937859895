<template>
  <svg
    width="40"
    height="38"
    viewBox="0 0 40 38"
  >
    <path d="M5 16H27.7C27.62 17.35 27.44 18.68 27.17 20L27.08 20.39V20.42C26.44 22.98 24.85 25.14 22.59 26.5C20.32 27.87 17.66 28.27 15.09 27.64L15 27.61C10.15 26.08 5.65 23.37 2 19.6V17C2 16.45 2.45 16 3 16H5Z" fill="#00FF86"/>
    <path d="M17 12C17.55 12 18 11.55 18 11V1C18 0.45 17.55 0 17 0C16.45 0 16 0.45 16 1V11C16 11.55 16.45 12 17 12Z" fill="#0F0017"/>
    <path d="M11 12C11.55 12 12 11.55 12 11V6C12 5.45 11.55 5 11 5C10.45 5 10 5.45 10 6V11C10 11.55 10.45 12 11 12Z" fill="#0F0017"/>
    <path d="M23 12C23.55 12 24 11.55 24 11V6C24 5.45 23.55 5 23 5C22.45 5 22 5.45 22 6V11C22 11.55 22.45 12 23 12Z" fill="#0F0017"/>
    <path d="M39.98 18.5C39.73 15.9 37.39 14 34.78 14H2C0.9 14 0 14.9 0 16V20.66C0 23.72 0.71 26.77 2.23 29.42C3.93 32.38 6.29 34.55 9 36H1C0.45 36 0 36.45 0 37C0 37.55 0.45 38 1 38H33C33.55 38 34 37.55 34 37C34 36.45 33.55 36 33 36H24.9C29.42 33.57 32.72 29.12 33.69 23.82C34.11 23.93 34.55 24 34.99 24C37.91 24 40.26 21.48 39.97 18.5H39.98ZM32 20.55C32 28.83 25.73 35.61 17.73 35.98C13.48 36.18 9.46 34.64 6.44 31.64C3.5 28.73 2.01 24.66 2.01 20.53V17C2.01 16.45 2.46 16 3.01 16H31C31.55 16 32 16.45 32 17V20.55ZM35 22C34.63 22 34.28 21.93 33.94 21.8C33.97 21.38 34 20.97 34 20.55V16H34.88C36.33 16 37.66 16.98 37.94 18.4C38.31 20.32 36.85 22 35 22Z" fill="#0F0017"/>
  </svg>
</template>
