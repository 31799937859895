export enum TypographyType {
  epic = 'epic',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  subtitle = 'subtitle',
  subtitleSmall = 'subtitleSmall',
  caption = 'caption',
  body = 'body',
  bodySmall = 'bodySmall',
  bodyMedium = 'bodyMedium',
  bodySmallMedium = 'bodySmallMedium',
  footnote = 'footnote',
  footnoteMedium = 'footnoteMedium',
  footnoteSmall = 'footnoteSmall',
  title = 'title',
  bodyTitle = 'body title',
}
