<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M13 15V1H23.2C25.6 5.4 26.9 10.2 27 15H13Z" fill="#00FF86"/>
    <path d="M39 40H31C30.4 40 30 39.6 30 39V31C30 30.4 30.4 30 31 30H34V24H21V30H24C24.6 30 25 30.4 25 31V39C25 39.6 24.6 40 24 40H16C15.4 40 15 39.6 15 39V31C15 30.4 15.4 30 16 30H19V24H6V30H9C9.6 30 10 30.4 10 31V39C10 39.6 9.6 40 9 40H1C0.4 40 0 39.6 0 39V31C0 30.4 0.4 30 1 30H4V23C4 22.4 4.4 22 5 22H19V16H13C12.4 16 12 15.6 12 15V1C12 0.4 12.4 0 13 0H27C27.6 0 28 0.4 28 1V15C28 15.6 27.6 16 27 16H21V22H35C35.6 22 36 22.4 36 23V30H39C39.6 30 40 30.4 40 31V39C40 39.6 39.6 40 39 40ZM32 38H38V32H32V38ZM17 38H23V32H17V38ZM2 38H8V32H2V38ZM14 14H26V2H14V14Z" fill="#0F0017"/>
  </svg>
</template>
