<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path opacity="0.85" fill-rule="evenodd" clip-rule="evenodd" d="M3 6.00002C3 5.66882 2.7312 5.40002 2.4 5.40002H0.6C0.2688 5.40002 0 5.66882 0 6.00002C0 6.33122 0.2688 6.60002 0.6 6.60002H2.4C2.7312 6.60002 3 6.33122 3 6.00002Z" />
    <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd" d="M11.4 5.40002H9.6C9.2688 5.40002 9 5.66882 9 6.00002C9 6.33122 9.2688 6.60002 9.6 6.60002H11.4C11.7312 6.60002 12 6.33122 12 6.00002C12 5.66882 11.7312 5.40002 11.4 5.40002Z" />
    <path opacity="0.55" fill-rule="evenodd" clip-rule="evenodd" d="M6.00002 9C5.66882 9 5.40002 9.2688 5.40002 9.6V11.4C5.40002 11.7318 5.66882 12 6.00002 12C6.33122 12 6.60002 11.7318 6.60002 11.4V9.6C6.60002 9.2688 6.33122 9 6.00002 9Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00002 0C5.66882 0 5.40002 0.2688 5.40002 0.6V2.4C5.40002 2.7312 5.66882 3 6.00002 3C6.33122 3 6.60002 2.7312 6.60002 2.4V0.6C6.60002 0.2688 6.33122 0 6.00002 0Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.81957 1.02355C3.65397 0.736746 3.28737 0.637746 2.99997 0.803946C2.71257 0.969546 2.61477 1.33675 2.78037 1.62355L3.68037 3.18235C3.84597 3.46915 4.21257 3.56755 4.49997 3.40195C4.78737 3.23635 4.88517 2.86915 4.71957 2.58235L3.81957 1.02355Z" />
    <path opacity="0.45" fill-rule="evenodd" clip-rule="evenodd" d="M8.31957 8.81759C8.15397 8.53079 7.78737 8.43239 7.49997 8.59799C7.21257 8.76359 7.11477 9.13079 7.28037 9.41759L8.18037 10.9764C8.34597 11.2632 8.71257 11.3622 8.99997 11.196C9.28737 11.0304 9.38517 10.6632 9.21957 10.3764L8.31957 8.81759Z" />
    <path opacity="0.75" fill-rule="evenodd" clip-rule="evenodd" d="M3.40201 7.49997C3.23641 7.21257 2.86921 7.11477 2.58241 7.28037L1.02361 8.18037C0.736807 8.34597 0.637807 8.71257 0.804007 8.99997C0.969607 9.28737 1.33681 9.38517 1.62361 9.21957L3.18241 8.31957C3.46921 8.15397 3.56761 7.78737 3.40201 7.49997Z" />
    <path opacity="0.15" fill-rule="evenodd" clip-rule="evenodd" d="M8.59799 4.49997C8.76359 4.78737 9.13079 4.88517 9.41759 4.71957L10.9764 3.81957C11.2632 3.65397 11.3622 3.28737 11.196 2.99997C11.0304 2.71257 10.6632 2.61477 10.3764 2.78037L8.81759 3.68037C8.53079 3.84597 8.43239 4.21257 8.59799 4.49997Z" />
    <path opacity="0.95" fill-rule="evenodd" clip-rule="evenodd" d="M1.02361 3.81957L2.58241 4.71957C2.86921 4.88517 3.23641 4.78737 3.40201 4.49997C3.56761 4.21257 3.46921 3.84597 3.18241 3.68037L1.62361 2.78037C1.33681 2.61477 0.969607 2.71257 0.804007 2.99997C0.637807 3.28737 0.736807 3.65397 1.02361 3.81957Z" />
    <path opacity="0.35" fill-rule="evenodd" clip-rule="evenodd" d="M10.9764 8.18037L9.41759 7.28037C9.13079 7.11477 8.76359 7.21257 8.59799 7.49997C8.43239 7.78737 8.53079 8.15397 8.81759 8.31957L10.3764 9.21957C10.6632 9.38517 11.0304 9.28737 11.196 8.99997C11.3622 8.71257 11.2632 8.34597 10.9764 8.18037Z" />
    <path opacity="0.65" fill-rule="evenodd" clip-rule="evenodd" d="M4.49997 8.59799C4.21257 8.43239 3.84597 8.53079 3.68037 8.81759L2.78037 10.3764C2.61477 10.6632 2.71257 11.0304 2.99997 11.196C3.28737 11.3622 3.65397 11.2632 3.81957 10.9764L4.71957 9.41759C4.88517 9.13079 4.78737 8.76359 4.49997 8.59799Z" />
    <path opacity="0.05" fill-rule="evenodd" clip-rule="evenodd" d="M7.49997 3.40195C7.78737 3.56755 8.15397 3.46915 8.31957 3.18235L9.21957 1.62355C9.38517 1.33675 9.28737 0.969546 8.99997 0.803946C8.71257 0.637746 8.34597 0.736746 8.18037 1.02355L7.28037 2.58235C7.11477 2.86915 7.21257 3.23635 7.49997 3.40195Z" />
  </svg>
</template>
