<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M3 4.01001C2.45 4.01001 2 4.46001 2 5.01001V9.01001C2 9.56001 2.45 10.01 3 10.01H26.99C26.61 7.98001 26.03 5.97001 25.22 4.01001H12V5.01001C12 5.56001 11.55 6.01001 11 6.01001C10.45 6.01001 10 5.56001 10 5.01001V4.01001H3Z" fill="#00FF86"/>
    <path d="M14.83 29.59C17.14 30.16 19.52 29.88 21.62 28.83C21.54 28.78 21.45 28.73 21.38 28.65C21.38 28.65 21.37 28.63 21.36 28.62L17.15 24.41L12.73 28.83C13.39 29.09 14.06 29.33 14.74 29.55L14.83 29.59Z" fill="#00FF86"/>
    <path d="M16.44 22.3C16.83 21.91 17.46 21.91 17.85 22.3L22.14 26.59L26.93 21.8C27.05 21.21 27.14 20.61 27.23 20.01H25.2C24.7 20.01 24.24 19.67 24.16 19.18C24.06 18.55 24.54 18.01 25.15 18.01H27.45C27.59 16.02 27.55 14.01 27.31 12.01H3C2.45 12.01 2 12.46 2 13.01V21.81C4.57 24.4 7.54 26.47 10.75 27.99L16.44 22.3Z" fill="#00FF86"/>
    <path d="M38 2H30V1C30 0.45 29.55 0 29 0C28.45 0 28 0.45 28 1V2H12V1C12 0.45 11.55 0 11 0C10.45 0 10 0.45 10 1V2H2C0.9 2 0 2.9 0 4V38C0 39.1 0.9 40 2 40H38C39.1 40 40 39.1 40 38V4C40 2.9 39.1 2 38 2ZM38 37C38 37.55 37.55 38 37 38H3C2.45 38 2 37.55 2 37V13C2 12.45 2.45 12 3 12H37C37.55 12 38 12.45 38 13V37ZM38 9C38 9.55 37.55 10 37 10H3C2.45 10 2 9.55 2 9V5C2 4.45 2.45 4 3 4H10V5C10 5.55 10.45 6 11 6C11.55 6 12 5.55 12 5V4H28V5C28 5.55 28.45 6 29 6C29.55 6 30 5.55 30 5V4H37C37.55 4 38 4.45 38 5V9Z" fill="#0F0017"/>
    <path d="M31.64 18H25.14C24.53 18 24.05 18.54 24.15 19.17C24.23 19.66 24.69 20 25.19 20H28.72L22.14 26.58L17.85 22.29C17.46 21.9 16.83 21.9 16.44 22.29L8.43996 30.29C8.04996 30.68 8.04996 31.31 8.43996 31.7C8.63996 31.9 8.88996 31.99 9.14996 31.99C9.40996 31.99 9.65996 31.89 9.85996 31.7L17.15 24.41L21.36 28.62C21.36 28.62 21.37 28.62 21.38 28.63C21.58 28.88 21.86 29 22.14 29C22.4 29 22.65 28.9 22.85 28.71L30.14 21.42V24.95C30.14 25.45 30.48 25.91 30.97 25.99C31.6 26.09 32.14 25.61 32.14 25V18.5C32.14 18.22 31.92 18 31.64 18Z" fill="#0F0017"/>
  </svg>
</template>
