<template>
  <svg
    width="40"
    height="37"
    viewBox="0 0 40 37"
  >
    <path d="M7.81 25.89C9.77 27.02 11.85 27.92 14 28.6L14.09 28.64C15 27 13 24 14 22C15 22 24.13 25.51 24.95 24.15C25.45 23.31 25.84 22.4 26.08 21.42C26.08 21.42 26.08 21.41 26.08 21.4L26.17 21.01C27.19 15.96 26.97 10.65 25.39 5.57996L16.01 8.00996H6L5 11.01H2L1 13.01V16.01L2 19.01H4L5 20.01L6.75 22.07L8 26.01" fill="#00FF86"/>
    <path d="M5 20H3C1.35 20 0 18.65 0 17V13C0 11.35 1.35 10 3 10H5C5.55 10 6 10.45 6 11V19C6 19.55 5.55 20 5 20ZM3 12C2.45 12 2 12.45 2 13V17C2 17.55 2.45 18 3 18H4V12H3Z" fill="#0F0017"/>
    <path d="M13 23H7C5.35 23 4 21.65 4 20V10C4 8.35 5.35 7 7 7H13C13.55 7 14 7.45 14 8V22C14 22.55 13.55 23 13 23ZM7 9C6.45 9 6 9.45 6 10V20C6 20.55 6.45 21 7 21H12V9H7Z" fill="#0F0017"/>
    <path d="M34 29C33.85 29 33.7 28.97 33.57 28.9L31.81 28.06C26.17 25.35 20.16 23.68 13.93 23.09L12.9 22.99C12.39 22.94 11.99 22.51 11.99 21.99V8.00004C11.99 7.48004 12.39 7.04004 12.91 7.00004C19.26 6.51004 25.41 4.90004 31.18 2.21004L33.58 1.09004C33.89 0.950044 34.25 0.970044 34.54 1.15004C34.83 1.33004 35 1.65004 35 1.99004V28C35 28.34 34.82 28.66 34.53 28.85C34.37 28.95 34.18 29 34 29ZM14 21.09H14.12C20.58 21.71 26.83 23.45 32.68 26.26L33 26.41V3.57004L32.03 4.02004C26.32 6.68004 20.26 8.33004 14 8.92004V21.09Z" fill="#0F0017"/>
    <path d="M35 20H34V18H35C36.65 18 38 16.65 38 15C38 13.35 36.65 12 35 12H34V10H35C37.76 10 40 12.24 40 15C40 17.76 37.76 20 35 20Z" fill="#0F0017"/>
    <path d="M16.75 37H10.6C10.15 37 9.76004 36.7 9.64004 36.26L5.79004 22.26L7.72004 21.73L11.36 34.99H15.44L11.94 22.25L13.87 21.72L17.72 35.72C17.8 36.02 17.74 36.34 17.55 36.59C17.36 36.84 17.07 36.98 16.76 36.98L16.75 37Z" fill="#0F0017"/>
  </svg>
</template>
