<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M27 16C27 18 26.8 20.1 26.4 22.1L26.2999 22.5C26.2999 22.7 26.2 22.9 26.2 23C25.5 25.3 24 27.3 21.9 28.6C19.6 30 17 30.4 14.4 29.7H14.3C12.6 29.1 10.9 28.5 9.19995 27.6L18 1H22L27 16Z" fill="#00FF86"/>
    <path d="M37.8 40C37.6 40 37.4 40 37.3 39.9002C35.5 39.8005 15.7 39.9002 2.5 39.9002C1.1 39.9002 0 38.803 0 37.4065V35.4115C0 34.015 1.1 32.9177 2.5 32.9177H6.3L17.1 0.698254C17.2 0.299252 17.6 0 18 0H22C22.4 0 22.8 0.299252 22.9 0.698254L33.7 32.9177H37.5C38.9 32.9177 40 34.015 40 35.4115V37.4065C40 38.3042 39.5 39.202 38.8 39.7007C38.5 39.9002 38.1 40 37.8 40ZM27.2 37.8055C35.7 37.8055 37.4 37.8055 37.8 37.9052C37.9 37.8055 38 37.606 38 37.3067V35.3117C38 35.0125 37.8 34.813 37.5 34.813H2.5C2.2 34.9127 2 35.1122 2 35.4115V37.4065C2 37.7057 2.2 37.9052 2.5 37.9052C14.2 37.9052 21.9 37.8055 27.2 37.8055ZM8.4 32.9177H31.6L28.6 23.9402H11.4L8.4 32.9177ZM12.1 21.9451H28L25 12.9676H15.1L12.1 21.9451ZM15.7 10.9726H24.3L21.3 1.99501H18.7L15.7 10.9726Z" fill="#0F0017"/>
  </svg>
</template>
