<template>
    <svg
        width="18"
        height="22"
        viewBox="0 0 18 22"
    >
        <path
            d="M13.4996 16.9848C13.4996 19.4267 12.3595 21.1835 10.4932 21.1835C9.5183 21.1835 9.15177 20.6407 8.80212 19.3877L8.59597 18.6158C8.49503 18.2571 8.31924 17.6463 8.06895 16.7848C8.06226 16.7617 8.0524 16.74 8.03957 16.7199L5.17281 12.2342C4.49476 11.1733 3.49429 10.3578 2.31841 9.90757L1.84513 9.72637C0.598402 9.24903 -0.125426 7.94525 0.128697 6.63468L0.533195 4.5486C0.774622 3.30351 1.71828 2.31261 2.9501 2.01069L10.5778 0.141163C13.109 -0.479228 15.6674 1.0557 16.3113 3.58103L17.7262 9.13019C18.1697 10.8695 17.1192 12.6389 15.3799 13.0824C15.1175 13.1493 14.8478 13.1832 14.5769 13.1832H12.7536C13.2497 14.8161 13.4996 16.0758 13.4996 16.9848ZM1.60127 6.92022C1.48576 7.51593 1.81477 8.10856 2.38147 8.32553L2.85475 8.50674C4.33036 9.0717 5.58585 10.0951 6.43674 11.4265L9.3035 15.9121C9.39354 16.053 9.46292 16.2061 9.50949 16.3666L10.0614 18.2869L10.2731 19.0783C10.4125 19.5662 10.4827 19.6835 10.4932 19.6835C11.3609 19.6835 11.9996 18.6994 11.9996 16.9848C11.9996 16.1003 11.6738 14.6494 11.0158 12.6697C10.8544 12.1842 11.2158 11.6832 11.7275 11.6832H14.5769C14.7228 11.6832 14.868 11.6649 15.0093 11.6289C15.9459 11.3901 16.5115 10.4373 16.2727 9.50079L14.8578 3.95164C14.4172 2.22378 12.6668 1.17356 10.9349 1.59804L3.30718 3.46757C2.64389 3.63014 2.13577 4.16371 2.00577 4.83414L1.60127 6.92022Z"
            fill="#0F0017"
        />
    </svg>
</template>
