<template>
  <svg
    width="40"
    height="34"
    viewBox="0 0 40 34"
  >
    <path d="M26.2 6C26.7 8.3 27 10.6 27 12.9C27 14.7 26.8 16.4 26.5 18.1C26.5 18.4 26.4 18.7 26.3 19.1L26.2 19.5C25.6 22.1 24 24.2 21.7 25.6C19.4 27 16.8 27.4 14.2 26.7H14.1C9.3 25.1 4.7 22.3 1 18.4V8C1 6.9 1.9 6 3 6H14" fill="#00FF86"/>
    <path d="M37 34H3C1.3 34 0 32.7 0 31V15V8C0 6.3 1.3 5 3 5H13V2C13 0.9 13.9 0 15 0H25C26.1 0 27 0.9 27 2V5H37C38.7 5 40 6.3 40 8V15V31C40 32.7 38.7 34 37 34ZM2 16.3V31C2 31.6 2.4 32 3 32H37C37.6 32 38 31.6 38 31V16.3L24 19.8V23C24 23.6 23.6 24 23 24H17C16.4 24 16 23.6 16 23V19.8L2 16.3ZM18 22H22V19V18H18V19V22ZM17 16H23C23.6 16 24 16.4 24 17V17.7L38 14.2V8C38 7.4 37.6 7 37 7H3C2.4 7 2 7.4 2 8V14.2L16 17.7V17C16 16.4 16.4 16 17 16ZM15 5H25V2H15V5ZM25 2H26H25Z" fill="#0F0017"/>
  </svg>
</template>
