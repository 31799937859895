<template>
    <svg
        width="18"
        height="22"
        viewBox="0 0 18 22"
    >
        <path
            d="M13.4996 4.20222C13.4996 1.76029 12.3595 0.00354004 10.4932 0.00354004C9.46699 0.00354004 9.11488 0.604905 8.74696 2.00311C8.67192 2.29196 8.63497 2.4326 8.59597 2.57121C8.49503 2.92995 8.31924 3.5407 8.06895 4.40222C8.06226 4.4253 8.0524 4.44705 8.03957 4.46712L5.17281 8.95278C4.49476 10.0137 3.49429 10.8292 2.31841 11.2794L1.84513 11.4607C0.598402 11.938 -0.125426 13.2418 0.128697 14.5523L0.533195 16.6384C0.774622 17.8835 1.71828 18.8744 2.9501 19.1763L10.5778 21.0459C13.109 21.6662 15.6674 20.1313 16.3113 17.606L17.7262 12.0568C18.1697 10.3175 17.1192 8.54808 15.3799 8.10461C15.1175 8.0377 14.8478 8.00385 14.5769 8.00385H12.7536C13.2497 6.37097 13.4996 5.11118 13.4996 4.20222ZM1.60127 14.2668C1.48576 13.6711 1.81477 13.0785 2.38147 12.8615L2.85475 12.6803C4.33036 12.1153 5.58585 11.0919 6.43674 9.76055L9.3035 5.27489C9.39354 5.134 9.46292 4.98095 9.50949 4.82038C9.76079 3.95537 9.93751 3.34138 10.0399 2.97749C10.083 2.82424 10.1239 2.66879 10.1976 2.38482C10.3875 1.66312 10.4809 1.50354 10.4932 1.50354C11.3609 1.50354 11.9996 2.48761 11.9996 4.20222C11.9996 5.08671 11.6738 6.53767 11.0158 8.51729C10.8544 9.00282 11.2158 9.50385 11.7275 9.50385H14.5769C14.7228 9.50385 14.868 9.52208 15.0093 9.55811C15.9459 9.7969 16.5115 10.7497 16.2727 11.6862L14.8578 17.2354C14.4172 18.9632 12.6668 20.0135 10.9349 19.589L3.30718 17.7194C2.64389 17.5569 2.13577 17.0233 2.00577 16.3529L1.60127 14.2668Z"
            fill="#0F0017"
        />
    </svg>
</template>
