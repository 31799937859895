<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M12.0001 27V28.9C10.7001 28.4 9.4001 27.7 8.1001 27H12.0001ZM24.0001 21V26.8C23.4001 27.5 22.6001 28.1 21.8001 28.6C20.0001 29.6 18.0001 30.1 16.0001 30V21H24.0001Z" fill="#00FF86"/>
    <path d="M36 40H28C27.4 40 27 39.6 27 39V15C27 14.4 27.4 14 28 14H36C36.6 14 37 14.4 37 15V39C37 39.6 36.6 40 36 40ZM29 38H35V16H29V38ZM24 40H16C15.4 40 15 39.6 15 39V21C15 20.4 15.4 20 16 20H24C24.6 20 25 20.4 25 21V39C25 39.6 24.6 40 24 40ZM17 38H23V22H17V38ZM12 40H3.99999C3.39999 40 2.99999 39.6 2.99999 39V27C2.99999 26.4 3.39999 26 3.99999 26H12C12.6 26 13 26.4 13 27V39C13 39.6 12.6 40 12 40ZM4.99999 38H11V28H4.99999V38ZM0.999992 24C0.599993 24 0.299993 23.8 0.0999925 23.5C-0.100007 23 -7.48038e-06 22.4 0.499993 22.1L1.49999 21.5L36.1 2.40005L33.8 2.00005C33.3 1.90005 32.9 1.40005 33 0.800045C33.1 0.300045 33.6 -0.0999547 34.2 4.52604e-05L39.2 1.00005C39.3 1.00005 39.5 1.10005 39.6 1.20005C39.7 1.30005 39.8 1.40005 39.9 1.50005C39.9 1.50005 40 1.60005 40 1.70005C40 1.80005 40 1.80005 40.1 1.90005C40 1.90005 40 2.00005 40 2.00005C40 2.10005 40 2.10005 40 2.20005L39 7.20005C38.9 7.70005 38.4 8.10005 37.8 8.00004C37.3 7.90004 36.9 7.40005 37 6.80005L37.6 3.90005L1.49999 23.9C1.29999 24 1.19999 24 0.999992 24Z" fill="#0F0017"/>
  </svg>
</template>
