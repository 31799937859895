<template>
  <svg
    width="16"
    height="40"
    viewBox="0 0 16 40"
  >
    <path d="M13.0001 6V25.5C12.2001 26.7 11.1001 27.8 9.8001 28.6C9.5001 28.8 9.1001 29 8.8001 29.1C7.9001 29.5 6.9001 29.8 5.9001 29.9C4.7001 30 3.5001 30 2.4001 29.7H2.3001C2.1001 29.6 1.8001 29.6 1.6001 29.5C2.0001 28.6 2.5001 27.7 3.2001 27.1L3.0001 8V6C3.0001 3.2 5.2001 1 8.0001 1C10.8001 1 13.0001 3.2 13.0001 6Z" fill="#00FF86"/>
    <path d="M8 40C3.6 40 0 36.4 0 32C0 31 0.2 30 0.6 29.1C1 28.2 1.5 27.4 2.1 26.6L2 8.1V8V7.9V6C2 2.7 4.7 0 8 0C11.3 0 14 2.7 14 6V26.7C15.3 28.2 16 30 16 32C16 36.4 12.4 40 8 40ZM4.1 24V27C4.1 27.3 4 27.5 3.8 27.7C3.2 28.3 2.7 29 2.4 29.8C2.1 30.5 2 31.2 2 32C2 35.3 4.7 38 8 38C11.3 38 14 35.3 14 32C14 30.4 13.4 28.9 12.3 27.8C12.1 27.6 12 27.4 12 27.1V6C12 3.8 10.2 2 8 2C5.8 2 4 3.8 4 6V7H5C5.6 7 6 7.4 6 8C6 8.6 5.6 9 5 9H4V10H5C5.6 10 6 10.4 6 11C6 11.6 5.6 12 5 12H4V13H5C5.6 13 6 13.4 6 14C6 14.6 5.6 15 5 15H4V16H5C5.6 16 6 16.4 6 17C6 17.6 5.6 18 5 18H4.1V19H5C5.6 19 6 19.4 6 20C6 20.6 5.6 21 5 21H4.1V22H5C5.6 22 6 22.4 6 23C6 23.6 5.6 24 5 24H4.1ZM8 36C5.8 36 4 34.2 4 32C4 30.1 5.3 28.6 7 28.1V6C7 5.4 7.4 5 8 5C8.6 5 9 5.4 9 6V28.1C10.7 28.5 12 30.1 12 32C12 34.2 10.2 36 8 36ZM8 30C6.9 30 6 30.9 6 32C6 33.1 6.9 34 8 34C9.1 34 10 33.1 10 32C10 30.9 9.1 30 8 30Z" fill="#0F0017"/>
  </svg>
</template>
