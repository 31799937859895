<template>
    <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
    >
        <path
            d="M8.05198 16.7057C8.39129 17.683 9.68406 18.1333 10.4829 17.2962C10.6455 17.1257 10.8081 16.9416 10.9176 16.7774C11.2379 16.2968 11.3725 15.6641 11.4218 15.0477C11.4721 14.4195 11.438 13.7454 11.3738 13.1352C11.3093 12.5225 11.2129 11.9604 11.1328 11.5522C11.1294 11.5345 11.1259 11.5171 11.1225 11.5H12.006C13.8777 11.5 15.2924 9.80486 14.9576 7.96334L14.2737 4.20164C13.8017 1.60569 11.2078 -0.029048 8.66224 0.665189L3.06251 2.19239C2.14894 2.44154 1.45455 3.18547 1.26885 4.11401L0.915812 5.87918C0.638089 7.2678 1.69991 8.43756 2.82905 8.88388C3.15163 9.01139 3.44337 9.17322 3.66974 9.37403C5.37583 10.8875 5.99442 12.0971 7.05406 14.2231C7.4084 14.9339 7.77205 15.8995 8.05198 16.7057ZM10.0165 11.1214L10.0169 11.1229L10.0187 11.1303L10.0262 11.1614C10.0328 11.1892 10.0426 11.231 10.0549 11.2851C10.0793 11.3933 10.1135 11.5507 10.1515 11.7446C10.2277 12.1333 10.3188 12.665 10.3793 13.2398C10.4401 13.8171 10.4685 14.4242 10.425 14.9679C10.3806 15.5235 10.2652 15.9532 10.0855 16.2227C10.0264 16.3113 9.91385 16.444 9.75942 16.6058C9.56051 16.8142 9.13137 16.7657 8.99666 16.3777C8.71411 15.5639 8.33339 14.5481 7.94904 13.777C6.88216 11.6365 6.19326 10.2759 4.33336 8.62597C3.99304 8.32406 3.58878 8.1089 3.19665 7.9539C2.31631 7.60592 1.75035 6.80549 1.89639 6.0753L2.24943 4.31013C2.36085 3.753 2.77748 3.30665 3.32562 3.15715L8.92536 1.62995C10.9052 1.08999 12.9227 2.36145 13.2898 4.38053L13.9738 8.14223C14.197 9.36991 13.2538 10.5 12.006 10.5H10.5015C10.3476 10.5 10.2022 10.5709 10.1074 10.6923C10.0127 10.8136 9.97921 10.972 10.0165 11.1214C10.0165 11.1214 10.0165 11.1213 10.0165 11.1214Z"
            fill="#0F0017"
        />
    </svg>
</template>
