<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M27 15.9C27 18 26.8 20.1 26.4 22.1L26.3 22.5C25.7 25.1 24.1 27.2 21.8 28.6C19.8 29.8 17.4 30.3 15.1 29.9C11.5 28.1 9 24.3 9 20C9 13.9 13.9 9 20 9C22.5 9 24.8 9.8 26.7 11.2C26.9 12.8 27 14.3 27 15.9Z" fill="#00FF86"/>
    <path d="M20 40C19.4 40 19 39.6 19 39V34C19 33.4 19.4 33 20 33C20.6 33 21 33.4 21 34V39C21 39.6 20.6 40 20 40ZM34.5 35.5C34.2 35.5 34 35.4 33.8 35.2L30.3 31.7C29.9 31.3 29.9 30.7 30.3 30.3C30.7 29.9 31.3 29.9 31.7 30.3L35.2 33.8C35.6 34.2 35.6 34.8 35.2 35.2C35 35.4 34.8 35.5 34.5 35.5ZM5.5 35.5C5.2 35.5 5 35.4 4.8 35.2C4.4 34.8 4.4 34.2 4.8 33.8L8.3 30.3C8.7 29.9 9.3 29.9 9.7 30.3C10.1 30.7 10.1 31.3 9.7 31.7L6.2 35.2C6 35.4 5.7 35.5 5.5 35.5ZM20 32C18.2 32 16.3 31.6 14.7 30.8C10.6 28.7 8 24.6 8 20C8 13.4 13.4 8 20 8C22.7 8 25.2 8.8 27.3 10.4C30.3 12.7 32 16.2 32 20C32 26.6 26.6 32 20 32ZM20 10C14.5 10 10 14.5 10 20C10 23.8 12.1 27.3 15.6 29C17 29.7 18.5 30 20 30C25.5 30 30 25.5 30 20C30 16.8 28.6 13.9 26 12C24.3 10.7 22.2 10 20 10ZM39 21H34C33.4 21 33 20.6 33 20C33 19.4 33.4 19 34 19H39C39.6 19 40 19.4 40 20C40 20.6 39.6 21 39 21ZM6 21H1C0.4 21 0 20.6 0 20C0 19.4 0.4 19 1 19H6C6.6 19 7 19.4 7 20C7 20.6 6.6 21 6 21ZM9 10C8.7 10 8.5 9.9 8.3 9.7L4.8 6.2C4.4 5.8 4.4 5.2 4.8 4.8C5.2 4.4 5.8 4.4 6.2 4.8L9.7 8.3C10.1 8.7 10.1 9.3 9.7 9.7C9.5 9.9 9.3 10 9 10ZM31 10C30.7 10 30.5 9.9 30.3 9.7C29.9 9.3 29.9 8.7 30.3 8.3L33.8 4.8C34.2 4.4 34.8 4.4 35.2 4.8C35.6 5.2 35.6 5.8 35.2 6.2L31.7 9.7C31.5 9.9 31.3 10 31 10ZM20 7C19.4 7 19 6.6 19 6V1C19 0.4 19.4 0 20 0C20.6 0 21 0.4 21 1V6C21 6.6 20.6 7 20 7Z" fill="#0F0017"/>
  </svg>
</template>
