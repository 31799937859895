<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M21.1999 28.8C19.0999 29.9 16.5999 30.2 14.1999 29.7H14.0999C11.4999 28.9 8.9999 27.7 6.6999 26.3C3.0999 21.3 0.999902 15.3 0.899902 8.7C0.899902 8.6 0.899902 8.5 0.899902 8.5C0.899902 8.5 0.899902 8.5 0.899902 8.4V8.3C0.899902 4.3 4.1999 1 8.1999 1C8.6999 1 9.1999 1 9.6999 1.1C10.8999 1.4 11.7999 2.4 11.9999 3.7C12.3999 6.1 12.9999 10.3 13.2999 12.3C13.3999 13 13.0999 13.8 12.4999 14.3C11.5999 14.9 10.5999 15.4 9.4999 15.6C10.6999 19.1 12.5999 22.2 15.1999 24.7L15.2999 24.8C17.2999 26.4 19.0999 27.8 21.1999 28.8Z" fill="#00FF86"/>
    <path d="M31.7 40C31.3 40 30.9 40 30.5 40C20.6 39.7 11.7 34.9 5.9 26.8C2.1 21.5 0.1 15.2 0 8.7C0 8.6 0 8.6 0 8.5V8.4C0 8.4 0 8.3 0 8.2C0.1 3.7 3.8 0 8.4 0C8.9 0 9.5 0.1 10 0.2H10.1C11.6 0.6 12.9 1.9 13.1 3.6L13.4 5.3C13.8 7.6 14.2 10.5 14.5 12.1C14.7 13.2 14.2 14.3 13.4 15C12.7 15.5 11.9 15.9 11.1 16.2C12.2 19.1 14 21.8 16.2 24L16.3 24.1C17.9 25.7 19.7 27 21.7 28C22.4 28.3 23 28.7 23.8 28.9C24 28.2 24.4 27.6 24.8 27C25.4 26.1 26.6 25.6 27.8 25.7L36.8 27.1C38.3 27.3 39.5 28.4 39.8 29.8V29.9C39.9 30.4 40 31 40 31.6C40 35.8 36.9 39.3 32.8 39.9C32.7 39.9 32.7 39.9 32.6 39.9C32.4 39.9 32.2 39.9 32 39.9C31.9 40 31.8 40 31.7 40ZM2 8.3C2 8.4 2 8.4 2 8.5V8.6C2.1 14.7 4 20.6 7.6 25.6C13 33.1 21.4 37.6 30.7 37.9C31.1 37.9 31.6 37.9 32 37.9C32.2 37.9 32.4 37.9 32.7 37.9C35.8 37.4 38.1 34.8 38.1 31.6C38.1 31.2 38.1 30.8 38 30.4V30.3C37.9 29.7 37.3 29.2 36.6 29.1L27.6 27.7C27.2 27.6 26.8 27.8 26.6 28.1C26.1 28.8 25.8 29.6 25.6 30.4C25.5 30.7 25.4 30.9 25.1 31.1C24.9 31.2 24.6 31.3 24.3 31.2C23.1 30.8 22 30.3 20.9 29.8C18.8 28.7 16.8 27.3 15 25.6L14.9 25.5C12.2 22.8 10.1 19.5 8.9 15.9C8.8 15.6 8.8 15.3 9 15.1C9.1 14.9 9.4 14.7 9.7 14.6C10.6 14.4 11.5 14 12.3 13.4C12.6 13.2 12.7 12.8 12.7 12.4C12.5 10.8 12 7.9 11.6 5.6L11.3 3.9C11 3 10.4 2.3 9.6 2.1H9.5C9.2 2 8.8 2 8.4 2C4.9 2 2.1 4.8 2 8.3Z" fill="#0F0017"/>
  </svg>
</template>
