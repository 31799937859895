<template>
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
  >
    <path d="M1.99664 1C1.58243 1 1.24664 1.33579 1.24664 1.75V3.5C1.24664 3.77614 1.02279 4 0.746643 4C0.470501 4 0.246643 3.77614 0.246643 3.5V1.75C0.246643 0.783502 1.03014 0 1.99664 0H3.74664C4.02279 0 4.24664 0.223858 4.24664 0.5C4.24664 0.776142 4.02279 1 3.74664 1H1.99664ZM8.24664 0.5C8.24664 0.223858 8.4705 0 8.74664 0H10.4966C11.4631 0 12.2466 0.783502 12.2466 1.75V3.5C12.2466 3.77614 12.0228 4 11.7466 4C11.4705 4 11.2466 3.77614 11.2466 3.5V1.75C11.2466 1.33579 10.9109 1 10.4966 1H8.74664C8.4705 1 8.24664 0.776142 8.24664 0.5ZM0.746643 8C1.02279 8 1.24664 8.22386 1.24664 8.5V10.25C1.24664 10.6642 1.58243 11 1.99664 11H3.74664C4.02279 11 4.24664 11.2239 4.24664 11.5C4.24664 11.7761 4.02279 12 3.74664 12H1.99664C1.03014 12 0.246643 11.2165 0.246643 10.25V8.5C0.246643 8.22386 0.470501 8 0.746643 8ZM11.7466 8C12.0228 8 12.2466 8.22386 12.2466 8.5V10.25C12.2466 11.2165 11.4631 12 10.4966 12H8.74664C8.4705 12 8.24664 11.7761 8.24664 11.5C8.24664 11.2239 8.4705 11 8.74664 11H10.4966C10.9109 11 11.2466 10.6642 11.2466 10.25V8.5C11.2466 8.22386 11.4705 8 11.7466 8Z" />
    <path d="M6.99664 9.25C6.99664 8.83579 6.66086 8.5 6.24664 8.5C5.83243 8.5 5.49664 8.83579 5.49664 9.25C5.49664 9.66421 5.83243 10 6.24664 10C6.66086 10 6.99664 9.66421 6.99664 9.25Z" />
    <path d="M8.24664 4.5C8.24664 3.39543 7.35121 2.5 6.24664 2.5C5.14207 2.5 4.24664 3.39543 4.24664 4.5C4.24664 4.77614 4.4705 5 4.74664 5C5.02279 5 5.24664 4.77614 5.24664 4.5C5.24664 3.94772 5.69436 3.5 6.24664 3.5C6.79893 3.5 7.24664 3.94772 7.24664 4.5C7.24664 4.9169 7.14213 5.12898 6.76468 5.52255L6.50059 5.79209C5.95842 6.36031 5.74664 6.76947 5.74664 7.5C5.74664 7.77614 5.9705 8 6.24664 8C6.52279 8 6.74664 7.77614 6.74664 7.5C6.74664 7.0831 6.85115 6.87102 7.2286 6.47745L7.49269 6.20791C8.03487 5.63969 8.24664 5.23053 8.24664 4.5Z" />
  </svg>
</template>
