<template>
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
  >
    <path d="M16 13C16 15.4853 13.9853 17.5 11.5 17.5C9.01472 17.5 7 15.4853 7 13C7 10.5147 9.01472 8.5 11.5 8.5C13.9853 8.5 16 10.5147 16 13ZM13.8536 11.1464C13.6583 10.9512 13.3417 10.9512 13.1464 11.1464L10.5 13.7929L9.85355 13.1464C9.65829 12.9512 9.34171 12.9512 9.14645 13.1464C8.95118 13.3417 8.95118 13.6583 9.14645 13.8536L10.1464 14.8536C10.3417 15.0488 10.6583 15.0488 10.8536 14.8536L13.8536 11.8536C14.0488 11.6583 14.0488 11.3417 13.8536 11.1464Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 4.5C3 2.29086 4.79086 0.5 7 0.5C9.20914 0.5 11 2.29086 11 4.5C11 6.70914 9.20914 8.5 7 8.5C4.79086 8.5 3 6.70914 3 4.5ZM7 1.5C5.34315 1.5 4 2.84315 4 4.5C4 6.15685 5.34315 7.5 7 7.5C8.65685 7.5 10 6.15685 10 4.5C10 2.84315 8.65685 1.5 7 1.5ZM0 11.5C0 10.3869 0.903151 9.5 2.00873 9.5L7.25716 9.5C7.00353 9.80711 6.7826 10.1422 6.59971 10.5L2.00873 10.5C1.44786 10.5 1 10.9467 1 11.5C1 12.8088 1.62226 13.7837 2.67262 14.4534C3.65932 15.0825 5.02927 15.441 6.59631 15.4933C6.77938 15.8527 7.00079 16.1892 7.25514 16.4975C7.17038 16.4992 7.08532 16.5 7 16.5C5.14526 16.5 3.41697 16.114 2.13499 15.2966C0.83281 14.4663 0 13.1912 0 11.5ZM8.67133 9.5C8.31898 9.78512 8.01056 10.1224 7.75777 10.5L12 10.5C12.4875 10.5 12.8936 10.849 12.9821 11.3108L13.1464 11.1464C13.3417 10.9512 13.6583 10.9512 13.8536 11.1464C13.9509 11.2438 13.9998 11.3714 14 11.499C13.9995 10.395 13.1042 9.50001 12 9.50001L8.67133 9.5ZM14 11.5015C13.9995 13.192 13.1668 14.4665 11.865 15.2966C10.9505 15.8797 9.80895 16.2432 8.55637 16.4038C8.24471 16.134 7.97048 15.8222 7.74252 15.4771C8.69096 15.4174 9.55922 15.2422 10.3036 14.9599C10.4857 15.0375 10.7045 15.0023 10.8531 14.854L11.1403 14.5668C11.2041 14.5301 11.2665 14.4923 11.3274 14.4534C11.9045 14.0854 12.3524 13.6253 12.6388 13.0683L13.8536 11.8536C13.9508 11.7563 13.9996 11.629 14 11.5015Z" />
  </svg>
</template>
