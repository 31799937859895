<template>
  <svg
    width="32"
    height="40"
    viewBox="0 0 32 40"
  >
    <path d="M16 1C17.3 1 18.6 1.3 19.7001 1.9C21.5 5.5 22.6 9.3 22.9 13.1C21.5 15.4 18.9 17 16 17C11.6 17 8.00005 13.4 8.00005 9C8.00005 4.6 11.6 1 16 1ZM16 17C18.5 17 20.8 17.4 22.9 18.2C22.8 19.5 22.6 20.8 22.4 22L22.3 22.4C21.7 25 20.1 27.1 17.8 28.5C15.5 29.9 12.9 30.3 10.3 29.6H10.2C9.10005 29.3 8.00005 28.8 7.00005 28.4C5.20005 27.6 3.40005 26.6 1.80005 25.5C3.70005 20 9.40005 17 16 17Z" fill="#00FF86"/>
    <path d="M29 40H3C1.3 40 0 38.7 0 37V30C0 28.3 0.3 26.7 0.8 25.2C2.4 20.9 6.1 17.8 11.1 16.6C8.6 15 7 12.2 7 9C7 4 11 0 16 0C17.4 0 18.9 0.3 20.1 1C23.1 2.5 25 5.6 25 9C25 10.6 24.6 12.2 23.7 13.6C23 14.8 22 15.8 20.8 16.5C21.6 16.7 22.4 17 23.2 17.3C28.8 19.5 32 24.1 32 30V37C32 38.7 30.7 40 29 40ZM26 38H29C29.6 38 30 37.6 30 37V30C30 25 27.3 21.1 22.5 19.2C20.5 18.4 18.3 18 16 18C9.5 18 4.4 21 2.7 25.9C2.2 27.1 2 28.5 2 30V37C2 37.6 2.4 38 3 38H6V28C6 27.4 6.4 27 7 27C7.6 27 8 27.4 8 28V38H24V28C24 27.4 24.4 27 25 27C25.6 27 26 27.4 26 28V38ZM16 2C12.1 2 9 5.1 9 9C9 12.9 12.1 16 16 16C18.5 16 20.8 14.7 22 12.6C22.6 11.5 23 10.3 23 9C23 6.4 21.5 4 19.2 2.8C18.2 2.3 17.1 2 16 2Z" fill="#0F0017"/>
  </svg>
</template>
