<template>
  <svg
    width="26"
    height="40"
    viewBox="0 0 26 40"
  >
    <path d="M16.2 1C17.3 3 18.1 5 18.7 7.1C19.1 8.6 19.5 10.1 19.7 11.6L13 15L3 10V1H16.2ZM13 15C15.6 15 18 15.8 20 17.2C19.9 18.8 19.7 20.4 19.4 22L19.3 22.4C19 23.5 18.6 24.5 18 25.4C17.2 26.6 16.1 27.7 14.8 28.5C13.3 29.4 11.5 29.9 9.8 29.9C9 29.9 8.2 29.8 7.3 29.6H7.2C5.1 29 3 28.1 1 26.9C1 20.3 6.4 15 13 15Z" fill="#00FF86"/>
    <path d="M13 40C5.8 40 0 34.2 0 27C0 21 4.1 15.9 9.6 14.4L2.5 10.9C2.2 10.7 2 10.4 2 10V1C2 0.4 2.4 0 3 0H23C23.6 0 24 0.4 24 1V10C24 10.4 23.8 10.7 23.4 10.9L16.3 14.4C17.8 14.8 19.2 15.5 20.5 16.4C24 18.9 26 22.8 26 27C26 34.2 20.2 40 13 40ZM13 16C7 16 2 20.9 2 27C2 33.1 6.9 38 13 38C19.1 38 24 33.1 24 27C24 23.5 22.3 20.1 19.4 18C17.5 16.7 15.3 16 13 16ZM4 9.4L13 13.9L22 9.4V2H20V7C20 7.4 19.7 7.8 19.3 7.9L13.3 9.9C13.1 10 12.9 10 12.7 9.9L6.7 7.9C6.3 7.8 6 7.4 6 7V2H4V9.4ZM8 6.3L13 8L18 6.3V2H8V6.3ZM16.7 33.7C16.5 33.7 16.4 33.7 16.2 33.6L13 31.9L9.8 33.6C9.5 33.8 9.1 33.7 8.7 33.5C8.4 33.3 8.2 32.9 8.3 32.5L8.9 28.9L6.3 26.3C6 26 5.9 25.6 6 25.3C6.1 24.9 6.4 24.7 6.8 24.6L10.4 24.1L12 20.8C12.3 20.1 13.5 20.1 13.8 20.8L15.4 24.1L19 24.6C19.4 24.7 19.7 24.9 19.8 25.3C19.9 25.7 19.8 26.1 19.5 26.3L16.9 28.9L17.5 32.5C17.6 32.9 17.4 33.3 17.1 33.5C17.1 33.6 16.9 33.7 16.7 33.7ZM13 29.8C13.2 29.8 13.3 29.8 13.5 29.9L15.4 30.9L15 28.7C14.9 28.4 15.1 28 15.3 27.8L16.8 26.3L14.7 26C14.4 26 14.1 25.7 13.9 25.5L12.9 23.6L11.9 25.5C11.8 25.8 11.5 26 11.1 26L9 26.3L10.5 27.8C10.7 28 10.8 28.4 10.8 28.7L10.4 30.8L12.3 29.8C12.7 29.8 12.8 29.8 13 29.8Z" fill="#0F0017"/>
  </svg>
</template>
