<template>
  <svg
    width="37"
    height="40"
    viewBox="0 0 37 40"
  >
    <path d="M3.00002 18.8022C5.0259 19.9745 7.1736 20.9101 9.39998 21.6H9.49997C12.1 22.2 14.7 21.8 17 20.5C19.3 19.1 20.9 17 21.5 14.4L21.6 14C21.9665 11.9698 22.1423 9.89196 22.116 7.80001C22.0877 5.54796 21.9564 3.45029 21.7332 1.49999C21.7332 1.49999 16 -1 8.50002 3.99999C1.00004 8.99998 3.00002 18.8022 3.00002 18.8022Z" fill="#00FF86"/>
    <path d="M29.7154 23.7994C29.6154 23.7994 29.4154 23.7994 29.3154 23.6994C29.1154 23.5994 28.9154 23.3994 28.8154 23.0994L26.9154 17.7994C26.7154 17.2994 27.0154 16.6994 27.5154 16.4994C28.0154 16.2994 28.6154 16.5994 28.8154 17.0994L30.4154 21.4994L34.8154 19.8994C35.3154 19.6994 35.9154 19.9994 36.1154 20.4994C36.3154 20.9994 36.0154 21.5994 35.5154 21.7994L30.2154 23.7994C29.9154 23.7994 29.8154 23.7994 29.7154 23.7994Z" fill="#0F0017"/>
    <path d="M26.5154 32.2994C25.9154 32.2994 25.5154 31.8994 25.5154 31.2994V29.2994C25.5154 28.4994 25.7154 27.5994 26.0154 26.6994C26.2154 26.1994 26.8154 25.8994 27.3154 26.0994C27.8154 26.2994 28.1154 26.8994 27.9154 27.3994C27.6154 28.0994 27.5154 28.7994 27.5154 29.2994V31.2994C27.5154 31.8994 27.0154 32.2994 26.5154 32.2994Z" fill="#0F0017"/>
    <path d="M30.0155 23.4994C29.8155 23.4994 29.7155 23.4994 29.5155 23.3994C29.0155 23.0994 28.8155 22.4994 29.1155 21.9994C30.2155 19.9994 30.8155 17.6994 30.8155 15.4994C30.8155 11.6994 29.2155 7.99938 26.3155 5.39938C23.4155 2.79938 19.7155 1.59938 15.8155 1.99938C14.2155 2.19938 12.7155 2.59938 11.1155 3.39938C10.6155 3.59938 10.0155 3.39938 9.81552 2.99938C9.61552 2.49938 9.81552 1.89938 10.2155 1.69938C12.0155 0.799379 13.8155 0.299379 15.6155 0.0993785C19.9155 -0.400622 24.3155 0.999378 27.6155 3.99938C30.9155 6.89938 32.8155 11.1994 32.8155 15.5994C32.8155 18.0994 32.1155 20.6994 30.9155 22.9994C30.7155 23.2994 30.3155 23.4994 30.0155 23.4994Z" fill="#0F0017"/>
    <path d="M17.3154 32.3994H8.11536C7.51536 32.3994 7.11536 31.9994 7.11536 31.3994V29.3994C7.11536 28.0994 6.61536 26.8994 5.61536 25.7994C2.61536 22.3994 1.31536 17.9994 1.91536 13.4994C2.31536 10.4994 3.61536 7.49939 5.71536 5.09939C6.11536 4.69939 6.71536 4.59939 7.11536 4.99939C7.51536 5.39939 7.61536 5.99939 7.21536 6.39939C5.31536 8.59939 4.21536 11.0994 3.81536 13.7994C3.31536 17.6994 4.51536 21.4994 7.11536 24.4994C8.41536 25.9994 9.11536 27.6994 9.11536 29.3994V30.3994H16.3154V20.3994C16.3154 19.7994 16.7154 19.3994 17.3154 19.3994C17.9154 19.3994 18.3154 19.7994 18.3154 20.3994V31.3994C18.3154 31.8994 17.9154 32.3994 17.3154 32.3994Z" fill="#0F0017"/>
    <path d="M24.8155 36.0994H9.61548C9.01548 36.0994 8.61548 35.6994 8.61548 35.0994C8.61548 34.4994 9.01548 34.0994 9.61548 34.0994H24.8155C25.4155 34.0994 25.8155 34.4994 25.8155 35.0994C25.8155 35.6994 25.4155 36.0994 24.8155 36.0994Z" fill="#0F0017"/>
    <path d="M22.9155 39.9994H11.6155C11.0155 39.9994 10.6155 39.5994 10.6155 38.9994C10.6155 38.3994 11.0155 37.9994 11.6155 37.9994H22.8155C23.4155 37.9994 23.8155 38.3994 23.8155 38.9994C23.8155 39.5994 23.4155 39.9994 22.9155 39.9994Z" fill="#0F0017"/>
    <path d="M7.71543 12.2994C7.21543 12.2994 6.81543 11.9994 6.71543 11.4994L5.71543 6.89937L1.21543 7.79937C0.615426 7.89937 0.115426 7.49937 0.0154261 6.99937C-0.0845739 6.49937 0.315426 5.89937 0.815426 5.79937L6.31543 4.79937C6.91543 4.69937 7.41543 4.99937 7.51543 5.49937L8.61543 10.9994C8.71543 11.4994 8.41543 12.0994 7.81543 12.1994L7.71543 12.2994Z" fill="#0F0017"/>
    <path d="M17.2154 21.1994C15.5154 21.1994 13.8154 20.4994 12.6154 19.2994C12.2154 18.8994 12.2154 18.2994 12.6154 17.8994C13.0154 17.4994 13.6154 17.4994 14.0154 17.8994C14.9154 18.7994 16.0154 19.1994 17.2154 19.1994C18.4154 19.1994 19.5154 18.6994 20.4154 17.8994C20.8154 17.4994 21.4154 17.4994 21.8154 17.8994C22.2154 18.2994 22.2154 18.8994 21.8154 19.2994C20.6154 20.4994 19.0154 21.1994 17.2154 21.1994Z" fill="#0F0017"/>
  </svg>
</template>
