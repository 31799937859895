<template>
  <svg
    width="40"
    height="24"
    viewBox="0 0 40 24"
  >
    <path d="M27 1C26.9 2.6 26.7 4.1 26.4 5.6L26.3 6C26.2 6.2 26.2 6.5 26.1 6.8C25.4 9 23.9 10.9 21.8 12.1C21.2 12.4 20.6 12.7 20 12.9C18.2 13.6 16.2 13.7 14.3 13.2H14.2C13.5 13 12.7 12.7 12 12.4C8.7 11.1 5.7 9.2 3 6.9C2.3 6.3 1.6 5.7 1 5V3C1 1.9 1.9 1 3 1H27Z" fill="#00FF86"/>
    <path d="M37 24H3C1.3 24 0 22.7 0 21V3C0 1.3 1.3 0 3 0H37C38.7 0 40 1.3 40 3V21C40 22.7 38.7 24 37 24ZM34 22H37C37.6 22 38 21.6 38 21V18C35.8 18 34 19.8 34 22ZM8 22H32C32 18.7 34.7 16 38 16V8C34.7 8 32 5.3 32 2H8C8 5.3 5.3 8 2 8V16C5.3 16 8 18.7 8 22ZM2 18V21C2 21.6 2.4 22 3 22H6C6 19.8 4.2 18 2 18ZM34 2C34 4.2 35.8 6 38 6V3C38 2.4 37.6 2 37 2H34ZM3 2C2.4 2 2 2.4 2 3V6C4.2 6 6 4.2 6 2H3ZM20 21C15 21 11 17 11 12C11 7 15 3 20 3C25 3 29 7 29 12C29 17 25 21 20 21ZM20 5C16.1 5 13 8.1 13 12C13 15.9 16.1 19 20 19C23.9 19 27 15.9 27 12C27 8.1 23.9 5 20 5ZM22 17H18C17.4 17 17 16.6 17 16C17 15.4 17.4 15 18 15H19V9.6L18.4 9.9C17.9 10.1 17.3 9.9 17.1 9.5C16.9 9 17.1 8.4 17.5 8.2L19.5 7.2C19.8 7 20.2 7.1 20.5 7.2C20.8 7.3 21 7.7 21 8V15H22C22.6 15 23 15.4 23 16C23 16.6 22.6 17 22 17Z" fill="#0F0017"/>
  </svg>
</template>
