<template>
  <svg
    width="32"
    height="37"
    viewBox="0 0 32 37"
  >
    <path d="M29.0001 11.75L28.7701 11H17.2301L17.0301 11.75L15.0001 19.36V19.62C14.9201 23.75 17.9101 27.3 22.0001 27.93V35H18.9901C18.4401 35 18.0001 35.45 18.0001 36C18.0001 36.55 18.4501 37 19.0001 37H27.0001C27.5501 37 28.0001 36.55 28.0001 36C28.0001 35.45 27.5501 35 27.0001 35H24.0001V27.93C28.0801 27.3 31.0701 23.75 31.0001 19.62V19.37L29.0001 11.75ZM18.7701 13H27.2301L28.8101 19H17.2001L18.7701 13ZM23.0001 26C20.1101 25.93 17.6701 23.84 17.1501 21H28.8501C28.3301 23.84 25.8901 25.93 23.0001 26Z" fill="#0F0017"/>
    <path d="M11 34V35H10C10.55 35 11 34.55 11 34Z" fill="#0F0017"/>
    <path d="M3 35V34C3 34.55 3.45 35 4 35H3Z" fill="#0F0017"/>
    <path d="M8.00006 5V2H6.00006V5H8.00006ZM11.0001 18V15C11.0201 13.57 10.6201 12.16 9.84006 10.95C8.95006 9.79 8.34006 8.44 8.06006 7H5.94006C5.66006 8.44 5.04006 9.79 4.15006 10.95C3.37006 12.16 2.97006 13.57 3.00006 15V18H11.0001ZM11.0001 26V20H3.00006V26H11.0001ZM11.0001 35V28H3.00006V35H11.0001ZM13.0001 15V35C13.0001 36.1 12.1001 37 11.0001 37H2.90006C1.84006 36.94 1.00006 36.07 1.00006 35V15C0.960063 13.32 1.40006 11.66 2.26006 10.21C3.09006 8.83 4.00006 7.7 4.00006 6V1.5C4.00006 0.67 4.67006 0 5.50006 0H8.50006C9.33006 0 10.0001 0.67 10.0001 1.5V6C10.0501 7.3 10.5201 8.55 11.3301 9.57C12.4901 11.14 13.0801 13.05 13.0001 15Z" fill="#0F0017"/>
    <path d="M8 3.5V5H6V3.5V2H8V3.5Z" fill="#00FF86"/>
    <path d="M23.7702 13C23.7902 13.27 23.7902 13.53 23.7902 13.8C23.7902 15.55 23.6402 17.29 23.3502 19H17.2002L18.7702 13H23.7702Z" fill="#00FF86"/>
    <path d="M17.1501 21H22.9201C22.4001 22.64 21.4901 24.09 20.2401 25.25C18.6601 24.4 17.4901 22.86 17.1501 21Z" fill="#00FF86"/>
    <path d="M11 20V26H6.93C5.57 25.36 4.26 24.61 3 23.76V20H11Z" fill="#00FF86"/>
    <path d="M11.0001 15V18H3.00011V15C2.97011 13.57 3.37011 12.16 4.15011 10.95C5.04011 9.79 5.66011 8.44 5.94011 7H8.06011C8.34011 8.44 8.95011 9.79 9.84011 10.95C10.6201 12.16 11.0201 13.57 11.0001 15Z" fill="#00FF86"/>
  </svg>
</template>
