<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path d="M25.5001 6.5C26.5001 9.6 27.0001 12.8 27.0001 15.9C27.0001 18 26.8001 20.1 26.4001 22.1L26.3001 22.5C25.7001 25.1 24.1001 27.2 21.8001 28.6C20.8001 29.2 19.8001 29.6 18.7001 29.8C18.7001 29.5 18.7001 29.2 18.7001 28.9C18.7001 25.5 17.3001 23.8 16.2001 23.8L16.1001 23.7C13.8001 24.6 10.5001 25.7 7.3001 26.3C7.2001 26.3 7.1001 26.3 7.1001 26.3H7.0001C4.9001 25 2.9001 23.4 1.1001 21.5C1.3001 20.8 1.6001 20.1 2.1001 19.6L10.0001 11L3.0001 1L13.0001 7.2L12.0001 3H14.0001C17.4001 3 21.6001 3.9 25.5001 6.5Z" fill="#00FF86"/>
    <path d="M39 40H12C11.4 40 11 39.6 11 39C11 38.4 11.4 38 12 38H13C15.2 38 17.7 34 17.7 28.9C17.7 25.9 16.5 24.9 16.2 24.9C16.1 24.9 16.1 24.9 16 24.9C13.7 25.8 10.6 26.7 7.49997 27.4C7.39997 27.4 7.29997 27.4 7.19997 27.5L6.19997 27.7C4.49997 28.1 2.69997 27.6 1.39997 26.3C0.199969 25 -0.300031 23.2 0.0999691 21.5C0.299969 20.6 0.799969 19.8 1.39997 19.1L8.69997 11.1L2.19997 1.59999C1.89997 1.19999 1.99997 0.699993 2.29997 0.299993C2.59997 -7.00355e-06 3.09997 -0.100007 3.49997 0.099993L11.5 4.99999L11 3.19999C10.9 2.89999 11 2.59999 11.2 2.29999C11.4 2.09999 11.7 1.99999 12 1.99999H14C18.1 1.99999 22.4 3.29999 26.1 5.59999C29.1 7.49999 31.8 10.2 33.9 13.4C38 19.8 40 28.4 40 39C40 39.6 39.6 40 39 40ZM26.9 38H38C37.9 30.8 36.8 24.6 34.7 19.5C34.5 20.3 34.1 21.1 33.8 21.9C33.1 23.6 32.4 25.3 32.4 27.7V29C32.4 32.7 30 36 26.9 38ZM16.8 38H20.5C25.5 38 30.4 33.5 30.4 28.9V27.6C30.4 24.8 31.2 22.8 32 21.1C33 18.8 33.7 17.1 32.2 14.6C30.3 11.6 27.8 9.19999 25.1 7.39999C20.5 4.39999 16.2 3.99999 14 3.99999H13.3L14 6.89999C14.1 7.29999 13.9 7.69999 13.6 7.89999C13.3 8.09999 12.8 8.19999 12.5 7.89999L6.59997 4.39999L10.8 10.4C11.1 10.8 11 11.3 10.7 11.6L2.89997 20.3C2.49997 20.7 2.19997 21.2 2.09997 21.8C1.79997 22.9 2.09997 24 2.89997 24.8C3.59997 25.6 4.69997 25.9 5.79997 25.6L6.89997 25.4H6.99997C7.09997 25.4 7.09997 25.4 7.09997 25.4C11.8 24.4 16.5 22.6 18.3 21.7C19.8 20.9 20.8 20 21.1 18.8C21.4 17.6 21.1 16.2 20.2 14.5C19.9 14 20.1 13.4 20.6 13.1C21.1 12.8 21.7 13 22 13.5C23.3 15.7 23.6 17.6 23.1 19.4C22.6 21.1 21.3 22.4 19.3 23.5C19 23.6 18.7 23.8 18.4 23.9C19.3 24.9 19.9 26.6 19.9 28.9C19.7 32.6 18.6 36 16.8 38Z" fill="#0F0017"/>
  </svg>
</template>
