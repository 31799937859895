<template>
  <svg
    width="13"
    height="16"
    viewBox="0 0 13 16"
  >
    <path d="M2.24664 0H10.2502C11.3548 0 12.2502 0.895431 12.2502 2V13.5013C12.2502 13.7775 12.0264 14.0013 11.7502 14.0013H1.24664C1.24735 14.553 1.69479 15 2.24664 15H11.7502C12.0264 15 12.2502 15.2239 12.2502 15.5C12.2502 15.7762 12.0264 16 11.7502 16H2.24664C1.14207 16 0.246643 15.1046 0.246643 14V2C0.246643 0.895429 1.14207 0 2.24664 0ZM1.24664 13.0013H11.2502V2C11.2502 1.44772 10.8025 1 10.2502 1H2.24664C1.69436 1 1.24664 1.44771 1.24664 2V13.0013Z" />
    <path d="M6.24664 6.68347C7.07507 6.68347 7.74664 6.0119 7.74664 5.18347C7.74664 4.35504 7.07507 3.68347 6.24664 3.68347C5.41822 3.68347 4.74664 4.35504 4.74664 5.18347C4.74664 6.0119 5.41822 6.68347 6.24664 6.68347Z" />
    <path d="M4.69543 7.43347C4.17143 7.43347 3.74664 7.85826 3.74664 8.38226C3.74664 9.2295 4.32326 9.96801 5.1452 10.1735L5.20421 10.1883C5.88863 10.3594 6.60465 10.3594 7.28907 10.1883L7.34809 10.1735C8.17003 9.96801 8.74664 9.2295 8.74664 8.38226C8.74664 7.85826 8.32186 7.43347 7.79785 7.43347H4.69543Z" />
  </svg>
</template>
