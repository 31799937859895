<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3 22.1C26.8 20.1 27 18 27 15.9C27.075 14.7 26.9813 13.5 26.8875 12.3C26.8562 11.9 26.825 11.5 26.8 11.1C26.6398 10.0109 26.4181 8.93212 26.1381 7.86685C25.9733 7.23964 23.5 6 23.5 6L23.6 1.30002L17.5 1L15 7.5L8.5 4.5L4.5 9L7.5 15L1 18V21.3C1.5 23.5 5.5 24 5.5 24C5.5 24 6.84148 26.159 7.5 26.5606C9.58405 27.8317 11.7979 28.8483 14.1 29.6H14.2C16.1 30 17.9 30 19.7 29.4C20.4 29.2 21.1 28.9 21.7 28.5C23.9 27.2 25.5 25.1 26.2 22.5L26.3 22.1ZM20 23C21.6569 23 23 21.6569 23 20C23 18.3431 21.6569 17 20 17C18.3431 17 17 18.3431 17 20C17 21.6569 18.3431 23 20 23Z" fill="#00FF86"/>
    <path d="M20 23C18.3 23 17 21.7 17 20C17 18.3 18.3 17 20 17C21.7 17 23 18.3 23 20C23 21.7 21.7 23 20 23ZM20 15C17.2 15 15 17.2 15 20C15 22.8 17.2 25 20 25C22.8 25 25 22.8 25 20C25 17.2 22.8 15 20 15Z" fill="#0F0017"/>
    <path d="M37.8 22.3L32.6 22.9L32.4 23.5C32.1 24.5 31.7 25.4 31.3 26.3L31 26.9L34.3 30.9C33.3 32.2 32.2 33.3 31 34.2L26.9 31L26.3 31.3C25.4 31.8 24.5 32.2 23.6 32.4L22.9 32.6L22.4 37.7C20.8 37.9 19.4 37.9 17.7 37.7L17.1 32.5L16.5 32.3C15.5 32 14.6 31.6 13.7 31.2L13.1 31L9.1 34.3C7.8 33.3 6.7 32.2 5.8 31L9 26.9L8.7 26.3C8.2 25.4 7.8 24.5 7.6 23.6L7.4 22.9L2.3 22.4C2.1 21.6 2 20.8 2 20C2 19.3 2.1 18.5 2.2 17.7L7.4 17.1L7.6 16.5C7.9 15.5 8.3 14.6 8.7 13.7L9 13.1L5.7 9.1C6.7 7.8 7.8 6.7 9 5.8L13.1 9L13.7 8.7C14.6 8.2 15.5 7.8 16.4 7.6L17.1 7.4L17.6 2.3C19.2 2.1 20.6 2.1 22.3 2.3L22.9 7.5L23.5 7.7C24.5 8 25.4 8.4 26.3 8.8L26.9 9L30.9 5.7C32.2 6.7 33.3 7.8 34.2 9L31 13.1L31.3 13.7C31.8 14.6 32.2 15.5 32.4 16.4L32.6 17.1L37.7 17.6C37.8 18.4 37.9 19.3 37.9 20C38 20.7 37.9 21.5 37.8 22.3ZM39.7 16.5L39.6 15.8L34.3 15.3C34.1 14.6 33.8 14 33.5 13.4L36.7 9L36.3 8.4C35 6.5 33.3 4.9 31.4 3.6L30.8 3.2L26.7 6.6C26 6.3 25.4 6 24.7 5.8L24 0.4L23.3 0.3C20.8 -0.1 18.8 -0.1 16.4 0.3L15.7 0.4L15.2 5.7C14.5 5.9 13.9 6.2 13.3 6.5L9 3.3L8.4 3.7C6.5 5 4.9 6.7 3.6 8.6L3.1 9.2L6.5 13.3C6.3 14 6 14.6 5.8 15.3L0.4 16L0.3 16.7C0.1 17.9 0 19 0 20C0 21.1 0.1 22.3 0.3 23.5L0.4 24.2L5.7 24.7C5.9 25.4 6.2 26 6.5 26.6L3.3 31L3.7 31.6C5 33.5 6.7 35.1 8.6 36.4L9.2 36.8L13.3 33.4C13.9 33.7 14.6 34 15.2 34.2L15.9 39.6L16.6 39.7C17.9 39.9 19 40 20 40C21.1 40 22.3 39.9 23.5 39.7L24.2 39.6L24.7 34.3C25.4 34.1 26 33.8 26.6 33.5L30.9 36.8L31.5 36.4C33.4 35.1 35 33.4 36.3 31.5L36.7 30.9L33.3 26.8C33.6 26.2 33.9 25.5 34.1 24.9L39.5 24.2L39.6 23.5C39.9 22.1 40 21 40 20C40 18.9 39.9 17.7 39.7 16.5Z" fill="#0F0017"/>
  </svg>
</template>
