<template>
  <svg
    width="104"
    height="28"
    viewBox="0 0 104 28"
  >
    <path
      d="M20.7083 5.14069C16.2415 5.14069 12.7055 8.74529 12.7055 13.3804C12.7113 18.3201 16.0737 21.9338 21.3691 21.9338C25.3834 21.9338 27.3409 20.2612 27.3409 20.2612L26.4199 16.6379C26.4199 16.6379 24.2637 18.3685 21.5737 18.3685C17.1851 18.3685 16.6559 14.7291 16.6559 14.7291H28.0069C28.8278 9.54416 26.1295 5.14069 20.7083 5.14069ZM16.8179 11.4458C17.3213 9.98554 18.7153 8.74529 20.7934 8.74529C22.687 8.74529 23.9055 9.96747 24.2882 11.4458H16.8179Z"
    />
    <path
      d="M84.0245 5.14069C79.5577 5.14069 76.0217 8.74529 76.0217 13.3804C76.0217 18.3201 79.3899 21.9338 84.6847 21.9338C88.6983 21.9338 90.6558 20.2612 90.6558 20.2612L89.7348 16.6398C89.7348 16.6398 87.5786 18.3711 84.8899 18.3711C80.5013 18.3711 79.9721 14.731 79.9721 14.731H91.3238C92.1454 9.54416 89.4457 5.14069 84.0245 5.14069ZM80.1334 11.4458C80.6381 9.98554 82.0309 8.74529 84.109 8.74529C86.0026 8.74529 87.2211 9.96747 87.6038 11.4458H80.1334Z"
    />
    <path
      d="M10.6943 17.7785C10.6943 17.7785 9.83725 18.3412 8.74009 18.3412C7.34348 18.3412 7.0195 17.261 7.0195 15.3225V9.06707H10.6943V5.48183H7.0195V0.617615L3.1659 3.02649V5.48247H0.382355V9.06707H3.1659V15.8426C3.1659 21.2076 6.19921 21.9529 8.27993 21.9529C10.3316 21.9529 11.554 21.1973 11.554 21.1973L10.6943 17.7785Z"
    />
    <path
      d="M53.0162 6.24216C50.8116 4.79283 47.9712 4.96512 45.8292 5.69817C44.9931 5.98853 44.195 6.37852 43.4522 6.8597C41.1753 4.76895 37.8935 4.87736 35.4901 5.69817C32.5213 6.71516 30.7787 8.83753 30.7787 8.83753V21.6182H34.6433V9.81708C34.6433 9.81708 35.9147 8.70524 38.3633 8.70524C40.7248 8.70524 41.1172 9.99583 41.1172 12.1737V21.6182H44.9818V9.81708C44.9818 9.81708 46.2551 8.70524 48.7037 8.70524C51.0646 8.70524 51.4563 9.99583 51.4563 12.1737V21.6182H55.3209V10.7205C55.3209 8.94271 54.5013 7.22107 53.0162 6.24216Z"
    />
    <path
      d="M58.1741 8.80004C60.1348 6.85771 62.2149 5.14575 65.82 5.14575C69.9505 5.14575 73.6595 8.33672 73.6595 13.4855C73.6595 17.716 70.7811 21.9466 65.3599 21.9466V18.4272C67.7671 18.4272 69.762 16.6771 69.762 13.692C69.762 10.8179 67.9601 8.72325 65.3599 8.72325C64.1666 8.73535 63.0066 9.11878 62.0413 9.82025V27.3787H58.1741V8.80004Z"
    />
    <path
      d="M103.644 8.7226H101.484C100.29 8.73444 99.1298 9.11787 98.1641 9.8196V21.6072H94.297V8.80004C96.2583 6.85771 98.3384 5.14575 101.943 5.14575C102.515 5.14584 103.085 5.2075 103.644 5.32966V8.7226Z"
    />
  </svg>
</template>
