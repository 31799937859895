<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M23 11.9C23 14 22.8 16.1 22.4 18.1L22.3 18.5C21.7 21.1 20.1 23.2 17.8 24.6C15.5 26 12.9 26.4 10.3 25.7H10.2C7.4 24.8 4.6 23.5 2.1 21.8C1.4 20 1 18.1 1 16C1 7.7 7.7 1 16 1C17.9 1 19.7 1.3 21.4 2C22.5 5.2 23 8.6 23 11.9Z" fill="#00FF86"/>
    <path d="M16 32C7.2 32 0 24.8 0 16C0 7.2 7.2 0 16 0C24.8 0 32 7.2 32 16C32 24.8 24.8 32 16 32ZM16 2C8.3 2 2 8.3 2 16C2 23.7 8.3 30 16 30C23.7 30 30 23.7 30 16C30 8.3 23.7 2 16 2ZM15 19H10C9.4 19 9 18.6 9 18C9 17.4 9.4 17 10 17H15C15.4 17 16 16.8 16 16V7C16 6.4 16.4 6 17 6C17.6 6 18 6.4 18 7V16C18 18.2 16.2 19 15 19Z" fill="#0F0017"/>
  </svg>
</template>
