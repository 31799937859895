<template>
  <svg
    width="40"
    height="36"
    viewBox="0 0 40 36"
  >
    <path d="M16 20H10V14H16V20ZM17 12H9C8.45 12 8 12.45 8 13V21C8 21.55 8.45 22 9 22H17C17.55 22 18 21.55 18 21V13C18 12.45 17.55 12 17 12Z" fill="#0F0017"/>
    <path d="M22 30H18C17.45 30 17 30.45 17 31C17 31.55 17.45 32 18 32H22C22.55 32 23 31.55 23 31C23 30.45 22.55 30 22 30Z" fill="#0F0017"/>
    <path d="M23 12C22.45 12 22 12.45 22 13C22 13.55 22.45 14 23 14C23.55 14 24 13.55 24 13C24 12.45 23.55 12 23 12Z" fill="#0F0017"/>
    <path d="M27 12C26.45 12 26 12.45 26 13C26 13.55 26.45 14 27 14C27.55 14 28 13.55 28 13C28 12.45 27.55 12 27 12Z" fill="#0F0017"/>
    <path d="M31 12C30.45 12 30 12.45 30 13C30 13.55 30.45 14 31 14C31.55 14 32 13.55 32 13C32 12.45 31.55 12 31 12Z" fill="#0F0017"/>
    <path d="M23 16C22.45 16 22 16.45 22 17C22 17.55 22.45 18 23 18C23.55 18 24 17.55 24 17C24 16.45 23.55 16 23 16Z" fill="#0F0017"/>
    <path d="M27 16C26.45 16 26 16.45 26 17C26 17.55 26.45 18 27 18C27.55 18 28 17.55 28 17C28 16.45 27.55 16 27 16Z" fill="#0F0017"/>
    <path d="M31 16C30.45 16 30 16.45 30 17C30 17.55 30.45 18 31 18C31.55 18 32 17.55 32 17C32 16.45 31.55 16 31 16Z" fill="#0F0017"/>
    <path d="M23 20C22.45 20 22 20.45 22 21C22 21.55 22.45 22 23 22C23.55 22 24 21.55 24 21C24 20.45 23.55 20 23 20Z" fill="#0F0017"/>
    <path d="M27 20C26.45 20 26 20.45 26 21C26 21.55 26.45 22 27 22C27.55 22 28 21.55 28 21C28 20.45 27.55 20 27 20Z" fill="#0F0017"/>
    <path d="M31 20C30.45 20 30 20.45 30 21C30 21.55 30.45 22 31 22C31.55 22 32 21.55 32 21C32 20.45 31.55 20 31 20Z" fill="#0F0017"/>
    <path d="M39.96 27.6C39.77 26.69 38.97 26 38 26V2C38 0.9 37.1 0 36 0H26C24.9 0 24 0.9 24 2V4V6H4C2.9 6 2 6.9 2 8V26C1.03 26 0.23 26.69 0.04 27.6C0.01 27.73 0 27.86 0 28V34C0 34.41 0.13 34.8 0.34 35.12C0.48 35.33 0.67 35.52 0.88 35.66C1.2 35.88 1.58 36 2 36H38C38.41 36 38.8 35.87 39.12 35.66C39.33 35.52 39.52 35.33 39.66 35.12C39.88 34.8 40 34.42 40 34V28C40 27.86 39.99 27.73 39.96 27.6ZM26 5V3C26 2.45 26.45 2 27 2H35C35.55 2 36 2.45 36 3V6H26V5ZM4 25V9C4 8.45 4.45 8 5 8H35C35.55 8 36 8.45 36 9V26H4V25ZM38 33C38 33.55 37.55 34 37 34H3C2.45 34 2 33.55 2 33V29C2 28.45 2.45 28 3 28H37C37.55 28 38 28.45 38 29V33Z" fill="#0F0017"/>
    <path d="M16 8H5C4.45 8 4 8.45 4 9V19.49C7.24 22.29 11 24.35 15 25.6L15.09 25.64C17.66 26.27 20.32 25.87 22.59 24.5C24.02 23.64 25.19 22.45 26.01 21.04C26.01 21.03 26.01 21.01 26.01 21C26.01 20.65 26.2 20.36 26.46 20.18C26.72 19.62 26.93 19.03 27.09 18.41C27.09 18.41 27.09 18.4 27.09 18.39L27.18 18V17.96C27.13 17.97 27.07 18 27.01 18C26.46 18 26.01 17.55 26.01 17C26.01 16.45 26.46 16 27.01 16C27.19 16 27.34 16.06 27.49 16.14C27.6 15.32 27.69 14.5 27.74 13.68C27.56 13.88 27.3 14.01 27.01 14.01C26.46 14.01 26.01 13.56 26.01 13.01C26.01 12.46 26.46 12.01 27.01 12.01C27.33 12.01 27.61 12.17 27.79 12.41C27.82 10.94 27.74 9.47 27.56 8.01H16.01L16 8ZM18 21C18 21.55 17.55 22 17 22H9C8.45 22 8 21.55 8 21V13C8 12.45 8.45 12 9 12H17C17.55 12 18 12.45 18 13V21ZM23 22C22.45 22 22 21.55 22 21C22 20.45 22.45 20 23 20C23.55 20 24 20.45 24 21C24 21.55 23.55 22 23 22ZM23 18C22.45 18 22 17.55 22 17C22 16.45 22.45 16 23 16C23.55 16 24 16.45 24 17C24 17.55 23.55 18 23 18ZM23 14C22.45 14 22 13.55 22 13C22 12.45 22.45 12 23 12C23.55 12 24 12.45 24 13C24 13.55 23.55 14 23 14Z" fill="#00FF86"/>
    <path d="M16 14H10V20H16V14Z" fill="#00FF86"/>
    <path d="M26 4.00005V6.00005H27.24C27 4.76005 26.69 3.52005 26.29 2.30005C26.11 2.48005 26 2.73005 26 3.00005V4.00005Z" fill="#00FF86"/>
  </svg>
</template>
