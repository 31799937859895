<template>
  <svg
    width="22"
    height="40"
    viewBox="0 0 22 40"
  >
    <path d="M15.3 3.2751C15.6 4.0751 16 4.8751 16.2 5.5751L14 5.9751V3.9751L15.3 3.2751ZM2 0.975098L8 3.9751H14L13 7.9751L15.2 26.5751C14.5 27.3751 13.7 27.9751 12.9 28.5751C11 29.7751 8.8 30.1751 6.6 29.9751L9 7.9751L8 3.9751V5.9751L1 4.9751L2 0.975098Z" fill="#00FF86"/>
    <path d="M11 39.975C10.7 39.975 10.5 39.875 10.3 39.675L6 35.375C5.4 34.775 5 33.875 5.1 32.875L8 8.075L7.7 6.975L0.9 5.975C0.6 5.975 0.4 5.775 0.2 5.575C0 5.275 0 4.975 0 4.775L1 0.775C1.1 0.475 1.3 0.275 1.6 0.075C1.9 -0.025 2.2 -0.025 2.4 0.075L8.2 2.975H13.7L19.5 0.075C19.8 -0.025 20.1 -0.025 20.4 0.075C20.7 0.175 20.9 0.475 21 0.775L22 4.775C22 4.975 22 5.275 21.8 5.575C21.6 5.775 21.4 5.975 21.1 5.975L14.2 6.975L14 8.075L16.9 32.975C17 33.875 16.7 34.775 16 35.475L11.7 39.775C11.5 39.875 11.3 39.975 11 39.975ZM9.9 8.975L7.1 33.175C7.1 33.475 7.2 33.775 7.4 33.975L11 37.575L14.6 33.975C14.8 33.775 14.9 33.475 14.9 33.175L12.1 8.975H9.9ZM9.8 6.975H12.2L12.7 4.975H9.3L9.8 6.975ZM2.2 4.175L7 4.775V4.575L2.7 2.475L2.2 4.175ZM15 4.575V4.775L19.8 4.075L19.4 2.375L15 4.575Z" fill="#0F0017"/>
  </svg>
</template>
