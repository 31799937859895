<template>
  <svg
    width="28"
    height="40"
    viewBox="0 0 28 40"
  >
    <path d="M17 6H19.4C19.8 7.3 20.2 8.6 20.4 9.9C20.8 11.9 21 13.9 21 15.9C21 18 20.8 20.1 20.4 22.1L20.3 22.5C19.7 25.1 18.1 27.2 15.8 28.6C13.5 30 10.9 30.4 8.3 29.7H8.2C5.7 28.9 3.3 27.8 1 26.4V8C1 6.9 1.9 6 3 6H11" fill="#00FF86"/>
    <path d="M25 40H3C1.3 40 0 38.7 0 37V8C0 6.3 1.3 5 3 5H10V4C10 1.8 11.8 0 14 0C16.2 0 18 1.8 18 4V5H25C26.7 5 28 6.3 28 8V37C28 38.7 26.7 40 25 40ZM3 7C2.4 7 2 7.4 2 8V37C2 37.6 2.4 38 3 38H25C25.6 38 26 37.6 26 37V8C26 7.4 25.6 7 25 7H22V9C22 9.8 21.5 10.5 20.8 10.8C20.6 10.9 20.3 11 20 11H8C6.9 11 6 10.1 6 9V7H3ZM8 7V9H20V7H8ZM12 5H16V4C16 2.9 15.1 2 14 2C12.9 2 12 2.9 12 4V5Z" fill="#0F0017"/>
  </svg>
</template>
