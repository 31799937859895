<template>
  <svg
    width="34"
    height="40"
    viewBox="0 0 34 40"
  >
    <path d="M24 15.9C24 18 23.8 20.1 23.4 22.1L23.3 22.5C22.7 25.1 21.1 27.2 18.8 28.6C16.5 30 13.9 30.4 11.3 29.7H11.2C7.9 28.7 4.7 27.1 1.9 25C1.3 23.4 1 21.7 1 20V8.50002C1 7.70002 1.6 6.80002 2.3 6.40002C6.1 5.40002 9.8 4.10002 13.4 2.50002L16.6 1.10002C16.9 1.00002 17.2 1.00002 17.4 1.10002L20.6 2.50002C20.8 2.60002 20.9 2.60002 21.1 2.70002C23 6.70002 23.9 10.8 24 15C24 15.3 24 15.6 24 15.9Z" fill="#00FF86"/>
    <path d="M17 40C16.9 40 16.7 40 16.6 39.8999C16.1 39.6996 4.5 34.4931 1 25.2816C0.3 23.4793 0 21.7772 0 19.975V8.46058C0 7.25907 0.8 5.95745 1.9 5.45682H2C5.7 4.45557 9.4 3.15394 12.9 1.55194L16.1 0.150188C16.6 -0.0500626 17.2 -0.0500626 17.7 0.150188L21.4 1.75219C24.7 3.25407 28.3 4.45557 31.9 5.45682C32 5.45682 32 5.45682 32.1 5.55695L32.3 5.65707C33.3 6.1577 34 7.15895 34 8.36045V19.975C34 32.4906 18.1 39.6996 17.4 40C17.3 40 17.1 40 17 40ZM2.7 7.3592C2.3 7.55945 2 8.06007 2 8.46058V19.975C2 21.4768 2.3 23.0788 2.9 24.5807C5.8 32.0901 15 36.8961 17 37.8974C19.4 36.6959 32 30.1877 32 19.975V8.26033C32 7.85982 31.8 7.55945 31.5 7.3592L31.4 7.25907C27.7 6.25782 24.1 5.05632 20.7 3.55444L20.5 3.45432C20.4 3.45432 20.3 3.35419 20.2 3.35419L17 1.95244L13.8 3.35419C10.2 4.9562 6.5 6.25782 2.7 7.3592ZM14 25.9825C13.7 25.9825 13.5 25.8824 13.3 25.6821L7.3 19.6746C6.9 19.2741 6.9 18.6733 7.3 18.2728C7.7 17.8723 8.3 17.8723 8.7 18.2728L14 23.5795L26.3 11.2641C26.7 10.8636 27.3 10.8636 27.7 11.2641C28.1 11.6646 28.1 12.2653 27.7 12.6658L14.7 25.6821C14.5 25.8824 14.3 25.9825 14 25.9825Z" fill="#0F0017"/>
  </svg>
</template>
