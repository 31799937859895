import * as Sentry from '@sentry/vue'

type SentryConfigArgs = {
  router: VueRouter,
  sentryDsn: string,
  enableSentry: boolean
  normalizeDepth: number
}

export default {
  install: (app, { router, sentryDsn, enableSentry, normalizeDepth }: SentryConfigArgs) => {
    if (enableSentry) {
      Sentry.init({
        app,
        dsn: sentryDsn,
        sampleRate: 1,
        normalizeDepth: Number(normalizeDepth),
        tracesSampleRate: 1.0,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
          Sentry.replayIntegration(),
        ],
      })
      app.provide('sentry', Sentry)
    }
  }
}
